@import '../../styles/mixins.less';

.device-quick-view-widget {
  overflow: auto;
  overflow-x: hidden !important;
  padding-bottom: 0px;
  margin-bottom: 20px;
  .small-flex-lines;
  .device-high-low-graphs{
    .line{
      &:nth-child(even) {
        background-color: var(--color-bg-table-widget-line-alt);
      }
      &:nth-child(odd){
        background-color: var(----color-text-main);
      }
    }
  }
  .line{
    padding: 6px 10px 5px;
    .label {
      text-transform: capitalize;
    }
    .high-lows{
      display: flex;
      .slash{
        font-size: 1.3rem;
        display: inline-block;
        padding: 0 0px 0 4px;
      }
    }
    &.yesterday{
      > .label {
        display: none;
      }
      .device-from-yesterday{
        width: 100%;
        .from{
          padding-top: 0px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          .fdp{
            padding-left: 0px;
            &:before{
              display: none;
            }
          }
        }
      }
    }
  }
}
.device-widget.view{
  display: flex;
  flex-direction: column;
  .graph{
    display: none;
  }
}

@media (max-width: @phone-max) {
  .device-quick-view-widget {
    padding-top: 5px;
  }
  
}