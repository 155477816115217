@import '../containers/style.less';
@import '../styles/mixins.less';
@import '../styles/global.less';
@import '../styles/themes/index.less';
@import '../features/device/style.less';
@import '../features/social/style.less';

.component-stand-alone-loader {
    font-family: 'Ubuntu', sans-serif !important;
    padding: 40px;
    //device popup card
    .wind {
      .content {
        display: flex;
        justify-content: space-evenly;
        height: 200px;
      }
    }
    .device-device-popup {
      //max-width: 670px;
      max-width: 100%;
      margin: 0 auto;
      width: 100vw;
      //background: @gray-light-amb;
      border: 1px solid @gray-light-amb;
      .top {
        padding: 10px 20px 0 20px;
        .meta {
          line-height: 1.3;
          align-items: center;
          font-size: 1.1em;
          .user-avatar {
            width: 70px;
            height: 70px;
            background: center center no-repeat;
            background-size: 100%;
            border-radius: 100%;
            border: 2px solid var(--color-bg-widget-main); 
            margin-right: 20px;
          }
        }
        .ago {
          font-size: 1em;
          flex-direction: initial;
          align-items: center;
          .utility,
          .long,
          .short {
            display: none;
          }
          .full{
            display: block;
          }
        }
      }
      .content {
        .device-temp-widget {
          //background: #ddd;
          padding: 0;
          .body {
            display: flex;
            justify-content: space-evenly;
            height: 200px;
            //background: pink;
            .top {
              padding: 0;
              align-self: center;
              line-height: initial;
              //width: 50%;
              .fdp-val, .suff {
                font-size: 5em;
                line-height: 1;
              }
            }
            .bottom {
              padding: 0;
              align-self: center;
              //width: 50%;
              .label {
                font-size: 1em;
                font-weight: normal;
                color: var(--color-text-main-lt);
                padding-bottom: 6px;
              }
              .fdp-val, .suff {
                font-size: 2em;
                color: @blue;
              }
              >div {
                margin: 0;
                padding: 0px 15px;
                border-width: 2px;
              }
            }
          }
        }
        .wind-widget {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: flex-start;
          padding: 0;
          .rel {
            width: 60%;
            .compass {
              //margin: 10px auto;
              margin-top: -5px;
              background-size: 96%;
              .compass-create(190px);
            }
            .val {
              width: 100%;
              top: 60px;
            }
          }
          .corner-wrap {
            position: initial;
            box-sizing: border-box;
            top: 0;
            left: 0;
            text-align: center;
            align-self: center;
            border-right: 2px solid #cdd4da;
            color: #97A2AD;
            font-size: 0.8em;
            line-height: 1.2;
            padding: 0 15px;
            font-weight: normal;
            //height: 130px;
            //min-height: 130px;
            &.right {
              //padding-left: 0;
              border: none;
            }
            .fdp {
              font-size: 2em;
              font-weight: normal;
              &.winddir {
                display: block;
                &.degrees {
                  display: none;
                }
              }
            }
          }
        }
        .rain-widget {
          height: 200px;
          .last-rain {
            font-size: 1em;
            color: #97A2AD;
            line-height: 1.2;
            font-weight: normal;
          }
          .outer-wrap {
            .rain-wrap {
              padding: 0 2% !important;
              .wrap {
                //padding-bottom: 15px !important;
                .fdp-val,.suff {
                  font-size: 1.3em;
                }
                .label {
                  font-size: 1em;
                  color: var(--color-text-main-lt);
                }
              }
            }
            .fdp {
              color: @blue;
            }
          }
        }
      }

      > .bottom {
        // display: none;
        padding: 25px 20px;
        a.btn-primary {
          padding: 10px 25px;
          font-size: 1em;
        }
      }
      .social-comments {
        display: none;
      }
      &.social{
        > .bottom{
          display: none;
        }
        .social-post{
          max-height: 210px;
          overflow: hidden;
          a.img{
            height: 210px !important;
            background-size: cover;
          }
          .expiration{
            display: none;
          }
        }
      }
    }
    .device-widget {
      //background: #f4f4f4;
      border: 1px solid #313236;
      border-radius: 5px;
      width: 100%;
      height: 340px !important;
      .buttons-wrap {
        display: none;
      }
      .indoor-widget {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        justify-content: center;
        background-color: pink;
        .top, .bottom {
          display: flex;
          flex-direction: column;
          flex-basis: 100%;
          flex: 1;
          padding: 0;
        }
        .top {
          .wrap {
            > div {
              &:last-child {
                //display: none;
              }
            }
            .label {
              //display: none;
              font-size: 0.7em;
            }
          }
        }
        .bottom {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .label {
            color: #97A2AD;
            font-size: 0.8em;
            line-height: 1.2;
            font-weight: normal;
          }
          .val {
            font-size: 1.8em;
          }
        }
      }
      
    }

    .device-sun-moon-widget .wrap .info{
      width: 100%;
    }
  .device-widget.temp1f,
  .device-widget.temp2f,
  .device-widget.temp3f,
  .device-widget.temp4f,
  .device-widget.temp5f,
  .device-widget.temp6f,
  .device-widget.temp7f,
  .device-widget.temp8f,
  .device-widget.temp9f,
  .device-widget.temp10f,
  .device-widget.soiltemp10,
  .device-widget.indoor{
    .soil-widget,
    .indoor-widget{
      .top {
        .fdp{
          //font-size: 2.8em;
          //line-height: 1.2;
        }
        .wrap > div{
          //padding-top: 10px;
          //padding-bottom: 10px;
        }
      }
      .bottom{
        //font-size: 22px;
      }
    }
  }
}

