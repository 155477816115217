@import '../../styles/mixins.less';

.user-default-page {
  .user-signup{
    .form-center;
    text-align: center;
    form{
      font-size: 19px;
      padding-top: 15px;
      input[type="email"],
      input[type="text"],
      input[type="password"]{
        width: 100%;
      }
    }
    .terms{
      width: auto;
      height: 415px;
      background-color: @white;
    }
    .ReactPasswordStrength{
      width: 100%;
    }
    input.btn-primary{
      margin-top: 40px;
    }
    div.error,
    p.username-message{
      padding-top: 5px;
      text-align: left;
      font-size: 0.75em;
      padding-bottom: 0;
    }
  }
  .jumbotron{
      background-color: transparent;
      text-align: center;
      //color: @text-dark;
      padding: @bigPad;
      p{
        font-size: 1.1em;
        &:first-of-type{
          margin-bottom: 30px;
        }
      }
      h1{
        sup{
          top: -41px;
        }
      }
  }
  .open-terms{
    display: inline-block;
    margin-top: 15px;
    font-size: 1.3rem;
  }
  .terms{
    position: fixed;
    top: 100px;
    left: 0;
    margin: 0 100px;
    height: calc(~"100vh - 200px");
    border: solid 1px @blue;
    padding: @generalPad; 
    background-color: @white;
    overflow: auto;
    z-index: 20;
    h2{
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .close{
      display: block;
      width: 24px;
      height: 24px;
      background: url(../../assets/plus-blue.svg) center center no-repeat;
      background-size: 80%;
      transform: rotate(45deg);
      position: fixed;
      right: 100px;
      top: 75px;
    }
  }

}
@media (max-width: @phone-max) {
  .user-default-page {
    .terms{
      margin: 0 20px;
      .close{
        right: 20px;
      }
    }
    .jumbotron h1 sup{
      top: -1.6em;
    }
  }
}
