@import '../../styles/mixins';

.payment-enhanced-cam-modal {
  margin-bottom: 1em;
  .buyBtn {
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p{
      padding-top: 10px;
    }
  }

  .btn.btn-primary.buy-plan {
    font-size: 2rem;
    width: 50%;
    margin: 0 auto;
  }


  header {
    text-align: center;
    margin-bottom: 2.3em;
    padding-top: 1em;

    h2 {
      border-bottom: 0 !important;
      padding-bottom: 0.1em !important;
      font-weight: 500 !important;

      @media (max-width: @phone-max) {
        margin-top: 1em !important;
      }
    }

    
    strong {
      font-weight: 500;
    }
  }
  
}
header{
  .term {
    color: green;
    font-size: 1.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    span{
      margin-left: 10px;
    }
    width: 100%;
  }
}
    // justify-content: space-between;
