@import '../../styles/mixins';

.payment-all-payments .payment-form-field {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;

  

  &:first-of-type {
    border-top: none;
  }
  label {
    width: 20%;
    min-width: 70px;
    padding: 11px 0;
    color: #c4f0ff;
    overflow: hidden;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-right: 1px solid #819efc;
  }
  input {
    font-size: 16px;
    width: 100%;
    padding: 11px 15px 11px 8px;
    color: #000;
    background-color: transparent;
    animation: 1ms void-animation-out;
  
    &::placeholder {
      color: #87bbfd;
    }
  }
}

