@import '../../styles/mixins.less';

.alert-alert-list {
  .light;
  padding: @bigPad 0 0;
  font-size: 1.1em;
  h3{
    color: var(--color-text-main-lt);
    font-size: 12px;
  }
  .alert-group{
    margin-bottom: @bigPad;
  }
  .line{
    margin-bottom: @generalPad;
    a{
      display: inline-block;
      margin-right: 10px;
      color: @text-dark;
      font-size: 0.9em;
      &.ifttt{
        width: 15px;
        height: 15px;
        background: url(../../assets/ifttt.png) center center no-repeat;
        background-size: 100%;
        display: inline-block;
      }
    }
    .leak{
      display: inline-block;
      position: relative;
      top: 6px;
      width: 20px;
      height: 20px;
      background: url(../../assets/common/leak-black.svg) center center no-repeat;
      background-size: auto 100%;
      margin-right: 6px;
    }
  }

}
