@import './variables.less';
@import './mixins.less';


.rel{
  position: relative;
}
.component-sitewide-modals,
.share-modal{
  .ReactModal__Overlay{
    z-index: 1001;
  }
}
.react-switch-bg{
  // background-color: @blue !important;
}
.ReactModal__Overlay{
  z-index: 999;
  .close{
    float: right;
    text-decoration: none;
    margin-bottom: 10px;
  }
}
.fsp-modal__body{
  padding-top: env(safe-area-inset-top, 0) !important;
}
.fsp-picker__close-button{
  top: 13px;
  top: calc(~"env(safe-area-inset-top, 0px) + 13px") !important;
}
.app .alert{
  border-radius: @br;
  border-width: 2px;
  font-weight: 500;
  padding: 4px 15px;
  font-size: 1.1em;
  &.alert-danger{
    background-color: rgba(234, 117, 121, 0.2);
    border-color: @pink;
    color: @pink;
    a{
      color: @white;
    }
  }
  a{
    font-weight: 400;
  }
}

html{
  font-size: 10px;
  height: 100%;
}
body{
  background-color: @bg !important;
  font-size: 14px;
  .app{
    color: @gray-dark-amb;
  }
  h1 {
    font-size: 24px;
    font-weight: bold;
    sup{
      font-size: 12px;
      top: -2em;
    }
  }

  h2 {
    font-size: 22px;
    font-weight: bold;
  }

  h3 {
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 @generalPad;
  }

  h4 {
    font-size: 18px;
    font-weight: 300;
    margin: 0 0 @generalPad;
  }

  h5 {
    font-size: 16px;
    font-weight: bold;
  }
  pre{
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  h6 {
    font-size: 14px;
    font-weight: bold;
  }

  a{
      cursor: pointer;
      text-decoration: underline;
      &.glyphicon{
        text-decoration: none;
      }
      &.edit{
        font-size: 0.9em;
        color: @gray-amb;
        font-weight: 300;
        text-decoration: none;
      }
      &.dark{
        color: @text-dark;
      }
  }
  button{
    &.a{
      border: none;
      background-color: transparent;
      color: var(--brandColor);
      text-decoration: none;
      outline: none;
      padding: 0px;
      &:active,
      &:hover{
        color: @blue-darker;
      }
    }
  }
  .warning{
    color: @pink;
    font-weight: 400;
  }
}
#root{
  // overflow: hidden;
}
form {
  width: 100%;
  margin: 0px auto;
  padding: 3em 2em;
  color: #555;
}
body .has-error {
  .form-control:focus,
  .form-control{
    border-color: @red;
    border-width: 2px;
    box-shadow: none;
    color: @red;
  }
  .help-block{
    color: @red;
  }
} 
fieldset {
  border: 0;
  max-width: 580px;
  margin: 0 auto;
}

label {
  font-size: 12px;
  text-transform: uppercase;
  display: block;
  padding-top: 0;
  margin-top: 0;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  line-height: 1.5;
  height: auto;
  border-radius: @br;
  font-size: 1.1em;
  padding: 5px 15px 6px;
  vertical-align: middle;
  outline: none;
  display: inline;
  width: auto;
  border: solid 1px @blue-dark;
  &:focus {
    border: 1px solid @text-dark;
    outline: none;
    box-shadow: none;
  }
  &.boxy{
    border-radius: 0;
    border-color: @blue-dark;
    border-width: 1px;
    height: 30px;
    padding: 5px 10px;
  }
}
select.form-control{
  border-radius: @br;
  border: solid 1px @blue-dark;
  -webkit-appearance: none;
  .rel;
  background: url(../assets/down-carrot.svg) ~"calc(100% - 10px)" 10px no-repeat;
  height: 30px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 30px;
  font-size: 1.1em;
  option{
    color: @gray-amb;
  }
  &:hover{
    border-color: var(--brandColor);
  }
  &:focus{
    border: 1px solid @text-dark;
    outline: none;
    box-shadow: none;
  }
  &.carrot-gray{
    background-image: url(../assets/down-carrot-gray.svg);
  }
}
.sitename{
  &:before{
    content: 'Ambient Weather';
  }
}

.passwordField {
  width: 100%;
}

.passwordField__strength {
  color: #999;
}
.hidden{
  display: none;
}
body{
  .pagination{
    > li {
      .round-edge-children(@br);
      > a{
        .btn-circle;
      }
      &.active a{
        color: #fff;
        background-color: var(--brandColor);
        &:hover{
          background-color: var(--brandColor);
          border-color: @gray-lighter;
        }
      }
    }
  }
  .nav > li > a:hover,
  .nav > li > a:focus{
    background-color: transparent;
  }
}
.private,
.public{
  font-weight: bold;
  color: @text-dark;
}
.public{
  color: @green-dark;
}

.page-body{
  padding: @bigPad;
}
.form-center{
  form{
    max-width: 400px;
    margin: auto;
    display: block;
    > h4:first-of-type{
      margin-top: 0;
    }
  }
  h4{
    margin-top: 20px;
    margin-bottom: 10px;
  }
  input[type="password"]{
    width: 100%;
  }
  .row{
    margin-left: 0px;
    margin-right: 0px;
  }
  p{
    padding-top: 15px;
    font-size: 0.95em;
  }
  .btn{
    margin-top: 10px;
  }
}
.alexa-logo{
  background: url(../assets/alexa-white.svg) center center no-repeat;
  background-size: 100%;
}
.ifttt-logo{
  background: url(../assets/ifttt-white.svg) center center no-repeat;
  background-size: 100%;
}
.badge{
  display: inline;
  color: @white;
  background-color: @gray-light-amb;
  padding: 3px 15px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 12px;
  &:after{
    content: 'OFF'
  }
  &.on{
    background-color: @green-dark;
    &:after{
      content: 'ON';
    }
  }
}
.device-sub-head{
  color: var(--color-text-main-lt);
  padding-right: 0;
  padding-left: 0;
  padding-top: 10px;
  .item{
    float: left;
    .label,
    label{
      text-transform: none;
      font-weight: normal;
      font-size: 1.4rem;
      display: inline-block;
      margin-right: 3px;
    }
    > span{
      font-weight: bold;
      display: inline-block;
      margin-right: 25px;
    }
    input.editable-title{
      position: relative;
      top: -1px;
      color: @text-dark;
    }
    &.tz{
      position: relative;
      width: 268px;
      .component-timezone-picker{
        position: absolute;
        left: 68px;
        bottom: 0;
        display: inline;
        .css-2o5izw:hover,
        .css-2o5izw,
        .css-vj8t7z{
          border-radius: 0;
          border: none;
          border-bottom: 1px solid transparent;
          font-size: 1em;
          font-weight: bold;
          padding: 0 0 0px 0;
          width: 200px;
          background: none;
        }
        .css-vj8t7z:hover,
        .css-2o5izw:hover{
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
  .type-info-location,
  .tz-select__single-value,
  .css-xp4uvy{
    color: var(--color-text-main-lt) !important;
  }
  .css-11unzgr{
    max-height: 100px;
  }
}
.glyphicon{
  &.x-gray{
    display: inline-block;
    width: 15px;
    height: 15px;
    background: url(../assets/x-gray.svg) center center no-repeat;
  }
}

.img-contain{
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.img-cover{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.beta{
  &:before{
    content: 'BETA';
    font-size: 9px;
    color: @white;
    background-color: #E6A923;
    border-radius: 8px;
    padding: 1px 6px;
    margin-right: 5px;
    bottom: 1px;
    position: relative;
  }
}
.create-post-btn{
  @w: 50px;
  position: fixed;
  .btn-primary-active-hover-colors;
  .btn-circle;
  .general-shadow;
  background: url(../assets/plus.svg) no-repeat center center;
  background-size: 100%;
  border: none;
  width: @w;
  height: @w;
  border-radius: 100%;
  display: block;
  right: @bigPad;
  bottom: @bigPad;
  z-index: 100;

}
.notifications-component  {
  display: none;
  z-index: 400;
}
.app.home-page:not(.forecast-open){
  .notifications-component {
    display: block;
    position: fixed;
    z-index: 522;
  }
}
.app {
  .notification__timer{
    margin-top: 0;
    opacity: 0;
  }
  .notification-container--top-left {
    left: @generalPad;
    top: 70px;
    @media (max-width: @tablet-max) {
      top: @generalPad;
      top: calc(~"env(safe-area-inset-top, 0) " + @generalPad);
    }
    .notification {
      // height: auto !important; // this was causing problems, not sure why it's here? olw 11.16.22
      @media (max-width: @tablet-phone-max) {
        max-width: 275px;
      }
    }
  }
  .notification-container--bottom-right {
    @media (max-width: @tablet-phone-max) {
      bottom: 65px;
      right: 0;
    }
    bottom: 170px;
    right: 70px;
    transition: all 0.2s ease-out;
    .notification {
      // height: auto !important;
      border-radius: 20px;
      @media (max-width: @phone-max) {
        margin-left: @generalPad;
      }
    }
  }
  .notification__item--default {
    @media (max-width: @phone-max) {
      border-top-left-radius: 0;
      border-bottom-right-radius: 22px;
    }
    @media (min-width: 768px) and (max-width: @tablet-phone-max){
      border-bottom-left-radius: 0;
      border-top-left-radius: 22px;
      border-bottom-right-radius: 22px;
    }
    background-color: var(--color-bg-notification-main);
    box-shadow: 0px 4px 4px rgba(@black, 0.15);
    border-left: none;
    max-width: 350px;
    border-radius: 22px;
    border-bottom-right-radius: 0;
    .notification__message {
      line-height: 130%;
      width: auto !important;
      a {
        color: var(--color-text-lt);
        text-decoration: none;
        font-size: 1em;
        font-weight: 400; 
        display: block;
      }
    }
    .notification__content {
      padding: 10px 15px;
    }
  }
  //post notifications
  .notification__item--info {
    max-width: 350px;
    box-shadow: none;
    border: none;
    background-color: transparent;
    .favstar, .component-share {
      display: none;
    }
    .notification__message {
      max-width: 100%;
    }
    .notification__content {
      padding: 0;
      width: 350px !important;
      position: relative;
     
      .notification__close-mark {
        right: -17px;
      }
      .notification__close{
        z-index: 4;
        background-color: transparent;
        &:after{
          color: #000;
        }
      }
    }
    .device-device-popup > .bottom {
      background-color: var(--color-bg-card-content-main);
      padding: 0;
      .btn.btn-primary {
        background-color: var(--color-bg-card-content-main);
        color: @blue;
        padding-right: 0;
      }
    }
    .device-device-popup.social.boosted {
      box-shadow: none;
      border: none;
      .general-shadow;
      width: auto !important;
      .social-comments, .component-like, .expiration {
        display: none;
      }
      .bottom .btn-primary {
        color: transparent;
        position: absolute;
        top: 0px;
        width: 100%;
        height: 125px;
        display: block;
        z-index: 10;
        background-color: transparent;
      }
      .social-post {
        min-height: auto;
        .text {
          margin-top: 0;
          background-color: transparent;
        }
        &.has-img a.img {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
      .top {
        //border-bottom: none;
        //justify-content: left;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        .device-name-wrap {
          //display: none;
        }
  
      }
      .top .ago {
        .component-from-now {
          margin-top: 24px;
        }
        .utility {
          display: none;
        }
      }
    }
    .top .meta {
      //margin-top: 1em;
      .user-avatar {
        //width: 58px !important;
        //height: 58px !important;
        //margin-right: 15px !important;
        //margin-bottom: .5em !important;
      }
      .device-name-wrap {
       // font-size: 18px;
        //line-height: 1.2;
        padding-left: 5px;
      }
    }
    .notification__close-mark {
      background-color: var(--color-bg-card-content-main);
      z-index: 99;
      &:after {
        color: @text-light;
        font-size: 30px;
        font-weight: 300;
      }
    }
  }
}

.app {
  &.sidebar {
    .notification-container--bottom-right {
      right: 420px;
      bottom: 185px; 
      @media (max-width: @tablet-max) {
        right: 250px;
        bottom: 80px;
      }
    }
  }
}


@media (max-device-width: @phone-max) {
  body{
    .pagination{
      > li {
        .round-edge-children(40px);
        > a{
          font-size: 1.7rem;
          padding: 5px 15px;
        }
      }
    }
    .create-post-btn{
      @b: @mobile-nav-height + @generalPad;
      bottom: @b;
      bottom: calc(~"env(safe-area-inset-bottom, 0) + " @b);
      right: @generalPad;
    }
    .notification-container--top-left{
      top: 20px;
    }
  }
}
@media (max-height: 600px) {
  .user-default-page{
    .component-help-links{
      display: none;
    }
  }

}
