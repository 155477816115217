@import '../../styles/mixins.less';

.user-signup {
    position: relative;
    .plus-13{
      padding-top: 10px;
      label{
        font-weight: normal;
        text-transform: none;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        > span{
          display: inline-block;
          padding-left: 5px;
          position: relative;
          text-align: left;
          top: 2px;
        }
      }
    }

    .terms{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: @bg;
        z-index: 20;
        .component-terms-of-use{
          line-height: 1.8;
          text-align: left;
          h2{
              font-size: 2rem;
          }
          height: 267px;
          overflow: auto;
        }
    }
    .username-wrap{
      position: relative;
      .component-loader{
        position: absolute;
        top: 18px;
        right: 6px;
      }
    }
    .terms-btn{
      a{
        font-size: 0.75em;
        text-decoration: none;
      }
    }
    p.username-message{
      padding-top: 5px;
      text-align: left;
      color: @color-alert;
      font-size: 0.75em;
      &.ok{
        color: @color-ok;
      }
    }
    .agree{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0 25px;
      .btn{
        margin-top: 0px !important;
        margin-right: 15px;
      }
    }
    .passwordField__info{
        bottom: -25px !important;
    }
    .passwordField{
        margin-bottom: 20px;
    }
    .ReactPasswordStrength{
      border: none;
      display: inline-block;
        font-size: 19px;
    }
    .ReactPasswordStrength-strength-desc{
      top: -3px;
      right: -36px;
    }
}
