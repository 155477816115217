@import '../../styles/mixins';

.app.dashboard.v4 .common-menu {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-widget-main);
  > a {
    text-decoration: none;
    padding: @generalPad @bigPad;
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--color-text-main);
  }
  .component-dashboard-nav{
    display: block;
    width: 100%;
    .wrap{
      position: relative;
      left: 0px;
      top: 0px;
      width: 100%;
      background-color: var(--color-bg-widget-main);
      a.account{
        padding-top: 30px;
        height: auto;
        justify-content: flex-start;
        padding-bottom: 10px;
      }
      a.logout{
        padding-top: 30px;
      }
    }
  }
}
.app.dashboard.v4.common-menu{
  .page-container{
    background-color: var(--color-bg-page-block-main);
  }
}