@import '../../styles/mixins.less';

.device-widget.gdd {
  text-align: center;
  .column;
  .device-grow-days-widget {
    justify-content: center;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .from {
    padding: 0.5rem 1rem;
  }
  .growdays {
    .fdp-val {        
      font-size: 10vw;
      font-size: clamp(2em, 10vw, 3em);
      color: var(--color-text-widget-big-number);
    }
    .suff{
      font-size: 8vw;
      font-size: clamp(1.5em, 8vw, 1.3em);
      color: var(--color-text-widget-big-number);
    }
  }
  .from {
    .label{
      font-size: 2vw;
      font-size: clamp(0.7em, 2vw, 0.8em);
      margin-bottom: 0.4em;
    }
    .fdp {
      font-size: 2vw;
      font-size: clamp(0.9em, 2vw, 1em);
    }
  }
}

@media (max-width: @tablet-max) {
  .device-widget.gdd {
    
    .growdays {
      .fdp-val {
        font-size: 15vw;
        font-size: clamp(1em, 15vw, 4em);
      }   
      .suff{
        font-size: 10vw;
        font-size: clamp(0.8em, 10vw, 1.4em);
      }    
    }
    .from {
      .label{
        font-size: 1vw;
        font-size: clamp(0.9em, 1vw, 1.5em);
      }
      .fdp {
        font-size: 1vw;
        font-size: clamp(1.2em, 1vw, 2.2em);
      }
    }
  }

}

@media (max-device-width: @phone-max) {
  .device-widget.gdd {
     
    .growdays {
      .fdp-val {
        font-size: 20vw;
        font-size: clamp(1em, 20vw, 4em);
      }
      .suff{
        font-size: 10vw;
        font-size: clamp(0.8em, 10vw, 1.2em);
      }
    }
  }
}

