@import '../../styles/mixins.less';

.relays-widget {
  .dot{
    background-color: @white;
    .general-shadow;
    display: block;
    width: 15px;
    height: 15px;
    margin: 4px 0px 0 7px;
    border-radius: 100%;
    float: left;
    &.on{
      background-color: @green;
    }
  }
  .line{
    input{
      float: none !important;  
    }
  }

}
.device-widget.relays{
  .no-graph-button;
}
@media (max-width: @tablet-max) {
  
}
