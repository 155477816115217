@import '../../styles/mixins.less';

.user-settings {
  font-size: 1.1em;
  font-weight: 300;
  .line{
    display: flex;
    padding: @generalPad 0;
    border-top: 1px solid #e3e3e3; 
    &:last-child{
      border-bottom: 1px solid #e3e3e3; 
    }

    > div {
      display: flex;
      flex-grow: 1;
      &:first-child{
        flex-grow: 0;
        width: 140px;
      }
      &.options{
        > div {
          float: left;
          width: 10%;
          margin-right: 50px;
          > div{
            &:first-child{
              width: 50px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: @phone-max) {
  .user-settings{
    .line{
      margin-left: 0px;
      display: block;
      padding-bottom: 0px;
      > div {
        margin-bottom: 10px;
        &.options{
          flex-wrap: wrap;
          > div {
            width: 27%;
            margin-bottom: 5px;
          }
        }
      }
    }
    
  }
}