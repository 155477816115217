@import '../../styles/mixins.less';

.heatindex-widget {
    > .fdp {
      font-size: 3.3em;
      .suff{
        vertical-align: top;
      }
    }

  }
  .device-widget.heatindex{
    .column;
  }