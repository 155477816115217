@import '../../styles/mixins';

.common-map-legend {
  .common-map-legend-items{
    display: flex;
    flex-direction: column-reverse;
  }
  .common-map-legend-item{
    display: flex;
    align-items: center;
    &:last-of-type{
      .common-map-legend-item-color{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
    &:first-of-type{
      .common-map-legend-item-color{
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
  .common-map-legend-item-color{
    width: 30px;
    height: 40px;
    margin-right: 10px;
    display: inline-block;
  }
}
.info{
  margin-top: 25px;
}
.horizontal-map-legend-items{
  display: flex;
  flex-direction: row-reverse;
  .common-map-legend-item{
    flex: 1;
    .common-map-legend-item-color{
      width: 20px;
      height: 12px;
      margin-right: 0;
    }
    &:last-of-type{
      .common-map-legend-item-color{
        border-radius: 10px 0px 0px 10px;
      }
    }
    &:first-of-type{
      .common-map-legend-item-color{
        border-radius: 0px 10px 10px 0px;
      }
    }
  }
}
.horizontal-map-legend-items-reverse{
  .horizontal-map-legend-items;
  flex-direction: row;
  .common-map-legend-item{
    &:last-of-type{
      .common-map-legend-item-color{
        border-radius: 0px 10px 10px 0px;
      }
    }
    &:first-of-type{
      .common-map-legend-item-color{
        border-radius: 10px 0px 0px 10px;
      }
    }
  }
}
.general-modal{
  .common-map-legend {
    &.snow,
    &.wind,
    &.temp{
      h3{
        margin-bottom: 25px;
      }
      .common-map-legend-items{
        .horizontal-map-legend-items;
      }
      .common-map-legend-item{
        flex-direction: column;
        .common-map-legend-item-color{
          width: 100% !important;
          margin-bottom: 2px;
        }
      }
    }
    &.temp{
      .common-map-legend-item{
        &.i-17,
        &.i-15,
        &.i-13,
        &.i-11,
        &.i-9,
        &.i-7,
        &.i-5,
        &.i-3,
        &.i-1{
          .common-map-legend-item-label{
            display: none;
          }
        }
      }
    }
  }
}
.component-map{
  .common-map-legend {
    .common-map-legend-item-label,
    .info,
    h3{
      display: none;
    }
    .common-map-legend-items{
      .horizontal-map-legend-items;
    }
  }
}
@media (max-width: @phone-max) {
  .component-map{
    .common-map-legend {
      &.temp .common-map-legend-items{
        .common-map-legend-item-color{
          width: 15px;
          height: 10px;
        }
      }
    }
  }
}
