@import '../../styles/mixins.less';

.device-widget.air,
.device-widget.airin,
.device-widget.aqin {
  .fill-column;
  @w: 96%;
  .device-air-2-widget {
    .fill-column;
    .gauge-wrap{
      position: relative;
      flex: 3;
      overflow: hidden;
      .arrow,
      .gauge{
        position: absolute;
        z-index: 1;
        background: url(../../assets/widgets/aqi-gauge.svg) center top no-repeat;
        background-size: 100% auto;
        content: '';
        top: (100% - @w) / 2;
        left: (100% - @w) / 2;
        width: @w;
        padding-top: @w;
      }
      .gauge:before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: var(--color-bg-widget-main);
        padding-top: @w * 0.552;
        z-index: 2;
      }
      .arrow{
        z-index: 4;
        background: url(../../assets/widgets/aqi-gauge-arrow-main.svg) center top no-repeat;
        background-size: 100% auto;
        transition: transform 4000ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
        transform: rotate(0deg);
        &.alt{
          z-index: 5;
          background-image: url(../../assets/widgets/aqi-gauge-arrow-alt.svg);
        }
      }
      .values{
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 4;
        text-align: center;
        color: var(--color-text-widget-big-number);
        padding-bottom: @w * 0.05;
        .main{
          font-weight: bold;
          font-size: 3.6rem;
          line-height: 1.3;
        }
        .units{
          font-size: 1.2rem;
        }
      }
    }
    .bottom{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 2;
      .extended{
        display: none;
      }
      .label{
        font-weight: 500;
        font-size: 2.5rem;
        line-height: 1.3;
      }
    }
    .corners{
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0px @generalPad @generalPad;
      color: var(--color-text-main);
      font-size: 1.5rem;
      font-weight: 300;
      .fdp{
        color: var(--color-text-widget-main);
        font-size: 2rem;
        font-weight: normal;
        display: inline-block;
        padding-left: 5px;
      }
      .suff{
        display: none;
      }
    }
    @media(max-width: @tablet-max) {
      .gauge-wrap {
        .values {
          .main {
            font-size: 6.5rem;
          }
          .units {
            font-size: 2rem;
          }
        }
      }
      .bottom {
        .label {
          font-size: 1.8rem;
        }
      }
    }
    @media(max-width: @phone-max) {
      height: 240px !important;
      
      .gauge-wrap {
        flex: 1.5;
        .gauge {
          background-size: 75% auto;
          padding-top: 82%;
        }
        .arrow {
          background-size: 70% auto;
          padding-top: 63%;
        }
        .values {
          .main {
            font-size: 4.5rem;
          }
          .units {
            font-size: 1.2rem;
          }
        }
      }
      .bottom {
        flex: 1;
        .label {
          font-size: 2rem;
        }
      }
    }
  }
  &:not(.expanded) {
    .device-air-2-widget{
      &.pm10 .arrow.main,
      &.pm25 .arrow.alt{
        display: none;
      }
    }
  }
  // when it's expanded
  &.expanded{
    .device-air-2-widget{
      .gauge-wrap{
        .values{
          bottom: auto;
          top: @w * 0.45;
          @media(max-width: @tablet-max) {
            top: @w * 0.35;
          }
          @media(max-width: @phone-max) {
            top: @w * 0.35;
          }
          .main{
            font-size: 6.5rem;
          }
          .units{
            font-size: 2.2rem;
          }
        }
      }
      .bottom{
        .extended{
          display: flex;
          width: 100%;
          padding: 10px @bigPad @bigPad;
          color: var(--color-text-main);
          border-top: 1px solid var(--color-bg-table-widget-line-alt);
          @media(max-width: @tablet-max) {
            display: none;
          }
          @media(max-width: @phone-max) {
            display: flex;
            flex-direction: column;
            .pm25 { border: none !important; }
            .pm10 { margin-top: 0.8em; }
            .label { padding-bottom: 0 !important; }
          }
          > div {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            &:first-of-type{
              border-right: 1px solid var(--color-bg-table-widget-line-alt);
            }
            .label{
              font-size: 2rem;
              padding-left: 25px;
              padding-bottom: 10px;
              background: url(../../assets/widgets/aqi-arrow-main.svg) left top no-repeat;
              background-size: auto 25px;
            }
            &.pm10 {
              .label{
                background-image: url(../../assets/widgets/aqi-arrow-alt.svg);
              }
            }
            table{
              font-weight: 300;
              font-size: 1.5rem;
              .fdp-val{
                color: var(--color-text-widget-main);
                font-weight: normal;
                font-size: 2rem;
                display: inline-block;
                padding: 0px 5px 0 10px;
              }
              .suff{
                display: none;
              }
              td{
                &:nth-child(2) {
                  text-align: right;
                }
                &:nth-child(3) {
                  color: var(--color-text-main-md);
                  font-size: 1.3rem;
                }
              }
            }
          }
        }
      }
      @media(max-width: @tablet-max) {
        &.aqin {
          height: 420px !important;
        }
      }
      @media(max-width: @phone-max) {
        &.aqin {
          height: 490px !important;
        }
        .gauge-wrap {
          .values {
            .main {
              font-size: 4.5rem;
            }
            .units{
              font-size: 1.2rem;
            }
          }
        }
        .bottom {
          .label {
            font-size: 2rem;
          }
        }
      }
    }
  }
  @media(max-width: @phone-max) {
    padding-bottom: 20px;
  }
}
// only air widget and airin widget
.device-widget.air,
.device-widget.airin {
  .device-air-2-widget {
    .bottom{
      .label{
        padding-bottom: 10px;
      }
    }
  }
  &.expanded{
    .device-air-2-widget{
      .bottom{
        justify-content: space-between;
        .label{
          flex-grow: 1;
          display: flex;
          align-items: center;
          font-size: 3rem;
        }
        .corners{
          font-size: 2rem;
          padding: 0px @bigPad @bigPad;
          .fdp{
            font-size: 2.5rem;
          }
        }
      }
      @media(max-width: @tablet-max) {
        .bottom {
          .label {
            font-size: 2rem;
          }
        }
      }
      @media(max-width: @phone-max) {
        .bottom {
          .label {
            font-size: 2rem;
          }
          .corners {
            font-size: 1.5rem;
            padding: 0px 15px;
            .fdp {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}
