@import '../../styles/mixins.less';

.device-device-list {
  .alexa{
    margin-top: 3px;
    margin-left: 30px;
  }
}
@media (max-width: @phone-max) {
  .device-device-list {
    table{
      thead{
        display: none;
      }
      td{
        display: block;
        width: 100%;
      }
    }

  }
}
