@import '../../styles/mixins.less';

.alert-instances {
  float: left;
  text-align: center;
  .group{
    position: relative;
    text-align: left;
    .date{
      position: absolute;
      left: 0;
      top: @generalPad - 3px;
      padding: 2px 6px;
      border: 1px solid @gray-amb;
      border-radius: 6px;
    }
  }
  .instance{
    padding-left: 80px;
    display: flex;
    .time{
      width: 50px;
      border-right: 1px solid @blue-dark;
      color: @blue;
      padding-top: @generalPad;
      flex-shrink: 0;
    }
  }
  .info{
    position: relative;
    padding: 15px 0 0px 0;
    margin-bottom: @generalPad;
    margin-left: 19px;
    // border-bottom: 1px solid var(--color-text-main-lt);
    &:after{
      content: '';
      border: 1px solid @blue;
      background-color: @white;
      width: 11px;
      height: 11px;
      border-radius: 100%;
      position: absolute;
      left: -25px;
      top: @generalPad + 4px;

    }
    .device{
      color: @blue;
      // font-weight: 300;
      // font-size: 0.9em;
      margin-bottom: 4px;
    }
    b{
      font-weight: 400;
      font-size: 1.2em;
    }
    p{
      .light;
      color: var(--color-text-main-lt);
      margin-bottom: 5px;
      margin-top: 1px;
      font-weight: 400;
      font-size: 1.15em;
    }
  }
  a.more{
    text-decoration: none;
    display: inline-block;
    margin: 20px auto;
    font-size: 1.1em;
    i {
      display: inline-block;
      padding: 0px 0 0 6px;
      .rel; 
      top: 3px;
      margin-bottom: -2px;
    }
  }
  .ifttt{
    width: 12px;
    height: 12px;
    margin-left: 10px;
  }

}
@media (max-width: @phone-max) {
  .alert-instances {
    .group{
      .date{
        position: relative;
        display: inline-block;
      }
      .instance:nth-of-type(2) {
          margin-top: 20px;
        }
      .instance{
        padding-left: 0px;
      }
    }
  }
  
}
