@import '../../styles/mixins.less';

.device-webcam-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  font-size: 0.9em;
  text-align: center;
  position: relative;
  color: var(--color-text-main-lt);
  &.offscreen{
    background: url(../../assets/common/cloudy.svg) center center no-repeat @gray-lightest !important;
    h5 {
      display: none;
    }
  }
  &.nope{
      text-decoration: none;
    h5{
      margin-top: 0;
      margin-bottom: 45px;
      text-decoration: none;
    }
    background: url(../../assets/common/cloudy.svg) center center no-repeat @gray-lightest !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .timestamp{
    position: absolute;
    right: 10px;
    top: 10px;
    text-decoration: none;
    background-color: var(--color-bg-base);
    display: inline-block;
    border-radius: 10px;
    padding: 1px 7px;
    color: @text-dark;
    font-size: 0.73em;
  }
  .video-link{
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: none;
    @w: 35px;
    width: 35px;
    height: 35px;
    background: url(../../assets/common/play.svg) center center no-repeat;
    background-size: 100%;
    z-index: 2;
  }
  &.video{
    .video-link{
      display: block;
    }
  }
  &.play-video{
    .timestamp,
    .video-link{
      display: none;
    }
    .column;
    justify-content: center
  }
  .copy-link{
    display: none;
    position: fixed;
    right: @generalPad;
    top: calc(env(safe-area-inset-top, 0px) + 8px);
    padding-left: 40px;
    background-image: url(../../assets/common/link-white.svg);
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 15px;
    z-index: 3;
  }
}

.app.webcam{
  // in App.js rn
  .webcam-modal{
    .image-modal;
    .device-webcam-image{
      .copy-link{
        display: block;
        position: fixed;
        top: calc(env(safe-area-inset-top, 0px) + 50px);
      }
    }
    &.video{
      &:after{
        display: none;
      }
    }
    .component-share{
      position: fixed;
      right: 15px;
      top: calc(env(safe-area-inset-top, 0px) + 95px);
      z-index: 100;
      a.share{
        .btn-primary;
        .btn-white;
        height: auto;
        width: auto;
        background-size: 20px;
        background-position: 13px center;
        padding-left: 40px;
        &:hover,
        &:active{
          background-image: url(../../assets/common/share/share-arrow-white.svg);
        }
      }
    }
  }
}
@media(max-width: @phone-max) {
  .app.webcam{
    .webcam-modal{
      .component-share a.share,
      .device-webcam-image .copy-link{
        font-size: 1.1rem;
      }
      .component-share{
        top: calc(env(safe-area-inset-top, 0px) + 85px);
        a.share{
          padding-bottom: 5px;
        }
      }
      // .component-share{
      //   bottom: calc(~"env(safe-area-inset-bottom, 0) + " 24px);
      // }
    }
  }
}