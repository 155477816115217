@import '../../styles/mixins';

.device-rw-offsets {
  margin-bottom: 40px;
  h2{
    font-weight: normal;
    font-size: 1.3em;
    margin-bottom: 10px;
    margin-top: 30px;
  }
  h3{
    margin-top: 20px;
    font-size: 1.1em;
    font-weight: 300;
  }
  .sensor-item{
    display: inline-block;
    padding-right: 20px;
    padding-bottom: 5px;
    input{
      margin-right: 4px;
    }
    > span{
      position: relative;
      top: -1px;
      display: inline-block;
    }
  }
  .offsets-section{
    margin-bottom: 20px;
  }
  .offset-item{
    margin-bottom: 10px;
  }
  .warning{
    margin-top: 10px;
  }

}
