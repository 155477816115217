@import '../../styles/mixins.less';

.device-alert-banner {
  color: @white;
  padding: 10px 25px 10px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url(../../assets/common/lightbulb-white.svg) 15px center no-repeat @blue;
  background-size: auto 40px;
  font-size: 1.1em;
  // font-weight: bold;
  &.error{
    background-color: @color-alert;
    background-image: url(../../assets/common/alert-white.svg);
    background-size: auto 50%;
  }
  &.warn{
    background-color: @color-warn;
  }
  &.offline{
    background-image: url(../../assets/common/offline-white.svg);
    background-size: auto 50%;
  }
  span{
    display: block;
    position: relative;
  }
  a{
    color: @white;
    font-weight: normal;
    font-size: 0.8em;
    &:hover{
      color: @text-dark;
    }
  }
  .alexa-logo{
    display: inline-block;
    height: 20px;
    width: 100px;
  }
  .ifttt-logo{
    display: inline-block;
    height: 15px;
    width: 60px;
  }
  .gh-logo{
    background: url(../../assets/gh-logo.png) center center no-repeat;
    display: inline-block;
    height: 17px;
    width: 110px;
    background-size: auto 100%;
  }
}

@media (max-device-width: @phone-max) {
  .device-alert-banner{
    flex-wrap: wrap;
    justify-content: center;
    > span{
      width: 100%;
      text-align: center;
      padding-bottom: 3px;
    }
  }
}