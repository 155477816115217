@import '../../styles/mixins.less';

.indoor-widget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  .top{
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .wrap{
      display: flex;
      justify-content: space-between;
      padding-bottom: 0px;
      .label {
        font-weight: 300;

      }
      & > div {
        flex-grow: 1;
        line-height: 1.25;
        .fdp{
          display: block;
        }
        &:last-child{
          .fdp{
            border-right: none;
          }
        }
      }
    }
    .fdp{
      font-size: 3vw;
      font-size: clamp(1em, 3vw, 4em);
      .fdp-val, .suff {
        color: var(--color-text-widget-big-number);
      }
    }
    .label {
      font-size: 1.3vw;
      font-size: clamp(0.7em, 1.3vw, 0.8em);
    }
  }
  .bottom{
    display: flex;
    justify-content: center;
    > div{
      padding: 0 15px;
      line-height: 1.4;
      &:first-of-type{
        border-right: 1px solid var(--color-border-widget-header-main);
      }
      .label {
        font-size: 1.3vw;
        font-size: clamp(0.6em, 1.3vw, 0.65em);
        font-weight: 300;
      }
      .val {
        color: @blue;
      }
    }
  }
  &.co2{
    .top .wrap{
      padding-bottom: 0px;
    }
    .bottom{
      padding-bottom: 10px;
    }
  }
  &.single{
    .top{
      padding-top: 0px;
      .fdp{
        font-size: 6rem;
        line-height: 1.1;
      }
    }
  }
  .co2-progress{
    padding: 10px @generalPad;
    background-color: var(--color-bg-main);
    align-self: flex-end;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 10px;
    .progress{
      flex-grow: 1;
    }
    .label{
      padding-left: 10px;
      font-size: 0.6em;
    }
  }
  .nav{
    display: inline-block;
  }
  .device-device-data-table{
    height: 300px;
    overflow: scroll;
    font-size: 0.7em;
    table.main th.dateutc{
      padding-top: 8px;
    }
  }

}
.device-widget.temp1f,
.device-widget.temp2f,
.device-widget.temp3f,
.device-widget.temp4f,
.device-widget.temp5f,
.device-widget.temp6f,
.device-widget.temp7f,
.device-widget.temp8f,
.device-widget.temp9f,
.device-widget.temp10f,
.device-widget.indoor{
  display: flex;
  flex-direction: column;
  &.expanded{
    .indoor-widget{
      justify-content: flex-center;
      .top{
        flex-grow: 0;
        padding-bottom: 10px;
        .label {
          font-size: 1.3vw;
          font-size: clamp(0.8em, 1.3vw, 0.8em);
        }
        .fdp{
          font-size: 5vw;
          font-size: clamp(0.7em, 5vw, 7em);
          line-height: 1;
        }
        .wrap{
          padding-bottom: 0px;
        }
        &.co2{
          .top{
            padding: 25px 0;
          }
        }
      }
      .bottom{
        // padding-top: @generalPad;
        // padding-bottom: @generalPad;
        .label{
          line-height: 1.2;
        }
        .fdp{
          font-size: 0.9em;
        }
      }
      .expanded-wrap{
        border-top: 1px solid var(--color-border-widget-header-main);
        text-align: center;
        padding: @bigPad @bigPad 0;
        .component-time-mode-nav{
          padding: 10px 0;
        }
        .device-high-low-graphs .line-wrap {
          padding: 0px 0 20px;
        }

      }
    }
    
  }
}
@media (max-width: @tablet-max) {
  .device-widget.temp1f.expanded,
  .device-widget.temp2f.expanded,
  .device-widget.temp3f.expanded,
  .device-widget.temp4f.expanded,
  .device-widget.temp5f.expanded,
  .device-widget.temp6f.expanded,
  .device-widget.temp7f.expanded,
  .device-widget.temp8f.expanded,
  .device-widget.temp9f.expanded,
  .device-widget.temp10f.expanded,
  .device-widget.indoor.expanded{
    .indoor-widget{
      .top{
        font-size: 1em;
        padding-top: @generalPad;
        padding-bottom: @generalPad;
        border: none;
        .fdp{
          font-size: 10vw;
          font-size: clamp(1em, 10vw, 2.3em);
        }
        .wrap{
          flex-direction: row;
          > div{
            padding-top: 0px;
            padding-bottom: 0px;
          }
        }
      }
      .expanded-wrap{
        padding: @generalPad;
        .device-high-low-graphs {
          .line-wrap {
            padding: 0px 0 11px;
          }
        }
      }
      &.co2{
        .bottom {
          display: none;
        }
      }
    }
  }
  .indoor-widget{
    .top{
      font-size: @tablet-font-grow;
      padding-bottom: @generalPad;
      .wrap{
        flex-direction: column;
        > div {
          &:last-child{
            padding-bottom: 0;
          }
          .fdp{
            border: none;
            font-size: 6vw;
            font-size: clamp(2em, 6vw, 5.5em);
            .suff {
             // font-size: 2vw;
             // font-size: clamp(0.5em, 2vw, 1em);
            }
          }
        }
      }
    }
    .bottom {
      padding-top: 0;
      >div {
        line-height: 1.1;
        margin-bottom: 20px;
        .fdp-val, .suff {
          font-size: 1vw;
          font-size: clamp(1.2em, 1vw, 2.2em);
        }
        .label {
          padding-bottom: 4px;
          font-size: 1.3vw;
          font-size: clamp(0.7em, 1.3vw, 0.8em);
        }
      }
    }
  }
}



@media (max-width: @tablet-phone-max) {
  .device-widget.temp1f.expanded,
  .device-widget.temp2f.expanded,
  .device-widget.temp3f.expanded,
  .device-widget.temp4f.expanded,
  .device-widget.temp5f.expanded,
  .device-widget.temp6f.expanded,
  .device-widget.temp7f.expanded,
  .device-widget.temp8f.expanded,
  .device-widget.temp9f.expanded,
  .device-widget.temp10f.expanded,
  .device-widget.indoor.expanded,
  .device-widget.indoor{

    .body {
      .top {
        .fdp-val {
          font-size: 15vw;
          font-size: clamp(1em, 15vw, 2.5em);
        }
        .suff{
          font-size: 15vw;
          font-size: clamp(1em, 15vw, 2.5em);
        } 
      }
      .bottom {
        > div {
          .label {
            font-size: 14px;
          }
        }
      }
      
    }
    .expanded-wrap {
      padding: 15px;
      .device-high-low-graphs {
        .line-wrap {
          padding: 5px 0 15px !important;
        }
      }
    }
  }
}

//breakpoint right before ipad starts
@media (max-width: @phone-max) {
  .indoor-widget{
    padding: @generalPad 0;
    .top {
      .wrap {
        >div {
          .fdp {
            .fdp-val, .suff {
              font-size: 11vw;
              font-size: clamp(1em, 11vw, 4em);
            }
          }
        }
      }
      .label {
        font-size: 0.8vw;
        font-size: clamp(0.7em, 0.8vw, 1em);
      }
    }
    .expanded-wrap{
      padding-top: @generalPad;
    }
    .bottom{
      padding-bottom: 0px;
    }
  }
  .device-widget.temp1f.expanded,
  .device-widget.temp2f.expanded,
  .device-widget.temp3f.expanded,
  .device-widget.temp4f.expanded,
  .device-widget.temp5f.expanded,
  .device-widget.temp6f.expanded,
  .device-widget.temp7f.expanded,
  .device-widget.temp8f.expanded,
  .device-widget.temp9f.expanded,
  .device-widget.temp10f.expanded,
  .device-widget.indoor.expanded{
    padding-bottom: 20px;
    .indoor-widget{
      padding: @generalPad 0 ;
      .top{
        font-size: 1.1em;
        .fdp {
          font-size: 11vw;
          font-size: clamp(1em, 11vw, 4em);
        }
        .wrap{
          flex-wrap: wrap;
        }
      }
      &.co2{
        padding-bottom: 0px;
        .co2-progress{
          padding-bottom: 20px;
          position: relative;
          bottom: 0;
        }
        .bottom{
          display: flex;
          > div {
            .label {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
