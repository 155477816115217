@import '../../styles/mixins.less';

.device-device-list-rows {
  .device-line{
    .block{
      padding-right: 0px;
      padding-left: 0px;
      margin-bottom: 0px;
      position: relative;
      z-index: 2;
      header.main-top{
        display: flex;
        justify-content: space-between;
        padding-right: @bigPad;
        padding-left: @bigPad;
        padding-bottom: 10px;
        border-bottom: 1px solid @gray-light-amb;
        height: 47px;
        input.editable-title{
          font-size: 1.7em;
          width: 100%;
        }
        .right{
          display: flex;
          .icon{
            display: none;
            flex-shrink: 0;
            width: 75px;
            height: 75px;
            background-color: @blue;
            border-radius: 100%;
            margin-right: 10px;
            margin-left: 10px;
          }
          > .glyphicon{
            color: @red;
            margin-top: 9px;
          }
        }
      }
      .sub-head{
        padding-right: @bigPad;
        padding-left: @bigPad;
        padding-top: 10px;
        .item{
          float: left;
          label{
            text-transform: none;
            font-weight: normal;
            font-size: 1.4rem;
            display: inline-block;
            margin-right: 3px;
          }
          > a,
          > span{
            font-weight: bold;
            display: inline-block;
            margin-right: 25px;
          }
          input.editable-title{
            position: relative;
            top: -1px;
            color: @text-dark;
          }
          &.tz{
            position: relative;
            width: 268px;
            .component-timezone-picker{
              position: absolute;
              left: 68px;
              bottom: 0;
              display: inline;
              .css-2o5izw:hover,
              .css-2o5izw,
              .tz-select__control,
              .css-vj8t7z{
                border-radius: 0;
                border: none;
                border-bottom: 1px solid transparent;
                font-size: 1em;
                font-weight: bold;
                padding: 0 0 0px 0;
                width: 200px;
                background: none;
              }
              .tz-select__control:hover,
              .css-vj8t7z:hover,
              .css-2o5izw:hover{
                border-bottom: 1px solid #eee;
              }
            }
          }
        }
      }
      .register{
        margin-top: 15px;
        font-size: 1.3rem;
      }
      .services{
        padding-right: @bigPad;
        padding-left: @bigPad;
        padding-top: @bigPad;
        .service{
          margin-bottom: @generalPad;
          header{
            color: @white;
            padding: 10px;
            font-size: 1.7rem;
            cursor: pointer;
            background: url(../../assets/down-carrot.svg) right 10px center no-repeat @blue;
            .badge{
              float: right;
              margin-right: 30px;
            }
          }
          .bottom{
            transition: all 0.3s ease-in;
            max-height: 0;
            overflow: hidden;
            background-color: @bg;
            padding-left: @bigPad;
            padding-right: @bigPad;
            .message{
              background-color: @green-dark;
              color: @white;
              padding: 10px;
              margin-bottom: @generalPad;
            }
            .form{
              display: flex;
              align-items: center;
              > span{
                font-size: 1.7rem;
                margin-right: 10px;
              }
              input{
                margin-right: 10px;
              }
            }
          }
          &.open{
            header{
              background-image: url(../../assets/up-carrot.svg);
            }
            .bottom{
              max-height: 500px;
              padding: @bigPad;
            }
          }
        }
      }
      &.connections{
        margin: 0px 15px @bigPad;
        display: flex;
        padding-left: @bigPad;
        padding-right: @bigPad;
        z-index: 1;
        > a {
          flex: 1;
          text-decoration: none;
          padding: 0px @generalPad;
          text-align: right;
          display: flex;
          justify-content: center;
          background: none;
          align-items: center;
          &:nth-of-type(2) {
            border-left: 1px solid @gray-light-amb;
            border-right: 1px solid @gray-light-amb;
          }
          .icon{
            background: url(../../assets/alexa-logo.png) center center no-repeat;
            background-size: auto 144%;
            display: block;
            width: 100px;
            height: 60px;
          }
          &.ga-service{
            .icon{
              background-image: url(../../assets/google-assistant-logo.png);
              background-size: auto 110%;
            }
          }
          &.ifttt-service{
            .icon{
              background-image: url(../../assets/ifttt-blue.svg);
              background-size: 85% auto;
            }
          }

          .badge{
            margin-left: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: @tablet-max) {
  .device-servers-form{
    display:flex;
    flex-direction: column;
    > *{
      margin-bottom: 10px;
    }
  }
}
@media (max-width: @phone-max) {
  .device-device-list-rows {
    .device-line{
      header.main-top{
        .right{
          .device-share-button{
            .btn{
              padding-left: 11px;
              padding-right: 11px;
            }
            span{
              display: none;
            }
          }
          .icon{
            display: none;
          }
        }
      }
      .block{
        &.connections{
          padding-top: 0px;
          padding-bottom: 0px;
          flex-direction: column;
          > a{
            padding: @bigPad 0;
            &.ga-service{
              border-left: none !important;
              border-right: none !important;
              border-bottom: 1px solid @gray-light-amb;
              border-top: 1px solid @gray-light-amb;
            }
          }
        }
        .services{
          .service{
            header{
              .badge{
                margin-right: 19px;
              }
            }
            .bottom{
              .form{
                flex-direction: column;
                .device-servers-form > *,
                > *{
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
