@import '../../styles/mixins.less';

a.device-summary-table-cell {
  text-decoration: none;
  color: @text-dark;
  &:focus,
  &:hover{
    text-decoration: none;
  }
}
.device-summary-table-cell {
  padding: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  &.editing{
    z-index: 10;
  }
  .date{
    padding-top: 1px;
    font-size: 10px;
    color: var(--color-text-main-lt);
    display: block;
    input[type="text"] {
      width: 16px;
    }
  }
  .fdp{
    color: @text-dark;
    white-space: nowrap;
  }
  input[type="text"] {
    border: none;
    background-color: transparent;
    display: inline-block;
    font-size: 1em;
    line-height: 1.3;
    padding: 0;
    width: 50px;
    text-align: center;
    color: @blue; 
    &:hover{
      color: @blue; 
    }
    &.hour{
      text-align: right;
      padding-right: 2px;
    }
  }
  .close{
    position: absolute;
    right: -30px;
    .general-shadow;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30px;
    background-color: @blue;
    color: @white;
    text-decoration: none;
    z-index: 10;
  }
  select{
    appearance: none;
    background: url(../../assets/triangle-down-blue.svg) right center no-repeat transparent;
    border: none;
    color: @blue;
    padding: 0 10px 0 0;
    display: inline-block;
    width: auto;
  }
}
