@import '../../styles/mixins';

.common-tags {
  > span {
    display: inline-block;
    padding: 0px 0px 0px 10px;
    border-radius: 10px;
    background-color: var(--color-bg-chart-date-picker-main);
    margin: 0px 10px 10px 0px;
    a {
      color: @gray-amb;
      text-decoration: none;
      font-weight: bold;
      display: inline-block;
      padding: 2px 7px 2px 7px;
    }
  }

}
