@import '../../styles/mixins';

.payment-display-subscription {
  a.change-plan{
    display: block;
  }
  .aw-fields{
    width: 100%;
    overflow: auto;
  }
  table{
    width: 100%;
    overflow: auto;
    th{
      font-weight: bold;
      padding: 15px;
      background-color: lightgray;
    }
    td{
      border-bottom: 1px;
      border-style: solid;
      border-color: lightgray;
      vertical-align: middle;
      padding: 10px;
    }
  }
  a.cancel{
    display: block;
    margin-top: 5px;
    color: @red;
  }
  .placeholder{
    display: block;
    height: 30px;
  }
  .component-loader{
    margin-top: 10px;
    height: 20px;
  }
}
@media (max-width: @phone-max) {
    .payment-display-subscription {
    }
}


