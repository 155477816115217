@import '../../styles/mixins.less';

.device-device-edit-form {
  label{
    display: block;
    text-transform: none;
    font-weight: normal;
    font-size: 1.3em;
    margin-bottom: 10px;
    margin-top: 30px;
    &.normal{
      font-size: 1em;
      display: inline;
      margin: 0;
    }
  }
  input.name{
    min-width: 240px;
  }
  .warning{
    font-size: 0.8em;
  }
  .component-location{
    .btns-wrap{
      display: none;
    }
  }
  .privacy{
    p {
      margin-top: 30px;
      color: var(--color-text-main-md);
      font-size: 1.2rem !important;
    }
  }
  &.wxw{
    label.loc,
    .sharemydevice,
    .privacy,
    .component-location{
      display: none;
    }
  }

}
