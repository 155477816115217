@import '../styles/mixins.less';

.component-share {
  a.share{
    display: inline-block;
    background: url(../assets/common/share/share-arrow-blue.svg) center center no-repeat;
    width: 19px;
    height: 20px;
    background-size: 100%;
  }


}
.share-modal{
  .img-wrap{
    width: 100%;
    max-width: 450px;
    margin: 10px auto;
    margin-top: 35px;
    position: relative;
    text-align: center;
    display: none;
    img{
      height: auto;
      width: 100%;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        padding-top: 52%;
      }
    }
  }
  .priv-wrap{
    text-align: center;
  }
  .share-icons{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 15px;
    a{
      display: inline-block;
      height: 30px;
      width: 40px;
      background: url(../assets/common/share/email-black.svg) center center no-repeat;
      background-size: auto 100%;
      margin: 0 5px;
      &.fb{
        background-image: url(../assets/common/share/fb-black.svg);
      }
      &.twitter{
        background-image: url(../assets/common/share/twitter-black.svg);
      }
      &.download{
        background-image: url(../assets/common/share/download-black.svg);
      }
    }
  }
  &.priv{
    .share-icons{
      a.twitter,
      a.email,
      a.fb{
        display: none;
      }
    }
    .copy{
      display: none;
    }
  }
  .copy{
    .column;
    align-items: center;
    input.form-control{
      display: block;
      border-radius: 2px;
      border-color: @gray-light-amb;
      width: 70%;
      margin: 15px 0 15px;
      font-size: 0.9em;
      &[readonly]{
        background-color: transparent;
      }
    }
    .btn-primary{
      padding: 8px 70px;
    }
  }
}
// states we dont support yet
.device-widget.indoor.expanded,
.device-widget.temp.expanded{
  .component-share{
    display: none;
  }
}

@media (max-width: @phone-max) {
  .component-share {
    a.share {
      width: 24px;
      height: 27px;
    }
  }
  .share-modal{
    .img-wrap{
      margin-top: 30px;
      img{
        width: 100%;
        height: auto;
      }
    }
    .copy{
      input.form-control{
        width: 90%;
      }
    }
  }
}