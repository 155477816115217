@import '../../styles/mixins.less';

.device-default-page {
    width: 700px;
    margin: auto;
    h1{
      display: none;
    }
    input{
        text-align: center;
    }
    p{
      padding: 15px 0 0 0;
      margin-bottom: 0px;
    }
    .form-group ul{
      list-style: disc;
      width: 350px;
      margin: 0 auto;
      text-align: left;
      li{
        margin-left: 20px;
        list-style-type: disc;
        margin-bottom: 5px;
      }
    }
    .dashboard & {
        margin: 0;
      .form-group ul{
        margin: 0;
        width: auto;
      }
    }
    form{
      padding: 0;
      h4{
        margin-bottom: 15px;
        margin-top: 10px;
      }
      input:first-of-type{
        margin-bottom: @generalPad;
      }
    }
    #tz{
      margin-top: 20px;
    }
    .component-timezone-picker{
      margin-bottom: 20px;
    }
    .slick-dots{
      display: none !important;
      li:last-of-type{
        &:after{
          content: " ";
          width: 100%;
          height: 30px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 100;
        }
      }
    }
    .congrats{
      .square(170px);
      margin: 20px 0;
      background: url(../../assets/success.svg) center center no-repeat;
      background-size: 100%;
    }
    .to-dash{
      margin-left: 11px;
    }
    &.loggedout{
      text-align: center;
      // min-height: 600px;
      h1{
        display: block;
        margin-bottom: 20px;
      }
      .btn{
        margin-top: 20px;
      }
      input[type="text"],
      input[type="email"],
      input[type="password"]{
        font-size: 19px;
        font-weight: 300;
        padding: 7px 46px;
      }
      .slick-dots{
        display: block !important;
      }
      .component-timezone-picker{
        .css-10nd86i{
          margin: auto;
          width: 283px;
        }
        .css-2o5izw,
        .css-vj8t7z{
          background-color: @white;
          padding-top: 7px;
          padding-bottom: 7px;
          font-weight: 300;
          font-size: 18px;
        }
        .css-1hwfws3{
          justify-content: center;
        }
      }
    }

    .jsx-693531783 {
      font: inherit;
    }
}
@media (max-width: @phone-max){
  .device-default-page{
    width: 100%;
  }
}
