@import '../../styles/mixins.less';

.user-avatar {
  display: inline-block;
  .square(40px);
  background: url(../../assets/avatar.svg) center center no-repeat;
  background-size: cover;
  border-radius: 100%;
  //border: 2px solid var(--color-bg-widget-main); 
  &.no-def{
    background-image: none;
  }
  &.is-def{
    border: none;
  }
}
