@import '../../styles/mixins';

.floating-white{
  color: @white;
  font-size: 18px;
  line-height: 20px;
  text-shadow: 1px 1px 2px #00000080;
}
.p-small{
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  color: @text-dark;
  text-shadow: none;
}

.common-advanced-graph-modal {
  padding: 25px;
  header.logo{
    background: url(../../assets/adgraph-modal/Introducing\ Desktop.png) no-repeat center center;
    background-size: auto 90%;
    position: absolute;
    z-index: 5;
    padding: 20vh 0 0;
    top: -20vh;
    left: 0;
    width: 100%;
  }
  .carousel{
    &.carousel-slider{
      overflow: visible;
    }
    .control-dots{
      bottom: -65px;
      .dot{
        box-shadow: none;
      }
    }
  }
  li.slide{
    display: flex;
    overflow: auto;
  }
  a.slide{
    flex-grow: 1;
    text-decoration: none;
    .mobile{
      display: none;
    }
    h2{
      font-size: 32px;
      line-height: 34px;
      color: @white;
      border: none;
      margin: 0px auto;
      max-width: 500px;
      margin-bottom: 15px;
      font-weight: 700;
      text-decoration: none;
      text-shadow: 0px 0px 12px #0000004D;

    }
    .imgs{
      display: flex;
      margin-bottom: 15px;
      > div {
        height: 300px;
        display: flex;
        flex: 1;
        background: url(../../assets/adgraph-modal/Frame1_Image1_desktop.png) no-repeat center center;
        background-size: contain;
        align-items: flex-end;
        p {
          padding: 0px 15px;
          .p-small;
        }
      }
    }
    p {
      color: @text-dark;
      text-decoration: none;
      font-size: 24px;
      line-height: 26px;
      max-width: 500px;
      margin: 0 auto;
    }
    &.s-0 .imgs > div:nth-of-type(2){
      background-image: url(../../assets/adgraph-modal/Frame1_Image2_desktop.png);
    }
    &.s-1{
      .top {
        display: flex;
        > * {
          flex-shrink: 0;
          flex-grow: 0;
          width: 30%;
        }
        > *:nth-child(2) {
          width: 40%;
          background: url(../../assets/adgraph-modal/Frame2_Image1_desktop.png) no-repeat center center;
          background-size: auto 110%;
          height: 180px;
        }
        p{
          .floating-white;
          padding-right: 10%;
          padding-top: 15px;
        }
      }
      .imgs{
        > div {
          height: 210px;
          background-image: url(../../assets/adgraph-modal/Frame2_Image2_desktop.png);
        }
      }
      p{
        .p-small;
        max-width: 100%;
      }
    }
    &.s-2{
      .imgs {
        > div {
          background-position-y: top;
          background-size: 100% auto;
          height: 340px;
          &:nth-child(1){
            background-image: url(../../assets/adgraph-modal/Frame3_Image1_desktop.png);
          }
          &:nth-child(2){
            background-image: url(../../assets/adgraph-modal/Frame3_Image2_desktop.png);
          }
        }
      }
    }
    &.s-3{
      .centered{
        display: flex;
        > div {
          &:nth-child(3),
          &:nth-child(1){
            width: 20%;
            flex-grow: 0;
            flex-shrink: 0;
            p{
              .floating-white;
            }
          }
          &:nth-child(1){
            p:nth-child(1){
              padding-top: 40px;
            }
            p:nth-child(2){
              padding-top: 105px;
            }
          }
          &:nth-child(2){
            // width: 50%;
            width: 60%;
            background: url(../../assets/adgraph-modal/Frame4_Image1_desktop.png) no-repeat center top;
            background-size: 100% auto;
            height: 450px;
          }
          &:nth-child(3){
            p{
              padding-top: 155px;
            }
          }
        }
      }
    }
    &.s-4{
      .imgs div {
        background-image: url(../../assets/adgraph-modal/Frame5_Image1_desktop.png);
        height: 350px;
      }
      p{
        max-width: 80%;
      }
    }
    &.s-5{
      .imgs div {
        background-image: url(../../assets/adgraph-modal/Sign\ Up\ Now_desktop.png);
        margin-top: 50px;
        height: 450px;
      }
      &.plus{
        .imgs div {
          background-image: url(../../assets/awn+logo-white.png);
        height: 350px;
        }
        button{
          font-size: 2em;
          padding: 7px 50px;
          background-color: @green-dark;
          border-radius: 35px;
        }
      }
    }
  }
}
.general-modal.adgraph{
  .ReactModal__Content{
    background: url(../../assets/adgraph-modal/Desktop\ Background.png) no-repeat center top !important;
    background-size: cover !important;
    padding: 0px !important;
    overflow: visible !important;
    top: calc(20vh + 20px) !important;
    .close{
      display: none;
      position: absolute;
    }
    @media (max-width: @tablet-phone-max) {
      margin: 0 auto !important;
      max-width: 90% !important;
      left: 5% !important;
      max-height: calc(80vh - 65px) !important;
    }
  }
}

@media(max-width: @tablet-phone-max) {
  .common-advanced-graph-modal{
    a.slide{
      &.s-1{
        .top{
          > * {
            width: 25%;
            &:nth-child(2){
              width: 50%;
            }
          }
          p{
            padding-right: 0;
          }
        }
      }
      &.s-2{
        .imgs{
          > div {
            height: 300px;
          }
        }
      }
      &.s-3{
        .centered{
          > div {
            &:nth-child(1){
              p:nth-child(1){
                padding-top: 10px;
              }
              p:nth-child(2){
                padding-top: 35px;
              }
            }
            &:nth-child(3){
              p{
                padding-top: 90px;
              }
            }
          }
        }
      }
    }
  }
}
@media(max-width: @phone-max) {
  .common-advanced-graph-modal{
    a.slide{
      .desktop{
        display: none;
      }
      .mobile{
        display: block;;
      }
      h2{
        margin-bottom: 0;
      }
      &.s-0{
        .imgs{
          flex-direction: column;
          height: 440px;
        }
        p{
          .p-small;
        }
      }
      &.s-1{
        .top{
          flex-direction: column;
          > div {
            width: 100% !important;
            height: 145px !important;
          }
          p{
            width: 100%;
            padding-top: 10px;
            padding-bottom: 5px;
            .p-small;
          }
        }
        .imgs{
          > div {
            background-image: url(../../assets/adgraph-modal/Frame2_Image2_mobile.png);
          }
        }
      }
      &.s-2{
        .imgs{
          flex-direction: column;
          height: 59vh;
          > div {
            flex: 1;
            margin: 0 auto;
            background-size: 85% auto;
            &:nth-of-type(2) {
              background-position-y: 15px;
            }
          }
        }
      }
      &.s-3{
        .centered{
          flex-direction: column;
          div {
            &:nth-child(2){
              order: -1;
              background-image: url(../../assets/adgraph-modal/Frame4_Image1_mobile.png);
              width: 100%;
              height: 230px;
            }
            &.mobile{
              p{
                .p-small;
                margin-top: 10px;
              }
            }
          }
        }
      }
      &.s-4{
        .imgs div {
          height: 200px;
        }
        p{
          .p-small;
          max-width: 100%;
        }
      }
      &.s-5.plus .imgs div{
        height: 250px;
      }
    }

    .carousel{
      .control-dots{
        position: fixed;
        bottom: 5px;
        left: 0;
      }
    }
  }
}
@media (max-height: 850px) and (max-width: @phone-max) {
  .common-advanced-graph-modal{
    a.slide{
      &.s-0{
        .imgs {
          height: 290px;
          > div:nth-of-type(2){
            display: none;
          }
        }
      }
      &.s-1{
        .top{
          display: none;
        }
      }
      &.s-2{
        .imgs{
          height: 250px;
          p{
            // font-size: 14px;
          }
          > div:nth-of-type(2){
            background-position-y: 0px;
            display: none;
          }
        }
      }
    }
  }
}