@import '../../styles/mixins.less';

.sensors-widget {
  .line{
    input{
      width: 60%;
    }
    .fdp {
      width: 20%;
    }
  }
}
.expanded .sensors-widget{
  .line{
    input{
      width: 70%;
    }
    .fdp{
      width: 15%;
    }
  }
}

.device-widget.sensors{
  .no-graph-button;
}