@import '../../styles/mixins.less';

.general-modal .ReactModal__Content .user-create-username-modal {
  text-align: center;
  h2{
    font-size: 2.5rem;
    font-weight: 300;
    border-bottom: none;
    padding: 30px 50px 30px;
    color: var(--color-text-main-md);
  }
  h4{
    margin-bottom: 10px;
  }
  .user-username-edit.open .form{
    flex-direction: column;
    justify-content: center;
    position: relative;
    .component-loader,
    .message{
      padding-top: 5px;
    }
  }
  label{
    display: block;
    text-align: center;
    font-weight: normal;
    text-transform: none;
    padding: 30px 0px 10px;
    a{
      text-decoration: none;
      position: relative;
      top: -2px;
    }
  }
  p.small{
    padding: 15px 0 0;
    color: var(--color-text-main-lt);
  }
  .btn-primary{
    padding: 10px 40px;
  }

}
