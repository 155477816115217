@import '../styles/mixins.less';

.component-min-max-btns {
  a:focus {
    outline: none;
  }
  a.chevron-down,
  a.chevron-up{
    background: url(../assets/up-carrot.svg) center center no-repeat;
    filter: brightness(0.65) sepia(0.15) hue-rotate(180deg) saturate(0.2);
    background-size: 16px;
    display: inline-block;
    width: 22px;
    height: 23px;
  }
  a.chevron-down{
    background-image: url(../assets/down-carrot.svg);
  }
}
.app.plus{
  .component-min-max-btns {
    a.chevron-down,
    a.chevron-up{
      filter: invert(48%) sepia(94%) saturate(1099%) hue-rotate(165deg) brightness(97%) contrast(98%);
    }
  }
}

@media (max-width: @phone-max) {
  .component-min-max-btns {
    a.chevron-down,
    a.chevron-up{
      width: 30px;
      height: 30px;
      background-size: 23px;
    }
  }
}