@import '../../styles/mixins.less';

.device-sun-moon-graph {

}

.tooltip{
  background-color: @white;
  border: 1px solid @gray-light-amb;
  padding: 10px;
  .line{
    display: flex;
    p{
      flex-grow: 1;
    }
    div{
      width: 35px;
    }
  }
}
