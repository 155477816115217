@import '../../styles/mixins.less';

.user-account {
  .edit{
    display: inline-block;
    margin-left: 20px;
  }
  form{
    padding: 0;
  }
  .mt{
    margin-top: 10px;
  }
  .line{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > span{
      display: inline-block;
      margin-left: 10px;
    }
  }
  input[type="email"]{
    width: 250px;
  }
  .block{
    h3{
      margin-top: 30px;
      &:first-of-type{
        margin-top: 0px;
      }
    }
    h4{
      font-size: 1em;
      margin-bottom: 3px;
      &:nth-of-type(2){
        margin-top: 10px;
      }
    }
  }
  .profile-wrap{
    display: flex;
    .avatar-info{
      position: relative;
      .logout{
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 10px;
        text-align: center;
        text-decoration: none;
        width: 100%;
      }
    }
    .user-info{
      flex-grow: 1;
      margin-left: @bigPad;
    }
  }
  .user-avatar{
    .square(100px);
  }
  .theme{
    .choose-location{
      margin-left: 30px;
      h5{
        margin-top: 20px;
        font-weight: 300;
        font-size: 0.95em;
      }
    }
  }
  .component-amb-radio{
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-bottom: 10px;
    .circle{
      margin-left: 0;
      margin-right: 10px;
    }
  }
  .app-start{
    h3{
      margin-top: 30px !important;
    }
    .flex{
      display: flex;
      > div {
        margin-right: 20px;
      }
    }
  }

  .suggestion-box-posts{
    .controls{
      padding-top: 10px;
      justify-content: flex-end;
      .sort .dropdown-menu-button{
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    .posts{
      padding-left: 0;
      padding-right: 0;
    }

  }
}
.app.dashboard.account-page {
  &:before{
    display: none;
  }
  .tabs{
    background-color: var(--color-bg-page-block-main);
    .header-rect-tabs;
    a {
      padding: 22px 18px;
    }
  }
}

@media (max-width: @tablet-phone-max){
  .app.dashboard.account-page {
    .tabs{
      position: sticky;
      // margin-top: @headingHeight;
      top: 0px;
      z-index: 100;
    }
  }
}
@media(max-width: @phone-max){
  .app.dashboard.account-page {
    .tabs{
      // margin-top: 60px;
    }
    .profile-wrap{
      flex-direction: column;
      .avatar-info{
        .logout{
          display: block;
          position: relative;
          bottom: auto;
          right: auto;
          padding: 0;
          text-align: left;
          width: auto;
          margin-top: 25px;
        }
      }
      .user-info{
        margin-left: 0;
      }
    }
  }
}