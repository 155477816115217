@import '../../styles/mixins';

.mapboxgl-popup-close-button{
  background: url(../../assets/x-blue.svg) no-repeat center;
  background-size: 60%;;
  padding: 10px 15px;
  color: transparent;
}
.common-data-popup {
  > .top {
    border-bottom: 1px solid @gray-lighter;
    .meta{
      font-size: 1.6rem;
      width: 100%;
      padding-left: 10px;
      line-height: 1.3;
      flex-grow: 1;
      text-transform: capitalize;
      .state{
        text-transform: uppercase;
      }
    }
    .ago{
      padding-top: 25px;
      flex-shrink: 0;
    }
  }
  .content{
    padding: 10px;
    .sans;
    .field{
      display: flex;
      justify-content: space-between;
      padding: 7px 10px;
      font-size: 16px;
      .value{
        font-weight: 300;
        .suff{
          display: inline-block;
          padding-left: 3px;
        }
      }
      &:nth-child(even) {
        background-color: @gray-lighter;
      }
    }
  }
}
@media (max-width: @phone-max){
  .common-data-popup {
    max-width: 85vw;
  }
}