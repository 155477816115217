@import '../styles/mixins.less';

.component-simple-nav {
  ul ul {
    margin-left: 10px;
  }
  a {
    color: blue;
    line-height: 150%;
  }
}
