@import '../../styles/mixins';

.app.v4 .user-home-page{
  .forecast-first{
    background-color: var(--color-bg-page-block-main);
    .component-loader{
      width: 70px;
      margin: 62px auto;
    }
  }
  //FORECAST FIRST
  @hourlyCellWidth: 4em;
  @hourlyCellSidePad: 0.8em;
  .forecast {
    padding-bottom: @mobile-nav-height;
    padding-bottom: calc(~"env(safe-area-inset-bottom, 0) + " @mobile-nav-height);
    .device-forecast-widget {
      margin-top: 50px;
      padding: 0;
      border-top: 1px solid var(--color-border-alt-lt);
      .hourly{
        .hour{
          padding-right: @hourlyCellSidePad;
          padding-left: @hourlyCellSidePad;
          .weather-icon{
            width: @hourlyCellWidth;
            background-position-x: center;
          }
        }
        .precip{
          display: block;
          padding-top: 1em;
          span.drop{
            font-size: 0.85em;
          }
          .dailyrainin{
            padding-top: 0.33em;
            font-size: 0.75em;
            color: var(--color-text-main-lt);
            text-align: center;
          }
        }
      }
    }
    .hourly-wrap {
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .scrollable-wrap{
      width: 3764px;
      .labels{
        display: flex;
        width: 100%;
        justify-content: space-between;
        position: relative;
        .label{
          width: @hourlyCellWidth + (2 * @hourlyCellSidePad);
          .column;
          text-align: center;
          .fdp-val,
          b {
            font-weight: 500;
            color: var(--color-text-main);
            font-size: 1.2rem;
          }
          .suff{
            display: block;
            color: var(--color-text-main-lt);
            font-size: 0.9em;
            padding-top: 2px;
          }
        }
      }
      .uv-index{
        .bar{
          background-color: #23a455;
          height: 7px;
          border-radius: 3px;
          margin: 0 @hourlyCellSidePad 10px;
          &.uv-3,
          &.uv-4,
          &.uv-5{
            background-color: #f6d060;
          }
          &.uv-6,
          &.uv-7{
            background-color: #f58d4c;
          }
          &.uv-8,
          &.uv-9,
          &.uv-10{
            background-color: #fd5219;
          }
          &.uv-11{
            background-color: #6232d5;
          }
        }
        .label {
          padding: 15px 0;
          border-top: 1px solid var(--color-border-alt-lt);
          &.alt {
            background-color: #eaf7ff;
            border-top: 1px solid #eaf7ff;
          }
          .suff {
            font-weight: 300;
          } 
        }
      }
      .wind{
        
        .label {
          padding: 15px 0;
          border-top: 1px solid var(--color-border-alt-lt);
          position: relative;
          &.high-winds{
            background: url(../../assets/forecast/gust.svg) left 16px bottom 28px no-repeat;
            background-size: auto;
          }
          &.alt {
            background-color: #eaf7ff;
            border-top: 1px solid #eaf7ff;
          }
          .winddir {
            .fdp-val {
              font-weight: normal;
              font-size: 1.05rem;
            }
          }
          .windspeedmph {
            line-height: 0.85;
            .fdp-val {
              font-weight: normal;
              font-size: 1.9rem;
            }
            .suff {
              font-size: 0.9rem;
              color: var(--color-text-main);
            }
          }
          .windgustmph {
            position: absolute;
            right: 14px;
            top: 70px;
            .fdp-val {
              color: var(--color-text-main-lt);
              font-weight: 400;
              font-size: 1rem;
            }
            .suff {
              display: none;
            }
          }
        }
        .arrow-wrap{
          position: relative;
          width: 100%;
          display: flex;
          height: 40px;
          justify-content: center;
          align-items: center;

          .arrow{
            background: url(../../assets/forecast/arrow-up-black.svg) center center no-repeat;
            width: 100%;
            height: 50px;
            background-size: auto 40%;
            &.no-wind{
              background-image: url(../../assets/forecast/dash-black.svg);
              background-size: 20%;
            }
          }
        }
      }
    }
    .line-graph-wrap{
      width: 100%;
      border-top: 1px solid var(--color-border-alt-lt);
      margin-bottom: 1.5rem;
      .common-simple-line-graph{
        margin-top: 1.5rem;
        g.recharts-layer.recharts-line-dots {
          circle {
            stroke-width: 2;
            r: 3;
            &.alt{
              stroke: var(--brandColor) !important;
            }
            &:first-child {
              outline: 2px solid #84D3F3;
              outline-offset: 0;
              stroke: var(--brandColor);
              border-radius: 30px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
            }
          }
        }
      }
      .labels {
        .label {
          &.alt {
            b, .baromrelin .fdp-val{
              color: var(--brandColor);
            }
          }
          b {
            font-size: 1.2rem;
          }
          .suff {
            font-weight: 300;
            font-size: 1.1rem;
          }
          .fdp {
            .fdp-val {
              font-size: 1.2rem;
            }
            .suff {
              font-weight: 300;
              font-size: 1.1rem;
            }
          }
        }
      }
    }
    .hourly {
      width: 100%;
      background-color: transparent;
      margin: 0;
      .hour {
        padding: 1.3em 1.7em 1.5em;
        .title {
          color: #B9B9B9;
          font-size: 0.95em;
          font-weight: 300;
          margin-bottom: 1em;
          width: @hourlyCellWidth;
          text-align: center;
        }
        .fdp.tempf {
          font-size: 1.25em;
        }
        .weather-icon {
          filter: none;
          width: 2em;
          height: 2em;
        }
      }
    }
    .forecast-container {
      display: flex;
    }
    .location {
      padding: 10px 20px 0px;
      font-size: 1.3em;
      color: var(--color-text-main);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .forecast-summary {
      margin-top: 8px;
      margin-left: 15px;
      margin-right: 15px;
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      .weather-icon {
        background-size: contain;
        height: auto;
      }
      &__currently {
        font-size: 1.8em;
        margin-right: 5px;
      }
      .fdp{
        font-size: 68px;
        padding-left: 5px;
        position: relative;
        color: var(--color-text-main);
        .suff{
          font-size: 13px;
          position: absolute;
          bottom: 6px;
        }
        &.tempf {
          .suff {
            display: none;
          }
          .after {
            font-size: 0.8em;
            position: absolute;
            top: 0;
          }
        }

      }
      .the-rest {
        padding-left: 3em;
        color: var(--color-text-main);
        p {
          font-size: 16px;
          margin: 5px 0;
          line-height: 1.4;
        }
        .high-lows {
          margin-top: 15px;
          span {
            font-size: 1.3em;
            margin-right: 20px;
            color: #CDCDCD;
            &.title-precip {
              span.drop {
                padding-left: 11px;
                background: url(../../assets/forecast/raindrop.svg) left center no-repeat;
              }
            }
            span {
              margin-right: 0;
              font-size: 1em;
              span {
                margin-right: 0;
              }
            }
            .fdp-val, .after {
              color: var(--color-text-main);
            }
          }
        }
      }

    }
    .extended {
      padding: 0;
      border-top: 1px solid var(--color-border-alt-lt);
      .forecast-summary__hourly, .forecast-summary__today {
        color: var(--color-text-main-lt);
        font-size: 1.3em;
        text-align: left;
        font-weight: 300;
        line-height: 1.3;
        margin: 4px 0;
      }
      .forecast-summary__details {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .label {
          font-weight: 700;
          font-size: 1em;
          padding: 15px 0px;
          width: 42%;
          display: flex;
          .label-title {
            flex-grow: 1;
            color: var(--color-text-main);
          }
          .fdp {
            color: var(--color-text-main-lt);
            font-weight: 300;
            //padding-left: 5px;
            text-align: right;
            flex-grow: 1;
          }
        }
      }
      .extended-summary {
        display: flex;
        flex-wrap: wrap;
        padding:20px 20px;
      }
      .other-day-a{
        text-decoration: none;
        .scrollable-wrap{
          width: 1879px;
          .hour.alt{
            background-color: transparent;
          }
        }
      }
      .other-days {
        .component-day-forecast {
          padding: 17px 20px 14px;
          border-top: 1px solid var(--color-border-alt-lt);
          display: flex;
          font-size: 1.3em;
          line-height: 1.5;
          .title {
            flex-grow: 1;
            order: 1;
            width: 25%;
            color: var(--color-text-main);
            position: relative;
            span.day{
              width: 36px;
              display: inline-block;
            }
            span.date{
              font-size: 0.65em;
              color: var(--color-text-main-lt);
              display: inline-block;
              bottom: 2px;
              padding-left: 5px;
              position: relative;
            }
          }
          .weather-icon {
            width: 40px;
            height: 30px;
            order: 2;
            background-position: right;
            flex-grow: 1;
            margin-right: 8px;
          }
          .hl {
            font-weight: 500;
            flex-grow: 1;
            text-align: right;
            order: 4;
            .suff {
              display: none;
            }
            .fdp {
              padding: 0 5px;
              color: var(--color-text-main);
              &:last-of-type {
                color: #B9B9B9;
              }
            }
          }
          .title-precip {
            display: none !important;
          }
          .precip {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            order: 3;
            background: url(../../assets/forecast/raindrop.svg) left 10px top 2px no-repeat;
            width: 45px;
            font-size: 0.65em;
            color: var(--color-text-main-lt);
            position: relative;
            // top: -8px;
            line-height: 1.3;
            padding-left: 10px;
            margin-top: 6px;
            .drop {
              margin-left: 10px;
            }
            .dailyrainin{
              padding-top: 1px;
              font-size: 0.95em;
            }
          }
          &.precip-below-thresh{
            .precip {
              background: none;
              color: transparent;
              margin-top: 0;
            }
          }
        }
        .weather-icon {
          &.fog{
            background-size: 45% auto;
          }
        }
        .device-forecast-widget{
          border-top: none;
          .hourly{
            .hour{
              font-size: 14px;
              padding-top: 10px;
            }
            .unknown{
              flex-grow: 1;
              flex-shrink: 0;
              background: url(../../assets/forecast/crystal-ball.svg) center center no-repeat var(--color-border-main-lt);
              background-size: auto 40%;
              width: 300px;
            }
          }
          .no-hourlies{
            padding: @bigPad;
            text-align: center;
            font-size: 1.3em;
            background-color: var(--color-border-main-lt);
          }
        }
        .no-hourlies{
          a {
            text-decoration: none;
          }
        }
      } 
    }
  }
}
.app.v4.ios-c-or-b .user-home-page .forecast .line-graph-wrap .common-simple-line-graph g.recharts-layer.recharts-line-dots circle:first-child {
  outline: none !important;
}
