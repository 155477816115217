@import '../../styles/mixins.less';

.device-leak-widget {
  .line{
    display: flex;
    justify-content: space-between;
    align-items: center;
    input{
      width: 100%;
      padding-left: 10px;
      &:focus{
        padding-left: 10px;
      }
    }
    .icon{
      font-size: 0.7em;
      color: @color-ok;
      background: url(../../assets/common/check-green.svg) center right no-repeat;
      background-size: auto 1.4em;
      padding-right: 1.8em;
      line-height: 1.5em;
      margin-right: 10px;
    }
    &.leak-1{
      background-color: @color-alert;
      color: @white;
      border-top: 2px solid @white;
      input{
        color: @white;
      }
      .icon{
        color: @white;
        padding-right: 1.9em;
        background-image: url(../../assets/common/alert-white.svg);
      }
    }
    &.leak-2{
      background-color: @color-warn;
      border-top: 2px solid @white;
      input{
        color: @white;
      }
      .icon{
        color: @white;
        padding-right: 2em;
        background-image: url(../../assets/common/offline-white.svg);
      }
    }
  }
}

.device-widget.leak{
  &.expanded {
    .device-leak-widget {
      .line {
        font-size: 1em;
        font-size: clamp(0.8em, 2vw, 1em);
        .icon {
          font-size: 1em;
          font-size: clamp(0.8em, 1vw, 1em);
        }
      }
    }
  }
}