@import '../../styles/mixins.less';

@keyframes wind-ripple {}

.small-compass {
  .compass-create(165px);
}
.large-compass{
  .compass-create(270px);
}
.expanded {
  .wind-widget {
    justify-content: flex-start;
  }
}
.wind-widget {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: flex-start;
  //padding-bottom: 30px;
  &.clicked {
    .corner-wrap .fdp{
      &.degrees {
        display: block;
      }
      &.direction {
        display: none;
      }
    }
  }
  .val{
    position: absolute;
    width: 100%;
    color: var(--color-text-widget-big-number);
    z-index: 2;
    text-align: center;
    top: 27%;
    > .fdp{
      font-size: 2.5vw;
      font-size: clamp(1.9em, 2.5vw, 2em);
      line-height: 1;
    }
    .label{
      font-size: 0.75em;
      line-height: 0.5em;
      .fdp-val{
        display: none;
      }
    }
    .max{
      font-weight: 300;
      display: block;
      margin-top: 10px;
      line-height: 1.2;
      .peak{
        display: block;
       // font-size: 1.2vw;
        //font-size: clamp(0.6em, 1.2vw, 0.65em);
        font-size: 14px;
      }
      .maxdailygust {
        color: @blue;
        font-weight: normal;
        font-size: 1em;
        line-height: 1;
        .suff {
          display: none;
        }
      }
    }
    &.maxdailygust{
      > .fdp{
        //font-size: 7vw;
        //font-size: clamp(0.9em, 7vw, 2em);
      }
    }
    .max {
      //display: none;
    }
  }
  .compass{
    .compass-create(150px);
    position: relative;
    display: block;
    margin: 15px auto;
    transition: transform 4000ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
    background: url(../../assets/wind-circle.svg) center center no-repeat;
    background-size: 96%;
  }
  .corner-wrap{
    position: absolute;
    bottom: @generalPad;
    left: @generalPad;
    color: var(--color-text-main);
    font-size: 1.2vw;
    font-size: clamp(0.6em, 1.2vw, 0.65em);
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
    .fdp{
      margin-top: 4px;
      color: var(--brandColor);
      font-size: 2.5vw;
      font-size: clamp(0.7em, 2.5vw, 1.7em);
      display: block;
      font-weight: normal;
      &.degrees{
        display: none;
      }
    }

    &.right{
      text-align: right;
      left: auto;
      right: @generalPad;
    }
  }
  .expanded-wrap{
    display: flex;
    margin-top: @generalPad;
    padding: 0 @generalPad/2;
    .avg{
      flex: 1;
      background-color: var(--color-bg-main);
      padding: @generalPad;
      margin: @generalPad/2;
      h3{
        font-weight: 300;
        color: var(--color-text-main);
        margin-bottom: 5px;
        width: 100%;
        font-size: 0.85em;
      }
      .flex{
        display: flex;
        justify-content: flex-start;
        justify-content: space-between;
      }
      .fdp{
        //font-size: 1.3em;
        line-height: 1;
        font-weight: 400;
        .suff{
          //color: @gray-dark-amb;
          //text-transform: uppercase;
          //font-size: 0.7em;
        }
      }
    }

  }
}
.device-widget.wind{
  display: flex;
  flex-direction: column;
  &.expanded{
    .corner-wrap{
      font-size: 1.3vw;
      font-size: clamp(0.8em, 1.3vw, 0.8em);
    }
    .compass{
      .large-compass;
    }
    .val{
      top: 80px;
      font-size: 1.5em;
      .max{
        padding-top: 15px;
        display: block;
        .peak {
          font-size: 16px;
        }
      }
    }
    
  }
}
.component-stand-alone-loader{
  .device-widget.wind{
    .val {
      top: 116px;
      > .fdp {
        font-size: 3em !important;
      }
    }  
  }

}
.device-outside-bar{
  .wind-widget{
    flex-direction: row;
    align-items: center;
    padding-bottom: 0;
    .val{
      top: 35px;
      > .fdp{
        font-size: 26px;
      }
      .max{
        margin-top: 5px;
        @media (max-width: @tablet-max) {
          margin-top: 3px;
        }
        .peak {
          font-size: 12px;
          line-height: 0.8;
        }
        .maxdailygust {
          //font-size: 1.2em;
          color: var(--color-text-main);
          @media (max-width: @tablet-max) {
            font-size: 16px;
          }
        }
      }
      .label{
        font-size: 0.5em;
        @media (max-width: @tablet-max) {
          font-size: 10px;
        }
      }
    }
    
    
    .rel{
      margin-right: 10px;
    }
    .compass{
      .compass-create(100px);
    }
    .corner-wrap{
      padding: 0px 10px;
      position: relative;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      .fdp {
        color: var(--color-text-main);
      }
      &.right{
        //border-left: 1px solid @gray-light-amb;
      }
    }
  }
}

@media (min-width: 1400px) {
  .wind-widget {
    justify-content: center;
    .rel {
      padding-bottom: 30px;
    }
    .compass{
      .compass-create(180px);
    }
  }
}

@media (max-width: @tablet-max) {
  .small-wind-widget{
    .wind-widget{
      font-size: 0.95em;
    }
    .compass{
      .small-compass;
    }
    .val{
      top: 60px;
      > .fdp {
       
      }
      &.maxdailygust{
        > .fdp {
          font-size: 1.4em;
        }
      }
      .max{
        padding-top: 0px;
        //display: none;
      }
    }
  }
  .device-widget.wind.expanded{
    .small-wind-widget;
    .corner-wrap{
      font-size: 0.8em;
    }
    .expanded-wrap{
      .avg{
        padding: 10px;
        h3{
          font-size: 14px;
        }
        .fdp{
          font-size: 1vw;
          font-size: clamp(1em, 1vw, 1.2em);
          //padding-right: 8px;
        }
      }
    }
    .val {
      top: 52px;
      .max {
        .peak {
          font-size: 14px;
        }
      }
    }
  }
  .wind-widget{
    font-size: @tablet-font-grow;
    padding-bottom: 11px;
    justify-content: center;
    .compass{
      .large-compass;
    }
    .val{
      top: 100px;
      .max{
        padding-top: 5px;
        font-size: 1.4vw;
        font-size: clamp(0.6em, 1.4vw, 0.6em);
        .peak {
          font-size: 18px;
        }
        .maxdailygust {
          font-size: 2.4vw;
          font-size: clamp(1em, 2.4vw, 1.5em);
          line-height: 1.3;
        }
      }
      > .fdp {
        font-size: 10vw;
        font-size: clamp(1em, 10vw, 2em);
      }
    }
    .corner-wrap {
      .fdp {
        font-size: 1vw;
        font-size: clamp(1.8em, 1vw, 2.2em);
      }
    }
  }
  .device-device-popup.windspeedmph{
    a.btn-primary{
      margin-top: 5px;
    }
    .wind-widget{
      .small-wind-widget;
      .val{
        top: 63px;
        > .fdp {
          font-size: 53px;
        }
      }
    }
  }
}
@media (max-width: @tablet-phone-max) {
  .device-widget.wind .wind-widget {
    justify-content: center;
  }
  .device-widget.wind.expanded {
    .val {
      top: 56px;
    }
    .expanded-wrap{
      .avg{
        .fdp{
          font-size: 1vw;
          font-size: clamp(1em, 1vw, 1.2em);
        }
      }
    }
    .wind-widget {
      justify-content: flex-start;
      .compass {
        .compass-create(180px);
      }
    }
  }
  .wind-widget {
    .val {
      .max {
        font-size: 1.2vw;
        font-size: clamp(0.6em, 1.2vw, 0.6em);
        .maxdailygust {
          font-size: 1.4em;
        }
      }
    }
  }
}
@media (max-width: @phone-max) {
  .device-widget.wind.expanded{
    .wind-widget{
      .val{
        .max{
          padding-top: 10px;
          .peak {
            font-size: 18px;
          }
        }
      }
    }
  }
  .device-device-popup.windspeedmph{
    .wind-widget{
      .val{
        top: 63px;
        .label{
          line-height: 1;
        }
        &.maxdailygust{
          > .fdp{
            font-size: 2.3em;
          }
        }
        > .fdp {
          font-size: 11vw;
          font-size: clamp(1em, 11vw, 4em);
        }
      }
    }
  }
  .device-widget.wind .wind-widget {
    justify-content: flex-start;
    .val{
      top: 80px;
      
    }
  }
  .device-widget.wind,
  .device-widget.wind.expanded{
    .wind-widget{
      font-size: 22px;
      .corner-wrap{
        font-size: 18px;
        left: @generalPad;
        bottom: -25px;
        .fdp {
          font-size: 22px;
        }
        &.right{
          right: @generalPad;
        }
      }
      .compass{
        .compass-create(270px);
      }
      .val{
        font-size: 1.2em;
        .max{
          display: block;
          font-size: 1.2vw;
          font-size: clamp(0.7em, 1.2vw, 0.8em);
          .maxdailygust {
            font-size: 24px;
          }
        }
        &.maxdailygust{
          > .fdp {
            font-size: 2.5em;
            line-height: 1.3;
          }
        }
      }
      .expanded-wrap{
        font-size: 0.7em;
        border-bottom: 1px solid @gray-light-amb;
        margin-bottom: 10px;
        margin-top: 10px;
        padding-bottom: 10px;
        h3{
          font-size: 0.9em;
        }
        .avg {
          font-size: 1vw;
          font-size: clamp(1.2em, 1vw, 2.2em);
        }
      }
    }
  }
}
