@import '../styles/mixins.less';

@border-color: darken(@bg, 10%);
.react-datepicker{
  font-size: 1.2rem;
  color: var(--color-text-main);
  border-color: @border-color;
  background-color: var(--color-bg-page-block-main);
  .react-datepicker__header{
    background-color: var(--color-bg-page-block-main);
    border-radius: 0;
    border-color: @border-color;
  }
  .datepicker__month{
    margin: 0.6rem;
    border-color: @border-color;
    border-radius: 0;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    margin: 0.5em;
    color: var(--color-text-main);
  }
  .react-datepicker__day-name,
  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    color: var(--color-text-main);
    font-size: 1em;
    font-weight: normal;
  }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range{
    background-color: var(--color-text-main-lt);
  }
}

.app .component-date-picker {
  position: relative;
  a {
    text-decoration: none;
    font-size: 15px;
    color: var(--color-text-main);
    &:hover{
      text-decoration: none;
    }
  }
  > a {
    background: url(../assets/triangle-down-blue.svg) right center no-repeat;
    padding-right: 20px;
  }
  &.open{
    > a{
      background-image: url(../assets/triangle-up-blue.svg);
    }
  }
  .picker{
    @h: 368px;
    position: absolute;
    z-index: 14;
    background-color: var(--color-bg-page-block-main);
    bottom: -@h - 5px;
    right: 0;
    padding: @generalPad;
    .general-shadow;
    height: @h;
    width: 243px;
    transition: 0.3s width ease-out;
    .close{
      position: absolute;
      bottom: 10px;
      right: @generalPad;
      z-index: 3;
      color: @blue;
      font-size: 11px;
      .x{
        position: relative;
        top: -1px;
        left: -1px;
      }
    }
    .pw2{
      width: 0;
      overflow: hidden;
      transition: 0.3s width ease-out;
    }
    header{
      padding-bottom: 8px;
      a{
        font-size: 13px;
        display: inline-block;
        padding-right: 15px;
        background: url(../assets/calendar-gray.svg) left center no-repeat;
        background-size: 17px;
        padding-left: 25px;
        line-height: 1.3;
        &:hover,
        &.active{
          color: @blue;
          &.single{
            background-image: url(../assets/calendar-blue.svg);
          }
          &.multi{
            background-image: url(../assets/calendar-range-blue.svg);
          }
        }
        &.multi{
          background-image: url(../assets/calendar-range-gray.svg);
        }
        &:nth-of-type(2){
          padding-right: 0px;
        }
      }
      .select-wrap{
        margin-top: 15px;
        display: none;
        span{
          font-size: 12px;
        }
      }
      select{
        float: right;
        appearance: none;
        background: url(../assets/triangle-down-gray.svg) right 8px center no-repeat @bg;
        border: none;
        color: @blue;
        display: inline-block;
        width: auto;
        padding-right: 33px;
        font-size: 12px;
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
    > div{
      display: flex;
      justify-content: space-between;
    }
    label{
      display: block;
      text-transform: none;
      font-size: 12px;
    }
    .btn-wrap{
      display: flex;;
      justify-content: flex-end;
      padding: 10px;
      position: absolute;
      width: 100%;
      left: 0px;
      bottom: 0px;
      a{
        &.btn-primary{
          color: @white;
          padding-right: 35px;
          padding-left: 35px;
          margin-left: 10px;
        }
      }
    }
  }
  &.multi{
    .picker{
      width: 638px;
    }
    .pw2{
      width: 213px;
    }
    .chooser{
      background-color: var(--color-bg-chart-date-picker-main);
      padding: 10px 15px 10px 10px;
      align-self: flex-start;
      a{
        font-size: 13px;
        padding-bottom: 6px;
        display: block;
        color: var(--color-text-main-lt);
        &.active{
          color: @blue;
        }
        &.plus{
          background: url(../assets/awn+blue.svg) right -3px no-repeat;
          padding-right: 29px;
        }
      }
    }
  }
}
@media (max-width: @phone-max){
  .app .component-date-picker {
    .picker{
      > div{
        flex-direction: column;
      }
      header{
        .select-wrap{
          display: block;
        }
      }
      .btn-wrap{
        display: none;
      }
      &.btn-avail{
        padding-top: 100px;
        .btn-wrap{
          bottom: auto;
          top: 0px;
          display: flex;
          flex-direction: column-reverse;
          border-bottom: 1px solid @border-color;
        }
      }
    }
    &.multi{
      .chooser{
        display: none;
      }
      .picker{
        width: 244px;
        height: 642px;
        bottom: -646px;
      }
    }
  }
}
@media (max-width: @super-small) {
  .app .component-date-picker {
    padding-bottom: 10px;
  }
}