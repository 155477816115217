@import '../../styles/mixins';

.device-widget.generic{
  .column;
}
.device-generic-widget {
  flex-grow: 1;
  .column;
  justify-content: center;
  .big-number{
    margin-bottom: 25px;
    .suff{
      font-size: 0.3em;
    }
    .device-formatted-data-point.evapratelbft3hr,
    .device-formatted-data-point.twlwm2,
    .device-formatted-data-point.crosswindmph,
    .device-formatted-data-point.thinrc,
    .device-formatted-data-point.thiyousef,
    .device-formatted-data-point.humidityratiogplb,
    .device-formatted-data-point.airflowcfm{
      .column;
      .suff{
        font-size: 0.3em;
      }
    }
  }
}
