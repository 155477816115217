@import '../../styles/mixins.less';


.app.km.light .user-kmsupport .prodSupport,
.app.light .user-kmsupport .prodSupport {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  font-size: 1.75rem;

  // align-items: stretch;
  ul {
    list-style: square inside none;
    padding: 0 0 0rem 0;
  }

  li {
    text-indent: -2.75rem;
    margin: 0.33rem 0.5rem 1rem 1.5rem;
  }

  img {
    width: 250px;
    display: inline-block;
    border: solid lightgray 1px;
    // background-color: white;
    box-shadow: 2px 2px 2px lightgrey;
    border-radius: 2px;
    padding: 20px;
  }

  .page-img {
    vertical-align: middle;
    // border: solid gray 1px;
    flex-grow: 0;
    padding: 20px;
  }

  .videos {
    // border: solid gray 1px;
    flex-grow: 1;
    padding: 20px;
    width: 300px;
  }

  .manuals {
    // border: solid gray 1px;
    flex-grow: 1;
    padding: 20px;
    width: 300px;

  }
}


.user-kmsupport {
  .banner-wrap {
    margin: @bigPad;
    margin-bottom: 0;
  }

  .top-bar {
    display: flex;
    margin-bottom: 15px;
    align-items: flex-end;

    >* {
      padding: 0 10px;
      margin-bottom: 0px;
    }

    .csv {
      margin-left: auto;
    }
  }

  header.main {
    .children {
      margin-left: auto;
      padding-right: 90px;
    }
  }

  .whitelist {
    h3 {
      width: 250px;

      a {
        float: right;
      }
    }

    textarea {
      border-radius: 5px;
      height: 200px;
      width: 250px;
    }
  }

  .alert-banner {
    input {
      display: block;
      width: 400px;
      margin-bottom: 10px;
    }
  }
}