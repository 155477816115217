@import '../../styles/mixins';

.device-wxw-edit {
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }
  .btn-wrap{
    background-color: var(--color-bg-page-block-main);
    padding: @generalPad 0px;
    .btn{
      padding: 10px 40px;
      font-size: 1.2em;
    }

  }
  .wxw-layouts{
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 45%;
      margin-right: 5%;
      margin-bottom: 25px;
      &:nth-of-type(2n){
        margin-right: 0;
      }
      .wxw-layout{
        margin-top: 5px;
        width: 100%;
        height: 200px;
        background: url(../../assets/wxw/layout-0.jpg) center center no-repeat;
        background-size: contain;
        &.wxw-layout-1{
          background-image: url(../../assets/wxw/layout-1.jpg);
        }
        &.wxw-layout-2{
          background-image: url(../../assets/wxw/layout-2.jpg);
        }
        &.wxw-layout-3{
          background-image: url(../../assets/wxw/layout-3.jpg);
        }

      }
    }
  }
  .component-map,
  .component-map .map,
  .map-with-forecast{
    height: 500px;
  }
  .map-with-forecast{
    position: relative;
    .component-device-chooser{
      position: absolute;
      top: 10px;
      left: 10px;
      width: 350px;
      max-width: 95%;
    }
    .component-fetches-forecast,
    .layer-btns{
      display: none;
    }
  }

  .wxw-example{
    .editable-title{
      font-size: 18px;
      width: 100%;
      margin-bottom: 5px;
    }
    .btn-wrap{
      display: flex;
      justify-content: space-between;
      a {
        text-decoration: none;
      }
    }
  }
  &.loading{
    a.blank {
      display: none !important;
    }
  }
  .device-wxw-preview{
    margin-bottom: 15px;
    align-items: normal;
    max-height: 50vh;
    .wxw-widgets{
      flex: 1;
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      /* two rows, both with equal height */
      grid-template-columns: repeat(3, 1fr);
      /* three columns, all with equal width */
      gap: 0.8%;
      padding: 0.8%;
      padding-bottom: 8%;

      > a {
        border-radius: 8px;
        &.blank{
          background-color: #aab4bd;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          &:before {
            padding: clamp(0px, 0vw, 25px);
            font-size: clamp(0px, 1.5vw, 18px);
            text-align: center;
            content: 'Click to customize the tile\'s parameter and station';
            color: @white;
          }
        }
      }

      &.layout-1,
      &.layout-3,
      &.layout-2 {
          grid-template-rows: 1.7fr 1fr 1.7fr;
          grid-template-columns: repeat(4, 1fr);
      }

      &.layout-1 {
          > a {
              grid-column: span 1;

              &:nth-child(1) {
                  grid-row: span 2;
                  grid-column: span 2;
              }

              &:nth-child(4) {
                  grid-row: 3 / span 1;
                  grid-column: 1 / span 1;
              }

              &:nth-child(5) {
                  grid-row: 3 / span 1;
                  grid-column: 2 / span 1;
              }

              &:nth-child(6) {
                  grid-row: 2 / span 2;
                  grid-column: 3 / span 2;
              }
          }
      }

      &.layout-2 {
          > a {
              grid-column: span 1;
              grid-row: span 1;

              &:nth-child(3) {
                  grid-row: 1 / span 2;
                  grid-column: 3 / span 2;
              }

              &:nth-child(4) {
                  grid-row: 3 / span 1;
                  grid-column: 1 / span 1;
              }

              &:nth-child(5) {
                  grid-row: 3 / span 1;
                  grid-column: 2 / span 1;
              }

              &:nth-child(6) {
                  grid-row: 3 / span 1;
                  grid-column: 3 / span 1;
              }

              &:nth-child(7) {
                  grid-row: 3 / span 1;
                  grid-column: 4 / span 1;
              }
          }
      }

      &.layout-3 {
          grid-template-rows: 1fr 1fr 1.25fr;

          > a {
              grid-column: span 1;
              grid-row: span 1;

              &:nth-child(1) {
                  grid-row: 1 / span 1;
                  grid-column: 1 / span 2;
              }

              &:nth-child(2) {
                  grid-row: 1 / span 2;
                  grid-column: 3 / span 2;
              }

              &:nth-child(3) {
                  grid-row: 2 / span 1;
                  grid-column: 1 / span 2;
              }
          }
      }
    }
  }
  &.editing{
    h2 .btn{
      // display: none;
    }
  }
}

@media (max-width: @phone-max) {
  .device-wxw-edit {
    padding-bottom: 30px;
    .wxw-layouts{
      > div {
        width: 100%;
      }
    }
    .btn-wrap{
      position: fixed;
      bottom: 50px;
      left: 0px;
      width: 100%;
      text-align: center;
      padding: @generalPad;
      align-items: center;
    }
    .device-wxw-preview{
      .wxw-widgets{
        > a {
          &.blank{
            &:before {
              font-size: clamp(11px, 1.5vw, 18px);
              content: 'Customize';
            }
          }
        }
      }
    }
  }
}