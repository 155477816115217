@import '../styles/mixins.less';

.component-forecast {
  transition: all 0.3s ease-out;
  user-select: none;
  .forecast{
    transition: all 0.3s ease-out;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    user-select: none;
    background-color: var(--color-bg-map-forecast-main);
    color: var(--color-text-map-forecast-main);
    border-radius: 15px;
    padding: 20px 20px 40px;
    .link{
      font-size: 0.8em;
      color: @blue;
      text-decoration: underline;
      padding-top: 4px;
    }
    .currently{
      font-size: 2.8rem;
      text-align: center;
    }
    .fdp .suff{
      display: none;
    }

  }
  .close{
    @wh: 10px;
    background: url(../assets/x-black.svg) center center no-repeat;
    background-size: 100%;
    height: @wh;
    width: @wh;
    display: block;
    position: absolute;
    right: 10px;
    top: 9px;
  }
}
@media (max-width: @phone-max) {
  .component-forecast{
    .forecast{
    }
  }

}
