// dark mode

.app.dark{

  // General Colors - try these first
  --color-bg-base: @black;
  --color-bg-main: @gray-darkest-amb;
  --color-border-main-lt: @gray-dark-alt;
  --color-border-alt-lt: @gray-dark-alt;

  // text
  --color-text-main: @white; 
  --color-text-main-md: #657888; 
  --color-text-main-lt: #97A2AD; 
  --color-text-lt: #000;

  // buttons
  // --color-bg-btn-primary: var(--brandColor);
  // --color-bg-hover-btn-primary: #23B7F1;
  // --color-bg-active-btn-primary: #038BC1;
  // --color-btn-primary: @white;
  --color-bg-find-location-btn-primary: var(--brandColor);
  --color-border-find-location-btn-primary: var(--brandColor);
  --color-text-find-location-btn-primary: @white;

  // header
  //  --color-bg-app-header-main: var(--brandColor);

  // page container 
  --color-bg-page-container-main: @black;
  --color-bg-page-block-main: @gray-darkest-amb;
  --color-text-page-block-main: @white;

  // device widgets
  --color-bg-widget-main: #333435;
  --color-text-widget-main: var(--brandColor);
  --color-bg-widget-icon: var(--brandColor);
  --color-border-widget-header-main: #48484a;
  --color-text-widget-header-main: @white;
  --color-bg-widget-rounded-btns-main: @gray-dark-amb;
  --color-border-widget-rounded-btns-main: #48484a;
  --color-bg-table-widget-line-alt: @gray-darker-amb;
  --color-bg-social-post-widget-main: @gray-darkest-amb;
  --color-bg-social-post-widget-title-main: #3f3f41;
  --color-text-social-post-widget-title-main: @text-light;
  --color-text-widget-big-number: rgba(255, 255, 255, 0.8);
  --color-bg-widget-snapshot: @gray-darker-amb;
  // for pressure widget
  --bg-image-gauge: url(../../assets/gauge-dm.svg);
  --bg-image-gauge-1: url(../../assets/gauge-1-dark-mode.svg);
  --bg-image-gauge-2: url(../../assets/gauge-2-dark-mode.svg);

   // sidebar
  --color-bg-dashboard-nav: @white;
  --color-bg-navbar-brand: #eeeeee;
  --color-bg-navbar-nav: @white;

  // dashboard
  --color-bg-dashboard-main: @black;
  --color-bg-dashboard-header-main: @black;
  --color-text-dashboard-header-main: @white;
  --color-bg-dashboard-tab-main: @black;
  --color-bg-active-dashboard-tab-main: @black;
  --color-border-dashboard-tab-main: var(--brandColor);

  // nav
  --image-bg-logo: url(../../assets/AWN_full_logo_for_dkbkgd.svg);
  --color-bg-logo-main: #3b3b3c;
  --color-bg-dashboard-nav-main: @gray-darkest-amb;
  --color-bg-active-dashboard-nav-main: #3b3b3c;
  --color-text-active-dashboard-nav-main: @white;

  // map
  --color-bg-map-btn-main: @white;
  --color-bg-map-layer-btn-main: @black;
  --color-bg-map-search-main: @black;
  --color-bg-map-search-btn-main: var(--brandColor);
  --color-bg-map-forecast-main: @black;
  --color-text-map-forecast-main: @white;

  --color-bg-device-chooser-main: @gray-darker-amb;
  --color-text-device-chooser-main: @text-light;

  //outside bar
  --color-bg-outside-bar-main: @black;

  // cards
  --color-bg-card-top-main: @gray-darkest-amb;
  --color-text-device-popup-top-main: @white;
  --color-bg-card-content-main: @gray-darkest-amb;
  --color-bg-card-bottom-main: #3f3f41;
  --color-text-card-content-main: @white;
  --color-border-card-main: @gray-darker-amb;

  --color-border-line-separator-main: #48484a;
  --color-bg-high-low-graphs-main: #2C2C2E;

  // forecast widget
  --color-bg-forecast-day-main: #48484A;
  --color-bg-forecast-day-alt: #48484A;

  // react modal
  --color-bg-modal-main: @gray-dark-amb;
  --color-text-modal-main: @white;

  // social create post
  --color-bg-create-post-textarea-main: @gray-darkest-amb;
  --color-text-create-forecast-option-main: @white;
  --color-border-create-forecast-option-main: @gray-darkest-amb;
  --color-bg-hover-create-forecast-option-main: @gray-darkest-amb;
  --color-bg-hover-create-forecast-option-day-main: @black;
  --color-bg-create-forecast-icon-chooser-main: @gray-darkest-amb;
  --color-text-create-forecast-icon-chooser-main: @white;
  --color-bg-create-forecast-icon-main: @gray-darkest-amb;
  --color-text-create-forecast-input-main: @white;
  --color-link-create-forecast-icon-chooser-main: @white;
  --color-bg-create-my-forecast-main: @black;

  // devices 
  --color-bg-connections-main: @gray-darkest-amb;

  // charts and graphs
  --color-bg-chart-header-main: @gray-dark-amb;
  --color-bg-chart-row-even-main: @gray-darkest-amb;
  --color-bg-chart-row-odd-main: @gray-dark-amb;
  --color-bg-chart-date-picker-main: @gray-dark-amb;

  // device chooser
  --color-bg-option-selected: var(--color-bg-widget-main);

  // misc
  --color-shadow-main: rgba(255, 255, 255, 0.2);

  //sitewide modals 
  --bg-image-close: url(../../assets/x-gray.svg);

  //payment modal
  --color-bg-payment-card-main: @gray-dark-alt;
  --color-bg-video-main: @gray-dark-alt;
  --color-bg-video-banner-main: @gray-darker-amb;
  //notifications 
  --color-bg-notification-main: rgba(255,255,255,0.7);

  .recharts-tooltip-wrapper {
    .tooltip,
    .recharts-default-tooltip{
      background-color: var(--color-bg-page-container-main) !important;
      color: var(--color-text-main);
    }
  }

  // mobile homepage
  &.home-page {
    //scrollable forecast
    .user-mobile-home-page.forecast .settings-nav .show-settings,
    .user-home-page .forecast .scrollable-wrap .wind .arrow-wrap .arrow {
      filter: invert(1);
    }
    .user-home-page .forecast .scrollable-wrap .line-graph-wrap .common-simple-line-graph .recharts-surface .recharts-line path {
      stroke: @white;
    }
    .user-home-page .forecast .scrollable-wrap .line-graph-wrap .common-simple-line-graph .recharts-surface .recharts-line g.recharts-layer.recharts-line-dots circle {
      stroke: @white;
      fill: var(--color-bg-main);
    }
    .navbar-brand {
      background-color: transparent !important;
    }
  }

  .user-home-page .forecast .extended .other-days .device-forecast-widget .hourly .unknown {
    background-color: #2c2c2e !important;
    background-image: url(../../assets/forecast/crystal-ball-dark.svg)!important;
  }

  @media (max-width: @phone-max) {
    .user-home-page .mobile-post a.component-image-upload-link .camera { 
      filter: invert(0) !important;
    }
    .user-home-page a.back {
      background-image: url(../../assets/arrow-left-mobile_white.svg)!important;
    }
    .user-home-page {
      a.search-btn {
        background-color: var(--color-bg-map-layer-btn-main) !important;
        background-image: url(../../assets/search.svg) !important;
        border: none !important;
        &:hover {
          background-color: #09a8e6 !important;
        }
      }
    }
  }

  .device-forecast-widget .other-days .component-day-forecast.title-s, 
  .device-forecast-widget .hourly .hour.alt, 
  &.v4 .user-home-page .forecast .scrollable-wrap .wind .label.alt,
  &.v4 .user-home-page .forecast .scrollable-wrap .uv-index .label.alt,
  .common-data-popup .content .field:nth-child(even),
  .table-striped > tbody > tr:nth-of-type(odd) { 
    background-color: @gray-darker-amb; 
  }
  &.v4 .user-home-page .forecast .scrollable-wrap .wind .label.alt,
  &.v4 .user-home-page .forecast .scrollable-wrap .uv-index .label.alt{
    border-top: @gray-darker-amb; 
  }
  .page-container, .device-device-data-table-wrap.tz .data-table, .device-device-list-rows .device-line.v2 .block.connections .tabs > a.open, .device-device-list-rows .device-line.v2 .block.connections .tabs > a:hover {
    background-color: @black;
  }
  .navbar-brand {
    background-color: #2c2c2e !important;
  }
  .social-create-forecast .day-forecast {
    border-color: @black;
  }
  .component-device-chooser .wrap {
    // border: none;
    //background-color: @black;
  }
  .form-control,
  .component-device-chooser .open > .search input, .device-device-list-rows .device-line.v2 .service .bottom .form .form-control, .component-location .autocomplete, .component-timezone-picker .css-2o5izw, .component-timezone-picker .css-vj8t7z, .component-location input[type="text"], .device-create-device .body .form-control, .device-share-device-modal input[type="text"], .device-share-device-modal input[type="text"][readonly], .device-share-device-modal textarea, select.form-control {
    background-color: @black;
    border-color: @black;
  }
  .form-control,
  .device-create-device .body .form-control{
    color: @white;
  }
  .device-outside-bar.open .tabs.inverse a.active {
    background-color: @black !important; 
  }
  .device-dashboard-wrap a.expand, .social-notifications .notifications, .device-air-widget .wrap .meter .needle, .component-forecast .close, .device-export-data .close.export{ filter: invert(1);  }
  .device-outside-bar.open .tabs.inverse a {
    background: #000;
  }
  .device-dashboard-wrap .view-btns a.btn-circle:not(.active){
    background-color: var(--color-bg-widget-main);
  }
  .device-create-webcam .camera:before {
    filter: grayscale(0);
  }
  .social-comments .comments {
    border-bottom: 1px solid #48484a;
  }
  .device-widget.air .device-air-2-widget .gauge-wrap .arrow, .device-widget.airin .device-air-2-widget .gauge-wrap .arrow, .device-widget.aqin .device-air-2-widget .gauge-wrap .arrow, .device-air-2-widget .gauge-wrap .arrow {
    filter: brightness(600%) !important;
  }
  .hourly-wrap, .device-quick-view-widget, .device-summary-table .device-device-data-table .scroller, .device-outside-bar > .wrap {
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #48484A;
      outline: 1px solid #48484A;
    }
  }
  .social-comments form .form-control {
    background-color: @gray-darkest-amb;
    color: var(--color-text-main);
  }
  .social-post.type-widget-my-forecast {
    background-color: @gray-darkest-amb;
  }
  .favstar:not(.on),
  .social-create-post .share-wrap .twitter, .social-create-post .share-wrap .email, .social-create-post .share-wrap .fb {
    filter: invert(1);
  }
  .recharts-cartesian-grid rect {
    fill: #000;
  }
  .device-outside-bar > .top .close {
    background: url(../../assets/x-blue.svg) center center no-repeat;
    background-size: 45%;
  }
  .component-device-chooser .header-wrap a.header {
    background: url(../../assets/down-carrot.svg) right 10px center no-repeat;
  }
  .device-device-list-rows .device-line.v2 .block.connections .tabs > a.pws {
    background-image: url(../../assets/pws-weather-white.svg)
  }
  .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    background-color: @black;
    color: @white;
  }
  .social-post.type-widget-wind .wind-widget .compass,
  .wind-widget .compass {
    background: url(../../assets/wind-circle-dm.svg) center center no-repeat;
    background-size: 96%;
  }
  .pressure-widget .gauge .compass {
    filter: invert(1);
  }
  .rain-widget .outer-wrap .rain-wrap .rain-tube .bg:before {
    background:  url(../../assets/rain-ticks-dm.svg) 0px 0px repeat-y;
    background-size: 30%;
  }
  .device-dashboard-wrap .dashboard-top .top-toggle, .device-dashboard-wrap .dashboard-top .full-screen {
    border-color: var(--color-bg-map-layer-btn-main);
  }
  .component-map .layer-btns {
    a {
      border-color: var(--color-bg-map-layer-btn-main);
      &:hover {
        background-color: var(--brandColor);
      }
      &.main-btn{
        &.plus{
          background-image: url(../../assets/awn+white.svg);
        }
        &.active{
          &.plus-fires{
            background-image: url(../../assets/map/btn-fires-white.svg);
            background-size: 70%;
          }
          &.plus-cyclones{
            background-image: url(../../assets/map/btn-cyclones-white.svg);
            background-size: 70%;
          }
          &.plus-road{
            background-image: url(../../assets/map/btn-road-white.svg);
            background-size: 70%;
          }
          &.plus-air{
            background-image: url(../../assets/map/btn-air-white.svg);
            background-size: 70%;
          }
          &.plus-river{
            background-image: url(../../assets/map/btn-river-white.svg);
            background-size: 70%;
          }
          &.plus-snow{
            background-image: url(../../assets/map/snow-white.svg);
            background-size: 70%;
          }
        }
      }
    }
    .plus-layers{
      .plus-flyout{
        a{
          &.active{
            color: @blue;
          }
          &.plus-fires{
            background-image: url(../../assets/map/btn-fires-white.svg);
          }
          &.plus-cyclones{
            background-image: url(../../assets/map/btn-cyclones-white.svg);
          }
          &.plus-road{
            background-image: url(../../assets/map/btn-road-white.svg);
          }
          &.plus-air{
            background-image: url(../../assets/map/btn-air-white.svg);
          }
          &.plus-river{
            background-image: url(../../assets/map/btn-river-white.svg);
          }
          &.plus-snow{
            background-image: url(../../assets/map/snow-white.svg);
          }
        }
      }
    }
    .radar{
      background-image: url(../../assets/map/btn-radar-white.svg);
      background-color: var(--color-bg-map-layer-btn-main);
      border-radius: 100%;
      &.active,
      &:hover{
        background-image: url(../../assets/map/btn-radar-white.svg);
        background-color: var(--brandColor);
      }
    }
    .temp{
      background-image: url(../../assets/map/btn-temp-white.svg);
      background-size: 71%;
      &.active,
      &:hover{
        background-image: url(../../assets/map/btn-temp-white.svg);
      }
    }
    .wind{
      background-image: url(../../assets/map/btn-wind-white.svg);
      background-size: 72%;
      &.active,
      &:hover{
        background-image: url(../../assets/map/btn-wind-white.svg);
      }
    }
    .sidebar-btn{
      background-image: url(../../assets/map/btn-sidebar-white.svg);
      background-size: 50%;
      &:hover,
      &.active{
        background-image: url(../../assets/map/btn-sidebar-white.svg);
      }
    }
  }
  .device-accessories > header > a.caret,
  .device-dashboard-wrap.no-dash-top .dashboard-top .top-toggle{
    background-image: url(../../assets/down-carrot.svg);
  }
  .device-dashboard-wrap .dashboard-top .top-toggle {
    background-image: url(../../assets/up-carrot.svg);
  }
  .device-dashboard-wrap .dashboard-top .full-screen {
    background-image: url(../../assets/dashboard/expand-arrow-white.svg);
  }
  .component-device-chooser .device-select .device-option.recent {
    background: url(../../assets/clock-white.svg) 21px center no-repeat;
    background-size: 16px;
    padding-left: 45px;
  }

  //Weather Cam
  .device-dashboard-wrap.cam-library .dashboard-top .archive-select-container .archive-select__dropdown-indicator, .device-dashboard-wrap.cam-charts .dashboard-top .archive-select-container .archive-select__dropdown-indicator {
    color: var(--color-text-device-chooser-main);
    background-image: url(../../assets/dashboard/icon-calendar-white.svg);
  }
  .device-dashboard-wrap.cam-library .dashboard-top .archive-select-container .archive-select__control, .device-dashboard-wrap.cam-charts .dashboard-top .archive-select-container .archive-select__control {
    background-color: var(--color-bg-device-chooser-main);
    color: @white;
    border-color: var(--color-bg-device-chooser-main);
  }
  .device-dashboard-wrap.cam-charts .dashboard-top .archive-select-container .archive-select__control .archive-select__single-value, .device-dashboard-wrap.cam-library .dashboard-top .archive-select-container .archive-select__control .archive-select__single-value {
    color: @white;
  }
  .notifications-component > .notification-container--top-left > .notification > .notification__item--default {
    background: rgba(@white, 0.7);
    box-shadow: 0px 4px 4px rgba(@white, 0.15);
  }
  .camera-btn .camera{
    filter: invert(0%);
  }
  .device-accessories,
  .device-accessories .cat-wrap,
  .device-accessories .cat-wrap .cat-inner .cat{
    border-color: var(--color-border-main-lt);
  }
  .btn{
    &:focus,
    &:hover{
      color: var(--color-text-main);
    }
  }
}