@import '../../styles/mixins.less';

.device-comparison-table {
  background-color: @white;
  padding-top: @bigPad;
  padding-bottom: @bigPad;
  box-sizing: border-box;
  margin-left: 40px;
  margin-bottom: 15px;
  display: flex;
  > div {
    flex: 1;
    padding-left: @bigPad;
    padding-right: @bigPad;
    h4{
      font-size: 1em;
      font-weight: 500;
    }
    &:last-of-type{
      .flex{
        border-right: none;
      }
    }
    .flex{
      display: flex;
      border-right: 1px solid @gray-light-amb;
      > div {
        flex: 1;
        .fdp{
          color: @blue;
          font-weight: 400;
          font-size: 1.7em;
        }
        h6{
          margin-top: 5px;
          font-size: 0.9em;
          font-weight: 400;
          color: @gray-blue;
        }
      }
    }
  }
}

@media (max-width: @phone-max) {
  .device-comparison-table-wrap {
    width: 100%;
    overflow-x: scroll;
    .device-comparison-table {
      margin-left: 0px;
      min-width: 700px;
    }
  }
}