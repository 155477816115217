@import '../styles/mixins.less';

.component-like {
  a{
    display: inline-block;
    background: url(../assets/common/thumb-empty.svg) center center no-repeat;
    background-size: auto 100%;
    height: 20px;
    width: 30px;
    &:hover{
      background-image: url(../assets/common/thumb.svg);
    }
    &.liked{
      background-image: url(../assets/common/thumb.svg);
    }
  }
  .count{
    font-size: 0.8em;
    position: relative;
    top: -3px;
  }
}
