@import '../styles/mixins.less';

.component-amb-radio {
  cursor: pointer;
  display: flex;
  &:hover{
    .circle{
      opacity: 1;
    }
  }
  .circle{
    opacity: 0.7;
    margin-left: 10px;
    width: 20px;
    height: 20px;
    display: block;
    background: url(../assets/radio-button-off.svg) center center no-repeat;
    background-size: 100%;
    flex-shrink: 0;
  }
  &.active{
    .circle{
      opacity: 1;
      background-image: url(../assets/radio-button-on.svg);
    }
  }

}
