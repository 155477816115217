@import '../styles/mixins.less';

.component-from-now {
  .full,
  .long{
    display: none;
  }
  .short {
    &:after {
      content: ' ago';
    }
  }
  &.over-week{
    .short {
      &:after {
        content: '';
      }
    }
  }
}
