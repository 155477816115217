@import '../../styles/mixins';

.common-switch-slider {
  .switch-wrap{
    display: flex;
    justify-content: center;
    position: relative;
    .discount {
      background-color: @yellow;
      color: @text-dark;
      border-radius: 10px;
      padding: 1px 8px;
      font-size: 0.8em;
      margin-left: 10px;
      position: absolute;
      bottom: -8px;
      left: 20.5%;
      z-index: 3;
    }
    &.second{
      .discount {
        display: none;
        background-color: @gray-amb;
        color: @white;
      }
    }
  }
  .switch-container {
    border-radius: 50px;
    display: flex;
    background-color: @blue-med;
    padding: 5px;
    position: relative;
    overflow: hidden; // Hide overflow to keep sliding background contained
  
    // Sliding background
    .slider {
      background-color: @blue;
      border-radius: 50px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 50%; // Assuming there are two buttons
      transition: left 0.3s ease-out;
      z-index: 1;
    }
  
    .toggle-btn {
      flex: 1;
      text-align: center;
      border: none;
      background: none;
      padding: 0px 40px;
      cursor: pointer;
      position: relative;
      white-space: nowrap;
      z-index: 2; // Above the sliding background
      > div {
        font-size: 1em;
        font-weight: normal;
        line-height: 1.2;
        > span {
          font-size: 0.8em;
        }
      }
  
      &:focus {
        outline: none;
      }
  
      &.active {
        color: @white;
      }
    }
  
    // Adjust sliding background based on active button
    &.first .slider { left: 0; }
    &.second .slider { left: 50%; }
  }

}
.dark{
  .common-switch-slider {
    .switch-container {
      background-color: @gray-blue;
    }
  }
}
