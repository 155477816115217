@import '../../styles/mixins';

.device-device-type-chooser {
  .device-type-list{
    display: flex;
    flex-wrap: wrap;
    > a {
      width: 200px;
      text-align: center;
      padding: 140px 10px 10px;
      margin: 10px;
      text-decoration: none;
      color: @text-dark;
      font-weight: bold;
      .general-shadow;
      border-radius: 15px;
      background: url(../../assets/device-types/WS-1965.png) no-repeat center 10px var(--color-bg-connections-main);
      background-size: 90% auto;
      border: 1px solid transparent;
      @media(hover: hover) {
        &:hover {
          background-color: @blue-light;
          border: 1px solid @blue;
          color: @gray-dark-amb;
        }
      }
      &.WS-tbd{
        background-image: url(../../assets/device-types/WS-2902.png);
        background-position: center -20px;
        background-size: 75% auto;
      }
      &.WS-4000{
        background-image: url(../../assets/device-types/WS-4000.png);
        background-position: center 10px;
        background-size: 60% auto;
      }
      &.WS-5000{
        background-image: url(../../assets/device-types/WS-5000.png);
        background-position: center 10px;
        background-size: 55% auto;
      }
      &.AWX{
        background-image: url(../../assets/device-types/awx.png);
        background-position: center center;
        background-size: 80% auto;
      }
      &.AWX-tbd{
        background-image: url(../../assets/device-types/WS-2902-AW.png);
        background-position: center -10px;
        background-size: 70% auto;
      }
      &.WS-5000-AW{
        background-image: url(../../assets/device-types/WS-5000-AW.png);
        background-position: center -7px;
        background-size: 85% auto;
      }
      &.WS-1938{
        background-image: url(../../assets/device-types/WS-1938.png);
        background-position: center 5px;
        background-size: 100% auto;
      }
      &.OBSERVERIP2{
        background-image: url(../../assets/device-types/OBSERVERIP2.png);
        background-position: center 10px;
        background-size: 65% auto;
      }
      &.WEATHERBRIDGE{
        background-image: url(../../assets/device-types/WEATHERBRIDGE.png);
        background-position: center 10px;
        background-size: 100% auto;
      }
      &.WEATHERBRIDGEPRO2{
        background-image: url(../../assets/device-types/WEATHERBRIDGEPRO2.png);
        background-position: center 10px;
        background-size: 100% auto;
      }
      &.legacy{
        background-image: url(../../assets/device-types/missing.svg);
        background-position: center 25px;
        background-size: 90% auto;
      }
    }

  }
}
.device-tbd-station-modal{
  img{
    max-width: 100%;
  }
  .btns{
    text-align: center;
    margin: 10px 0px;
    .btn{
      margin: 0px 5px 5px;
    }
  }
}
