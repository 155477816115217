@import '../../styles/mixins.less';

.user-username-edit {
  .form{
    display: none;
  }
  &.open{
    .user-label{
      display: none;
    }
    .form{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .form-control{
    width: 250px;
  }
  .btn{
    margin-left: 5px;
  }
  .message{
    margin-left: 10px;
    animation: 0.4s ease 0s normal forwards 1 fadein;
    color: @color-alert;
    &.ok{
      color: @color-ok;
    }
  }
}
