@import '../../styles/mixins';

.payment-plus-subscription-modal {
  padding: 35px;
  // border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  header.awn-logo{
    background: url(../../assets/awn+logo.png) no-repeat center top;
    background-size: auto 170px;
    padding-top: 180px;
    margin-bottom: 10px;
    h2{
      border: none;
      font-size: 25px;
      line-height: 1.4;
      color: @gray-dark-amb;
    }
    h3{
      text-align: center;
    }
    .term{
      font-size: 1.3em;
    }
  }
  .switch-wrap{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    position: relative;
    .discount {
      background-color: @yellow;
      color: @text-dark;
      border-radius: 10px;
      padding: 1px 8px;
      font-size: 0.8em;
      margin-left: 10px;
      position: absolute;
      bottom: -8px;
      left: 20.5%;
      z-index: 3;
    }
    &.monthly{
      .discount {
        display: none;
        background-color: @gray-amb;
        color: @white;
      }
    }
  }
  .switch-container {
    border-radius: 50px;
    display: flex;
    background-color: #337498;
    padding: 5px;
    position: relative;
    width: 80%;
    overflow: hidden; // Hide overflow to keep sliding background contained
  
    // Sliding background
    .slider {
      background-color: @white;
      border-radius: 50px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 50%; // Assuming there are two buttons
      transition: left 0.3s ease-out;
      z-index: 1;
    }
  
    .toggle-btn {
      flex: 1;
      text-align: center;
      border: none;
      background: none;
      padding: 8px 20px 12px;
      cursor: pointer;
      position: relative;
      z-index: 2; // Above the sliding background
      font-weight: bold;
      > div {
        font-size: 1.1em;
        font-weight: normal;
        line-height: 1.2;
        > span {
          font-size: 0.8em;
        }
      }
  
      &:focus {
        outline: none;
      }
  
      &.active {
        color: @blue;
      }
    }
  
    // Adjust sliding background based on active button
    &.annual .slider { left: 0; }
    &.monthly .slider { left: 50%; }
  }

  .product-tiers{
    .tier{
      // width: 48%;
      &.tier-0{
        width: 34%
      }
      &.tier-1{
        width: 62%;
        &.trial{
          p.trial{
            position: absolute;
            bottom: -27px;
            background-color: @green-alt;
            color: @text-dark;
            display: inline-block;
            border: 1px solid @white;
            border-radius: 12px;
            width: 80%;
            left: 10%;
            padding: 2px 0px;
          }
        }
      }
      p.trial{
        display: none;
      }
    }
    .top{
      position: relative;
      padding-bottom: 20px;
      min-height: 245px;
      h2{
        margin-top: 35px;
      }
      p{
        color: @white;
        font-size: 1.2em;
        a{
          color: @white;
        }
      }
    }
    .bottom .group .line {
      .val{
        flex: 1;
        span {
          font-weight: normal;
        }
      }
      .label{
        width: 110px;
      }
    }
  }
  .awn-plus-member{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .table{
      > div {
        padding-bottom: 5px;
        display: flex;
        > span:first-of-type{
          font-weight: bold;
          padding-right: 15px;
          width: 140px;
        }
        &:last-child{
          td{
            padding-bottom: 0px;
          }
        }
      }
  
    }
    .box {
      color: @gray-dark-amb;
      border: 1px solid @blue;
      padding: @generalPad;
      border-radius: 15px;
      background-color: rgba(255, 255, 255, 0.5);
    }
    h3{
      text-align: center;
      color: @green-dark;
    }
    .btns{
      margin-top: 15px;
      .btn:first-of-type{
        margin-right: 10px;
      }
    }
    .switch-term{
      display: flex;
      padding-top: 3px;
      .component-amb-radio{
        flex-direction: row-reverse;
        .circle{
          margin-left: 0;
          margin-right: 4px;
        }
        > div {
          .save{
            font-weight: 300;
            font-size: 0.9em;
            display: block;
          }
        }
      }
    }
    &.giftCode{
      .box{
        max-width: 400px;
        background: url(../../assets/common/check-green.svg) no-repeat center 25px rgba(255, 255, 255, 0.9);
        background-size: 100px;
        padding-top: 150px;
        h2{
          font-size: 1.3em;
          line-height: 1.4;
          border: none;
          margin: 0px 0 10px;
        }
        p{
          font-size: 1.3em;
          text-align: center;
        }
      }
      .plan-changer{
        display: none;
      }

    }
  }
  .prorate{
    font-size: 1.3em;
  }
  .privacy{
    padding-top: 15px;
    text-align: center;
    a{
      color: @text-dark;
    }
  }
  .dont-worry{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    font-size: 1.1em;
    h3{
      font-size: 1.4em;
    }
    p{
      max-width: 500px;;
      margin-bottom: 25px;
      text-align: center;
    }
  }
  .warning{
    color: @red;
    a {
      color: @red;
    }
  }
}
@media(max-width: @tablet-phone-max){
  .payment-plus-subscription-modal {
    .awn-plus-member{
      &.switchAvail{
        .options{
          flex-direction: column;
        }
      }
    }
    .product-tiers{
      .tier{
        &.tier-1{
          &.trial{
            .top{
              padding-left: 0;
              padding-right: 0;
              padding-bottom: 0;
              p.trial{
                position: relative;
                top: 0px;
                bottom: 0px;
                width: 100%;
                border-radius: 0px;
                left: 0px;
                margin-bottom: 0;
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }

}
@media (max-width: @phone-max) {
  .payment-plus-subscription-modal {
    height: 100vh;
    overflow: auto;
    padding: 15px;
    header.awn-logo{
      background-size: 70% auto;
      padding-top: 50px;
      h2{
        font-size: 20px;
      }
    }
    .product-tiers{
      .tier.tier-1,
      .tier.tier-0{
        width: 100%;
      }
    }
    .switch-wrap{
      .switch-container{
        width: 100%;
      }
      .discount {
        left: 11%;
      }
    }
    .awn-plus-member{
      .btns{
        text-align: center;
        .btn{
          width: 180px;
          margin-right: 0 !important;
          margin-top: 10px;
          &.btn-primary{
          }
        }
      }
    }
  }
  .general-modal.plus-subscription{
    .ReactModal__Content{
      border-radius: 0px !important;
    }
  }
}
.general-modal.plus-subscription{
  .ReactModal__Content{
    background: url(../../assets/modal-bg.png) no-repeat center top !important;
    background-size: cover !important;
    padding: 0px !important;
    // border-radius: 0px !important;
    .close{
      right: 15px;
      top: 15px;
    }
  }
}
