@import '../../styles/mixins.less';

.device-device-data-graph-wrap {
  .video-container .video{
    aspect-ratio: 638/388;
  }

}
.recharts-cartesian-grid{
  // filter: drop-shadow(0 4px 4px rgba(0,0,0,0.25))
}
