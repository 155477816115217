@import '../../styles/mixins.less';

.user-oauth-tile {
  width: 400px;
  margin: 150px auto 0;
  text-align: center;
  form{
    padding: 0;
  }
  .icon{
    width: 100px;
    height: 100px;
    background: url(../../assets/ifttt.png) center center no-repeat;
    background-size: 100%;
    margin: auto;
    &.alexa{
      background-image: url(../../assets/alexa.png);
    }
    &.google,
    &.google-home{
      background-image: url(../../assets/AWN_logo.svg);
      background-size: 100% 200%;
    }
  }
  .details{
    width: 225px;
    margin: 20px auto;
    text-align: left;
    ul{
      list-style: disc;
      padding-left: 15px;
    }
  }
}
@media(max-width: @phone-max){
  .user-oauth-tile{
    width: 100%;
    padding: 0px 15px 40px;
  }
}
