@import '../styles/mixins.less';

.component-map {
  position: relative;
  overflow: hidden;
  .map{
    .sidebar-transition(height);
    height: 100vh;
    .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group{
      margin-right: 28px;
    }
    canvas:focus{
      outline: none;
    }
  }
  .mapboxgl-ctrl-attrib-inner{
    a{
      color: @text-dark;
      &.map-legend{
        font-weight: bold;
      }
      &.mapbox-improve-map{
        font-weight: normal;
      }
    }
  }
  .layer-btns{
    position: absolute;
    right: 10px;
    bottom: 140px;
    width: 68px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 400;
    @wh: 41px;
    .plus-layers{
      position: relative;
      .plus-flyout{
        @w: 180px;
        position: absolute;
        bottom: 0px;
        width: @w;
        background-color: var(--color-bg-map-layer-btn-main);
        border-radius: 10px;
        left: -@w - 10px;
        display: flex;
        flex-direction: column;
        padding: 10px;
        z-index: 4;
        a{
          padding-left: 20px;
          text-decoration: none;
          background: url(../assets/map/btn-air-quality.svg) 0px 2px no-repeat;
          background-size: 15px;
          padding-bottom: 5px;
          color: var(--color-text-main);
          &.active{
            color: @blue;
          }
          &.plus-fires{
            background-image: url(../assets/map/btn-fires-black.svg);
          }
          &.plus-cyclones{
            background-image: url(../assets/map/btn-cyclones-black.svg);
          }
          &.plus-road{
            background-image: url(../assets/map/btn-road-black.svg);
          }
          &.plus-air{
            background-image: url(../assets/map/btn-air-black.svg);
          }
          &.plus-snow{
            background-image: url(../assets/map/snow-black.svg);
          }
          &.plus-river{
            background-image: url(../assets/map/btn-river-black.svg);
          }
        }
        .component-amb-radio{
          flex-direction: row-reverse;
          justify-content: flex-end;
          margin-bottom: 5px;
          .circle{
            margin-left: 0;
            margin-right: 5px;
          }
        }
      }
    }
    .btn-wrap{
      position: relative;
      width: @wh;
      height: @wh;
      margin-top: 10px;
      background-repeat: no-repeat;
      a.main-btn{
        position: relative;
        z-index: 4;
        margin-top: 0;
      }
      .high-low{
        @media (min-width: 768px) and (max-width: @tablet-phone-max){
          right: -82px;
          top: -44px;
          border-radius: 20px;
        }
        position: absolute;
        right: @wh / 2;
        z-index: 3;
        top: 0px;
        width: 0px;
        padding-right: 0px;
        padding-left: 0px;
        overflow: hidden;
        height: @wh;
        display: flex;
        background-color: var(--color-bg-map-layer-btn-main);
        z-index: 3;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
        justify-content: center;
        align-items: center;
        .general-shadow;
        .sidebar-transition();
        a {
          text-decoration: none;
          // padding-right: 10px;
          // padding-left: 10px;
          text-align: center;
          flex-grow: 1;
          line-height: 1.2;
          &:last-of-type {
            border-left: 2px solid @gray-lighter;
          }
          span {
            display: flex;
            justify-content: center;
          }
          .label {
            color: var(--color-text-main);
            font-size: 0.9em;
          }
          .fdp {
            font-size: 1.1em;
            font-weight: 500;
            position: relative;
            .suff{
              font-size: 0.7em;
              font-weight: normal;
              padding-top: 4px;
              padding-left: 2px;
            }
          }
        }
      }
      &.radar{
        > a {
          margin-top: 0;
          position: absolute;
          z-index: 10;
          &.sub{
            .general-shadow;
            transition: all 0.2s ease-in;
            @playWh: 26px;
            width: @playWh;
            height: @playWh;
            top: (@wh - @playWh) / 2;
            left: (@wh - @playWh) / 2;
            box-shadow: none;
            background-color: @white;
            z-index: 5;
            border: 2px solid @white;
            &:hover{
              .lift-shadow;
              
            }
          }
        }
        &.active{
          a{
            transition-timing-function: ease-out;
            &.play{
              left: -19px;
              top: -30px;
              &.active,
              &:hover {
                background-color: @blue;
                background-image: url(../assets/map/btn-play-white.svg);
              }
            }
            
            &.lightning{
              // display: none;
              left: 10px;
              top: -30px;
              &.active,
              &:hover {
                background-color: @blue;
                background-image: url(../assets/lightning-white.svg);
              }
            }
          }
        }
      }
      &.active{
        .high-low{
          width: 200px;
          padding-right: (@wh / 2) + 10px;
          padding-left: 20px;
        }
      }
    }
    a {
      &.play{
        .btn-map(@wh);
        background-image: url(../assets/map/btn-play.svg);
        background-size: 45%;
        &.active{
          background-image: url(../assets/map/btn-pause.svg);
          background-size: 27%;
          background-color: @blue;
        }
      }
      &.lightning{
        .btn-map(@wh);
        background-image: url(../assets/lightning-black.svg);
        background-size: 58%;
        &.active{
          background-image: url(../assets/lightning-white.svg);
          background-size: 58%;
          background-color: @blue;
        }
      }
      &.main-btn{
        .btn-map(@wh);
        &.covid-19{
          background-image: url(../assets/map/btn-virus.svg);
          &.active,
          &:hover{
            background-image: url(../assets/map/btn-virus-white.svg);
          }
        }
        &.plus{
          background-image: url(../assets/awn+black.svg);
          background-size: 100%;
          &:hover{
            background-image: url(../assets/awn+white.svg);
          }
          &.active.air{
            background-image: url(../assets/map/btn-air-quality-white.svg);
            background-size: 70%;
          }
        }
        &.radar{
          background-image: url(../assets/map/btn-radar.svg);
          &.active,
          &:hover{
            background-image: url(../assets/map/btn-radar-white.svg);
            background-color: @blue !important;
          }
        }
        &.temp{
          background-image: url(../assets/map/btn-temp.svg);
          background-size: 71%;
          &.active,
          &:hover{
            background-image: url(../assets/map/btn-temp-white.svg);
          }
          
        }
        &.humidity{
          background-image: url(../assets/map/btn-humidity.svg);
          background-size: 51%;
          &.active,
          &:hover{
            background-image: url(../assets/map/btn-humidity-white.svg);
          }
        }
        &.wind{
          background-image: url(../assets/map/btn-wind.svg);
          background-size: 72%;
          &.active,
          &:hover{
            background-image: url(../assets/map/btn-wind-white.svg);
          }
        }
        &.forecast-btn{
          width: auto;
          height: auto;
          color: @text-dark !important;
          font-size: 13px;
          font-weight: bold;
          display: inline-block;
          padding: 5px 2px;
          border-radius: 30px;
          background-image: none;
          text-decoration: none;
          &:hover,
          &.active{
            color: @white !important;
          }
          span{
            display: inline-block;
            padding: 0px 5px;
            &:first-of-type{
              border-right: 2px solid @gray-light-amb;
            }
          }
        }
        &.sidebar-btn{
          background-image: url(../assets/map/btn-sidebar.svg);
          background-size: 50%;
          &:hover,
          &.active{
            background-image: url(../assets/map/btn-sidebar-white.svg);
          }
        }
        &.active{
          &.plus-fires{
            background-image: url(../assets/map/btn-fires-white.svg);
            background-size: 70%;
          }
          &.plus-cyclones{
            background-image: url(../assets/map/btn-cyclones-white.svg);
            background-size: 70%;
          }
          &.plus-road{
            background-image: url(../assets/map/btn-road-white.svg);
            background-size: 70%;
          }
          &.plus-air{
            background-image: url(../assets/map/btn-air-white.svg);
            background-size: 70%;
          }
          &.plus-river{
            background-image: url(../assets/map/btn-river-white.svg);
            background-size: 70%;
          }
          &.plus-snow{
            background-image: url(../assets/map/snow-white.svg);
            background-size: 70%;
          }
        }
      }
    }
  }
  .map-legend-btn{
    position: absolute;
    z-index: 400;
    left: 10px;
    bottom: 30px;
    text-decoration: none;
    border: 2px solid @white;
    .general-shadow;
    border-radius: 10px;
    &::before{
      content: 'Map Legend';
      position: absolute;
      top: -15px;
      left: -2px;
      font-size: 0.8em;
      color: var(--color-text-main-md);
      background-color: @white;
      display: inline-block;
      padding: 2px 5px 7px;
      font-weight: 300;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      line-height: 1;
      z-index: -1;
    }
  }

  .animation-timeline{
    position: fixed;
    bottom: 50px;
    width: 100%;
    z-index: 0;
    left: 0px;
    padding: 0px 200px;
    .sidebar-transition(padding);
    .default-input-range;
    .input-range__label{
      opacity: 1;
    }
    .input-range__label--min, 
    .input-range__label--max{
      bottom: -2.4rem;
    }
    .input-range__label--value{
      top: -51px !important;
      display: none;
      .input-range__label-container{
        position: relative;
        background-color: @white;
        border-radius: 8px;
        padding: 6px 10px;
        display: inline-block;
        &:after{
          position: absolute;
          bottom: -4px;
          left: 50%;
          margin-left: -2px;
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4px 3px 0 3px;
          border-color: #ffffff transparent transparent transparent;
        }
      }
    }
    &.dragging{
      .input-range__label--value{
        display: block;
      }
    }
  }
  .awxjs__ia-map .awxjs__loader{
    bottom: 4px;
    top: auto;
  }
}
.sidebar {
  .component-map{
    .animation-timeline{
      padding-left: 100px;
      padding-right: @sidebar-width + 150px;
    }
  }
}
@media (max-width: @tablet-phone-max) {
  .component-map{
    .animation-timeline{
      bottom: 157px;
      padding: 0px 50px;
    }
    .map-legend-btn{
      bottom: 140px;
    }
    .layer-btns{
      flex-direction: row;
      justify-content: space-evenly;
      padding: 0px @generalPad;
      .sidebar-transition(bottom);
      right: auto;
      left: 0;
      bottom: 40px;
      width: 100%;
      a {
        &:hover {
          background-color: @white;
        }
        &.wind{
          &:hover{
            background-image: url(../assets/map/btn-wind.svg);
          }
        }
        &.humidity{
          &:hover{
            background-image: url(../assets/map/btn-humidity.svg);
          }
        }
        &.temp{
          &:hover{
            background-image: url(../assets/map/btn-temp.svg);
          }
        }
        &.sidebar-btn{
          &:hover{
            background-image: url(../assets/map/btn-sidebar.svg);
          }
        }
        &.forecast-btn{
          &:hover{
            color: @text-dark !important;
          }
        }
        &.active {
          background-color: @blue;
          .general-shadow;
          &:hover {
            background-color: @blue;
            .general-shadow;
          }
          &.wind {
            &:hover {
              background-image: url(../assets/map/btn-wind-white.svg);
            }
          }
          &.humidity {
            &:hover {
              background-image: url(../assets/map/btn-humidity-white.svg);
            }
          }
          &.temp {
            &:hover {
              background-image: url(../assets/map/btn-temp-white.svg);
            }
          }
          &.sidebar-btn{
            &:hover {
              background-image: url(../assets/map/btn-sidebar-white.svg);
            }
          }
          &.forecast-btn{
            &:hover{
              color: @white !important;
            }
          }
        }
      }
      .btn-wrap{
        margin-right: 0;
        margin-top: 10px;
        a {
          &.radar{
            background-image: url(../assets/map/btn-radar.svg);
            background-color: @white;
            &:hover {
              background-color: @white;
            }
            &.active {
              background-image: url(../assets/map/btn-radar-white.svg);
              background-color: @blue;
            }
          }
        }
        &.active{
          a.sub{
            &.play{
              left: -20px;
              top: -25px;
              @media (max-width: @tablet-max) {
                left: -75px;
                top: -30px;
              }
              @media (min-width: 768px) and (max-width: @tablet-phone-max){
                top: 10px;
                left: -60px;
              }
            }
            &.lightning{
              top: -25px;
              left: 34px;
              @media (max-width: @tablet-max) {
                left: -45px;
                top: -30px;
              }
              @media (min-width: 768px) and (max-width: @tablet-phone-max){
                top: 10px;
                left: -30px;
              }
            }
          }
        }
        
      }
    }
  }
}
@media (max-width: @phone-max) {
  .component-map{
    .mapboxgl-ctrl-bottom-right{
      display: none;
    }
    .layer-btns{
      .sidebar-btn{
        // display: none;
      }
    }
    .map-legend-btn{
      left: 10px;
      bottom: 260px;
    }
    .animation-timeline{
      bottom: 287px;
      padding: 0px 89px 0px @generalPad;
      .input-range__label--min{
        display: none;
      }
    }
  }
  .forecast-open{
    .map-legend-btn{
      display: none;
    }
  }
  .sidebar .component-map{
    // .map{
    //   height: ~"calc(100vh - @{sidebar-height})";
    // }
    // .layer-btns{
    //   bottom: 20px !important;
    // }
    // &.radar{
    //   .layer-btns{
    //     bottom: 40px !important;
    //   }
    // }
    .animation-timeline{
      position: absolute;
      bottom: 20px;
      padding: 0px @bigPad;
    }
  }
  .mobile-address{
    .component-map{
      .layer-btns{
        bottom: 130px;
      }
    }
  }
}
