@import '../styles/mixins.less';

.checkbox-list {
  position: relative;
  .dropdown-menu{
    box-sizing: border-box;
    padding: 5px 10px 0;
    margin-top: 0px;
    border: none;
    background-color: var(--color-bg-page-block-main);
    label{
      cursor: pointer;
      font-weight: 400;
      width: 100%;
      display: flex;
      justify-content: space-between;
      &:hover{
        color: var(--color-text-main-lt);
        opacity: 0.9;
      }
    }
  }
  .btn{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    margin-top: 1px;
  }
  &:hover{
    .dropdown-menu{
      display: block;
    }
  }
}
