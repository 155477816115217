@import '../styles/mixins.less';

@border-gray-light: #dadada;
@borderRadius: 20px;

.favstar{
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../assets/fav-off.svg) center center no-repeat;
  background-size: 79%;
  &.on{
    background-image: url(../assets/fav-on.svg);
  }
}
// google autocomplete
.pac-container{
  font-family: 'Ubuntu', sans-serif;
  border-radius: @borderRadius;
  box-shadow: none;
  border: none;
  margin-top: 15px;
  z-index: 2000;
  &.pac-logo{
    &:after{
      background-position: right 11px center;
    }
  }
  .pac-item{
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 15px;
    border: none;
    .pac-item-query{
      font-size: 15px;
    }
  }
}
.ReactModal__Body--open .pac-container{
  margin-top: 0px;
}

.component-device-chooser {
  font-family: 'Ubuntu', sans-serif;
  color: @text-dark;
  font-size: 15px;
  max-width: 500px;
  position: relative;
  margin-bottom: @generalPad;
  * {
    box-sizing: border-box;
  }
  .spacer{
    height: 30px;
  }
  .component-find-location-btn{
    display: none;
  }
  .wrap{
    border-radius: @borderRadius;
    position: absolute;
    border: 1px solid @gray-amb;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-bg-device-chooser-main);
    z-index: 20;
  }
  .header-wrap{
    display: flex;
    align-items: center;
    padding-left: 15px;
    a.header{
      display: inline-block;
      text-decoration: none;
      color: var(--color-text-device-chooser-main);
      padding: 6px 26px 6px 6px;
      font-size: 1.05em;
      line-height: 1.4;
      width: 100%;
      background: url(../assets/down-carrot-gray.svg) right 10px center no-repeat;
      min-height: 23px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.plus{
    .header-wrap a.header{
      background-image: url(../assets/plus.svg);
      background-size: 26px;
      background-position: right 6px center;
    }
  }
  .open{
    > .search{
      position: relative;
      padding: 15px;
      background-color: var(--color-bg-main);
      border-top: 1px solid @border-gray-light;
      border-bottom: 1px solid @border-gray-light;
      .component-loader{
        position: absolute;
        right: 22px;
        top: 25px;
      }
      &::before{
        content: '';
        background: url(../assets/search-gray.svg) 0px center no-repeat;
        width: 12px;
        height: 13px;
        top: 23px;
        left: 23px;
        position: absolute;
        z-index: 2;
      }
      .glyphicon{
        position: absolute;
        color: var(--color-text-main-lt);
        &.glyphicon-search{
          top: 22px;
          left: 23px;
        }
        &.x-gray{
          display: inline-block;
          width: 15px;
          height: 15px;
          background: url(../assets/x-gray.svg) center center no-repeat;
          top: 22px;
          right: 24px;
        }
      }
      input{
        font-size: 0.9em;
        width: 100%;
        padding-left: 30px;
        border-color: @border-gray-light;
        box-sizing: border-box;
        &:not(:placeholder-shown){
          // border-bottom-left-radius: 0;
          // border-top-left-radius: 15px;
          // border-top-right-radius: 15px;
          // border-bottom-right-radius: 0;
          // border-bottom: none;
        }
      }
    }
  }
  .device-select{
    .tabs{
      a{
        display: inline-block;
        border-radius: 9px;
        text-decoration: none;
        padding: 9px 13px;
        color: var(--color-text-main-lt);
        &:hover,
        &.active{
          color: @white;
          background-color: @blue;
          font-weight: bold;
        }
        &:first-of-type{
          margin-right: 12px;
          &:hover,
          &.active{
            margin-right: 10px;
          }
        }
      }
    }
    .dc__menu-notice--no-options{
      text-align: left;
    }
    .dc__menu{
      margin-top: 0;
      border-bottom-left-radius: @borderRadius;
      border-bottom-right-radius: @borderRadius;
      overflow: hidden;
      position: relative;
      box-shadow: none;
      margin-bottom: 0;
      background: transparent;
    }
    .dc__group{
      padding-bottom: 0;
      border-top: 1px solid @border-gray-light;
      &:first-of-type{
        border: none;
      }
    }
    .dc__menu-list{
      padding-bottom: 0;
      padding-top: 0;
    }
    .dc__option{
      padding-left: 0;
      outline: none;
      &.pl{
        padding-left: 30px;
      }
      .cta{
        text-align: center;
        img{
          display: inline-block;
          width: 100%;
        }
        .btn-circle{
          color: @blue;
          border: 1px solid @blue;
          font-size: 0.8em;
          margin-top: 5px;
        }
      }
      .load{
        padding: 10px 0px;
        text-align: center;
        color: @blue;
        .component-loader{
          display: inline-block;
        }
      }
      .help{
        padding-left: 30px;
        padding-top: 3px;
        padding-bottom: 3px;
        a{
          text-decoration: none;
          font-size: 0.85em;
        }
      }
    }
    .dc__option--is-selected,
    .dc__option--is-focused{
      color: @blue;
      font-weight: bold;
      background-color: var(--color-bg-option-selected);
    }
    .dc__option--is-disabled{
      background-color: transparent;
    }
    .dc__group:last-of-type {
      border-bottom-left-radius: @borderRadius;
      border-bottom-right-radius: @borderRadius;
      .dc__option:last-of-type{
        border-bottom-left-radius: @borderRadius;
        border-bottom-right-radius: @borderRadius;
      }
    }
    .dc__control--menu-is-open{
      height: 0px;
      min-height: 0px;
      border: none;
      padding: 0;
      overflow: hidden;
      box-shadow: none;
      input{
        outline: 0 !important;
      }
    }
    .dc__group-heading{
      // text-transform: none;
      font-style: normal;
      letter-spacing: 2px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
    }
    .device-option{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // flex-wrap: wrap;
      padding-left: 20px;
      line-height: 1.3;
      .noaa{
        color: @text-green;
      }
      &.recent{
        background: url(../assets/clock.svg) 21px center no-repeat;
        background-size: 16px;
        padding-left: 45px;
      }
      &.search,
      &.recent{
        padding-top: 3px;
        padding-bottom: 3px;
      }
      .option-label{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        > span{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .subtitle{
        width: 100%;
        flex-grow: 1;
        font-size: 0.7em;
        color: var(--color-text-main-lt);
        font-weight: normal;
        padding-top: 1px;
        &.old{
          color: @pink;
        }
      }
      a.subtitle{
        text-decoration: none;
        color: @blue;
      }
      > * {
        display: inline-block;
      }
      > .favstar{
        margin-right: 5px;
      }
      .dist{
        margin-left: auto;
        flex-shrink: 0;
      }
    }
  }
}
@media (max-width: @phone-max) {
  .component-device-chooser {
    &.open{
      .wrap{
        position: fixed;
        top: 0px;
        top: calc(~"env(safe-area-inset-top, 0)");
        left: 0;
        height: 100vh;
        z-index: 15;
        border-radius: 0;
        border: none;
        .device-select{
          .dc__group:last-of-type,
          .dc__group:last-of-type .dc__option:last-of-type,
          .dc__menu{
            border-radius: 0;
          }
          .dc__menu-list {
            max-height: ~"calc(100vh - 186px)";
          }
          .dc__option{
            .load{
              padding-bottom: 60px;
            }
          }
        }
        .header-wrap{
          a.header{
            padding-top: 10px;
            padding-bottom: 10px;
            background: url(../assets/x-gray.svg) right 10px center no-repeat;
            background-size: 20px;
            font-size: 1.2em;
          }
        }
      }
    }
  }
}