@import '../../styles/mixins.less';

@accentBg: #FAFAFA;

.device-forecast-widget {
  padding: 15px 10px 20px;
  .suff{
    display: none;
  }
  .precip{
    display: none;
    span.drop{
      font-size: 0.45em;
      padding-left: 11px;
      color: var(--color-text-main-lt);
      background: url(../../assets/forecast/raindrop.svg) left center no-repeat;
    }
    .fdp.dailyrainin{
      font-size: 0.42em;
      color: var(--color-text-main-lt);
      display: block;
      .suff{
        display: inline;
      }
    }
    &.snow,
    &.flurries{
      span.drop{
        background-image: url(../../assets/forecast/snow.svg);
        background-size: 11px;
        background-position: -1px 1px;
      }
    }
  }
  .hourly-wrap{
    width: 100%;
    overflow-x: auto;
  }
  .hourly{
    display: flex;
    margin: 1em 0 5px;
    background-color: var(--color-bg-main);
    width: 2300px;
    .hour{
      .column;
      align-items: center;
      flex-grow: 1;
      padding: 0.3em;
      &.alt{
        background-color: @blue-light;
      }
      .title{
        color: @text-dark;
        font-size: 1.2rem;
        margin-bottom: 0.4em;
      }
      .weather-icon{
        @wh: 1em;
        width: @wh;
        height: @wh;
        margin-bottom: 0.3em;
        filter: grayscale(100%) invert(1);
        &.part-sun{
          background-size: auto 90%;
        }
        &.clear-night{
          background-position-y: 4px;
        }
        &.wind{
          background-size: auto 77%;
        }
      }
      .fdp{
        color: @text-dark;
        font-size: 0.6em;
      }
    }
  }
  .today{
    display: flex;
    justify-content: center;
    align-items: center;
    > div:first-of-type{
      font-size: 0.6em;
      color: var(--color-text-main-lt);
      font-weight: 300;
    }
    .title-precip{
      display: block;
      .column;
      justify-content: center;
      span{
        color: @text-dark;
        font-size: 0.5em;
        margin-left: 10px;
      }
    }
    .weather-icon{
      @wh: 3em;
      width: @wh;
      height: @wh;
      margin: 0px 10px;
      &.part-sun{
        background-size: auto 90%;
      }
      &.wind{
        background-size: auto 70%;
      }
    }
    .hl{
      display: flex;
      flex-direction: column;
      .fdp{
        font-size: 1.2em;
        color: @blue;
        line-height: 1;
        background: url(../../assets/forecast/arrow-up-gray.svg) right -1px bottom 1px no-repeat;
        &:last-of-type{
          margin-top: 9px;
          background-image: url(../../assets/forecast/arrow-down-gray.svg);
        }
        .suff{
          display: none;
        }
      }
    }
  }
  .other-days{
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    margin: 5px 0 0;
    .component-day-forecast{
      .column;
      align-items: center;
      justify-content: space-between;
      justify-content: center;
      background-color: var(--color-bg-forecast-day-main);
      flex-grow: 1;
      margin-right: 4px;
      padding: 5px 0;
      .title{
        color: var(--color-text-main-lt);
        font-size: 1.2rem;
        .column;
        justify-content: center;
        flex-grow: 1;
      }
      .weather-icon{
        background-size: 70%;
        width: 40px;
        background-position: center center;
      }
      .hl{
        .column;
        justify-content: center;
        flex-grow: 1;
        .column;
        .fdp{
          font-size: 1.55rem;
          line-height: 1.2;
          color: @text-dark;
          text-align: center;
          &:last-of-type{
            color: var(--color-text-main-lt);
          }
        }
      }
      &.title-s{
        background-color: #eaf7ff;
        .title{
          color: @blue;
        }
      }
    }
  }
  &.md{
    .other-days{
      font-size: 24px;
    }
  }
  &.lg{
    .other-days{
      font-size: 27px;
      margin: 1em 0em 0;
    }
  }
}
.device-widget.forecast{
  .column;
  .device-forecast-widget,
  .component-fetches-forecast{
    .fill-column;
  }

  .device-forecast-widget{
    .forecast-text{
      display: none;
    }
  }
  .buttons-rounded {
    .graph {
      display: none;
    }
  }
}
.expanded{
  .device-forecast-widget{
    .today{
      margin: 0.5em 0;
      font-size: 25px;
    }
    .other-days{
      margin-top: 0;
      overflow-x: auto;
      .component-day-forecast{
        background-color: var(--color-bg-main);
        flex-grow: 1;
        margin-right: 5px;
        padding: 10px 0;
        .title{
          justify-content: center;
          flex-grow: 1;
          .column;
        }
        &.title-s{
          background-color: @blue-light;
        }
        &:last-of-type{
          margin-right: 0;
        }
      }
      .precip{
        &.title-precip{
          display: none;
        }
        .column;
        justify-content: center;
        flex-grow: 1;
      }
    }
  }
}

@media (max-width: @tablet-max) {
  .device-widget.forecast{
    .device-forecast-widget {
      .today{
        font-size: 25px;
        //margin: 0.5em 0 0;
      }
    }
  }
  .expanded{
    .device-forecast-widget{
      .today{
        margin-top: 0;
        margin-bottom: 0;
      }
      .hourly{
        margin: 5px 0;
        .hour.odd{
          display: none;
        }
      }
      .other-days{
        font-size: 22px;
        margin: 0;
      }
    }
  }
}
@media(max-width: 840px) {
  .expanded{
    .device-forecast-widget {
      .today{
        margin-bottom: 1em;
      }
      .hourly{
        display: none;
      }
    }
  }

}

@media(max-width: @phone-max) {
  .device-widget.forecast{
    padding-bottom: 10px;
    .other-days{
      height: 150px;
    }
  }
  .expanded{
    .device-forecast-widget {
      .today{
        margin-bottom: 0;
      }
      .hourly{
        display: flex;
      }
      .other-days{
        height: 180px;
      }
    }
  }

}