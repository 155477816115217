@import '../../styles/mixins.less';

.user-admin-social {
  .recharts-legend-item.legend-item-0{
    path{
      stroke: #fff;
      stroke-width: 4px;
      fill-opacity: 1;
    }
  }
  .recharts-tooltip-item-list{
    color: #000 !important;
  }
  .posts-wrap{
    display: flex;
    > div {
      flex: 1;
    }
    .device-device-popup{
      margin-bottom: 25px;
    }
  }
}
