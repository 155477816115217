@import '../../styles/mixins.less';

.device-high-low-graphs {
    .line-wrap{
      padding: 0px 0 30px;
      display: flex;
      .label{
        font-size: 0.7em;
        padding-right: 10px;
        flex: 1;
        text-align: left;
        min-width: 60px;
        width: 60px;
        color: @gray-blue;
      }
      .line-bg{
        @h: 4px;
        height: @h;
        border-radius: 3px;
        background-color: var(--color-bg-high-low-graphs-main);
        flex-grow: 1;
        align-self: center;
        flex: 7;
        position: relative;
        .line{
          @p: 10px;
          position: absolute;
          left: 0;
          top: 0;
          height: @h;
          background-color: @blue;
          width: 20px;
          display: flex;
          justify-content: space-between;
          .min, .max{
            color: @text-dark;
            font-size: 14px;
            position: absolute;
            padding-top: @p;
            left: -15px;
            width: 0px;

            &:after{
              content: " ";
              width: 14px;
              height: 14px;
              border-radius: 100%;
              background-color: @white;
              border: 1px solid @blue;
              position: absolute;
              top: -@p + 4px;
              left: 6px;
            }
          }
          .max{
            left: auto;
            right: 0px;
            &:after{
              left: auto;
              right: -11px;
            }
          }
          .avg{
            position: absolute;
            top: 0;
            font-size: 10px;
            color: @gray-amb;
            padding-top: 6px;
            &:after{
              content: " ";
              .square(9px);
              border-radius: 100%;
              background-color: @yellow;
              border: 1px solid @white;
              position: absolute;
              top: -3px;
              left: 5px;
            }
          }
        }
      }
    }
}
@media (max-width: @tablet-max) {
  .device-high-low-graphs {
    .line-wrap{
      padding: 0px 0 17px;
      .label{
        font-size: 0.60em;
      }
      .line-bg{
        .line{
          .min, .max{
            font-size: 11px;
          }
        }
      }
    }
  }
}
@media (max-width: @tablet-phone-max) and (min-width: @phone-max){
  .device-high-low-graphs {
    .line-wrap{
      &.yesterday{
        display: none;
      }
    }
  }
}
@media (max-width: @phone-max) {
  .device-high-low-graphs {
    .line-wrap{
      .label{
        font-size: 0.63em;
      }
      .line-bg{
        .line{
          .min, .max{
            font-size: 14px;
          }
        }
      }
    }
  }
}
