@import '../../styles/mixins.less';

.device-device-data-table-wrap {
  .device-device-data-graph-wrap{
    display: none;
  }
  &.graph{
    .device-device-data-graph-wrap{
      display: block;
    }
  }
  .data-table{
    width: 100%;
    overflow: scroll;
    padding: @generalPad @generalPad 20px @generalPad;
    box-sizing: border-box;
    min-height: 1000px;
  }
  &.tz {
    .data-table{
      padding-right: 0px;
    }
    .date-chooser{
      display: none;
    }
    &.graph{
      .data-table{
        padding-right: @generalPad;
      }
    }
  }
    .controls{
      box-sizing: border-box;
      padding: 7px @generalPad;
      background-color: var(--color-bg-page-block-main);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: var(--color-text-main-lt);
      border-top: 1px solid @gray-lighter;
      border-bottom: 1px solid @gray-lighter;
      span.div{
          display: inline-block;
          padding: 0 10px;
      }
      .wrap{
        flex: 1;
        text-align: left;
        display: flex;
      }
      a.adgraph{
        display: inline-block;
        padding: 10px 25px;
        background: url(../../assets/graph-plus.svg) center center no-repeat;
        background-size: auto 92%;
      }
      .graph-mode{
        align-self: flex-start;
        width: 80px;
        li{
          a {
            border: none;
            border-radius: 0;
            padding: 0px 8px 3px;
            border-bottom: 3px solid @gray-amb;
            outline: none;
            background-color: var(--color-bg-page-block-main);
            i {
              background: url(../../assets/graph.svg) center center no-repeat;
              background-size: 100%;
              display: block;
              width: 23px;
              height: 22px;
              &.icon-table{
                background-image: url(../../assets/tables.svg);
                background-size: 110%;
              }
            }
          }
          &:hover,
          &.active{
            a{
              background-color: transparent;
              border-bottom-color: @blue;
              i{
                background-image: url(../../assets/graph-blue.svg);
                &.icon-table{
                  background-image: url(../../assets/tables-blue.svg);
                }
              }
            }
          }
        }
      }
      .filter{
        width: 170px;
        align-self: flex-start;
      }
      .date-chooser{
        padding: 0 @generalPad;
        .btn-default{
          margin: 0 5px;
          border: none;
          color: var(--color-text-main-lt);
          &:focus{
            outline: none;
          }
        }
      }
    }

}
@media (max-width: @phone-max){
  .device-device-data-table-wrap{
    .controls{
      padding: 0;
      flex-wrap: wrap;
      .wrap{
        padding: 10px 10px 0 10px;
        margin-right: auto;
        flex: unset;
        width: 100%;
        .graph-mode{
          width: 92px; 
        }
        display: flex;
        justify-content: space-between;
        a.adgraph{
          background-size: auto 85%;
          background-position-x: right;
        }
      }
      .component-date-picker{
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: flex-end;
      }
      .date-chooser{
        text-align: right;
        font-size: @phone-font-grow;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
        padding-right: @generalPad;
        padding-left: 0px;
        .btn-default{
          font-size: 1em;
        }
      }
      .component-time-mode-nav{
        display: flex;
        width: 100%;
        padding: @generalPad;
        border-top: 1px solid @gray-light-amb;
        > li {
          flex: 1;
          a{
            width: 100%;
            text-align: center;
          }
        }
      }
      .csv,
      .checkbox-list{
        display: none;
      }
    }
    &.tz {
      .controls{
        .date-chooser{
          display: none;
        }
        .component-date-picker{
          // margin-right: @generalPad;
        }
      }
    }
  }
}
