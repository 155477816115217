
// naming convention: css thing (color, background color, border color) - css modifier (if needed: active, hover?) - semantic description (social-card, btn-primary...) - description modifier (main, alt)

:root {
  --brandColor: @blue;
}

.component-protected-link.nav.support{
  display: none;
}

//going from big to small order
.component-stand-alone-loader,
.app{

  // General Colors - try these first
  --color-a-hover: #23B7F1;
  --color-bg-base: @bg;
  --color-bg-main: @gray-lightest;
  --color-border-main-lt: var(--color-bg-main);
  --color-border-alt-lt: @gray-light-alt;

  // text
  --color-text-main: @gray-dark-amb; 
  --color-text-main-md: #657888; 
  --color-text-main-lt: #97A2AD; 
  --color-text-lt: #fff;

  // buttons
  --color-bg-btn-primary: var(--brandColor);
  --color-bg-hover-btn-primary: var(--color-a-hover);
  --color-bg-active-btn-primary: #038BC1;
  --color-btn-primary: @white;
  --color-bg-find-location-btn-primary: var(--brandColor);
  --color-border-find-location-btn-primary: var(--brandColor);
  --color-text-find-location-btn-primary: @white;

  // header
  --color-bg-app-header-main: var(--brandColor);

  // page container 
  --color-bg-page-container-main: var(--color-bg-base);
  --color-bg-page-block-main: @white;
  --color-text-page-block-main: @gray-dark-amb;

  // device widgets
  --color-bg-widget-main: @white;
  --color-text-widget-main: var(--brandColor);
  --color-bg-widget-icon: var(--brandColor);
  --color-border-widget-header-main: @gray-light-amb;
  --color-text-widget-header-main: @gray-dark-amb;
  --color-bg-widget-rounded-btns-main: @white;
  --color-border-widget-rounded-btns-main: @gray-light-amb;
  --color-bg-table-widget-line-alt: @gray-lightest;
  --color-bg-social-post-widget-main: @white;
  --color-bg-social-post-widget-title-main: @black;
  --color-text-social-post-widget-title-main: @white;
  --color-text-widget-big-number: @gray-dark-amb;
  --color-bg-widget-snapshot: #e0e0e0;
  // for pressure widget
  --bg-image-gauge: url(../../assets/gauge.svg);
  --bg-image-gauge-1: url(../../assets/gauge-1.svg);
  --bg-image-gauge-2: url(../../assets/gauge-2.svg);

  // sidebar
  --color-bg-dashboard-nav: @white;
  --color-bg-navbar-brand: #eeeeee;
  --color-bg-navbar-nav: @white;

  // dashboard
  --color-bg-dashboard-main: @white;
  --color-bg-dashboard-header-main: @white;
  --color-text-dashboard-header-main: @gray-dark-amb;
  --color-bg-dashboard-tab-main: @white;
  --color-bg-active-dashboard-tab-main: @gray-lightest;
  --color-border-dashboard-tab-main: var(--brandColor);

  // nav
  --image-bg-logo: url(../../assets/AWN_full_logo.svg);
  --color-bg-logo-main: #eeeeee;
  --color-bg-dashboard-nav-main: @white;
  --color-bg-active-dashboard-nav-main: #eeeeee;
  --color-text-active-dashboard-nav-main: #97A2AD;

  //map
  --color-bg-map-btn-main: @white;
  --color-bg-map-layer-btn-main: @white;
  --color-bg-map-search-main: @white;
  --color-bg-map-search-btn-main: var(--brandColor);
  --color-bg-map-forecast-main: @white;
  --color-text-map-forecast-main: @gray-dark-amb;

  --color-bg-device-chooser-main: @white;
  --color-text-device-chooser-main: @gray-dark-amb;

  //outside bar
  --color-bg-outside-bar-main: @white;

  // cards
  --color-bg-card-top-main: @white;
  --color-text-device-popup-top-main: @gray-dark-amb;
  --color-bg-card-content-main: @white;
  --color-bg-card-bottom-main: @gray-lightest;
  --color-text-card-content-main: @gray-dark-amb;
  --color-border-card-main: @gray-lightest;

  --color-border-line-separator-main: #cdd4da;
  --color-bg-high-low-graphs-main: #cdd4da;

  // forecast widget
  --color-bg-forecast-day-main: #FAFAFA;
  --color-bg-forecast-day-alt: #eaf7ff;

  // react modal
  --color-bg-modal-main: @white;
  --color-text-modal-main: @gray-dark-amb;

  // social create post
  --color-bg-create-post-textarea-main: @white;
  --color-text-create-forecast-option-main: #333333;
  --color-border-create-forecast-option-main: #F2F2F2;
  --color-bg-hover-create-forecast-option-main: #F2F2F2;
  --color-bg-hover-create-forecast-option-day-main: #f9fcfe;
  --color-bg-create-forecast-icon-chooser-main: @white;
  --color-text-create-forecast-icon-chooser-main: @black;
  --color-bg-create-forecast-icon-main: #f8f8f8;
  --color-text-create-forecast-input-main: @gray-dark-amb;
  --color-link-create-forecast-icon-chooser-main: @white;
  --color-bg-create-my-forecast-main: rgba(255, 218, 68, 0.1);

  // devices 
  --color-bg-connections-main: #f7f7f7;

  // charts and graphs
  --color-bg-chart-header-main: #d2f0fb;
  --color-bg-chart-row-even-main: #f9f9f9;
  --color-bg-chart-row-odd-main: #f1f1f1;
  --color-bg-chart-date-picker-main: @gray-lightest;

  // device chooser
  --color-bg-option-selected: @blue-light;

  // misc
  --color-shadow-main: rgba(0, 0, 0, 0.2); 

  //sitewide modals 
  --bg-image-close: url(../../assets/x-black.svg);

  //payment modal
  --color-bg-payment-card-main: #eeeeee;
  --color-bg-video-main: #B2BAC2;
  --color-bg-video-banner-main: #EAEFF1;
  //notifications
  --color-bg-notification-main: rgba(0,0,0,0.7);
}