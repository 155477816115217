@import '../../styles/mixins';

.device-device-widget-chooser {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 09px 25px;
  select.form-control{
    background-image: url(../../assets/down-carrot-gray.svg);
    height: 35px;
    margin-top: 5px;  
    background-position-y: 13px;
  }
  .form-control,
  .component-device-chooser{
    width: 100%;
  }
  > * {
    margin-bottom: 10px;
  }
  > label {
    margin-top: 10px;
    margin-bottom: 2px;
    width: 100%;
    text-transform: none;
    font-size: 16px;

  }
  .widget-preview-wrap{
    width: 100%;
    margin-top: 10px;
  }
}
.widget-preview{
  height: 200px;
  width: 100%;
  background: url(../../assets/wxw/tiles/temp.png) no-repeat center center;
  background-size: contain;
  &.temp.small{
    background-image: url(../../assets/wxw/tiles/temp-sm.png);
  }
  &.temp.large{
    background-image: url(../../assets/wxw/tiles/temp-lg.png);
  }
  &.co2.small {
    background-image: url(../../assets/wxw/tiles/co2-sm.png);
  }
  &.co2 {
    background-image: url(../../assets/wxw/tiles/co2.png);
  }
  &.co2.large {
    background-image: url(../../assets/wxw/tiles/co2-lg.png);
  }
  &.currentcast {
    background-image: url(../../assets/wxw/tiles/currentcast.png);
    &.small{
      background-image: url(../../assets/wxw/tiles/currentcast-sm.png);
    }
    &.large{
      background-image: url(../../assets/wxw/tiles/currentcast-lg.png);
    }
  }
  &.etos {
    background-image: url(../../assets/wxw/tiles/etos.png);
    &.small{
      background-image: url(../../assets/wxw/tiles/etos-sm.png);
    }
    &.large{
      background-image: url(../../assets/wxw/tiles/etos-lg.png);
    }
  }
  &.dew-point {
    background-image: url(../../assets/wxw/tiles/dew-point.png);
    &.small{
      background-image: url(../../assets/wxw/tiles/dew-point-sm.png);
    }
    &.large{
      background-image: url(../../assets/wxw/tiles/dew-point-lg.png);
    }
  }
  &.weekly-forecast {
    background-image: url(../../assets/wxw/tiles/weekly-forecast.png);
  }
  &.daily-forecast {
    background-image: url(../../assets/wxw/tiles/daily-forecast.png);
  }
  &.forecast {
    background-image: url(../../assets/wxw/tiles/forecast.png);
  }
  &.gdd {
    background-image: url(../../assets/wxw/tiles/gdd.png);
  }
  &.gdd.small {
    background-image: url(../../assets/wxw/tiles/gdd-sm.png);
  }
  &.gdd.large {
    background-image: url(../../assets/wxw/tiles/gdd-lg.png);
  }
  &.humidity {
    background-image: url(../../assets/wxw/tiles/humidity.png);
  }
  &.humidity.small {
    background-image: url(../../assets/wxw/tiles/humidity-sm.png);
  }
  &.humidity.large {
    background-image: url(../../assets/wxw/tiles/humidity-lg.png);
  }
  &.indoor {
    background-image: url(../../assets/wxw/tiles/indoor.png);
    &.small{
      background-image: url(../../assets/wxw/tiles/indoor-sm.png);
    }
    &.large{
      background-image: url(../../assets/wxw/tiles/indoor-lg.png);
    }
  }
  &.leak.small {
    background-image: url(../../assets/wxw/tiles/leak-sm.png);
  }
  &.leak {
    background-image: url(../../assets/wxw/tiles/leak.png);
  }
  &.leak.large {
    background-image: url(../../assets/wxw/tiles/leak-lg.png);
  }
  &.lightning {
    background-image: url(../../assets/wxw/tiles/lightning.png);
    &.small{
      background-image: url(../../assets/wxw/tiles/lightning-sm.png);
    }
    &.large{
      background-image: url(../../assets/wxw/tiles/lightning-lg.png);
    }
  }
  &.outdoor {
    background-image: url(../../assets/wxw/tiles/outdoor.png);
    &.large{
      background-image: url(../../assets/wxw/tiles/outdoor-lg.png);
    }
  }
  &.pm25 {
    background-image: url(../../assets/wxw/tiles/pm25.png);
    &.small {
      background-image: url(../../assets/wxw/tiles/pm25-sm.png);
    }
    &.large {
      background-image: url(../../assets/wxw/tiles/pm25-lg.png);
    }
  }
  &.pm25_in {
    background-image: url(../../assets/wxw/tiles/pm25_in.png);
    &.small {
      background-image: url(../../assets/wxw/tiles/pm25_in-sm.png);
    }
    &.large {
      background-image: url(../../assets/wxw/tiles/pm25_in-lg.png);
    }
  }
  &.aqin{
    background-image: url(../../assets/wxw/tiles/aqin.png);
    &.small{
      background-image: url(../../assets/wxw/tiles/aqin-sm.png);
    }
    &.large{
      background-image: url(../../assets/wxw/tiles/aqin-lg.png);
    }
  }
  &.pressure.small {
    background-image: url(../../assets/wxw/tiles/pressure-sm.png);
  }
  &.pressure {
    background-image: url(../../assets/wxw/tiles/pressure.png);
  }
  &.pressure.large {
    background-image: url(../../assets/wxw/tiles/pressure-lg.png);
  }
  &.rain.long {
    background-image: url(../../assets/wxw/tiles/rain-long.png);
  }
  &.rain.large {
    background-image: url(../../assets/wxw/tiles/rain-lg.png);
  }
  &.rain {
    background-image: url(../../assets/wxw/tiles/rain.png);
  }
  &.leafwetbin {
    background-image: url(../../assets/wxw/tiles/leafwetbin.png);
    &.small{
      background-image: url(../../assets/wxw/tiles/leafwetbin-sm.png);
    }
    &.large{
      background-image: url(../../assets/wxw/tiles/leafwetbin-lg.png);
    }
  }
  &.solarradiation {
    background-image: url(../../assets/wxw/tiles/solarradiation.png);
  }
  &.solarradiation.small {
    background-image: url(../../assets/wxw/tiles/solarradiation-sm.png);
  }
  &.solarradiation.large {
    background-image: url(../../assets/wxw/tiles/solarradiation-lg.png);
  }
  &.sun-moon {
    background-image: url(../../assets/wxw/tiles/sun-moon.png);
    &.small{
      background-image: url(../../assets/wxw/tiles/sun-moon-sm.png);
    }
    &.large{
      background-image: url(../../assets/wxw/tiles/sun-moon-lg.png);
    }
  }
  &.temp {
    background-image: url(../../assets/wxw/tiles/temp.png);
  }
  &.uv {
    background-image: url(../../assets/wxw/tiles/uv.png);
  }
  &.uv.large{
    background-image: url(../../assets/wxw/tiles/uv-lg.png);
  }
  &.uv.small{
    background-image: url(../../assets/wxw/tiles/uv-sm.png);
  }
  &.wind {
    background-image: url(../../assets/wxw/tiles/wind.png);
  }
  &.wind.small {
    background-image: url(../../assets/wxw/tiles/wind-sm.png);
  }
  &.wind.large {
    background-image: url(../../assets/wxw/tiles/wind-lg.png);
  }
  &.wind-rose {
    background-image: url(../../assets/wxw/tiles/wind-rose.png);
    &.small{
      background-image: url(../../assets/wxw/tiles/wind-rose-sm.png);
    }
    &.large{
      background-image: url(../../assets/wxw/tiles/wind-rose-lg.png);
    }
  }
  &.time{
    background-image: url(../../assets/wxw/tiles/time.png);
    &.small{
      background-image: url(../../assets/wxw/tiles/time-sm.png);
    }
    &.large{
      background-image: url(../../assets/wxw/tiles/time-lg.png);
    }
  }
  &.webcam {
    background-image: url(../../assets/wxw/tiles/webcam.png);
    &.small{
      background-image: url(../../assets/wxw/tiles/webcam-sm.png);
    }
    &.large{
      background-image: url(../../assets/wxw/tiles/webcam-lg.png);
    }
  }
  &.daycast{
    background-image: url(../../assets/wxw/tiles/daycast.png);
    &.small{
      background-image: url(../../assets/wxw/tiles/daycast-sm.png);
    }
    &.large{
      background-image: url(../../assets/wxw/tiles/daycast-lg.png);
    }
  }
  &.outdoor-temp-hum{
    background-image: url(../../assets/wxw/tiles/outdoor-temp-hum.png);
    &.small{
      background-image: url(../../assets/wxw/tiles/outdoor-temp-hum-sm.png);
    }
    &.large{
      background-image: url(../../assets/wxw/tiles/outdoor-temp-hum-lg.png);
    }
  }
  &.soiltens1,
  &.soiltens2,
  &.soiltens3,
  &.soiltens4,
  &.soiltens5,
  &.soiltens6,
  &.soiltens7,
  &.soiltens8,
  &.soiltens9,
  &.soiltens10,
  &.soiltens{
    background-image: url(../../assets/wxw/tiles/soiltens.png);
    &.small{
      background-image: url(../../assets/wxw/tiles/soiltens-sm.png);
    }
    &.large{
      background-image: url(../../assets/wxw/tiles/soiltens-lg.png);
    }
  }
  &.sensor1,
  &.sensor2,
  &.sensor3,
  &.sensor4,
  &.sensor5,
  &.sensor6,
  &.sensor7,
  &.sensor8,
  &.sensor9,
  &.sensor10,
  &.sensor{
    background-image: url(../../assets/wxw/tiles/sensor.png);
    &.small{
      background-image: url(../../assets/wxw/tiles/sensor-sm.png);
    }
    &.large{
      background-image: url(../../assets/wxw/tiles/sensor-lg.png);
    }
  }
  &.soil1,
  &.soil2,
  &.soil3,
  &.soil4,
  &.soil5,
  &.soil6,
  &.soil7,
  &.soil8,
  &.soil9,
  &.soil10,
  &.soil{
    background-image: url(../../assets/wxw/tiles/soil.png);
    &.small{
      background-image: url(../../assets/wxw/tiles/soil-sm.png);
    }
    &.large{
      background-image: url(../../assets/wxw/tiles/soil-lg.png);
    }
  }
}
@media(max-width: @phone-max) {
  .device-device-widget-chooser {
    .btns{
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: @white;
      z-index: 1020;
      padding-bottom: 70px;
      .btn{
        padding: 10px 30px;
        font-size: 1.3em;
        margin: 0 10px;
      }
    }
  }
}
.app.component-sitewide-modals.device-widget-chooser{
  .ReactModal__Content{
    overflow: visible !important;
  }
}
