@import '../../styles/mixins.less';

.user-user-card {
  padding: 20px 0 0;
  border-bottom: 1px solid @gray-amb;
  .user-avatar{
    width: 40px;
    height: 40px;
  }
  label.role {
    margin-right: 15px;
  }
  .dashboard-link{
    margin-left: 15px;
  }
  .top{
    display: flex;
    align-items: center;
    > * {
      margin-right: 10px;
    }
    .details{
      font-size: 0.8em;
    }
  }
  .app-devices{
    padding: 15px 0px 15px 50px;
    .app-device{
      margin-bottom: 10px;
    }
  }
  .device {
    margin-left: 60px;
    padding: 5px 0 3px;
    border-bottom: 1px solid @gray-amb;
    .device-webcam-image{
      height: 150px;
      aspect-ratio: 16 / 9;
    }
  }
  .user-api-keys{
    box-shadow: none;
    margin-left: 60px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 15px;
    margin-bottom: 0;
    h3{
      font-size: 1em;
    }
    .btn-primary{
      padding-top: 0px;
      background-color: @white;
      color: @gray-amb;
      font-size: 0.9em;
      text-decoration: underline;
    }
  }

}
