@import '../../styles/mixins.less';

.rain-widget {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .outer-wrap{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    text-align: center;
    .rain-wrap{
      .column;
      padding: 18px 5px 5px;
      .rain-tube{
        flex-grow: 1;
        display: flex;
        width: 40px;
        margin: auto;
        overflow: hidden;
        .bg{
          background: url(../../assets/rain-bg.svg) top left no-repeat;
          background-size: 100%;
          flex-grow: 1;
          position: relative;
          margin-bottom: 20px;
          &:after{
            z-index: 2;
            content: '';
            width: 100%;
            height: 40px;
            background: url(../../assets/rain-bottom.svg) center center no-repeat;
            background-size: 100%;
            position: absolute;
            left: 0%;
            bottom: -20px;
          }
          &:before{
            content:'';
            .abs;
            top: 30px;
            background: url(../../assets/rain-ticks.svg) 0px 0px repeat-y;
            background-size: 30%;
            width: 100%;
            height: 300px;
            z-index: 5;
          }
        }
      }
      &.zero{
        .rain{
          display:none;
        }
        .rain-tube{
          .bg:after{
            background-image: url(../../assets/rain-bottom-empty.svg);
          }
        }
      }
      .rain{
        transition: height 4000ms cubic-bezier(0.270, 1.010, 0.300, 0.935);
        height: 50px;
        position: absolute;
        z-index: 4;
        bottom: 0;
        left: 0;
        width: 100%;
        background: url(../../assets/rain-bar.svg) top left no-repeat;
        background-size: 100%;
      }
      .wrap{
        padding-bottom: @generalPad;
      }

      .fdp{
        display: block;
        border-bottom: 3px solid @gold;
        margin-bottom: 2px;
        padding-bottom: 2px;
        color: @blue;
        .suff{
          font-size: 0.8em;
          font-weight: 300;
        }
        &.hourlyrainin .suff{
          font-size: 0.65em;
        }
      }
    }
  }
  .last-rain{
    text-align: center;
    padding-bottom: @bigPad;
    color: @gray-blue;
    font-size: 0.7em;
  }
}
.device-widget.rain{
  .column;
  &.expanded{
    .rain-wrap{
      padding-top: @bigPad;
      padding-bottom: 20px;
    }
  }
}
.component-stand-alone-loader{
  .device-widget.rain{
    .rain-widget{
      .outer-wrap .rain-wrap {
        .rain{
          transition: none;
        }
        .wrap{
          padding-bottom: 25px;
        }
      }
    }
    &.expanded{
      .rain-widget{
        font-size: 0.9em;
        .rain-wrap{
          padding-left: 4px;
          padding-right: 4px;
        }
      }

    }
  }
}
.device-outside-bar{
  .rain-widget {
    .outer-wrap .rain-wrap {
      padding-top: 0;
      .wrap{
        padding-bottom: 10px;
        .label{
          display: inline;
          font-size: 0.65em;
          text-transform: lowercase;
          &:before{
            content: '/';
          }
          &.rate{
            display: none;
          }
        }
        .fdp{
          border: none;
          margin-bottom: 0;
          display: inline;
          color: var(--color-text-main);
        }
      }
    }
  }
}
@media (max-width: @tablet-max) {
  .device-widget.rain.expanded{
    .rain-widget{
      font-size: 0.7em;
      .rain-wrap{
        padding-left: 6px;
        padding-right: 6px;
        padding-bottom: 15px;
        .rain-tube{
          width: 30px;
        }
        .fdp-val {
          font-size: 1em;
        }
        .label {
          font-size: 0.9em;
        }
        .suff {
          font-size: 0.7em;
        }
      }
    }
    
  }
  .device-widget.rain {
    .rain-wrap{
      padding-bottom: 25px;
      padding-top: 25px;
      .label {
        font-size: 0.8em;
      }
    }
  }
}
@media (max-width: @tablet-phone-max) {
  .device-widget.rain.expanded{
    .rain-widget{
      font-size: 0.7em;
      .rain-wrap{
        padding-left: 3px;
        padding-right: 3px;
        padding-bottom: 15px;
        .rain-tube{
          width: 30px;
        }
        .fdp-val {
          font-size: 1em;
        }
        .label {
          font-size: 0.85em;
        }
        .suff {
          font-size: 0.7em;
        }
      }
    }
    
  }
  .device-widget.rain {
    .rain-wrap{
      padding-bottom: 25px;
      padding-top: 25px;
    }
  }
}
@media (max-width: @phone-max) {
  .device-widget.rain{
    height: 500px !important;
    .rain-widget{
      margin-top: @generalPad;
      font-size: @phone-font-grow + 0.1em;
      .rain-wrap{
        .rain-tube{
          width: 60px;
        }
      }
      .last-rain{
        padding-bottom: 0;
        font-size: 1.2em;
      }
    }
  }
  .device-widget.rain.expanded{
    .rain-widget{
     
      .rain-wrap{
        padding-left: 4px;
        padding-right: 4px;
        .fdp-val {
          font-size: 1.2em;
        }
        .label {
          font-size: 1em;
        }
        .suff {
          font-size: 1em;
        }
      }
    }
    
  }
}