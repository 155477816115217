@import '../../styles/mixins';

.device-wxw-default {
  header.main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 70px;
    .btn{
      border-color: @white;
    }
  }
  select.form-control{
    background-image: url(../../assets/down-carrot-gray.svg);
    width: auto;
    max-width: 100%;
  }
  .wxw-line{
    margin-bottom: 40px;
    padding-bottom: @bigPad;
    border-bottom: 10px solid var(--color-border-main-lt);
    &:last-child{
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
    > header{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    h2{
      font-weight: 400;
      margin-top: 0;  
      line-height: 1.3;
      span{
        display: inline-block;
        margin-right: 15px;
        margin-bottom: 10px;
      }
    }
    h3{
      line-height: 1.3;
    }
    p.help{
      margin-top: 15px;
    }
    .component-location{
      .show-map{
        .btns-wrap{
          display: none;
        }
      }
    }
    .forecast-layout{
      margin-top: @bigPad;
      .component-amb-radio{
        flex-direction: row-reverse;
        justify-content: start;
        margin-bottom: 10px;
        i.circle{
          margin-left: 0;
          margin-right: 7px;
        }
        b{
          font-weight: normal;
          margin-left: 5px;
        }
      }
    }
  }
  .wxw-layouts{
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    > * {
      width: 48%;
      margin-right: 4%;
      margin-bottom: 4%;
      &:nth-child(2n){
        margin-right: 0;
      }
      header{
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        h3{
          flex-grow: 1;
          margin-bottom: 0;
          color: var(--color-text-main-md);
        }
        .icon{
          background: url(../../assets/x-blue.svg) center center no-repeat;
          width: 20px;
          height: 20px;
          margin-left: 10px;
          background-size: 100%;
          &.pencil{
            background-image: url(../../assets/icon-pencil-blue.svg);
          }
        }
      }
      pre{
        display: none;
      }
      footer{
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
      }
      &.active{
        footer {
          > span {
            background: url(../../assets/check.svg) center center no-repeat;
            width: 25px;
            height: 25px;
            background-size: 80%;
          }
        }
      }
    }
  }
  footer{
    background-color: transparent;
  }
}
@media (max-width: @phone-max) {
  .device-wxw-default {
    header.main{
      flex-direction: column;
      padding-right: @generalPad;
      h1{
        margin: @generalPad 0;
      }
    }
    .wxw-line{
      > header{
      }
    }
    .wxw-layouts{
      > * {
        width: 100%;
        margin: 0 0 @bigPad 0;
      }
    }
  }
}
