@import '../../styles/mixins';

.payment-manage-subscriptions {
    .btns{
      margin-bottom: 10px;
      .btn{
        margin-right: 15px;
        &:last-child{
          margin-right: 0;
        }
      }
      .btn:not(.btn-primary):not(:disabled){
        border: 1px solid @blue;
      }
    }
    .subscription-list {
        margin: 10px 0px;
        padding: 15px;
        border-style: solid;
        border-color: lightgray;
        border-width: 2px;
    }
}

@media (max-width:@phone-max) {
  .payment-manage-subscriptions {
    .btns{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .btn{
        margin-bottom: 10px;
      }
    }
  }
}