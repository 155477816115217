@import '../../styles/mixins.less';

.mapboxgl-popup{
  z-index: 10;
  max-width: 350px !important;
}
.mapboxgl-popup-content{
  padding: 0;
}
.common-data-popup,
.device-device-popup {
  font-size: 19px;
  position: relative;
  width: 95vw;
  max-width: 350px;
  border-radius: 3px;
  color: var(--color-text-device-popup-top-main);
  background-color: var(--color-bg-card-top-main);
  .device-temp-widget .top .suff {
    font-size: 1.5vw;
    font-size: clamp(1em, 1.5vw, 1em);
  }
  .device-temp-widget .top .fdp-val {
    font-size: 50px !important;
  }
  .wind-widget {
    justify-content: center;
  }

  .device-webcam-image .timestamp{
    display: none;
  }
  &.social {
    // background: @bg;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    .top {
      background-color: var(--color-bg-card-top-main);
      overflow: visible;
    }
    .content {
      background-color: var(--color-bg-card-content-main);
      .social-post {
        &.has-img {
          padding-bottom: 0;
          padding-left: 0;
          padding-right: 0;
          a.img {
            margin-bottom: 0;
          }
        }
      }
    }
    &.boosted {
      background-color: var(--color-bg-card-top-main);
      border: 2px solid var(--brandColor);
      border-radius: 5px;
      .top {
        background-color: var(--color-bg-card-top-main);
        border-radius: 5px;
        border-bottom: 1px solid var(--color-bg-card-bottom-main);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        .ago .component-from-now {
          &:before {
            content: 'Trending';
            color: var(--brandColor);
            margin-right: 8px;
          }
        }
      }
    }
  }
  &.webcam{
    .favstar{
      // display: none;
    }
  }
  &.hourlyrainin,
  &.windspeedmph {
    padding-bottom: 0;
  }
  a.btn-primary{
    font-size: 1.2rem;
    padding-top: 5px;
    padding-bottom: 5px;
    &.loading{
      .component-loader{
        padding: 3px 14px 12px;
      }
    }
  }
  > .top{
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    overflow: hidden;
      
    .meta{
      .favstar,
      .user-avatar{
        margin-right: 7px;
        flex-shrink: 0;
      }
      .user-avatar{
        width: 45px;
        height: 45px;
      }
      display: flex;
      font-size: 1.4rem;
      align-items: center;
      line-height: 1.4;
      > div *{
        display: block;
      }
      b {
        font-weight: 700;
      }
      .device-name-wrap{
        width: 100%;
        max-width: 190px;
        padding-right: 10px;
        > span{
          display: block;
          .ellipsis;
        }
      }
    }
    .ago{
      font-size: 1.2rem;
      color: var(--color-text-main-lt);
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
    .utility{
      > a {
        height: 27px;
        background: url(../../assets/common/three-dots.svg) center 15px no-repeat;
        width: 30px;
        display: block;
      }
      > div {
        display: none;
      }
      &.open{
        position: relative;
        z-indeX: 10;
        > div {
          padding: 10px;
          display: block;
          background-color: var(--color-bg-page-container-main);
          .general-shadow;
          position: absolute;
          top: 25px;
          right: 0;
          min-width: 90px;
          a.share{
            width: auto;
            background: none;
            height: auto;
            filter: none;
            color: @text-dark;
            text-decoration: none;
            display: block;
            margin: 0px 0px 5px 0px;
            &:before{
              content: 'Share'
            }
            &:hover{
              color: var(--color-a-hover);
            }
          }
          > a{
            color: @text-dark;
            text-decoration: none;
            display: block;
            margin-bottom: 5px;
            &:hover{
              color: var(--color-a-hover);
            }
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  > .bottom{
    background-color: var(--color-bg-card-bottom-main);
    padding: 5px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .component-like{
      margin-right: auto;
    }
  }
  .content{
    position: relative;
    z-index: 2;
    background-color: var(--color-bg-card-content-main);
  }
  h3{
    text-align: center;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1.3;
  }
  .device-webcam-image{
    height: 220px;
  }
  .rain-widget {
    height: 140px;
    .outer-wrap .rain-wrap {
      padding: 0 5px 5px;
      .rain-tube .bg {
        margin-bottom: 15px;
      }
    }
    .label{
      font-size: 12px;
    }
  }
  .component-share a.share{
    width: 24px;
    margin-top: 3px;
    margin-left: 5px;
    background-image: url(../../assets/common/share/share-arrow-outline.svg);
    // filter: grayscale(80%);
  }
}
@media (max-width: @tablet-max) {
  .device-device-popup{
    .device-temp-widget .body .top .fdp-val {
      font-size: 4vw;
      font-size: clamp(1em, 4vw, 3em);
    }
  }
}

@media(max-width: @tablet-phone-max){
  .device-device-popup{
    padding-bottom: 0;
    font-size: 17px;
  }
}

@media(max-width: @phone-max){
  .device-device-popup{
    max-width: 275px;
    .rain-widget{
      .top{
        font-size: 50px;
        .tempf{
          font-size: 50px;
        }
      }
      .bottom{
        font-size: 20px;
      }
    }
  }
}