@import '../../styles/mixins.less';

.user-api-keys {
  em{
    font-size: 0.9em;
    // color: @blue;
    font-weight: 300;
  }
  .gray{
    color: @gray-amb;
    font-size: 0.9em;
  }
  .app-key-form{
    max-width: 350px;
    padding-top: 10px;
    h3 {
      margin-bottom: 10px;
    }
    textarea{
      width: 100%;
      height: 200px;
      margin-bottom: 10px;
      display: block;
    }
    .nm{
      float: right;
    }
  }
}
