@import '../../styles/mixins';

.app.baro-page {
  background-color: @white;
  min-height: 100vh;
  > div.loggedout,
  .social-notifications, .user-nav, .component-dashboard-nav, .mobile-header {
    display: none;
  }
  #footer_main {
    background-color: #fff;
  }
  .user-barometer-calibration {
    padding: @bigPad @bigPad 50px @bigPad;
    background-color: @white;
    .navbar-brand{
      background-size: 100%;
    }
    label {
      font-size: 20px;
      text-transform: capitalize;
      margin-bottom: 10px;
      font-weight: 400;
    }
    .component-location {
      margin-bottom: 20px;
      p {
        padding-top: 10px;
        position: relative;
        z-index: 999;
        font-size: 16px;
      }
      .middle .item > span {
        margin-right: 10px;
        font-size: 1em;
      }
      .btns-wrap {
        padding-top: 0;
      }
    }
    .device-barometer-info{
      p{
        font-size: 18px;
      }
    }
  }
  &.embed {
    > div.loggedout,
    .mobile-header, .common-mobile-nav {
      display: none;
    }
    #footer_main {
      background-color: #fff;
    }
  }
}

