@import '../../styles/mixins.less';

.device-export-data {
  position: fixed;
  z-index: 1000;
  width: 400px;
  right: 50%;
  top: 30vh;
  margin-right: -200px;
  background-color: var(--color-bg-page-block-main);
  color: var(--color-text-main-lt);
  padding: @bigPad;
  transition: all 0.2s ease-out;
  h3{
    margin-bottom: 25px;
    color: var(--color-text-main);
  }
  .btns{
    margin-top: 25px;
  }
  .component-question-mark{
    display: inline-block;
    margin-left: 10px;
  }
  &.fetching{
    width: auto;
    min-width: 300px;
    margin-right: 0;
    left: auto;
    right: 10px;
    top: 10px;
    padding: @generalPad;
    a.btn{
      padding-top: 3px;
      padding-bottom: 3px;
      margin-left: 0;
      margin-right: 10px;
    }
    a:not(.btn){
      font-size: 0.85em;
      text-decoration: none;
    }
  }
  .estimated{
    font-size: 0.85em;
    color: var(--color-text-main-lt);
    padding: 5px 0 15px;
  }
  &.error{
    .component-date-picker a,
    .estimated {
      color: @color-alert;
    }
    .estimated {
      padding-top: 0;
    }
  }
  .close.export{
    display: inline-block;
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 15px;
    background: url(../../assets/x-black.svg) center center no-repeat;
  }
  .white{
    margin-left: 10px;
  }
  .options {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0 30px;
    a{
      padding: 5px 20px;
      text-decoration: none;
      border-radius: 6px;
      background-color: @gray-lightest;
      color: @text-dark;
      margin-right: 5px;
      &.active{
        background-color: @blue;
        color: @white;
      }
      &:last-of-type{
        margin-right: 0;
      }
    }
  }
  .progress-wrap{
    display: flex;
    align-items: center;
  }
  .progress{
    flex-grow: 1;
    margin-top: 10px;
    border-color: @white;
    margin-bottom: 10px;
    margin-right: 5px;
    height: 12px;
    background-color: @gray-lightest;
  }
  b{
    font-weight: 500;
  }
  .component-date-picker{
    margin-bottom: @generalPad;
    a{
      color: @text-dark;
    }
  }
  .picker{
    width: 470px !important;
    .chooser,
    > header{
      display: none;
    }
  }
}
