@import '../styles/mixins.less';

.component-quick-location {
  .autocomplete-wrap{
    display: flex;
    flex-direction: flex-start;
    align-items: center;
    margin-bottom: 6px;
    input{
      width: 250px;
      font-size: 14px;
      border-color: var(--color-border-widget-header-main);
      margin-right: 5px;
      background: url(../assets/search-gray.svg) 10px center no-repeat;
      padding-left: 33px;
      background-size: 14px;
    }
  }
  a.selected{
    text-decoration: none;
    color: var(--color-text-main);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    div > span{
      display: block;
    }
    .search-locations{
      color: @blue;
      font-size: 0.9em;
      margin-left: 20px;
    }
  }
  .locations{
    padding-top: 5px;
    width: 100%;
    overflow: auto;
    display: flex;
    padding-bottom: 10px;
    a {
      text-decoration: none;
      color: var(--color-text-main);
      display: inline-block;
      background-color: var(--color-bg-page-container-main);
      border-radius: 4px;
      padding: 3px 5px;
      margin-right: 5px;
      font-weight: 500;
      flex-shrink: 0;
      &:hover{
      }
    }
  }

}
