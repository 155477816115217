@import '../../styles/mixins';

.user-forecast-settings {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: var(--color-bg-page-block-main);
  z-indeX: 402;
  padding-top: calc(~"env(safe-area-inset-top, 0) + 70px");
  &.create-account{
    display: flex;
    .column;
    justify-content: center;
    header{
      border: none;
      text-align: center;
      margin-bottom: 30vh;
      .btn{
        margin-top: 20px;
      }
    }
  }
  header {
    padding: 0 20px;
    border-bottom: 1px solid var(--color-border-alt-lt);
    margin-bottom: 2.2rem;
    margin-top: 1rem;
    h1{
      font-weight: normal;
      line-height: 1.2;
      color: var(--color-text-main);
    }
  }
  label{
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin-bottom: 5px;
    span {
      text-transform: capitalize;
      font-size: 1.8rem;
      line-height: 1.3;
      color: var(--color-text-main);
    }
  }
  .error{
    color: transparent;
    transition: all 0.2s ease-in-out;
    &.flash{
      color: @color-alert;
    }
  }
}
