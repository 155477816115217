@import '../../styles/mixins';

.device-advanced-graph {
  position: relative;
  margin-bottom: 35px !important;
  .highcharts-credits,
  .highcharts-title{
    display: none;
  }
  h3{
    margin-bottom: 15px;
  }
  input.debounced-input{
    border: none;
    width: 100%;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0px;
    padding-bottom: 0px;
    background-color: transparent !important;
    border-bottom: 1px solid transparent;
    @media (hover: hover){
      &:hover{
        border-bottom: 1px solid var(--color-border-widget-header-main);
      }
    }
  }
  &.edit{
    input.debounced-input{
      border-color: var(--color-border-widget-header-main);
    }
  }
  .buttons-wrap{
    left: 0px;
  }
  .buttons-rounded{
    a{
      &.trash{
        padding: 5px;
      }
      display: block;
    }
  }
  .loading-wrap{
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    .progress-wrap{
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .compare{
    display: flex;;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 25px;
    .switch{
      display: flex;
      > span {
        font-size: 1.2em;
        font-weight: 300;
        display: inline-block;
        padding-right: 5px;
      }
    }
    .common-switch-slider{
      margin: 0px 15px;
      .switch-wrap{
        .slider{
          width: 40%;
        }
        &.second{
          .slider{
            width: 60%;
            left: 40%;
          }
        }
      }
    }
    &.gtOne{
      .common-switch-slider{
        .switch-container{
          &.first{
            .slider{
              background-color: @gray-lighter-amb;
            }
            .toggle-btn.active{
              color: @gray-amb;
            }
          }
          &.second{
            background-color: @gray-lighter-amb;
            color: @gray-amb;
          }
        }
      }
    }
  }
  &.minimized{
    .buttons-wrap{
      .trash{
        display: none;
      }
    }
  }
}
.dark{
  .device-advanced-graph{
    .highcharts-axis-labels {
      color: var(--color-text-main);
    }
  }
}

@media(max-width: @phone-max) {
  .device-advanced-graph{
    .compare{
      flex-direction: column;
      align-items: flex-start;
      .common-switch-slider{
        margin: 10px 0;
      }
    }
    .buttons-rounded{
      padding-left: 10px;
      padding-right: 10px;
      a.glyphicon{
        font-size: 1.3em;
      }
    }
  }
}