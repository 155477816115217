@import '../../styles/mixins.less';

.device-devices-header {
  .component-device-chooser{
    width: 400px;
    margin-bottom: 0;
    .spacer{
      height: 43px;
    }
  }
  .countdown{
    height: 9px;
    display: inline-block;
    margin-left: 20px;
    color: @white;
    text-decoration: none;
  }
  .children{
    margin-left: auto;
    padding-right: 90px;
    display: flex;
    align-items: center;
  }
  a.glyphicon{
    padding-left: 10px;
    display: inline-block;
    font-size: 13px;
    height: 4px;
    color: @white;
    cursor: pointer;
  }
}
// remove search in v3
.v3{
  .device-devices-header{
    .children{
      display: none;
    }
  }
}
@media(max-width: @tablet-phone-max){
  .device-devices-header{
    a.glyphicon,
    .countdown{
      font-weight: 400;
      color: @text-dark;
    }
    .children{
      padding-right: 5px;
    }
  }
}
@media(max-width: @phone-max){
  .device-devices-header{
    flex-wrap: wrap;
    a.glyphicon,
    .countdown{
      height: 16px;
      font-size: 0.8em;
      flex-grow: 1;
      text-align: right;
      padding-right: 5px;
    }
    a.glyphicon{
      flex-grow: 0;
      padding-left: 5px;
      padding-right: 10px;
    }
    .children{
      padding-right: 0px;
      .device-search-bar{
        display: none;
      }
      .device-share-button{
        height: 27px;
        .btn.btn-green{
          padding: 3px 11px;
          font-size: 12px;
          
        }
      }
    }
  }
}
@media(max-width:@super-small){
  .device-devices-header{
    .countdown{
      // display: none;
    }
  }
}