@import '../../styles/mixins.less';

.alert-default-page {
  select.form-control{
    background-image: url(../../assets/down-carrot-gray.svg);
  }
  .component-question-mark{
    float: right;
  }
  .block{
    padding: @bigPad;
  }
  .prefs{
    display: flex;
    align-items: center;
    font-size: 1.1em;
    padding: @generalPad 0;
    .light;
    .component-amb-radio{
      margin-right: 20px;
      .circle{
        margin-left: 5px;
      }
      &.plus{
        > div {
          background: url(../../assets/awn+blue.svg) right -4px no-repeat;
          background-size: 30px;
          padding-right: 31px;
        }
        .circle{
          margin-left: 1px;
        }
      }
    }
    .user-setting-select{
      margin-left: 30px;
    }
  }
  .message{
    margin-top: 10px;
    .light;
    a.glyphicon{
      display: inline-block;
      margin-left: 5px;
    }
  }
  .form-row{
    display: flex;
    align-items: center;
    .form-control{
      margin-right: 5px;
    }
    p{
      padding-top: 8px;
      margin-right: 20px;
    }
  }
  .alert-instances{
    .component-from-now{
      display: none;
    }
  }

  .multi-sms{
    margin-top: 40px;
  }
  .sms-share-input{
    min-width: 250px;
  }
  .sms-recipients{
    margin-bottom: 20px;
    .glyphicon{
      margin-left: 10px;
      color: @text-dark;
    }
  }
  .alert-recipient-forms{
    .email{
      margin-top: 20px;
      > div {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        > *{
          margin-right: 5px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
@media (max-width: @phone-max){
  .alert-default-page{
    .form-row{
      flex-direction: column;
      align-items: flex-start;
      .form-control{
        margin-bottom: 10px;
      }
    }
    .prefs{
      flex-wrap: wrap;
      > * {
        width: 100%;
        margin-bottom: 5px;
        &.component-amb-radio{
          div {
            width: 115px;
          }
        }
        &.user-setting-select{
          margin-top: 10px;
          margin-left: 0px;
        }
      }
    }
  }
}
