@import '../../styles/mixins';

.device-advanced-graphing {
  h3{
    font-size: 2em;
    line-height: 1.4;
    margin-bottom: @bigPad;
  }
  .btns{
    display: flex;
    .btn-wrap{
      display: flex;
      flex-direction: column;
      max-width: 220px;
      text-align: center;
      padding: 0px 15px 15px 0px;
      .btn{
        font-size: 1.2em;
        margin-bottom: 10px;
      }
      span{
        font-weight: 300;
        display: inline-block;
        padding: 0px 10px;
      }
    }
  }
  .device-create-device.open {
    h4.stations,
    h4.params{
      display: none;
    }
    a.connect{
      display: none;
    }
  } 
  .devices{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 15px;
    .device-wrap{
      position: relative;
      flex: 1;
      .general-shadow;
      max-width: 300px;
      margin: 0px 25px 25px 0px;
      .delete{
        position: absolute;
        cursor: pointer;
        @wh: 22px;
        width: @wh;
        height: @wh;
        top: -@wh/2;
        right: -@wh/2;
        z-index: 2;
      }
    }
  }
  .line{
    margin-bottom: 15px;
    .common-tags{
      display: block;
      margin-top: 10px;
    }
  }
  .graphs{
    .graph-header{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 10px;
      i {
        color: @blue;
        font-size: 1.6em;
        display: inline-block;
        margin-right: 5px;
      }
      .refresh{
        padding-left: 5px;
        padding-top: 5px;

      }

    }
    .component-date-picker{
      padding: 10px 15px;
      background-color: var(--color-bg-page-block-main);
      text-align: right;
    }
  }
}
.app.light{
  .device-advanced-graphing {
    select.form-control{
      background-image: url(../../assets/down-carrot-gray.svg);
    }
  }
}

@media(max-width: @phone-max) {
  .app.advanced-graphing{
    .page-body{
      padding-left: 0;
      padding-right: 0;
      .device-create-device{
        margin-right: 10px;
        margin-left: 10px;
        .btns{
          flex-direction: column;
          align-items: center;
        }
      }
      .graph-header{
        margin-right: 10px;
      }
      .device-advanced-graph.block{
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

}