@import '../../styles/mixins.less';

.device-summary-table {
  @dateColWidth: 110px;
  &.block{
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0px;
  }
  .children.bottom{
    padding: @generalPad;
  }
  header{
    font-size: 18px;
    padding-left: @bigPad;
    padding-right: @bigPad;
    padding-bottom: @generalPad;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .label{
      font-weight: bold;
      padding-right: 5px;
    }
    > div{
      display: flex;
      align-items: center;
    }
  }
  .device-device-data-table{
    background: #61caf9;
    .scroller{
      margin-left: @dateColWidth - 1;
      background-color: var(--color-bg-base);
      .main.details {
        tbody:last-child {
          border-bottom: 29px solid #61caf9;
        } 
      }
    }
  }
  table{
    tbody{
      th{
        vertical-align: bottom !important;
      }
      tr.buttons-row{
        position: relative;
        .buttons-wrap{
          bottom: auto;
          margin-top: 0px;
          width: calc(~"100% - " @dateColWidth);
          &:last-child {
            //margin-top: -15px;
          }
          .buttons-rounded{
            padding: 0px 8px 0px 8px;
            align-items: center;
            > span{
              display: inline-block;
              color: var(--color-text-main-lt);
              overflow: hidden;
              width: 0px;
              transition: all 0.3s ease-out;
            }
            &:hover{
              > span{
                padding-left: 5px;
                width: 100px;
              }
            }
            .component-loader{
              padding-top: 3px;
            }
          }
        }
      }
      tr.day-data-header{
        th{
          .general-shadow-inset;
          vertical-align: bottom;
          background-color: @blue-med;
          height: 67px;
        }
        &.not-first{
          td.dateutc{
            box-shadow: none;
          }
        }
        td.dateutc{
          .general-shadow-inset;
          height: 67px;
        }
      }
      tr.day-data{
        &:nth-of-type(even){
          background-color: var(--color-bg-chart-row-even-main);
        }
        &:nth-of-type(odd){
          background-color: var(--color-bg-chart-row-odd-main);
        }
        td{
          padding: 12px !important;
          &.dateutc{
            background-color: @blue-med;
          }
        }
      }
      &:first-of-type{
        .date-line{
          td{
            box-shadow: none;
          }
        }
      }
    }
    th.dateutc{
      width: @dateColWidth;
      background-color: #d2f0fb !important;
    }
    td{
      padding: 0px !important;
      &.dateutc{
        text-align: right;
        width: @dateColWidth;
        &.high,
        &.low{
          height: 76px;
          padding: 19px 13px 13px 13px !important;
        }
        &.avg{
          height: 46px;
          padding: 13px !important;
        }
      }
      &.flex{
        display: flex;
        align-items: center;
        .date{
          flex-grow: 1;
        }
        .btns{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .clear-selections,
          .trash{
            background: url(../../assets/trash.svg) center center no-repeat;
            display: block;
            width: 16px;
            height: 16px;
            background-size: 95%;
          }
          .clear-selections{
            margin-bottom: 7px;
            background-image: url(../../assets/clear-all-boxes.svg);
          }
          .csv{
            background-image: url(../../assets/share-icon-white.svg);
            margin-left: 0px;
          }
        }
      }
    }
    &.table > tbody + tbody {
      border: none;
    }
    &.details{
      thead{
        th.dateutc{
          background-color: var(--color-bg-chart-header-main)!important;
        }
      }
      tr.high,
      tr.avg,
      tr.low{
        td:hover{
          .general-shadow;
          span{
            // background-color: @white;
          }
        }
        td.today:hover{
          box-shadow: none;
          span{
            background-color: transparent;
          }
        }
        td.dateutc:hover{
          box-shadow: none;
          background-color: #71cff3;
        }
      }
      td.dateutc{
        &.high,
        &.low{
          height: 61px;
        }
      }
    }
    tbody{
    }
    .date-line{
      td{
        .general-shadow-inset;
        padding: 5px 10px !important;
        background-color: @blue-med;
        color: @text-dark; 
        font-weight: bold;
        text-align: right;
      }
    }
  }

}

@media (max-width: @phone-max){
  .device-summary-table {
    header{
      > div{
        flex-direction: column;
        align-items: flex-start;
        &:nth-of-type(2){
          align-items: flex-end;
          .pagination{
            text-align: right;
            select{
              margin-right: 0px;
            }
          }
          .csv{
            display: none;
          }
        }
      }
    }
  }
}