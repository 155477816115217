@import '../../styles/mixins.less';

.humidity-widget {
  > .fdp {
    .suff{
      vertical-align: top;
    }
  }

}
.device-widget.humidity{
  .column;
  .buttons-wrap{
    // display: none;
  }
}

