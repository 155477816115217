@import '../../styles/mixins.less';

.user-nav {
  li.avatar{
    padding-right: @bigPad;
    a{
      padding: 0 0px 0 0;
      // background: url(../../assets/down-carrot.svg) right center no-repeat transparent;
    }
    &.impersonating{
      .user-avatar{
        border-color: @red;
      }
    }
  }
  li.my-dashboard{
    display: none;
  }
}
