.app {
  font-family: 'Ubuntu', sans-serif;
  position: relative;
  background-color: @bg;
  color: @gray-dark-amb;
  // min-height: 100vh; // we may have to put this back ¯\_(ツ)_/¯ 5.20.21 olw
  &.signin-page,
  &.get-started,
  &.welcome-page{
    min-height: 100vh;
  }
  &.offline{
    height: 100vh;
    width: 100%;
    display: flex;
    .column;
    text-align: center;
    justify-content: center;
    .icon{
      background: url(../assets//forecast/rain.svg) center center no-repeat;
      width: 100%;
      height: 150px;
      background-size: contain;
    }
    p {
      color: @gray-amb;
      font-size: 2.3rem;
      padding-bottom: 10px;
    }
    &.error{
      .error-description{
        p{
          color: var(--color-text-main);
          font-size: 1.5rem;
          padding: @generalPad (@generalPad * 2);
          line-height: 1.3;
        }
      }
      .icon{
        margin-bottom: 15px;
      }
      .still-having-trouble{
        p {
          text-align: left;
        }
      }
      h5{
        margin-top: @generalPad * 3;
      }
    }
  }
  > footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 15px 10px;
    color: @gray-amb;
    font-size: 0.9em;
    text-align: right;
    font-weight: 300;
    z-index: 2;
    // background-color: var(--color-bg-page-block-main);
    a {
      text-decoration: none;
    }
    .sep{
      // color: var(--brandColor);
      display: inline-block;
      padding: 0 5px;
    }
    .powered{
      display: none;
    }
  }
  a{
    color: var(--brandColor);
  }
  .ifttt{
    
    height: 15px;
    background: url(../assets/ifttt.png) center center no-repeat;
    background-size: 100%;
    display: inline-block;
  }
  .alexa{
    width: 15px;
    height: 15px;
    background: url(../assets/alexa.png) center center no-repeat;
    background-size: 100%;
    display: inline-block;
    &.gray{
      background-image: url(../assets/alexa-gray.png);
    }
  }
  .google-assistant{
    background-image: url(../assets/google-assistant.png);
  }
  .csv{
    @w: 17px;
    width: @w;
    height: @w + 2px;
    display: inline-block;
    background: url(../assets/share-icon.svg) center center no-repeat;
    background-size: 78%;
    margin: 0 5px 0 15px;
    outline: none;
  }
  .user-nav{
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 10;
      .mobile-login{
        display: none;
      }
  }
  &.v3{
    .user-nav{
      position: fixed;
      top: auto;
      right: auto;
      bottom: 45px;
      left: 0px;
      width: 200px;
      display: flex;
      justify-content: center;
      li.avatar{
        padding-right: 0;
        .user-avatar{
          width: 70px;
          height: 70px;
        }
      }
      a.add-device{
        .btn-primary;
      }
    }
    .component-dashboard-nav{
      display: block;
    }
  }
  .navbar-brand{
      background: var(--image-bg-logo) center center no-repeat;
      background-size: 88%;
      width: 200px;
      height: @headingHeight;
      display: block;
      color: transparent;
      float: none;
      //background-color: var(--color-bg-logo-main) !important;
  }
  .btn{
      border-radius: 25px;
      border-width: 2px;
      border: none;
      text-decoration: none;
      padding: 6px 18px;
      font-size: 15px;
      &:disabled{
        background-color: @gray-light-amb;
      }
      &.btn-lg{
        width: 100%;
        max-width: 400px;
      }
      &.btn-primary{
        text-decoration: none;
      }
      &.white{
        border: 1px solid var(--brandColor);
        background-color: @white;
        color: var(--brandColor);
      }
      &.btn-green{
        border: solid 1px @white;
        background-color: @green-dark;
        color: @white;
        &:hover{
          background-color: darken(@green-dark, 10%);
        }
      }
  }
  .progress{
    height: 10px;
    border-radius: 6px;
    margin-bottom: 0px;
    box-shadow: none;
    border: 1px solid @gray-light-amb;
    background-color: @white;
    .progress-bar{
      border-radius: 6px;
      background-color: @green;
      &.warn{
        background-color: @gold;
      }
      &.high{
        background-color: @pink;
      }
      &.blue{
        background-color: var(--brandColor);
      }
    }
  }
  .component-dashboard-nav{
      display: none;
  }
  .page-container{
   // background-color: var(--color-bg-page-container-main);
  }
  &.v3,
  &.devices-public,
  &.dashboard{
      display: flex;
      width: 100%;
      .navbar-brand{
        // background-color:  @gray-lighter;
      }
      &:before{
        content: '';
        position: absolute;
        height: @headingHeight;
        background-color: var(--brandColor);
        width: 100%;
        z-index: 1;
      }
      &.dashboard-page.v3{
        &:before{
          display: none;
        }
      }
      .loggedout{
          display: none;
      }
      .component-dashboard-nav{
          display: block;
          position: relative;
          z-index: 3;
      }
      .page-container{
        // overflow: hidden;
        min-width: 0;
        box-sizing: border-box;
        flex-grow: 1;
        position: relative;
        z-index: 2;
        padding-bottom: 30px;
      }
      &.home-page{
        .page-container{
          padding-bottom: 0px;
        }
      }
  }
  .pagination{
    margin: 0px;
  }
  header.main{
    height: @headingHeight;
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: @bigPad;
    color: #fff;
    align-items: center;
    background-color: var(--color-bg-app-header-main);
    position: sticky;
    top: 0px;
    z-index: 30;
    h1{
      margin: 0px;
      font-weight: 400;
      font-size: 24px;
      color: @white;
    }
    select{
      appearance: none;
      background-color: transparent;
      background: url(../assets/down-carrot.svg) right center no-repeat transparent;
      border: none;
      display: inline-block;
      font-size: 24px;
      width: auto;
      option{
        padding-right: 20px;
      }
      &:focus{
        option{
          color: @text-dark;
        }
      }
    }
  }
  .block{
    background-color: var(--color-bg-page-block-main);
    color: var(--color-text-page-block-main);
    .general-shadow;
    box-sizing: border-box;
    padding: @bigPad;
    margin-bottom: @bigPad;
    
  }
  .mobile-header{
    display: none;
    a{
      outline: none;
    }
  }
  .close-menu{
    display: none;
  }
  &.offline-not_used_any_more{
    &:after{
      position: fixed;
      bottom: @generalPad;
      right: @generalPad;
      content: 'Offline';
      background-color: @yellow;
      border-radius: 1px;
      padding: 10px @bigPad;
      width: 150px;
      color: @white;
      display: block;
      font-size: 1.8rem;
      z-index: 1000;
      text-transform: uppercase;
      text-align: center;
    }
  }
}
.btn{
  &.btn-gray{
    background-color: @gray-amb;
    color: @white;
    border-radius: 25px;
    border-width: 2px;
    border: none;
    text-decoration: none;
    padding: 6px 18px;
    font-size: 15px;
    &:hover{
      background-color: @gray-light-amb;
    }
  }
}
@media (max-width: @tablet-phone-max) {
  .ptr--top{
    .app{
      .mobile-header{
        position: absolute;
        background-color: var(--color-bg-main);
      }
    }
  }
  .app{
    .mobile-header{
      display: block;
      position: absolute;
      height: @headingHeight;
      top: 0;
      left: 0;
      width: 100%;
      background-color: var(--color-bg-main);
      z-index: 3;
      overflow: hidden;
    }
    &.v3{
      .user-nav{
        display: none;
      }
    }
    &.v3.signin.oauth{
      .user-nav,
      .component-help-links.help,
      .social-notifications,
      .common-mobile-nav{
        display: none;
      }
    }
    // cordova / iOS specific
    &.ios{
      *:not(textarea, input) {
        user-select: none;
      }
      textarea,
      input{
        user-select: text !important;
      }
      @howMuch: @iosExtraTopPad;
      .mobile-header{
        // height: @headingHeight + @howMuch;  Removing this is related to fix for iPhone 12 notch.
        height: @headingHeight;
        height: calc(~"env(safe-area-inset-top, 0) + 60px");
        padding-top: env(safe-area-inset-top);
      }
      .device-outside-bar.open.fs > .top .close{
        height: 60px;
        background-position: 15px 38px;
      }
      &.home-page{
        .loggedout{
          top: 10px;
        }
        .user-home-page{
          .map-with-forecast, 
          .map-with-forecast.minimized{
            .component-forecast{
              top: 68px + @howMuch;
            }
            .component-device-chooser.open.searched .wrap{
              top: 21px + @howMuch;
            }
          }
          .user-metric-chooser{
            top: 29px + @howMuch !important;
          }
        }
      }
      .page-container{
        padding-top: env(safe-area-inset-top);
      }
      &.open{
        .close-menu{
          top: env(safe-area-inset-top);
        }
      }
      &.dashboard{
        .component-dashboard-nav{
          .wrap{
            a.account{
              height: 150px + @howMuch;
              padding-top: 20px + @howMuch;
            }
            a.logout{
              top: @howMuch;
            }
          }
        }
      }
    }
    &.dashboard{
      &:before{
        display: none;
      }
      .mobile-header{
        .navbar-brand{
          padding: 0;
          background-size: 100%;
          height: 80px;
          margin-left: 60px;
        }
        .burger{
          float: left;
          padding: @headingHeight/2 @headingHeight/3;
          background: url(../assets/group-3.svg) center center no-repeat;
          background-size: 50%;
          display: inline-block;
        }
      }
    }
    &.open{
      .close-menu{
        padding: @headingHeight/2 @headingHeight/3;
        position: fixed;
        top: 0;
        left: 0;
        background: url(../assets/x-white.svg) center center no-repeat; 
        z-index: 5;
        background-size: 40%;
        display: inline-block;
      }
    }
    .user-nav{
      display: none;
    }
    header.main{
      margin-top: @headingHeight;
      color: var(--brandColor);
      font-size: @phone-font-grow;
      height: auto;
      padding-bottom: 10px;
      padding-top: 10px;
      h1,
      select{
        font-size: @phone-font-grow + 0.8em;
        padding-left: 0;
        padding-right: 0px;
      }
      select{
        background-image: url(../assets/down-carrot-gray.svg);
      }
    }
    &.devices-public{
      header.main{
        margin-top: 0px;
        height: @headingHeight;
        h1{
          color: @white;
          font-size: 24px;
        }
      }
      .device-devices-header{
        a.glyphicon,
        .countdown{
          color: @white;
        }
      }
    }
  }
}
@media(max-width: (@phone-max + 1)){
  .app{
    &.v3.loggedout{
      .component-dashboard-nav{
        display: none;
      }
      .user-nav{
        display: block;
        right: @generalPad;
        left: auto;
        top: 27px;
        height: 33px;
        width: auto;
        .login{
          display: block;
        }
        .mobile-login{
          display: none;
        }
      }
    }
  }
}
@media(max-width: @phone-max){
  .app.home-page-fff66a4d00c8ed7bb7814ff29d0faca2 .loggedout, .app.home-page .loggedout {
    height: 60px !important;
  }
  .app{
    padding-bottom: 30px;
    header.main{
      margin-top: 60px;
      h1, select{
        // max-width: 200px;
        width: 100%;
      }
    }
    .mobile-header {
      height: 60px;
    }
    .navbar-brand{ 
      height: 60px;
    }
    &.dashboard-page {
      .mobile-header {
        display: none;
      }
    }
    footer{
      font-size: 0.8em;
      text-align: center;
      background-color: var(--color-bg-base);
      padding-bottom: 7px;
      margin-bottom: 56px;
      z-index: 1;
      .br{
        display: block;
        height: 3px;
      }
    }
    &.v3.loggedout{
      .user-nav{
        top: 24px;
        position: absolute;
        .navbar-nav{
          margin: 0;
        }
        .login,
        .add-device{
          display: none;
        }
        .mobile-login{
          display: block;
          .btn-primary;
        }
      }
    }
    &.v4{
      &.home-page,
      &.social-page,
      &.common-menu{
        .navbar-brand,
        .mobile-header,
        &.dashboard .navbar-brand{
          display: none !important;
          left: 0px;
        }
      }
      &.common-menu{
        #footer_main{
          display: none;
        }
      }
      .navbar-brand{
        margin-left: @generalPad !important;
      }
      .mobile-header .burger,
      .component-dashboard-nav,
      &.dashboard .component-dashboard-nav{
      // .social-notifications{
        display: none;
      }
    }
    &.sidebar-fs.v3{
      .page-container{
        z-index: 999;
      }
    }
  }
}
