@import '../../styles/mixins.less';

@userMetricChooserRight: 118px;
.user-home-page {
  @removeWidth: 440px;
  @minimizedRightColWith: 300px;
  @leftRemove: @removeWidth + @minimizedRightColWith;
  @centerColWidth: ~"calc(100vw - @{removeWidth})";
  @minimizedLeftColWith: ~"calc(100vw - @{leftRemove})";
  @sidebarLeftColWidth: ~"calc(100vw - @{leftRemove} - 30px)";
  @trans: all 0.3s ease-out; 
  position: relative;
  display: flex;
  .user-metric-chooser{
    position: fixed;
    right: @userMetricChooserRight;
    .sidebar-transition();
    top: 27px;
    z-index: 500;
  }
  .component-device-chooser{
    transition: @trans;
    position: fixed;
    z-index: 525;
    .clean-device-chooser;
    .sidebar-transition();
    border-color: @white;
    top: 40vh;
    width: 600px;
    margin-left: -300px;
    left: 50%;
    .component-find-location-btn{
      display: inline-block;
      flex-grow: 1;
      padding-left: 0;
      padding-right: 0;
      font-size: 1.7rem;
      padding-top: 18px;
      margin-left: 15px;
      border-radius: 30px;
    }
    .device-select{
      display: none;
    }
    .open{
      display: flex;
      .search{
        flex-grow: 2;
      }
    }
    &.not-searched{
      .glyphicon.x-gray{
        display: none;
      }
    }
  }
  .component-forecast{
    position: absolute;
    top: 90px;
    width: 100%;
    z-index: 501;
    .wrap{
      margin: 0 auto;
      width: 100%;
      max-width: @centerColWidth;
      position: relative;
      .show-24{
        position: absolute;
        bottom: 0px;
        padding: 20px 20px 18px 20px;
        color: var(--color-text-main-lt);
        text-decoration: none;
        font-size: 0.9em;
      }
    }
  }
  .map-with-forecast{
    flex-grow: 1;
    .intro{
      position: absolute;
      top: ~"calc(40vh - 97px)";
      width: 100%;
      left: 0;
      display: flex;
      justify-content: center;
      h1{
        .blur-bg;
        margin: 0;
        text-align: center;
        font-size: 43px;
        padding: @bigPad 60px;
        border-radius: 15px;
        sup{
          font-size: 12px;
          top: -2em;
        }
      }

    }
    .expand{
      display: none;
    }
    &.searched{
      .intro{
        display: none;
      }
      .component-device-chooser{
        top: 19px;
        left: 207px;
        margin-left: 0;
        width: @centerColWidth;
        .component-find-location-btn{
          padding-top: 8px;
          font-size: 1.2rem;
          position: absolute;
          right: 5px;
          top: 5px;
          padding: 5px 10px;
          background-color: var(--color-bg-find-location-btn-primary);
          border: 1px solid var(--color-border-find-location-btn-primary);
          color: var(--color-text-find-location-btn-primary);
          @media(max-width: @tablet-max){
            font-size: 1.4rem;
            top: 3px;
            right: 3px;
          }
        }
        .open {
          > .search{
            ::placeholder{
              font-size: 1.5rem;
            }
            input{
              padding: 8px 0px 7px 40px;
              font-size: 1.5rem;
              background-color: var(--color-bg-map-search-main);
            }
            &:before{
              background-size: 14px;
              top: 9px;
              left: 12px;
            }
            .glyphicon.x-gray{
              background-size: 16px;
              top: 9px;
              right: 120px;
            }
            .component-loader{
              top: 15px;
            }
          }
        }
      }
    }
    .forecast{
      .link{
        display: none;
      }
    }
    &.minimized{
      .component-device-chooser{
        width: @minimizedLeftColWith;
      }
      .component-forecast{
        top: 8px;
        .forecast{
          position: absolute;
          right: 0;
          width: @minimizedRightColWith - 10px;
          text-align: center;
          .minimized-forecast;
          .link{
            display: block;
          }
          .currently{
            font-size: 12px;
          }
        }
        .close{
          position: absolute;
          background: none;
          width: @minimizedRightColWith;
          height: 85px;
          top: 0;
          right: 0;
        }
      }
    }
    &.sidebar{
      .component-forecast{
        display: none;
      }
      .component-device-chooser{
        width: @sidebarLeftColWidth;
      }
    }
  }
  .forecast-long-term-wrap{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 611;
    .component-forecast-long-term{
      position: fixed;
      right: 85px;
      bottom: 135px;
    }
  }
}
.app.home-page-fff66a4d00c8ed7bb7814ff29d0faca2,
.app.home-page{
  background-color: @white;
  &::before{
    display: none;
  }
  &.dashboard{
    .user-metric-chooser{
      display: none !important;
    }
  }
  &.ios{
    .page-container{
      padding-top: 0;
    }
  }
  .loggedout{
    position: fixed;
    z-index: 401;
    height: 80px;
    display: block !important;
    background-color: transparent;
  }
  @userNavRight: 58px;
  .user-nav{
    z-index: 402;
    top: 23px;
    left: auto;
    right: @userNavRight;
    height: 35px;
    width: 135px;
    .sidebar-transition();
    .avatar,
    .add-device{
      display: none;
    }
    li.my-dashboard{
      display: inline-block;
    }
    .login{
      display: none;
      &.mobile-login{
        display: block;
        .btn-primary;
      }
    }
  }
  &.loggedout{
    .user-nav{
      right: @bigPad;
    }
  }
  &.sidebar{
    .user-metric-chooser{
      right: @userMetricChooserRight + @sidebar-width - 9px;
    }
    .user-nav{
      right: @sidebar-width + @userNavRight
    }
    &.loggedout{
      .user-nav{
        right: @sidebar-width + @generalPad;
      }
    }
  }
  &.forecast-modal{
    .user-nav{
      z-index: 2;
    }
  }
  .component-dashboard-nav{
    display: none;
  }
  .mobile-post,
  #footer_main{
    display: none;
  }
}

@media (max-width: @tablet-phone-max) {
  .app.home-page{
    &.loggedout{
      .mobile-header{
        display: none;
      }
      .user-home-page{
        .user-metric-chooser{
          right: 15px;
          top: 25px;
          font-size: 1.1rem;
        }
        .map-with-forecast{
          .component-device-chooser.searched{
            left: 192px;
          }
          &.sidebar{
            .component-device-chooser.searched{
              left: 15px;
            }
          }
          &.minimized{
            .component-forecast{
              .forecast{
                right: 75px;
              }
            }
          }
        }
      }
    }
    &.dashboard{
      .mobile-header{
        background-color: transparent;
        width: 53px;
        z-index: 402;
        .navbar-brand{
          display: none;
        }
      }
      .navbar-brand{
        left: 43px;
      }
      .component-dashboard-nav{
        display: block;
      }
      &.open{
        .navbar-brand,
        .mobile-header{
          display: none !important;
        }
      }
    }
  }
  .user-home-page{
    @removeWidth: 245px;
    @minimizedRightColWith: 250px;
    @leftRemove: @removeWidth + @minimizedRightColWith;
    @centerColWidth: ~"calc(100vw - @{removeWidth})";
    @minimizedLeftColWith: ~"calc(100vw - @{leftRemove})";
    .component-forecast{
      padding: 0 @bigPad;
      top: 70px;
      .wrap{
        max-width: 100%;
      }
    }
    .map-with-forecast{
      &.searched{
        .component-device-chooser{
          left: @removeWidth;
          width: ~"calc(100vw - @{removeWidth} - @{biggestestPad})";
          .glyphicon.x-gray{
            right: 12px !important;
          }
        }
        .component-find-location-btn{
          padding: 5px 6px 2px;
          .text{
            display: none;
          }
          .glyphicon{
            font-size: 17px;
            display: inline-block;
          }
        }
      }
      &.minimized{
        .component-device-chooser{
          width: @minimizedLeftColWith;
        }
        &.searched{
          .component-device-chooser{
            left: @removeWidth;
          }
        }
        .component-forecast{
          padding: 0;
          .forecast{
            width: @minimizedRightColWith - @bigPad - 10px;
            right: @bigPad;
          }
          .timeline{
            margin: 0 @bigPad;
          }
          .close{
            width: @minimizedRightColWith;
          }
        }
      }
      &.sidebar{
        &.searched{
          .component-device-chooser{
            width: ~"calc(100vw - @{sidebar-width} - @{generalPad} - @{generalPad})";
            left: @generalPad;
            top: 70px;
          }
        }
      }
    }
    .forecast-long-term-wrap{
      .component-forecast-long-term{
        width: 100%;
        right: auto;
        height: 100vh;
        left: 0;
        top: 0;
        bottom: auto;
        border-radius: 0;
        padding-top: 80px;
        padding-bottom: 80px;
        .close{
          top: 20px;
          right: 20px;
          width: 40px;
          height: 40px;
        }
        .other-days{
          max-height: 300px;
        }
      }
    }
  }
}
@media (max-width: @phone-max) {
  .app.home-page-fff66a4d00c8ed7bb7814ff29d0faca2,
  .app.home-page{
    padding-bottom: 0;
    .page-container{
      overflow: hidden;
    }
    .navbar-brand{
      width: 43px;
      background-size: auto 46px;
      background-position: left center;
      margin-left: 5px;
    }
    .component-device-chooser .spacer,
    .user-nav .mobile-login,
    .user-nav .login{
      display: none !important;
    }
    // logged out 
    &.loggedout{
      .user-home-page{
        .user-metric-chooser{
          top: 29px;
        }
        .map-with-forecast,
        .map-with-forecast.minimized{
          &.searched{
            .component-device-chooser.open {
              .wrap{
                @l: 60px;
                left: @l;
                width: ~"calc(100vw - 122px - @{generalPad})";
              }
            }
          }
        }
      }
    }
    &.sidebar{
      .user-home-page{
        .mobile-post{
          display: none;
        }
        .mapboxgl-ctrl-bottom-left{
          bottom: 0px;
        }
      }
    }
  }
  .user-home-page{
    flex-direction: column;
    .map-with-forecast,
    .map-with-forecast.minimized{
      .intro{
        top: ~"calc(40vh - 144px)";
      }
      .component-forecast{
        top: 68px;
        padding: 0 @generalPad;
        .close{
          background-size: 10px 10px;
          background-position: top 0px right 0px;
          width: 100%;
          height: 40px;
        }
        .forecast{
          padding-left: @generalPad;
          padding-right: @generalPad;
          padding-bottom: 30px;
          .currently{
            font-size: 2rem;
          }
          .show-24{
            padding-bottom: 10px;
            font-size: 0.8em;
          }
        }
      }
      .component-device-chooser.open {
        width: 100%;
        left: 0;
        margin-left: 0;
        .wrap{
          position: relative;
          top: 10px;
          height: 130px;
          .open{
            flex-direction: column;
            padding: 0 @bigPad;
          }
          .component-find-location-btn{
            margin-left: 0;
            padding: 13px 0;
            margin-top: 10px;
          }
        }
      }
      &.searched{
        .component-device-chooser.open {
          .wrap{
            position: fixed;
            @l: 100px;
            top: 11px;
            left: @l;
            width: ~"calc(100vw - 138px - @{generalPad})";
            height: 40px;
            .open{
              padding-right: 0;
              padding-left: 0;
            }
          }
          .component-find-location-btn{
            padding: 5px 10px;
            margin-top: 0;
            top: 4px;
            // display: none;
            
          }
          .open > .search .glyphicon.x-gray{
            right: 24px;
            display: none;
          }
        }
      }
    }
    .map-with-forecast.minimized{
      .forecast{
        display: none;
      }
    }
    .forecast-long-term-wrap{
      display: flex;
      justify-content: center;
      align-items: center;
      .component-forecast-long-term{
        position: relative;
        top: auto;
        bottom: auto;
        right: auto;
        width: 100vw;
        padding: 60px 15px 80px 15px;
        margin: 0px @generalPad;
        &.short{
          padding: 50px 15px 15px;
        }
      }
    }
  }
}
@media(max-height: 600px) {

}
@media(max-width: @super-small) {
  .user-home-page{
    .forecast-long-term-wrap{
      .component-forecast-long-term{
        padding: 50px 15px 15px;
      }
    }
  }
}