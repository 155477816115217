@import '../../styles/mixins';

.payment-cell-plan-modal {
    .buyBtn {
        padding-top: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .btn.btn-primary.buy-plan {
        font-size: 2rem;
        width: 50%;
        margin: 0 auto;
    }

    // margin-bottom: 5em;

    header {
        text-align: center;
        margin-bottom: 2.3em;
        padding-top: 1em;

        h2 {
            border-bottom: 0 !important;
            padding-bottom: 0.1em !important;
            font-weight: 500 !important;

            @media (max-width: @phone-max) {
                margin-top: 1em !important;
            }
        }

        strong {
            font-weight: 500;
        }
    }

    .tiers {
        display: flex;
        width: 100%;
        justify-content: space-between;

        @media (max-width: @phone-max) {
            flex-direction: column;
        }

        .tier {
            background-color: var(--color-bg-payment-card-main);
            border-radius: 10px;
            // width: 32%;
            border: 2px solid var(--color-bg-widget-main);

            @media (max-width: @phone-max) {
                width: 100%;
                margin-bottom: 2em;

            }

            &.active {
                border: 2px solid var(--color-text-card-content-main);

                .top {
                    background-color: @blue-gray;
                }
            }

            .top {
                text-align: center;
                padding: @generalPad;
                background-color: @blue-dark-alt;
                color: @white;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;

                h2 {
                    font-size: 2em;
                    margin-top: 0.8em;
                    margin-bottom: 0.8em;
                    padding-bottom: 0;
                    border-bottom: none;
                    font-weight: 500;
                    letter-spacing: 0.08em;

                    @media (max-width: @phone-max) {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }

            .bottom {
                padding: @bigPad @generalPad;

                .group {
                    margin-top: 1em;

                    .line {
                        display: flex;
                        justify-content: space-between;
                        font-size: 0.95em;
                        margin-bottom: 0.8em;

                        .label {
                            width: 30%;

                        }

                        .val {
                            width: 70%;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}