@import '../../styles/mixins.less';

.device-create-device {
  @speed: 0.5s;
  position: relative;
  a.connect{
    font-size: 2em;
    line-height: 1.4;
    text-decoration: none;
    color: var(--color-text-page-block-main);
    display: block;
  }
  a.close{
    @w: 40px;
    transition: all @speed ease-out;
    background: url(../../assets/plus.svg) center center no-repeat;
    background-size: 100%;
    width: @w;
    height: @w;
    z-index: 10;
    position: absolute;
    right: @bigPad;
    top: @bigPad - 1;
  }
  .body{
    overflow: hidden;
    max-height: 0;
    transition: max-height @speed ease-out;
    input.editable-title{
      color: @text-dark;
      font-size: 2.2em;
      display: block;
      width: 100%;
      .placeholder(@gray-amb);
      &:hover,
      &:focus,
      &:active{
        border-color: transparent;
      }
    }
    .component-help-links{
      position: relative;
      bottom: auto;
      margin-top: 0;
      justify-content: flex-start;
      padding-bottom: 15px;
      font-size: 1em;
      a{
        text-decoration: underline;
      }
    }
    h2{
      font-size: 1.8em;
      line-height: 1.3;
      margin-bottom: 15px;
    }
    .step-1, .step-2{
      > p{
        padding-top: 10px;
        margin-bottom: 15px;
      }
      .tz{
        display: none;
      }
      .skip{
        float: right;
        font-size: 0.9em;
      }
      .component-help-links{
        padding-top: 100px;
        padding-bottom: 0;
      }
    }
    .mac{
      margin-top: 25px;
      margin-bottom: 20px;
      &.has-success,
      &.has-error{
        margin-bottom: 10px;
      }
    }
    .form-control{
      padding: 5px 15px 6px;
      text-align: center;
    }
    .mac-message{
      p{
        margin-bottom: 15px;
      }
      &.has-error{
        .help-block{
          margin-bottom: 40px;
        }
      }
      &.has-success{
        .help-block{
          margin-top: 0;
          margin-bottom: 25px;
        }
      }
    }
    label{
      display: block;
      text-transform: none;
      font-weight: normal;
      font-size: 1.3em;
      margin-bottom: 10px;
      margin-top: 30px;
      &.normal{
        font-size: 1em;
        display: inline;
        margin: 0;
      }
    }
    input.name{
      min-width: 240px;
      background-color: var(--color-bg-base);
    }
    .component-location{
      .btns-wrap{
        display: none;
      }
    }
    .privacy{
      margin-top: 30px;
      color: var(--color-text-main-md);
      font-size: 0.8em;
    }

    .not-too-wide{
      // max-width: 700px;
    }
    .wrap{
      .not-too-wide;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      .item{
        padding-top: @generalPad;
        flex: 1;
        display: flex;
        align-items: center;
        .form-group{
          display: flex;
          align-items: center;
          margin-bottom: 0px;
        }
        .label{
          font-size: 1.2em;
          padding-right: 8px;
          white-space: nowrap;
        }
      }
      .btn{
        margin-top: @generalPad;
        margin-left: 20px;
      }
    }
    .device-sub-head{
      .item.tz .component-timezone-picker{
        bottom: -5px;
        left: 73px;
      }
    }
    .btns-wrap{
      display: flex;
      align-items: center;
      padding-top: @generalPad;
      a.skip{
        color: var(--color-text-main-md);
        text-decoration: none;
        display: inline-block;
        margin-right: 30px;
      }
    }
  }
  &.open{
    a.close{
      transform: rotate(-405deg);
      filter: grayscale(100%);
      opacity: 0.8;
    }
    a.connect{
      // display: none;
    }
    .body{
      max-height: 1500px;
      // padding-bottom: 100px;
    }
    &.location{
      .body{
        padding-bottom: 0px;
      }
    }
  }

}

@media (max-width: @phone-max) {
  .device-create-device {
    a.connect{
      padding-right: 50px;
    }
    &.open{
      .body{
        padding-top: 17px;
        max-height: 2000px;
        .name,
        .form-control{
          width: 100%;
        }
        .step-1, .step-2{
          > p {
            a{
              display: block;
            }
          }
        }
        .btn-long{
          width: 100%;
        }
      }
    }
    .body{
      .wrap{
        flex-direction: column;
        .item{
          flex-direction: column;
          .label{
            padding-right: 0px;
            padding-bottom: 10px;
          }
          .form-group{
            width: 100%;
            margin-bottom: 15px;
            .form-control{
              margin: auto;
              width: 240px;
            }
          }
        }
      }
    }
  }
}