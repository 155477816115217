@import '../../styles/mixins.less';

.device-widget.lightning{
  .column;
  .device-lightning-widget {
    .column;
    flex: 1;
    justify-content: center;
    .top-wrap{
      //flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    .top{
      display: flex;
      justify-content: center;
      color: @text-dark;
      .val{
        color: var(--color-text-widget-big-number);
        font-size: 4.3em;
        line-height: 0.9;
        padding-right: 2px;
        font-size: 15vw;
        font-size: clamp(1em, 15vw, 4em);
      }
      .labels{
        .column;
        justify-content: flex-end;
        font-size: 0.9em;
        .icon{
          height: 22px;
          width: 19px;
          background: url(../../assets/lightning-black.svg) center center no-repeat;
          background-size: 100%;
        }
      }
    }
    .bottom{
      display: flex;
      padding: 20px 5px;
      justify-content: space-between;
      > div {
        text-align: center;
        flex-grow: 1;
        line-height: 1.2;
        padding: 0 8px;
        &:nth-of-type(2){
          border-left: 1px solid var(--color-border-line-separator-main);
          border-right: 1px solid var(--color-border-line-separator-main);
        }
        .label {
          font-size: 1.3vw;
          font-size: clamp(0.6em, 1.3vw, 0.65em);
        }
        .val{
          padding: 5px 0 0;
          font-size: 0.8em;
          font-size: 1vw;
          font-size: clamp(0.9em, 1vw, 1em);
          .icon{
            display: inline-block;
            padding-right: 12px;
            background: url(../../assets/lightning-blue.svg) center right no-repeat;
            background-size: 10px;
          }
        }
      }
    }
    // large widget
    &.lg{
      font-size: 25px;
    }
  }
  .buttons-rounded{
    .exp{
      display: none;
    }
  }
}
@media (max-width: @tablet-max) {
  .device-widget.lightning {
    .device-lightning-widget {
      .bottom {
        >div {
          padding: 0;
          .label {
            font-size: 1vw;
            font-size: clamp(0.65em, 1vw, 0.8em);
          }
        }
      }
    }
  }
}

@media (max-width: @phone-max) {
  .device-widget.lightning{
    padding-bottom: 0;
    //padding: 25px 0;
    .device-lightning-widget {
      padding: 20px 0;
      .top-wrap{
        //min-height: 150px;
      }
    }
  }

}