@import '../styles/mixins.less';

// a lot of this is in ForecastWidget.less because that's where it originally cam from 8.7.20
// below is stuff for the homepage forecast

.user-home-page .forecast-long-term-wrap .component-forecast-long-term {
  border-radius: 15px;
  background-color: var(--color-bg-modal-main);
  width: 600px;
  .general-shadow;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  .close{
    position: absolute;
    top: 10px;
    right: 10px;
    background: url(../assets/x-blue.svg) center center no-repeat;
    background-size: 70%;
    display: inline-block;
    width: 30px;
    height: 30px;
  }
  .forecast-text{
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    padding: 20px 0;
    color: var(--color-text-main);
  }
  .title-today{
    .title{
      display: none;
    }
  }
  .today{
    font-size: 28px;
  }
  .hourly{
    font-size: 19px;
    display: flex;
  }
  .other-days{
    min-height: 180px;
  }
}