@import '../../styles/mixins.less';

.device-outside-bar {
  @w: @sidebar-width;
  width: 0px;
  height: 100vh;
  background-color: var(--color-bg-outside-bar-main);
  .sidebar-transition();
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  * {
    box-sizing: border-box;
  }
  .beta:before{
    font-size: 8px;
    padding-top: 2px;
    padding-bottom: 1px;
  }
  > .top{
    padding-top: 40px;
    position: relative;
    display: none;
    .close{
      .sidebar-transition(height);
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 40px;
      background: url(../../assets/x-black.svg) center center no-repeat;
      background-size: 45%;
    }
  }
  .component-generic-ad{
    margin-bottom: 20px;
    .general-shadow;
  }
  .component-min-max-btns{
    position: absolute;
    top: 100px;
    .sidebar-transition();
    left: 1px;
    width: 36px;
    .buttons-rounded{
      padding-right: 1px;
    }
  }
  &.open{
    width: @w;
    > .top{
      display: block;
    }
    .component-min-max-btns{
      left: -19px;
    }
    .tabs{
      width: 100%;
      .sidebar-transition();
      padding: 0;
      display: flex;
      .rect-tabs;
      a {
        flex-grow: 1;
        padding-left: 0;
        padding-right: 0;
        &.hidden{
          display: none;
        }
      }
    }
  }
  .alt-show{
    text-align: center;
    color: var(--color-text-main-lt);
    font-weight: bold;
    padding-top: 10px;
  }
  .slick-cloned{
    .alt-show{
      display: none !important;
    }
  }
  > .wrap{
    width: @w;
    padding: 15px;
    overflow-y: auto;
    flex-grow: 1;
  }
  .create-post-wrap{
    text-align: center;
    padding: @generalPad;
    .general-shadow;
    h4{
      font-size: 1.4rem;
      margin: 0px 0 10px;
      color: var(--color-text-main-lt);
    }
  }
  .card-wrap{
    padding-bottom: 20px;
    cursor: pointer;
    
  }
  .device-device-popup{
    width: 100%;
    max-width: 100%;
    border: 1px solid var(--color-border-card-main);
    &.active{
      border-color: var(--color-border-card-main);
    }
    &.boosted {
      border-color: var(--brandColor);
    }
    &.hovered{
      .general-shadow;
    }
    .device-temp-widget{
      margin-bottom: 0;
      .body{
        border: none !important;
        .top{
          // font-size: 0;

        }
      }
    }
  }
}
@media (max-width: @phone-max) {
  @mobile-sidebar-card-content-height: @sidebar-height - 170;
  @mobile-social-card-height: 200px; // social and webcam
  .app.v4.home-page {
    .user-home-page .device-outside-bar {
      width: 100%;
      background: transparent;
      height: @sidebar-height;
      // height: 0;
      // position: fixed;
      // bottom: 0px;
      // left: 0px;
      > .wrap{
        // display: flex;
        padding: 0;
        height: @sidebar-height;
        width: 180%;
        overflow: hidden;
        outline: none;
        * {
          outline: none;
        }
        .card-wrap{
          padding: 10px @generalPad;
          .device-device-popup {
            .device-temp-widget,
            .device-webcam-image,
            .rain-widget{
              height: @mobile-sidebar-card-content-height;
            }
          } 
        }
        .social-post{
        }
      }
      > .top{
        padding-top: 0;
        .close{
          position: relative;
          background: none;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 20px;
          &:before{
            content: '';
            height: 6px;
            width: 40%;
            border-radius: 3px;
            background-color: @gray-light-amb;
          }
        }
      }
      &.open{
        height: @sidebar-height;
        .sidebar-transition();
        width: 100%;
        &.social{
          height: 360px;
        }
        &.not-fs{
          .carousel .slider-wrapper.axis-horizontal .slider{
            align-items: flex-end;
          }
        }
        &.fs{
          height: 100vh;
          .card-wrap{
            margin: 0;
            height: 100vh;
            .device-device-popup{
              display: flex;
              flex-direction: column;
              height: auto;
              text-align: left;
              height: 100vh;
              padding-top: env(safe-area-inset-top, 0);
              &.social{
                > .content{
                  padding-top: 70px;
                  .text{
                    display: block;
                  }
                }
                > .top{
                  position: relative;
                  background-color: var(--color-bg-outside-bar-main);
                  order: 3;
                }
                > .content{
                  order: 1;
                  height: auto;
                  max-height: none;
                  .social-post{
                    height: auto;
                    max-height: none;
                  }
                }
                > .bottom{
                  ordeR: 2;
                  display: flex;
                }
                .social-comments{
                  display: block;
                  order: 4;
                }
              }
              &.webcam{
                background-color: @black;
                > .top{
                  position: relative;
                }
                .content{
                  flex-grow: 1;
                  .column;
                  .device-webcam-widget a,
                  .device-webcam-widget{
                    background-color: @black;
                    flex-grow: 1;
                    .column;
                  }
                  .device-webcam-image{
                    flex-grow: 1;
                    width: 100%;
                    background-size: contain;
                  }
                }
              }
              .rain-widget{
                height: 200px;
              }
            }
          }
          .component-generic-ad{
            background-color: var(--color-bg-card-content-main);
            background-size: contain;
            height: 100vh;
            margin: 0;
            box-shadow: none;
          }
        }
      }
    }
  }
}
