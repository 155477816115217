@import '../../styles/mixins.less';

.social-my-timeline {
  padding-bottom: 30px;
  overflow: hidden;
  .controls{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    padding: 0px 10px 10px;
    background-color: var(--color-bg-page-block-main);
    box-shadow: 0px 4px 4px -4px rgba(0, 0, 0, 0.2);
    > a.btn {
      flex: 1;
      padding-top: 5px;
      padding-bottom: 5px;
      margin: 5px;
      &:first-of-type{
        margin-left: 0;
      }
      &:last-of-type{
        margin-right: 0;
      }
      &:not(.btn-primary){
        background-color: #e3e3e3;
        color: var(--color-text-main-md);
      }
    }
    ul.layout-switcher{
      display: none;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;
      margin-left: auto;
      li{
        margin: 0;
        padding: 0;
        display: inline-block;
        margin-right: 5px;
        &:last-of-type{
          margin-right: 0;
        }
        a{
          display: block;
          width: 28px;
          height: 25px;
          padding: 5px 8px;
          border-bottom: 3px solid @gray-amb;
          background: url(../../assets/social/masonry-icon.svg) no-repeat center center;
          background-size: auto 20px;
          &.list{
            background-image: url(../../assets/social/single-column-icon.svg);
          }
          &.active{
            background-image: url(../../assets/social/masonry-icon-blue.svg);
            border-color: @blue;
            &.list{
              background-image: url(../../assets/social/single-column-icon-blue.svg);
            }
          }
        }
      }
    }
    .common-dropdown-menu{
      .dropdown-menu-list-item,
      .dropdown-menu-button-text{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        i {
          display: inline-block;
          width: 18px;
          height: 17px;
          background: url(../../assets/social/all.svg) no-repeat 0px 1px;
          background-size: auto 90%;
          margin-right: 5px;
        }
        &.location i{
          background-image: url(../../assets/social/location.svg);
          background-size: auto 100%;
          background-position-y: 0px;
          width: 14px;
        }
        &.user i{
          background-image: url(../../assets/common/thumb-hollow.svg);
        }
        &.images i,
        &.imagery i{
          background-image: url(../../assets/social/photos.svg);
          background-size: auto 100%;
          height: 20px;
          width: 22px;
        }
        &.suggestions{
          i {
            background-image: url(../../assets/social/suggestion-box.svg);
          }
        }
        &.popularity{
          i {
            background-image: url(../../assets/common/thumb-empty.svg);
          }
        }
        &.date{
          i{
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.5 19C14.7385 19 19 14.7385 19 9.5C19 4.2617 14.7385 0 9.5 0C4.2617 0 0 4.2617 0 9.5C0 14.7385 4.2617 19 9.5 19ZM9.5 1.9C13.6907 1.9 17.1 5.30931 17.1 9.5C17.1 13.6909 13.6907 17.1 9.5 17.1C5.30931 17.1 1.9 13.6907 1.9 9.5C1.9 5.30931 5.30931 1.9 9.5 1.9Z' fill='%2371919D'/%3E%3Cpath d='M8.56881 9.6863C8.57404 9.71314 8.58425 9.73831 8.59209 9.76444C8.60159 9.79769 8.60942 9.83141 8.62272 9.86371C8.63531 9.89411 8.65265 9.92166 8.66832 9.95016C8.68234 9.97581 8.6935 10.0026 8.71012 10.0271C8.74195 10.0746 8.77852 10.1185 8.81819 10.1594C8.82199 10.1634 8.82436 10.1679 8.82816 10.172L12.6282 13.972C12.9991 14.343 13.6005 14.343 13.9715 13.972C14.3424 13.6008 14.3424 12.9994 13.9715 12.6284L10.45 9.10656V5.28447C10.45 4.75984 10.0247 4.33447 9.50005 4.33447C8.97541 4.33447 8.55005 4.75984 8.55005 5.28447V9.49986C8.55005 9.56232 8.55646 9.62479 8.56881 9.6863Z' fill='%2371919D'/%3E%3C/svg%3E");
          }
        }
      }
      &.sort{
        width: auto;
        min-width: 140px;
        .dropdown-menu-button{
          padding-left: 8px;
          justify-content: flex-end;
          background: none;
        }
      }
    }
  }
  .posts{
    margin: 0 auto;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1400px;
  }
  .no-posts{
    padding: 50px 0;
    color: var(--color-text-main-lt);
    font-weight: bold;
  }
  .within{
    color: var(--color-text-main-lt);
    font-weight: bold;
  }
  .show-all{
    display: block;
    text-decoration: none;
    text-align: center;
  }
  .device-temp-widget {
    .top {
      .suff {
        font-size: 2.6em;
      }
    }
  }
  .device-device-popup {
    // margin-bottom: 25px;
    max-width: 100%;
    width: 100%;
    .rain-widget{
      height: 200px;
    }
  }
  &.single{
    .posts{
      max-width: 500px
    }
    .device-device-popup{
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }
  &.force-list{
    .layout-switcher{
      display: none !important;
    }
  }
}
@media (min-width: @phone-max){
  .social-my-timeline {
    .device-device-popup{
      max-width: 450px;
    }
    .controls{
      ul.layout-switcher{
        display: flex;
      }
    }
  }
}
