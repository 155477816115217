@import '../../styles/mixins.less';

.device-every-widget {
  .snapshot{
    padding: 5px 0;
    background-color: var(--color-bg-widget-snapshot);
    color: var(--color-text-main-lt);
    border: 1px solid var(--color-bg-widget-snapshot);
    color: var(--color-text-main-lt);
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 10px;
  }
  .widget-title {
    text-align: center;
    span {
        color: @white;
        background: var(--color-bg-social-post-widget-title-main);
        text-align: center;
        font-weight: 300;
        position: relative;
        bottom: -14px;
        border-radius: 15px;
        padding: 5px 13px;
        font-size: 15px;
        margin: 0 auto;
    }
  }
  &.my-forecast{
    background-color: var(--color-bg-create-my-forecast-main) !important;
    .snapshot{
      display: none;
    }
    .device-forecast-widget{
      padding-top: 10px;
      .forecast-text,
      .today{
        display: none;
      }
      .other-days{
        justify-content: center;
        .component-day-forecast{
          max-width: 65px;
          min-height: 140px;
          .precip{
            display: none;
          }
        }
      }
    }
    .widget-title{
      padding: 10px 0px 15px;
      span{
        bottom: 0px;
        background: transparent;
        color: @text-dark;
        font-weight: normal;
        font-size: 16px;
      }
    }
  }
}
