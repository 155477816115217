@import '../../styles/mixins';

.app.awn-page{
  color: var(--color-text-main);
  &:before{
    display: none;
  }
  .cta{
    padding: 15px 0;
    .btn-lg{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 40px;
      margin: 0 auto;
      font-size: 1.3em;
      width: auto;
      text-decoration: none;
      i.awn-plus{
        background-image: url(../../assets/awn+logo-white.png);
        background-size: 100% auto;
        width: 50px;
      }
    }
  }

}

.user-awn-plus-page {
  .embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
  .page-body{
    overflow: hidden;
  }

  div#awn-plus div.awn-plus-header {
    text-align: center;
  }
  div#awn-plus div.awn-plus-header img{
    max-width: 1700px;
    width: 100%;
  }
  
  div#awn-plus h2 {
    color: #22A7DF;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    margin: 20px auto;
    max-width: 1200px;
  }
  
  div#awn-plus p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 100;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }
  
  div#awn-plus div.awn-plus-new-level div.awn-plus-new-level-text-image {
    display: flex;
    gap: 20px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
  }
  
  div#awn-plus div.awn-plus-new-level div.awn-plus-new-level-text-image div.awn-plus-new-level-text {
    width: 35%;
  }
  
  div#awn-plus div.awn-plus-new-level div.awn-plus-new-level-text-image div.awn-plus-new-level-image {
    width: 65%;
  }
  
  div#awn-plus div.awn-plus-map-layers h2 {
    margin-top: 20px;
    margin-bottom: 5px;
  }
  
  div#awn-plus div.awn-plus-map-layers div.awn-plus-map-layers-maps div.awn-plus-map-layers-map {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    margin-right: auto;
    max-width: 1200px;
    margin-left: auto;
  }
  
  div#awn-plus div.awn-plus-map-layers div.awn-plus-map-layers-maps div.awn-plus-map-layers-map div.awn-plus-map-layers-map-image {
    width: 50%;
  }
  
  div#awn-plus div.awn-plus-map-layers div.awn-plus-map-layers-maps div.awn-plus-map-layers-map div.awn-plus-map-layers-map-image img {
    width: 100%;
  }
  
  div#awn-plus div.awn-plus-map-layers div.awn-plus-map-layers-maps div.awn-plus-map-layers-map div.awn-plus-map-layers-map-text {
    width: 50%;
  }
  
  div#awn-plus div.awn-plus-map-layers div.awn-plus-map-layers-maps div.awn-plus-map-layers-map div.awn-plus-map-layers-map-text h3 {
    color: #22A7DF;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
  }
  
  div#awn-plus div.awn-plus-advanced-graphing {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }
  
  div#awn-plus div.awn-plus-advanced-graphing div.awn-plus-advanced-graphing-graph-text {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  div#awn-plus div.awn-plus-advanced-graphing div.awn-plus-advanced-graphing-graph-text div.awn-plus-advanced-graphing-graph {
    width: 30%;
  }
  
  div#awn-plus div.awn-plus-advanced-graphing div.awn-plus-advanced-graphing-graph-text div.awn-plus-advanced-graphing-graph img {
    width: 100%;
  }
  
  div#awn-plus div.awn-plus-advanced-graphing div.awn-plus-advanced-graphing-graph-text div.awn-plus-advanced-graphing-text {
    width: 70%;
  }
  
  div#awn-plus div.awn-plus-bonus-tiles {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }
  
  div#awn-plus ul li {
    font-size: 18px;
    line-height: 28px;
    font-weight: 100;
  }
  
  div#awn-plus div.awn-plus-bonus-tiles div.awn-plus-bonus-tiles-images {
    display: flex;
  }
  
  div#awn-plus div.awn-plus-bonus-tiles div.awn-plus-bonus-tiles-images div.awn-plus-bonus-tiles-image {
    width: 33%;
    text-align: center;
  }
  
  div#awn-plus div.awn-plus-bonus-tiles div.awn-plus-bonus-tiles-images div.awn-plus-bonus-tiles-image img {
    width: 100%;
    text-align: center;
  }
  
  div#awn-plus div.awn-plus-bonus-tiles p {
    margin-top: 15px;
    margin-bottom: 0;
  }
  
  div#awn-plus div.awn-plus-addl-text, div#awn-plus div.awn-plus-upgrade-today {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }
  .awn-plus-upgrade-today-images{
    img{
      height: 50px;
      margin-right: 25px;
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 768px) {
    div#awn-plus {
      padding-right: 10px;
      padding-left: 10px;
    }
  
    div#awn-plus div.awn-plus-new-level div.awn-plus-new-level-text-image {
      display: block;
      gap: 0px
    }
    
    div#awn-plus div.awn-plus-new-level div.awn-plus-new-level-text-image div.awn-plus-new-level-text {
      width: 100%;
    }
    
    div#awn-plus div.awn-plus-new-level div.awn-plus-new-level-text-image div.awn-plus-new-level-image {
      width: 100%;
    }
    
    div#awn-plus div.awn-plus-new-level div.awn-plus-new-level-text-image div.awn-plus-new-level-image img {
      width: 100%;
    }
    
    div#awn-plus h2 {
      text-align: center;
    }
    
    div#awn-plus div.awn-plus-map-layers div.awn-plus-map-layers-maps div.awn-plus-map-layers-map {
      display: block;
      gap: 0px;
      margin-bottom: 20px;
    }
    
    div#awn-plus div.awn-plus-map-layers div.awn-plus-map-layers-maps div.awn-plus-map-layers-map div.awn-plus-map-layers-map-image {
      width: 100%;
    }
    
    div#awn-plus div.awn-plus-map-layers div.awn-plus-map-layers-maps div.awn-plus-map-layers-map div.awn-plus-map-layers-map-text {
      width: 100%;
    }
    
    div#awn-plus div.awn-plus-map-layers div.awn-plus-map-layers-maps div.awn-plus-map-layers-map div.awn-plus-map-layers-map-text h3 {
      text-align: center;
      margin-top: 20px;
    }
    
    div#awn-plus div.awn-plus-advanced-graphing div.awn-plus-advanced-graphing-graph-text {
      display: block;
      gap: 0px;
      margin-top: 20px;
    }
    
    div#awn-plus div.awn-plus-advanced-graphing div.awn-plus-advanced-graphing-graph-text div.awn-plus-advanced-graphing-graph {
      width: 100%;
      text-align: center;
    }
    
    div#awn-plus div.awn-plus-advanced-graphing div.awn-plus-advanced-graphing-graph-text div.awn-plus-advanced-graphing-text {
      width: 100%;
    }
  }

}
