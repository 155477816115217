@import '../../styles/mixins';

.payment-display-card {
    // background-color: green;
    margin: 10px 0px;
    // .component-loader{
    //     display: block;
    //     height: 40px;
    // }

    // .row {
    //     width: 475px;
    //     margin: 30px auto;
    //     box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
    //     border-radius: 4px;
    //     background-color: #7795f8;
    //     position: relative;   
    // }

    // .formFieldContainer {
    //     display: -ms-flexbox;
    //     display: flex;
    //     -ms-flex-align: center;
    //     align-items: center;
    //     margin-left: 15px;
    //     border-top: 1px solid #819efc;
    
    //     &:first-of-type {
    //     border-top: none;
    //     }
    // }

    .aw-fields{
        background-color: lightgray;
        padding: 10px;
        display: flex;
        margin-bottom: 15px;
        .item{
            // background-color: green;
            flex-shrink: 1;
            width: 25%;
            // padding-right: 5px;
            // &:nth-of-type(2),
            // &:nth-of-type(3),
            // &:nth-of-type(4) {
            //     padding-left: 10px;
            // }
            label{
                font-size: 1.05em;
                font-weight: bold;
                margin: 0px 0 5px;
                padding-bottom: 10px;
            }
            // input{
            //     border-radius: 5px;
            //     transition: border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
            //     border: 1px solid #e6e6e6;
            //     box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
            //     width: 100%;
            //     &:focus{
            //         border-color: hsla(210, 96%, 45%, 50%);
            //         box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
            //     }
            // }
        }

    }
}
@media (max-width: @phone-max) {
    .payment-display-card {
        .aw-fields{
            flex-wrap: wrap;
            .item{
                width: 50%;
                &:nth-of-type(1),
                &:nth-of-type(2){
                    margin-bottom: 15px;
                }
                &:nth-of-type(3){
                    padding-left: 0;
                }
            }
        }
    }
}
