@import '../../styles/mixins';

.app .common-location-autocomplete {
  position: relative;
  padding: 0px 10px 15px;
  .component-find-location-btn{
    position: absolute;
    top: 6px;
    left: 20px;
    padding: 8px;
    .text{
      display: none;
    }
    .glyphicon{
      display: block;
      font-size: 19px;
    }
  }
  input.autocomplete{
    width: 100%;
    border-radius: 0px;
    border-color: var(--color-border-main-lt);
    padding: 10px 10px 12px 55px;
    font-size: 1.6rem;
    background-color: var(--color-bg-widget-main);
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
  .glyphicon.x-gray{
    position: absolute;
    top: 17px;
    right: 25px;
    font-size: 25px;
    display: none;
  }
  &.has-value{
    .glyphicon.x-gray{
      display: block;
    }
  }

}
