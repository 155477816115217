@import '../../styles/mixins.less';

.device-servers-form {
  .error{
    display: none;
  }
  &.verified{
    .btn-primary{
      // display: none;
    }
  }
}
.service{
  .error{
    display: block;
    color: @pink;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .component-loader{
    display: inline-block;
    margin-top: 10px;
  }
  .form{
    padding-bottom: 5px; 
    .glyphicon-trash{
      display: inline-block;
      margin-left: 10px;
      color: @red;
    }
  }
  .glyphicon-question-sign {
    position: relative;
    top: -9px;
    left: -1px;
  }
  .instructions,
  .desc{
    display: none;
  }
}
