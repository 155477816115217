@import '../../styles/mixins.less';


.device-widget {
  box-sizing: border-box;
  width: @widgetWidth;
  font-size: 20px;
  background-color: var(--color-bg-widget-main);
  color: var(--brandColor);
  margin: 0 @widgetMargin/2 (@widgetMargin + 0.5) @widgetMargin/2;
  float: left;
  display: block;
  position: relative;
  transform-origin: center center;
  .general-shadow;
  transition: all 0.350s, opacity 0.15s ease-in-out, transform 0.15s ease-in-out, height 0s;

  &.expanded {
    width: @widgetWidth*2 + @widgetMargin;
    margin-bottom: @widgetMargin;
  }

  > header {
    font-size: 0.8em;
    text-align: right;
    padding: 5px @generalPad 3px;
    border-bottom: 1px solid var(--color-border-widget-header-main);
    position: relative;

    input.editable-title {
      color: var(--color-text-widget-header-main);
      text-align: right;
      font-weight: 300;
      width: 100%;
      height: 26px;

      &:hover,
      &:focus {
        border-bottom-color: transparent;
        font-weight: 400;
      }

      &:disabled,
      &[disabled] {
        opacity: 1 !important;
        -webkit-text-fill-color: @text-dark;
        color: @text-dark;
      }

      &:disabled:hover,
      &[disabled]:hover {
        opacity: 1 !important;
        font-weight: 300;
        -webkit-text-fill-color: @text-dark;
        color: @text-dark;
      }
    }

    &:after {
      @size: 40px;
      content: ' ';
      width: @size;
      height: @size;
      border-radius: 100%;
      background: url(../../assets/tree.svg) center center var(--color-bg-widget-icon) no-repeat;
      background-size: 50%;
      position: absolute;
      left: @size/2 - 5;
      bottom: -@size/2;
      z-index: 10;
    }
  }

  // widgets without graphs
  &.battery,
  &.relays,
  &.leak {
    .graph {
      display: none;
    }
  }

  &.crosswind header:after,
  &.wind header:after {
    background-image: url(../../assets/wind.svg);
    background-size: 60%;
  }

  &.rain header:after {
    background-image: url(../../assets/drop.svg);
    background-size: 38%;
  }

  &.pressure header:after {
    background-image: url(../../assets/barometer.svg);
    background-size: 60%;
    background-position-y: 8px;
  }

  &.indoor header:after {
    background-image: url(../../assets/house.svg);
    background-size: 55%;
  }

  &.leak {
    header:after {
      background-image: url(../../assets/widgets/leak.svg);
      background-size: 49%;
    }
  }

  &.stats {
    header:after {
      background-image: url(../../assets/widgets/stats.svg);
      background-size: 65%;
    }
  }

  &.uv header:after {
    background-image: url(../../assets/hand.svg);
    background-size: 45%;
  }

  &.solarradday header:after,
  &.solar header:after {
    background-image: url(../../assets/sun.svg);
    background-size: 60%;
  }

  &.battery header:after {
    background-image: url(../../assets/battery-white.svg);
    background-size: auto 60%;
  }

  &.view {
    header:after {
      background-image: url(../../assets/quickview-icon.svg);
      background-size: 41%;
    }

    &.pinned {
      .buttons-rounded {
        .exp {
          display: none;
        }

        .pin {
          margin-right: 3px;
          margin-left: 7px;
        }
      }
    }
  }

  &.temp10f header:after,
  &.tempkestrel header:after,
  &.temp9f header:after,
  &.temp8f header:after,
  &.temp7f header:after,
  &.temp6f header:after,
  &.temp5f header:after,
  &.temp4f header:after,
  &.temp3f header:after,
  &.temp2f header:after,
  &.temp1f header:after,
  &.sensors header:after {
    background-image: url(../../assets/temp.svg);
    background-size: 90%;
    color: @white;
    padding-right: 10px;
    padding-top: 26px;
    font-size: 10px;
  }

  &.temp10f.hum header:after,
  &.temp9f.hum header:after,
  &.temp8f.hum header:after,
  &.temp7f.hum header:after,
  &.temp6f.hum header:after,
  &.temp5f.hum header:after,
  &.temp4f.hum header:after,
  &.temp3f.hum header:after,
  &.temp2f.hum header:after,
  &.temp1f.hum header:after {
    background-image: url(../../assets/temp-hum.svg);
    background-position-x: 0;
  }

  &.leafwetness1 header:after,
  &.leafwetness2 header:after,
  &.leafwetness3 header:after,
  &.leafwetness4 header:after,
  &.leafwetday header:after,
  &.leafwetness5 header:after,
  &.leafwetness6 header:after,
  &.leafwetness7 header:after,
  &.leafwetness8 header:after,
  &.leafwet1x header:after {
    background-image: url(../../assets/leafwet.svg);
    background-size: 100%;
  }

  &.soil header:after {
    background-image: url(../../assets/soil.svg);
    background-size: 90%;
  }

  &.gdd2 header:after,
  &.gdd header:after {
    background-image: url(../../assets/gdd.svg);
    background-size: 100%;
  }
  &.hdd header:after {
    background-image: url(../../assets/widgets/heating.svg);
    background-size: 77%;
  }
  &.cdd header:after {
    background-image: url(../../assets/widgets/cooling.svg);
    background-size: 77%;
  }

  &.evapratelbft3hr header:after,
  &.etrs header:after {
    background-image: url(../../assets/evapotrans.svg);
    background-size: 100%;
  }

  &.ahlu1 header:before,
  &.ahlu2 header:before,
  &.ahlu3 header:before,
  &.leafwetness1 header:before,
  &.leafwetness2 header:before,
  &.leafwetness3 header:before,
  &.leafwetness4 header:before,
  &.leafwetness5 header:before,
  &.leafwetness6 header:before,
  &.leafwetness7 header:before,
  &.leafwetness8 header:before,
  &.soil header:before,
  &.temp10f header:before,
  &.temp9f header:before,
  &.temp8f header:before,
  &.temp7f header:before,
  &.temp6f header:before,
  &.temp5f header:before,
  &.temp4f header:before,
  &.temp3f header:before,
  &.temp2f header:before,
  &.temp1f header:before {
    content: "1";
    background-color: @gray-amb;
    border-radius: 100%;
    color: @white;
    position: absolute;
    left: 38px;
    border: 1px solid @white;
    bottom: -23px;
    z-index: 11;
    font-size: 11px;
    width: 17px;
    height: 17px;
    text-align: center;
  }

  &.ahlu1 header:before {
    content: "1";
  }
  &.ahlu2 header:before,
  &.leafwetness2 header:before,
  &.soiltemp2 header:before,
  &.soiltens2 header:before,
  &.temp2f header:before {
    content: "2";
  }

  &.ahlu3 header:before,
  &.leafwetness3 header:before,
  &.soiltemp3 header:before,
  &.soiltens3 header:before,
  &.temp3f header:before {
    content: "3";
  }

  &.leafwetness4 header:before,
  &.soiltemp4 header:before,
  &.soiltens4 header:before,
  &.temp4f header:before {
    content: "4";
  }

  &.soiltemp5 header:before,
  &.leafwetness5 header:before,
  &.temp5f header:before {
    content: "5";
  }

  &.soiltemp6 header:before,
  &.leafwetness6 header:before,
  &.temp6f header:before {
    content: "6";
  }

  &.soiltemp7 header:before,
  &.leafwetness7 header:before,
  &.temp7f header:before {
    content: "7";
  }

  &.soiltemp8 header:before,
  &.leafwetness8 header:before,
  &.temp8f header:before {
    content: "8";
  }

  &.soiltemp9 header:before,
  &.temp9f header:before {
    content: "9";
  }

  &.soiltemp10 header:before,
  &.temp10f header:before {
    content: "10";
    font-size: 10px;
    width: 19px;
    height: 17px;
  }
  &.soiltemp11 header:before {
    content: "11";
  }

  &.soiltemp12 header:before {
    content: "12";
  }

  &.soiltemp13 header:before {
    content: "13";
  }

  &.soiltemp14 header:before {
    content: "14";
  }

  &.soiltemp15 header:before {
    content: "15";
  }

  &.soiltemp16 header:before {
    content: "16";
  }

  &.relays header:after {
    background-image: url(../../assets/lightbulb.svg);
    background-size: 59%;
  }

  &.humidity header:after {
    background-image: url(../../assets/cloud.svg);
    background-size: 65%;
  }
  &.humidity.kestrel header:after{
    background-image: url(../../assets/widgets/kestrel/relative-humidity.svg);
    background-size: 70%;
  }
  &.airflowcfm header:after,
  &.air header:after {
    background-image: url(../../assets/air-icon.svg);
    background-size: 51%;
  }

  &.aqin header:after,
  &.co2 header:after,
  &.airin header:after {
    background-image: url(../../assets/indoor-aqi.svg);
    background-size: 114%;
  }

  &.sunMoon header:after {
    background-image: url(../../assets/sun-moon/sun-moon-icon.svg);
    background-size: 68%;
  }
  &.wbgt header:after{
    background-image: url(../../assets/widgets/kestrel/wbgt.svg);
    background-size: 70%;
  }
  &.heatindex header:after{
    background-image: url(../../assets/widgets/kestrel/heat-index.svg);
    background-size: 60%;
  }
  &.pig header:after{
    background-image: url(../../assets/widgets/kestrel/pig.svg);
    background-size: 65%;
  }
  &.twlwm2 header:after{
    background-image: url(../../assets/widgets/kestrel/twlwm2.svg);
    background-size: 70%;
  }
  &.ahlu2 header:after,
  &.ahlu3 header:after,
  &.ahlu1 header:after{
    background-image: url(../../assets/widgets/kestrel/ahlu.svg);
    background-size: 70%;
  }
  &.thiyousef header:after,
  &.thinrc header:after{
    background-image: url(../../assets/widgets/kestrel/thi.svg);
    background-size: 70%;
  }
  &.deltaf header:after{
    background-image: url(../../assets/widgets/kestrel/deltaf.svg);
    background-size: 65%;
  }
  &.windchillf header:after{
    background-image: url(../../assets/widgets/kestrel/windchillf.svg);
    background-size: 65%;
  }
  &.densityaltitudeft header:after{
    background-image: url(../../assets/widgets/kestrel/densityaltitudeft.svg);
    background-size: 65%;
  }
  &.humidityratiogplb header:after{
    background-image: url(../../assets/widgets/kestrel/humidityratiogplb.svg);
    background-size: 65%;
  }
  &.airdensity header:after{
    background-image: url(../../assets/widgets/kestrel/relativeairdensity.svg);
    background-size: 65%;
  }
  &.altitudeft header:after{
    background-image: url(../../assets/widgets/kestrel/altitudeft.svg);
    background-size: 65%;
  }
  &.humidityratiogplb header:after{
    // background-image: url(../../assets/widgets/kestrel/kestrel_relative_humidity.svg);
    // background-size: 70%;
  }
  &.dischargerate header:after{
    background-image: url(../../assets/cloud.svg);
    background-size: 65%;
  }
  &.map header:after {
    background-image: url(../../assets/dashboard/icon-map.svg);
    background-size: 53%;
  }

  &.webcam header:after {
    background-image: url(../../assets/common/camera.svg);
    background-size: 59%;
  }

  &.forecast header:after {
    background-image: url(../../assets/forecast/icon-forecast.svg);
    background-size: 62%;
  }

  &.lightning header:after {
    background-image: url(../../assets/lightning-white.svg);
    background-size: 41%;
  }


  .label {
    font-size: 0.7em;
    font-weight: 300;
    color: @text-dark;
  }

  &.expanded {
    .label {
      font-size: 0.85em;
    }

    .val {
      font-size: 1.1em;

      }
    }
}
.expanded {
  .buttons-wrap .buttons-rounded .exp {
    transition: all 0.9s ease-out;
    transform: rotate(405deg);

    &:hover {
      transition: all 0.3s ease-out;
      transform: rotate(315deg);
    }
  }
}

.buttons-wrap {
  position: absolute;
  bottom: 0px;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 13;
  width: 100%;
}

.buttons-rounded {
  border-radius: 20px;
  border: 1px solid var(--color-border-widget-rounded-btns-main);
  height: 28px;
  text-decoration: none;
  background-color: var(--color-bg-widget-rounded-btns-main);
  display: inline-block;
  padding: 0px 4px 1px 1px;
  display: flex;

  .exp {
    display: block;
    width: 24px;
    height: 24px;
    background: url(../../assets/plus-blue.svg) center center no-repeat;
    background-size: 80%;
    transition: transform 0.3s ease-out;
    transform-origin: 12px 12px;
    backface-visibility: hidden;
    outline: none;

    &:hover {
      transform: rotate(90deg);
    }
  }

  &.small {
    height: 25px;

    .exp {
      @w: 22px;
      .square(@w);
      transform-origin: (@w / 2) (@w / 2);
    }
  }

  .graph {
    display: block;
    width: 24px;
    height: 24px;
    background: url(../../assets/graphs-blue.svg) center center no-repeat;
    background-size: 82%;
    margin-top: 1px;
    margin-left: 3px;
    outline: none;
  }

  .minus {
    display: block;
    width: 24px;
    height: 24px;
    background: url(../../assets/minus-blue.svg) center center no-repeat;
    background-size: 80%;
    margin-top: 1px;
    margin-left: 3px;
    outline: none;
  }

  .pin {
    font-size: 18px;
    position: relative;
    top: 4px;
    margin-right: 2px;
    margin-left: 3px;
    color: @gray-light-amb;
    outline: none;

    &.pinned,
    &:hover {
      color: var(--brandColor);
    }
  }
}

.restrict {
  .device-widget {

    &.map,
    &.webcam,
    &.view {
      .buttons-rounded {
        display: none;
      }
    }
  }

  .component-min-max-btns {

    .chevron-down,
    .chevron-up,
    .pin,
    .exp,
    .minus {
      display: none;
    }
  }
}

.big-number {
  flex-grow: 1;
  text-align: center;
  .column;
  justify-content: center;

  >.fdp {
    color: var(--color-text-widget-big-number);
    font-size: 4.3em;
    line-height: 1;

    .suff {
      font-size: 0.5em;
    }
  }
}
.med-number {
  flex-grow: 1;
  text-align: center;
  .column;
  justify-content: center;
  > .fdp{
    color: var(--color-text-widget-big-number);
    font-size: 2.75em;
    line-height: 1;
    .suff{
      font-size: 0.25em;
    }
  }
}
.from{
  padding-top: 10px;

  .fdp {
    @l: 16px;
    font-weight: 400;
    position: relative;
    padding-left: @l;
    margin-top: 2px;

    &:before {
      content: '';
      .abs;
      top: 2px;
      width: 10px;
      height: 19px;
      background: url(../../assets/up-arrow-big.svg) center center no-repeat;
      background-size: auto 100%;
      transition: all 2s ease-in;
    }
  }

  &.zero {
    .fdp {
      padding-left: 0px;

      &:before {
        display: none;
      }
    }
  }

  &.down {
    .fdp:before {
      transform: rotate(180deg);
    }
  }
}

.table-widget {
  padding: @bigPad 10px;

  .line {
    font-size: 0.8em;
    color: @text-dark;
    padding: 3px 0 2px;
    .light;

    &:nth-child(odd) {
      background-color: var(--color-bg-table-widget-line-alt);
    }

    input {
      display: block;
      width: 60%;
      float: left;
      color: @text-dark;
      padding-left: 10px;

      &:hover {
        font-weight: 400;
        text-decoration: none;
        border-color: transparent;
      }

      &:focus {
        padding-left: 10px;
        border-color: transparent;
      }
    }

    .fdp {
      display: block;
      width: 20%;
      float: left;
      text-align: center;
    }
  }
}

@media (max-width: @tablet-max) {

  .device-widget.expanded,
  .device-widget {
    width: 2 * @widgetWidth;
    margin-left: @widgetMargin;
    margin-right: @widgetMargin;
    margin-bottom: @widgetMargin*2 + 0.4;
  }

  .big-number {
    font-size: @tablet-font-grow + 0.1em;
  }
}

@media (max-width: @phone-max) {

  .device-widget.expanded,
  .device-widget {
    width: 100%;
    height: auto !important;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 35px;
    margin-bottom: 50px;

    header {
      font-size: @phone-font-grow + 0.3em;
      line-height: 1;
      padding: 5px @generalPad 0px;
      margin-bottom: @generalPad;
      @size: 60px;

      &:after {
        width: @size;
        height: @size;
        left: @size/2 - 15;
        bottom: -@size/3;
      }

      input.editable-title {
        height: auto;
        width: ~"calc(100% - 70px)";
      }
    }

    &.soil header:before,
    &.temp10f header:before,
    &.temp9f header:before,
    &.temp8f header:before,
    &.temp7f header:before,
    &.temp6f header:before,
    &.temp5f header:before,
    &.temp4f header:before,
    &.temp3f header:before,
    &.temp2f header:before,
    &.temp1f header:before {
      left: 50px;
      bottom: -23px;
      font-size: 14px;
      width: 24px;
      height: 24px;
      padding: 4px 0px 1px 0px;
    }

    &.pressure header:after {
      background-position-y: 13px;
    }
  }

  @bh: 39px;

  .buttons-wrap {
    bottom: 0;
  }

  .buttons-rounded {
    height: @bh;
    padding: 3px 1px;
    border-radius: 30px;
    @w: 30px;

    .minus,
    .exp,
    .graph {
      .square(@w);
      margin: 0 8px;
    }

    .exp {
      transform-origin: (@w / 2 + 1px) (@w / 2 + 1px);
    }

    .pin {
      font-size: 20px;
      top: 5px;
      margin-right: 12px;
      margin-left: 8px;

      &:hover {
        color: @gray-light-amb;
      }

      &.pinned {
        margin-right: 8px;

        &:hover {
          color: var(--brandColor);
        }
      }
    }

    &.small {
      height: 25px;

      .exp {
        @w: 22px;
        .square(@w);
        transform-origin: (@w / 2) (@w / 2);
      }
    }
  }

  .big-number {
    font-size: @phone-font-grow + 0.6em;
    padding-top: @biggestPad;
    padding-bottom: @bigPad;

    .from {
      margin-top: @bigPad;

      .fdp {
        &:before {
          height: 28px;
          top: 5px;
          left: -6px;
        }
      }
    }
  }
}