@import '../styles/mixins.less';

.component-cell-status {
    display: block;
    background-color: #eeeeee;
    border-radius: 5px;
    padding: 5px;
    .h2{
        color: black;
    }
    .status-bars {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 5px;
        .status-bar {
            padding: 5px 25px;
            text-align: center;
            .bar-title {
                font-size: 1.5rem;
                font-weight: bold;
                text-align: center;
                padding-bottom: 2px;
            }
            .bar-value {
                width: 100%;
                text-align: center;
            }
        }
    }
}
