@import '../../styles/mixins';

.device-air-density-widget {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  .label{
  }
  > .fdp {
    display: block;
  }
  > div {
    padding-top: 1px;
    font-size: 2rem;
    text-align: center;
  }
  .fdp{
    font-size: 2.7rem;
    &.airdensitylbft3{
      font-size: 5.5rem;
    }
    color: var(--color-text-widget-big-number);
    .suff{
      font-size: 0.3em;
    }
  }

}

.device-widget.airdensity{
  .column;
}