@import '../../styles/mixins.less';

.device-dashboard-wrap {
  position: relative;
  background-color: var(--color-bg-page-container-main);
  @mapHeight: 420px;
  @mapTrans: 0.2s ease-out; 
  .dashboard-top{
    position: relative;
    .top-toggle,
    .full-screen{
      position: absolute;
      top: @generalPad;
      right: 75px;
      .btn-map(38px);
      z-index: 505;
      margin-top: 0;
      background-image: url(../../assets/dashboard/expand-arrow-black.svg);
      background-size: 45%;
      &.active,
      &:hover{
        background-image: url(../../assets/dashboard/expand-arrow-white.svg);
      }
    }
    .top-toggle{
      right: @bigPad;
      background-image: url(../../assets/up-carrot-gray.svg);
      &.active,
      &:hover{
        background-image: url(../../assets/up-carrot.svg);
      }
    }
  }
  &.no-dash-top{
    .dashboard-top{
      height: 70px;
      .full-screen,
      .device-webcam-image,
      .map-with-forecast {
        display: none !important;
      }
      .top-toggle{
        background-image: url(../../assets/down-carrot-gray.svg);
        background-size: 50%;
        &.active,
        &:hover{
          background-image: url(../../assets/down-carrot.svg);
        }
      }
    }
  }
  .map-with-forecast{
    position: relative;
    transition: height @mapTrans;
    &.no-loc{
      min-height: 0px;
      .map,
      .component-map{
        max-height: 0;
      }
      .buttons-wrap,
      a.expand,
      .forecast-long-term-wrap,
      .component-forecast{
        display: none;
      }
    }
    .component-map{
      transition: max-height @mapTrans;
      overflow: hidden;
      max-height: @mapHeight;
      .mapboxgl-ctrl-attrib-inner,
      .mapboxgl-ctrl-attrib-inner a{
        color: var(--color-text-main-lt);
      }
      .map{
        transition: max-height @mapTrans;
        max-height: @mapHeight;
      }
      .layer-btns{
        position: absolute;
        bottom: 145px;
        .covid-19{
          display: none;
        }
      }
      .animation-timeline{
        position: absolute;
        padding: 0px 150px 0px 50px;
      }
      .forecast-btn{
        display: none;
      }
      .device-device-popup.on-dash{
        .bottom,
        .text-center{
          display: none;
        }
      }
    }
    .component-forecast{
      position: absolute;
      z-index: 501;
      width: 100%;
      padding: 0px 125px 0px @bigPad;
      .forecast{
        .minimized-forecast;
        margin-top: 15px;
        padding: 8px;
        margin-left: 510px;
        justify-content: center;
        .link{
          display: none;
        }
        .currently{
          font-size: 1.4rem;
        }
      }
      .timeline,
      .close{
        display: none;
      }
    }
    .component-device-chooser{
      display: none;
    }
    .forecast-long-term-wrap{
      position: absolute;
      width: 100%;
      height: @mapHeight;
      top: 0;
      left: 0;
      background-color: rgba(0,0,0,0.6);
      z-index: 503;
      .component-forecast-long-term{
        position: absolute;
        right: 85px;
        bottom: 35px;
      }
    }
  }
  @w: 250px;
  @h: @w / 16 * 9;
  &.top-webcam .map-with-forecast .component-map,
  .device-webcam-image,
  a.pip{
    display: block;
    position: absolute;
    bottom: @biggestPad;
    left: @bigPad;
    z-index: 501;
    width: 250px;
    height: @h;
  }
  a.pip{
    z-index: 503;
  }
  &.top-webcam {
    .map-with-forecast {
      height: @mapHeight;
      .component-map{
        z-index: 502;
        .map{
          height: @h;
          .mapboxgl-control-container{
            display: none;
          }
        }
      }
    }
    .device-webcam-image video{
      transition: height @mapTrans;
    }
    .device-webcam-image{
      width: 100%;
      top: -100px;
      height: @mapHeight + 100px;
      transition: height @mapTrans;
      bottom: auto;
      left: 0px;
      display: block;
      overflow: hidden;
      .video-container{
        .video{
          aspect-ratio: auto;
        }
      }
    }
  } 
  .dashboard-header{
    display: flex;
    flex-wrap: wrap;
    position: sticky;
    top: 0px;
    z-index: 604;
    justify-content: space-between;
    align-items: center;
    padding: 0px 65px 0 0;
    color: var(--color-text-dashboard-header-main);
    background-color: var(--color-bg-dashboard-header-main);
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    a.favstar{
      margin-right: 5px;
    }
    .device-info{
      width: 100%;
      justify-content: flex-start;
      padding: 10px 5px 10px 10px;
      .user-avatar{
        .square(60px);
        .sidebar-transition;
      }
    }
    .component-device-chooser{
      margin-bottom: 11px;
      margin-left: 10px;
      width: 500px;
      max-width: 80%;
      .sidebar-transition;
      .header-wrap a.header{
        font-size: 1.25em;
        .sidebar-transition;
        position: relative;
        &:after{
          .sidebar-transition;
          content: '';
          position: absolute;
          right: 5px;
          top: 4px;
          height: 30px;
          width: 38px;
          background: url(../../assets/down-carrot.svg) center center no-repeat @blue;
          background-size: 15px;
          z-index: 50;
          border-radius: 15px;
        }
      }
      &.open{
        .header-wrap a.header{
          &:after{
            background-image: url(../../assets/up-carrot.svg);
          }
        }
      }
    }
    > .tabs{
      //padding-left: @bigPad;
      margin-right: auto;
      //flex-shrink: 0;
      .header-rect-tabs;
      a.social{
        padding-left: 30px;
        padding-right: 30px;
      }
      a.active {
        background-color: var(--color-bg-active-dashboard-tab-main);
      }
    }
    .secondary{
      flex-shrink: 0;
    }
  }
  &:not(.top){
    .dashboard-header{
      .device-info{
        .user-avatar{
          .square(40px);
        }
      }
      .component-device-chooser{
        .header-wrap a.header{
          font-size: 1.05em;
          &:after{
            height: 26px;
            width: 36px;
            background-size: 13px;
          }
        }
      }
    }
    &.mine {
      .dashboard-header .component-device-chooser .header-wrap {
        a.header {
          &:after,
          &:before {
            font-size: 9px;
          }
        }
      }
    }

  }
  .countdown-wrap{
    text-align: right;
    .countdown{
      color: @text-dark;
      text-decoration: none;
      font-size: 0.9em;
      display: block;
      padding: 15px 35px;
    }
  }
  &.no-top{
    .dashboard-top{
      height: 80px;
      > a {
        display: none;
      }
    }
  }
  &:not(.no-loc){
    .video-link{
      display: none !important;
    }
  }
  .view-btns{
    display: flex;
    padding: 5px 0px 5px @bigPad;
    justify-content: flex-end;
    a.btn-circle {
      @wh: 40px;
      display: block;
      background: url(../../assets/dashboard/icon-dash.svg) center center no-repeat @gray-light-amb;
      background-size: 50%;
      margin-left: 5px;
      width: @wh;
      height: @wh;
      &.active{
        background-color: @blue;
      }
      .general-shadow;
      .btn-primary-active-hover-colors;
      color: transparent !important;
      border-radius: 100%;
      &.graphs{
        background-image: url(../../assets/dashboard/icon-graphs.svg);
      }
      &.map{
        background-image: url(../../assets/dashboard/icon-map.svg);
      }
      &.camera{
        background-image: url(../../assets/common/camera.svg);
        background-size: 56%;
      }
    }
    .create-post{
      line-height: 1.8;
      margin-left: 5px;
    }
  }
  &.no-maps{
    .view-btns{
      .map{
        display: none;
      }
    }
  }
  a.expand{
    display: none;
  }
  &.restrict{
    .dashboard-header .secondary{
    // .dashboard-top .component-min-max-btns{
      display: none;
    }
    .dashboard-top{
      .full-screen{
        right: @bigPad;
      }
    }
  }
  .component-site-alerts{
    padding: @generalPad @bigPad 0;
  }
  .device-device-popup{
    // margin-bottom: @bigPad;
    max-width: 350px;
    &.on-dash > .bottom{
      .btn-primary{
        display: none;
      }
    }
  }
  .social-my-timeline{
    .controls{
      padding-top: 15px;
    }
    &.at-top{
      padding-top: 76px;
      .controls{
        padding-left: 210px;
        position: fixed;
        top: 110px;
        width: 100%;
        left: 0;
        z-index: 200;
      }
    }
  }
  .create-post-btn{
    display: none;
  }
  &.social{
    .create-post-btn{
      display: block;
    }
  }
  &.social,
  &.graphs{
    .countdown-wrap{
      padding-top: 10px;
      padding-bottom: 5px;
      .countdown{
        display: none;
      }
    }
  }
  &.cam-library,
  &.cam-charts{
    &.video-playing{
      .map-with-forecast .component-device-chooser:not(.open){
        width: 35px !important;
        .header-wrap {
          padding-left: 0; 
          .header{
            color: transparent;
            width: 0px;
            overflow: hidden;
          }
        }
      }
      .archive-select__control:not(.archive-select__control--menu-is-open){
        width: 40px !important;
        float: right;
        .archive-select__dropdown-indicator{
          margin-right: 0 !important;
          margin-left: 8px;
        }
        .archive-select__value-container{
          display: none;
        }
      }

    }
    .device-device-data-table-wrap{
      padding-top: 15px;
      .controls{
        display: none;
      }
    }
    .dashboard-top{
      position: sticky;
      top: 48px;
      z-index: 601;
      background-color: var(--color-bg-page-container-main);
      .general-shadow;
      .archive-select-container{
        position: absolute;
        z-index: 504;
        top: 15px;
        right: 125px;
        width: 125px;
        padding-left: 3px;
        transition: width 0.2s ease-in-out;
        .archive-select__dropdown-indicator{
          color: var(--color-text-main);
          background: url(../../assets/dashboard/icon-calendar-black.svg) left center no-repeat;
          background-size: 100%;
          width: 21px;
          height: 21px;
          margin-right: 11px;
          svg{
            display: none;
          }
        }
        .archive-select__control{
          border-radius: 25px;
        }
        .archive-select__indicator-separator{
          display: none;
        }
      }
      .pip{
        display: none;
      }
    }
    .map-with-forecast{
      height: @mapHeight;
      .component-fetches-forecast,
      .component-map{
        display: none;
      }
    }
    .device-webcam-image{
      width: 100%;
      top: 0px;
      height: @mapHeight;
      bottom: auto;
      left: 0px;
      display: block;
      overflow: hidden;
      background-color: #000;
      .video-container{
        .video{
          height: @mapHeight !important;
          // aspect-ratio: auto;
        }
      }
    }
  }
  &.mine {
    .dashboard-header .component-device-chooser .header-wrap {
      padding-left: 0;
      a.header {
        &:before {
          content: 'MY STATION';
          font-size: 10px;
          color: #ffffff;
          background-color: #E6A923;
          border-radius: 8px;
          padding: 3px 9px;
          margin-right: 5px;
          margin-left: 5px;
          bottom: 2px;
          position: relative;
        }
      }
    } 
  }
}
.update-tz-modal{
  .body{
    background: url(../../assets/update-devices-illustration.png) center 14px no-repeat;
    background-size: 70% auto;
    padding-top: 35%;
    .device{
      padding: @bigPad;
      margin-top: @generalPad;
      background-color: @white;
      margin-bottom: @generalPad;
      .general-shadow;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3{
        margin-bottom: 5px;
      }
      .info{
        span{
          color: var(--color-text-main-lt);
          font-size: 12px;
          font-weight: bold;
          display: inline-block;
          &:nth-of-type(2){
            padding-right: 8px;
          }
          &.label{
            font-weight: normal;
            padding-right: 3px;
          }
        }
      }
    }
    .btn{
      float: right;
    }
  }
}
.app.loggedout.dashboard-page{
  .user-nav .login{
    display: none;
  }
}
@media(max-width: @tablet-phone-max){
  // @mapHeight: 380px;
  @mapHeight: 56vw;
  @topHeight: 110px;
  .device-dashboard-wrap {
    padding-top: @headingHeight;
    .dashboard-top{
      height: @mapHeight;
      .device-webcam-image{
        // height: @mapHeight;
      }
    }
    .dashboard-header{
      flex-wrap: wrap;
      padding-right: 0;
      padding-top: 0;
      > .tabs{
        padding-left: 0;
        width: 100%;
        order: 3;
        display: flex;
        align-items: normal;
        white-space: nowrap;
        overflow-x: auto;
        a {
          flex: 1;
          text-align: center;
          align-items: center;
          font-size: 16px;
          padding: 10px 15px;
        }
      }
    }
    .social-my-timeline{
      &.at-top{
        .controls{
          padding-left: 10px;
        }
      }
    }
    .map-with-forecast{
      .component-forecast{
        padding-right: @bigPad;
      }
      .component-map{
        max-height: @mapHeight;
        .map{
          max-height: @mapHeight;
        }
        .animation-timeline{
          padding-right: 110px;
        }
        .layer-btns{
          bottom: 40px;
        }
      }
    }
    &.cam-library,
    &.cam-charts{
      .dashboard-top{
        top: @topHeight;
        @h: 25px;
        .archive-select-container{
          right: 10px;
          bottom: -35px;
          top: auto;
          .archive-select__indicators,
          .archive-select__control{
            min-height: @h;
            height: @h;
          }
          .archive-select__dropdown-indicator{
            height: @h - 8;
            width: @h - 8;
            margin-right: 8px;
            position: relative;
            top: -1px;
          }
        }
        .device-webcam-image{
          height: @mapHeight;
        }
      }
      &.video-playing{
        .dashboard-top{
          .archive-select__dropdown-indicator{
            margin-left: 8px;
          }
        }
      }
      .map-with-forecast{
        height: @mapHeight;
      }
      .device-webcam-image{
        .video-container{
          .video{
            height: @mapHeight !important;
          }
        }
      }
      .device-device-data-table-wrap{
        padding-top: 30px;
      }
      .device-webcam-archive{
        padding-top: 45px;
      }
    }
    &.mine {
      .device-info {
        &::before {
          display: none;
        }
        &:after{
          display: inline;
        }
      }
    }
  }
}
@media(max-width: @phone-max){
  .update-tz-modal{
    .body{
      .device{
        flex-direction: column;
        align-items: flex-start;
        .info{
          margin-bottom: @generalPad;
          .br{
            display: block;
          }
        }
      }
    }
  }
  .device-dashboard-wrap{
    padding-top: 0px;
    @mapHeight: 56vw;
    @topHeight: 110px;
    @btnSize: 32px;
    .dashboard-top{
      height: @mapHeight;
      .full-screen,
      .top-toggle{
        right: 10px;
        width: @btnSize;
        height: @btnSize;
      }
      .full-screen{
        right: 50px;
      }
    }
    &.no-dash-top,
    &.restrict{
      .map-with-forecast .component-device-chooser{
        width: ~"calc(91vw - @{generalPad} - @{generalPad})";
      }
      .dashboard-top{
        .full-screen{
          right: 10px;
        }
      }
    }
    &.social,
    &.tiles,
    &.graphs{
      a.pip,
      &.top-webcam .map-with-forecast .component-map,
      &.top-webcam .map-with-forecast .component-map .map,
      &.top-map .device-webcam-image{
        @w: 37vw;
        width: @w;
        height: ~"calc(@{w} * 9 / 16)";
        bottom: 10px;
        left: 10px;
      }
      &.top-webcam{
        .map-with-forecast{
          height: @mapHeight;
        }
        .device-webcam-image{
          top: 0px;
        }
      }
      .device-webcam-image{
        .timestamp{
          font-size: 0.7em;
          right: 5px;
          top: auto;
          bottom: 5px;
        }
      }
      &.cam-library,
      &.cam-charts{
        .dashboard-top{
          .device-webcam-image{
            height: @mapHeight;
          }
        }
      }
    }
    .dashboard-header {
      > .tabs {
        a {
          font-size: 14px;
          &.social {
            padding: 10px 10px 15px 10px;
          }
        }
      }
      .component-device-chooser.open{
        .header-wrap a.header{
          &:after{
            top: 8px;
            right: 8px;
          }
        }
      }
    }

    .component-min-max-btns .full-screen{
      font-size: 1em;
    }
    .map-with-forecast{
      a.expand{
        z-index: 501;
        top: auto;
        bottom: 10px;
        right: 10px;
      }
      .component-device-chooser{
        left: 10px;
        width: ~"calc(81vw - @{generalPad} - @{generalPad})";
      }
      .component-forecast{
        display: none;
      }
      .component-map{
        max-height: @mapHeight;
        .map{
          max-height: @mapHeight;
        }
        .layer-btns{
          display: none;
        }
      }
      &.no-loc{
        min-height: 60px;
      }
    }
    .dashboard-header{
      .device-info{
        padding-top: 15px;
        padding-bottom: 10px;
      }
      .secondary{
        width: 100%;
        padding: 0 10px 10px;
        height: 50px;
        transition: 0.1s all ease-out;
        overflow: hidden;
      }
      .tabs {
        overflow: auto;
        white-space: nowrap;
      }
      .view-btns{
        padding: 0px;
        width: 100%;
        .create-post{
          // color: transparent;
          // width: 40px;
          // background-image: url(../../assets/dashboard/icon-pencil.svg);
          // background-repeat: no-repeat;
          // background-position: center center;
          // background-size: 56% auto;
        }
      }
    }
    .sticky{
      .dashboard-header{
        .secondary{
          height: 0px;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
    &.no-top{
      .dashboard-header{
        padding-top: 0;
      }
    }
    .countdown-wrap{
      .countdown{
        padding-right: 30px;
      }
    }
  }
}

@media (max-height: 730px) and (min-width: (@tablet-phone-max + 1)) {
  .device-dashboard-wrap:not(.top) {
    &.cam-charts{
      .dashboard-top{
        .map-with-forecast,
        .device-webcam-image,
        .device-webcam-image video{
          height: 200px !important;
        }
      }
    }
  }
}