@import '../styles/mixins.less';

.app{
  .component-public-header{
    display: none;
  }
  &.dashboard.devices-public,
  &.devices-public{
    flex-direction: column;
    &:before{
      top: 80px;
    }
    .navbar-brand{
      background-color: @white !important;
      background-size: auto 70%;
      background-position: center left;
      width: 500px;
      text-decoration: none;
      color: @text-dark;
      font-size: 14px;
      span{
        display: block;
        margin-top: 15px;
        margin-left: 208px;
        padding-left: 10px;
        border-left: solid 1px @text-dark;
      }
    }
    .component-public-header {
      display: block;
      background-color: @white;
      height: 80px;
      .wrap{
        max-width: 1140px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        width: 100%;
        .buttons{
          padding-top: 30px;
          a{
            margin-left: 10px;
            border-radius: 1.8em;
            padding: .4em .875em;
            border: 1px solid @blue;
            min-width: 7.25em;
            box-sizing: border-box;
            text-align: center;
            font-size: 16px;
            line-height: 16px;
            font-style: normal;
            font-weight: 500;
            text-transform: capitalize;
            letter-spacing: 0px;
            text-decoration: none;
            &:hover{
              background-color: @blue;
              color: @white;
            }
          }
        }

      }
    }
    &.embed{
      .component-public-header{
        display: none;
      }
      &:before{
        top: 0px;
      }
    }
  }
}

@media(max-width: 850px){
  .component-public-header{
    .buttons{
      display: none;
    }
  }
  
}