@import '../styles/mixins.less';

.component-generic-ad {
  display: inline-block;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 50%;
  width: 100%;
}
