@import '../../styles/mixins';

.device-accessories {
  @border-color: @gray-light-amb;

  padding: 0px 0 @generalPad;
  padding-bottom: 0px;
  border-bottom: 1px solid @border-color;
  box-shadow: inset 0px 6px 8px -5px var(--color-shadow-main);
  > header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3{
      // text-align: center;
      font-size: 30;
      font-weight: 500;
      padding-left: 20px;
      margin-bottom: 0;
    }
    .more-to-show{
      flex-grow: 1;
      padding-left: 15px;
      color: @color-warn;
      font-weight: bold;
      text-decoration: none;
    }
    > a.caret {
      display: block;
      height: 60px;
      background: url(../../assets/down-carrot-gray.svg) no-repeat center;
      width: 60px;
      transition: 0.5s transform;
      color: transparent;
    }
  }
  &.open{
    > header{
      > a.caret {
        transform: rotate(180deg);
      }
      .more-to-show{
        display: none;
      }
    }
    .cat-wrap{
      display: block;
      &.overflow{
        box-shadow: inset -13px 0 10px -6px rgba(0, 0, 0, 0.25);
      }
    }
  }
  .cat-wrap{
    border-top: 1px solid @border-color;
    width: 100%;
    overflow-x: auto;
    display: none;
    .cat-inner{
      display: flex;
      .sensors{
        display: flex;
        flex-grow: 1;
      }
      .cat{
        padding: 15px 20px 0;
        border-right: 1px solid @border-color;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        &:last-child{
          border-right: none;
        }
        h4{
          font-size: 1.6rem;
          margin-bottom: 3px;
        }
        .sensor-status{
          margin-bottom: 20px;
          font-size: 1.3rem;
          color: @color-warn;
          font-weight: bold;
          &.full{
            font-weight: normal;
            color: @text-green;
          }
        }
      }
    }
  }
  .sensors{
    > a {
      @imgSize: 80px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      margin-right: 15px;
      padding-top: @imgSize + 10px;
      text-align: center;
      background: url(../../assets/device-types/accessories/wh-31pf.png) no-repeat center 10px;
      background-size: auto 55px;
      width: @imgSize;
      color: var(--color-text-main);
      text-decoration: none;
      position: relative;
      font-size: 1.3rem;
      &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: @imgSize;
        border: 1px solid var(--color-border-main-lt);
        .general-shadow;
        border-radius: 100%;
      }
      .btn{
        padding: 3px 13px;
        font-size: 1em;
        margin-bottom: 8px;
        margin-top: auto;
        display: inline-block;
      }
      span.title{
        display: block;
        line-height: 1.3;
        margin-bottom: 5px;
      }
      @media(hover: hover){
        &:hover{
          &::after{
            background-color: rgba(0, 0, 0, 0.1);
            border-color: @blue;
          }
        }
      }
      &.sensor-wh-31pf {
        background-image: url(../../assets/device-types/accessories/wh-31pf.png);
      }
      &.sensor-wh-51rf {
        background-image: url(../../assets/device-types/accessories/wh-51rf.png);
        background-size: auto 64px;
        background-position: center 7px;
      }
      &.sensor-wh51lw {
        background-image: url(../../assets/device-types/accessories/wh-51lw.png);
      }
      &.sensor-wh-31p {
        background-image: url(../../assets/device-types/accessories/wh-31p.png);
        background-size: auto 64px;
        background-position: center 8px;
      }
      &.sensor-wh-31e {
        background-image: url(../../assets/device-types/accessories/wh-31e.png);
        background-size: auto 64px;
        background-position: center 8px;
      }
      &.sensor-wh-32b {
        background-image: url(../../assets/device-types/accessories/wh-32b.png);
      }
      &.sensor-wh-31l {
        background-image: url(../../assets/device-types/accessories/wh-31l.png);
      }
      &.sensor-pm25 {
        background-image: url(../../assets/device-types/accessories/pm25.png);
        background-size: auto 40px;
        background-position: 18px 20px;
      }
      &.sensor-pm25in {
        background-image: url(../../assets/device-types/accessories/pm25in.png);
        background-size: auto 45px;
        background-position: 10px 17px;
      }
      &.sensor-aqin {
        background-image: url(../../assets/device-types/accessories/aqin.png);
        background-size: auto 45px;
        background-position: 13px 17px;
      }
      &.sensor-wh-31la {
        background-image: url(../../assets/device-types/accessories/wh-31la.png);
        background-size: auto 45px;
        background-position: 30px 17px;
      }
      &.sensor-wh-31sm {
        background-image: url(../../assets/device-types/accessories/wh-31sm.png);
      }
      &.sensor-0672 {
        // background-image: url(../../assets/device-types/accessories/0672.png);
      }
      &.sensor-0671 {
        // background-image: url(../../assets/device-types/accessories/0671.png);
      }
      &.sensor-0670 {
        // background-image: url(../../assets/device-types/accessories/0670.png);
      }
    }
  }

}
