@import '../styles/mixins.less';

.component-sitewide-modals {
  .device-private{
    text-align: center;
    padding-bottom: @generalPad;
    h4{
      margin: @bigPad 0 @generalPad;
    }
  }
  &.plus-subscription,
  &.adgraph,
  &.wxw-tile-gallery,
  &.enhanced-cam{
    .ReactModal__Content{
      @w: 1000px;
      width: @w !important;
      margin-left: -(@w / 2) !important;
      border-radius: 8px !important;
      @media (max-width: @tablet-phone-max) {
        margin: 0 auto !important;
        max-width: 100% !important;
        left: 0 !important;
      }
    }
  }

}
