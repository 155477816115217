@import '../../styles/mixins.less';

input.editable-title{
  border: none;
  padding: 0;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  color: @gray-light;
  font-size: 1em;
  line-height: 1.6;
  border-radius: 0;
  &:hover{
    border-bottom: 1px solid @gray-lighter;
  }
  &:focus{
    border: none;
    padding: 0;
    border-bottom: 1px solid @gray-light;
  }
}

