@import '../../styles/mixins';

.device-widget{
  &.hdd,
  &.cdd,
  &.gdd2{
    .column;
    a.graph{
      display: none;
    }
    a.glyphicon-info-sign{
      font-size: 0.9em;
      padding: 3px 2px 0;
    }
  }
}
.gdd-form{
  padding: 10px;
  .line{
    text-align: left;
    margin-top: 10px;
    .start-date,
    select.form-control,
    input.form-control{
      font-size: 16px;
      padding-top: 3px;
      padding-bottom: 3px;
      color: var(--color-text-main);
    }
    select.form-control,
    input.form-control{
      width: 100%;
    }
    .cal{
      border: 1px solid @blue;
      background: url(../../assets/calendar-blue.svg) no-repeat center;
      width: 25px;
      height: 30px;
      display: inline-block;
      margin-left: 10px;
    }
    .react-datepicker{
      z-index: 100;
    }
  }
  label {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 0;
  }
  .btn-primary{
    margin-right: 10px;
  }
  &.type-hdd,
  &.type-cdd{
    .line{
      &.crop{
        display: none;
      }
    }
  }
}
.device-gdd-widget {
  padding: 10px;
  position: relative;
  .column;
  flex: 1;
  .create-gdd{
    position: absolute;
    bottom: @generalPad;
    right: @generalPad;
    font-size: 1.3rem;
    z-index: 3;
  }
  .gdd-widget-wrap{
    flex: 1;
    &.gdds-zero{
      .gdd-list{
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .create-gdd{
        font-size: 0.9em;
        position: relative;
        bottom: auto;
        right: auto;
      }
      .what{
        margin-top: 10px;
        font-size: 0.7em;
        text-decoration: none;
        display: block;
      }
    }
    &.gdds-one{
      .column;
      .gdd-list{
        flex: 1;
        .gdd-wrap{
          &.flipped{
            .back{
              .column;
              justify-content: center;
            }
          }

        }
        .gdd{
          border: none;
          .front{
            justify-content: center;
            margin-top: -20px;
            header{
              font-size: 1.8rem;
              flex-grow: 0;
            }
            .gdd-value{
              font-size: 4.3em;
              line-height: 1.1;
            }
          }
        }
      }
      &.type-hdd,
      &.type-cdd{
        .create-gdd{
          display: none;
        }
      }
    }
  }
  .gdd-list{
    display: flex;
    flex-wrap: wrap;
    padding-top: 15px;
    overflow: auto;
    aspect-ratio: 10/8.5;
    .what{
      display: none;
    }
  }
  .gdd-wrap{
    perspective: 1000px;
    flex: 1;
    text-decoration: none;
    display: flex;
    margin: 5px;
    .back{
      display: none;
    }
    &.flipped {
      flex: none;
      width: 90%;
      flex-grow: 1;
      .gdd {
        transform: rotateY(180deg);
        .back{
          display: block;
          width: 100%;
        }
        .front{
          display: none;
        }
      }
    } 
  }
  .gdd{
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
    flex: 1;
    border-radius: 10px;
    border: 1px solid var(--color-border-widget-header-main);
    padding: 5px;
    display: flex;
    .front,
    .back{
      backface-visibility: hidden;
      top: 0;
      left: 0;
      height: 100%;
      flex: 1;
    }
    .front{
      flex: 1;
      align-items: center;
      z-index: 2;
      transform: rotateY(0deg);
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      header{
        color: var(--color-text-main);
        font-weight: 300;
        font-size: 1.8rem;
        line-height: 1.3;
        // flex-grow: 1;
        display: flex;
        align-items: center;
      }
      .gdd-value{
        font-size: 4.5rem;
        color: var(--color-text-main);
        font-weight: 500;
      }
      .since{
        font-size: 0.8em;
        color: var(--color-text-main);
        margin-top: 5px;
        font-weight: 300;
      }
    }
    .back{
      transform: rotateY(180deg);
      padding: 5px;
      footer{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        font-size: 0.8em;
        color: var(--color-text-main);
        .bottom{
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
      }
      a.trash{
        width: 25px;
        height: 25px;
      }

    }
  }

}
