@import '../styles/mixins.less';

.component-timezone-picker {
  .css-1wy0on6{
    display: none;
  }
  .css-1g6gooi,
  .css-18amj6o,
  .tz-select__value-container,
  .css-1hwfws3{
    padding: 0;
    margin: 0;
  }
  .tz-select__value-container,
  .css-1hwfws3{
    height: 27px;
  }
  .css-2o5izw,
  .tz-select__control,
  .css-vj8t7z{
    line-height: 1.5;
    height: auto;
    border-radius: @br;
    font-size: 1.1em;
    padding: 4px @generalPad 3px;
    outline: none;
    border: solid 1px @blue-dark;
    min-height: 0px;
    background: url(../assets/down-carrot-gray.svg) right 13px center no-repeat;
    box-shadow: none;
    background-color: var(--color-bg-base);
    &:hover {
      border: 1px solid @text-dark;
      outline: none;
      box-shadow: none;
    }
    .css-dvua67-singleValue.tz-select__single-value {
      color: var(--color-text-main);
    }
  }
  .tz-select-container,
  .css-10nd86i{
    width: 240px;
  }
  .css-2o5izw{
    border: 1px solid @text-dark;
    outline: none;
    box-shadow: none;
  }
  .css-dvua67-singleValue.tz-select__single-value {
    color: var(--color-text-main);
  }
}
