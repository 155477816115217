@import '../../styles/mixins.less';


.device-widget.map{
  .full-bleed-widget;
  &.expanded{
    display: block;
  }
  &.minimized{
    > header{
      display: block;
    }
    .device-map-widget {
      a.expand {
        display: none;
      }
    }
  }
  a.expand{
    top: 15px;
    right: 15px;
  }
  .device-map-widget {
    .component-map{
      .map{
        display: flex;
        flex: 1;
        height: auto;
      }
      .animation-timeline,
      .layer-btns{
        display: none !important;
      }
    }
  }
}

@media (max-width: @phone-max){
  .device-widget.map{
    padding-bottom: 0;
  }
  .device-map-widget{
    .component-map{
      height: 300px;
    }
  }

}