@import '../../styles/mixins.less';

.device-widget.co2{
  .fill-column;
  .device-co-2-widget {
    .fill-column;
    .gauge{
      @h: 30px;
      height: @h;
      background: url(../../assets/widgets/co2-gauge.svg) center center no-repeat;
      background-size: 100% auto;
      width: 90%;
      border-radius: @h / 2;
      position: relative;
      margin: 1em 0;
      align-self: center;
      .dot{
        @dotH: @h * 1.4;
        height: @dotH;
        width: @dotH;
        position: absolute;
        top: -((@dotH - @h) / 2);
        left: -((@dotH - @h) / 2);
        z-index: 2;
        background: url(../../assets/widgets/co2-dot.svg) center center no-repeat;
        background-size: 100%;
      }
    }
    .label{
      color: #27AE60;
      font-weight: 500;
      font-size: 2em;
      margin: 20px 0;
      align-self: center;
      &.unhealthy-for-sensitive-groups,
      &.unhealthy{
        color: #F7D66B;
      }
      &.very-unhealthy,
      &.hazardous{
        color: #FD5C1A;
      }
    }
    .value {
      color: var(--color-text-main);
      font-size: 2em;
      align-self: center;
      flex: 1;
      margin-top: 2em;
      .fdp-val {
        font-size: 2.2em;
        font-weight: bold;
        margin: 0 8px;
        color: var(--color-text-widget-big-number) !important;
      }
      .suff {
        font-weight: 300;
        color: #959595;
        font-size: 0.6em;
      }
    }
    .bottom {
      .fill-column;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      flex: 1;
      padding: 25px;
      color: var(--color-text-main);
      font-weight: 300;
      font-size: 2vw;
      font-size: clamp(0.7em, 2vw, 0.8em);
      span {
        color: @blue;
        font-weight: normal;
        margin-left: 2px;
        font-size: 2.5rem;
      }
    }
    @media (max-width: @tablet-max) {
      .value {
        font-size: 1.3em;
      }
      .gauge {
        margin: 10px 0;
      }
      .label {
        font-size: 1.3em;
        margin: 15px 0;
      }
      .bottom {
        padding-top: 0;
        font-size: 1.3vw;
        font-size: clamp(0.7em, 1.3vw, 0.8em);
      }
    }
    @media(max-width: @phone-max) {
      .value {
        margin-top: 0;
      }
      .bottom {
        padding: 0 20px;
      }
    }
  }
  &:not(.expanded){
    .device-co-2-widget{
      .label{
        display: none;
      }
      .value {
        font-size: 1em;
        margin-top: 1.5em;
        .fdp-val {
          font-weight: bold;
          line-height: 1.3;
          font-size: 2.2em;
        }
        .suff {
          font-size: 0.6em;
        }
      }
      .gauge {
        margin: 0.5em 0;
      }
      .bottom {
        padding: 0 15px 15px;
        span {
          font-size: 1.8rem;
        }
      }
      @media (max-width: @tablet-max) {
        .value {
          font-size: 2em;
          margin-top: 2em;
          .fdp-val {
            font-size: 1.3em;
          }
        }
      }
      @media(max-width: @phone-max) {
        .value {
          margin-top: 1em;
        }
      }
    }
  }
}
