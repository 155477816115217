@import '../../styles/mixins';


.common-data-popup .top,
.device-device-card-header {
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding: 10px;
    
  .meta{
    .favstar,
    .user-avatar{
      margin-right: 3px;
      flex-shrink: 0;
    }
    .user-avatar{
      width: 45px;
      height: 45px;
      margin-right: 7px;
    }
    position: relative;
    display: flex;
    font-size: 1.4rem;
    align-items: center;
    line-height: 1.4;
    .favstar{
      position: absolute;
      z-index: 2;
      left: 29px;
      bottom: -4px;
    }
    > div *{
      display: block;
    }
    b {
      font-weight: 700;
    }
    .device-name-wrap{
      width: 100%;
      max-width: 190px;
      padding-right: 10px;
      > span{
        display: block;
        .ellipsis;
      }
    }
  }
  .ago{
    font-size: 1.2rem;
    color: var(--color-text-main-lt);
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .utility{
    > a {
      height: 27px;
      background: url(../../assets/common/three-dots.svg) center 15px no-repeat;
      width: 30px;
      display: block;
    }
    > div {
      display: none;
    }
    &.open{
      position: relative;
      z-indeX: 10;
      > div {
        padding: 10px;
        display: block;
        background-color: var(--color-bg-page-container-main);
        .general-shadow;
        position: absolute;
        top: 25px;
        right: 0;
        min-width: 90px;
        a.share{
          width: auto;
          background: none;
          height: auto;
          filter: none;
          color: @text-dark;
          text-decoration: none;
          display: block;
          margin: 0px 0px 5px 0px;
          &:before{
            content: 'Share'
          }
          &:hover{
            color: var(--color-a-hover);
          }
        }
        > a{
          color: @text-dark;
          text-decoration: none;
          display: block;
          margin-bottom: 5px;
          &:hover{
            color: var(--color-a-hover);
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
