@import '../../styles/mixins.less';

.user-metric-chooser {
  display: inline-block;
  a{
    display: inline-block;
    background-color: var(--color-bg-widget-main);
    color: @text-dark;
    text-decoration: none;
    padding: 3px 7px;
    .btn-primary-active-hover-colors;
    &.active{
      background-color: @blue;
      color: @white;
    }
    &:hover{
      text-decoration: none;
    }
  }
}