@import '../../styles/mixins.less';

.device-search-bar {
  @h: 22px;
  margin-right: 15px;
  height: 27px;
  a{
    color: #fff;
    display: inline-block;
    font-size: @h;
    &:focus,
    &:hover{
      color: #fff;
    }
  }
  input.search{
    border-radius: 0;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #fff;
    margin-right: 10px;
    height: @h;
    color: #fff;
    width: 0;
    transition: 0.3s all ease-out;
    padding: 0;
    .placeholder(@white);
  }
  &.open{
    input.search{
      width: 200px;
    }
  }

}

@media(max-width: @tablet-phone-max){
  .device-search-bar{
    .glyphicon{
      color: @gray-blue;
    }
    input.search{
      border-color: @gray-blue;
      .placeholder(@gray-blue);
      color: @text-dark;
    }
  }
}