@import '../../styles/mixins.less';
.device-temp-widget-expanded{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .body{
      padding: 0;
      padding-bottom: 15px;
      border-bottom: 1px solid var(--color-border-widget-header-main);
      .top{
        .fdp-val {
          font-size: 5vw;
          font-size: clamp(0.7em, 5vw, 7em);
        }
        .suff{
          font-size: 5vw;
          font-size: clamp(0.5em, 5vw, 6em);
        }
      }
      .bottom{
        flex-grow: 1;
        align-self: flex-top;
        .from {
          .fdp:before {
            height: 2vw;
            height: clamp(0.3em, 2vw, 1em);
          }
        }
        
        > div {
          line-height: 1.4;
          .label {
            font-size: 1.3vw;
            font-size: clamp(0.6em, 1.3vw, 0.65em);
          }
        }
      }
    }
    .expanded-wrap{
      //flex-grow: 1;
      padding: 25px 25px 20px;
     // display: flex;
      //flex-direction: column;
      // justify-content: center;
      .device-high-low-graphs .line-wrap {
        padding: 0px 0 20px;
      }
    }
}
.device-temp-widget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  .compact-show{
    display: none;
  }
  .top{
    color: var(--color-text-widget-big-number);
    text-align: center;
    line-height: 1;
    .fdp-val {
      font-size: 10vw;
      font-size: clamp(2em, 10vw, 3em);
    }
    .suff{
      font-size: 10vw;
      font-size: clamp(2em, 10vw, 3em);
    }
  }
  .bottom{
    display: flex;
    justify-content: center;
    align-items: flex-top;
    padding: 0 10px;
    > div {
      margin-top: 10px;
      padding: 0px 10px;
      line-height: 1.2;
      text-align: center;
      border-right: 1px solid var(--color-border-line-separator-main);
      
      &:last-of-type{
        border-right: none;
      }
      .fdp {
        
      }
      &.device-from-yesterday{
        .from{
          padding-top: 0px;
          .fdp {
            display: flex;
            justify-content: center;
            padding-left: 5px;
            .fdp-val {
              padding-left: 10px;
            }
          }
        }
      }
      .label{
        font-size: 0.66em;
        line-height: 1.2;
        padding-bottom: 2px;
        font-weight: 300;
      }
    }
  }
  .device-widget.expanded & {
    .device-temp-widget-expanded;
    .bottom{
      flex-wrap: wrap;
    }
  }
}
.compact {
  .device-temp-widget{
    .compact-show{
      display: block;
    }
  }
}
.device-widget.temp{
  .column;
}
.tablet-widget-expanded {
  .device-temp-widget{
    .body{
      //font-size: 0.8em;
      flex-direction: row;
      padding-bottom: @generalPad;
    }
  }
}
.device-outside-bar{
  .device-temp-widget {
    .body{
      display: flex;
      align-items: center;
      // padding-bottom: 0;
      // padding-right: @generalPad;
      // padding-left: @generalPad;
      padding: 5px @generalPad @generalPad @generalPad;
    }
    .top {
      font-size: 0;
      padding-top: 0;
      .tempf{
        font-size: 50px;
      }
    }           
    .bottom{
      padding-right: 0;
      flex-grow: 1;
      > div{
        border: none;
        padding-right: 0;
        &:first-of-type{
          //border-right: 1px solid var(--color-border-line-separator-main);
          padding-right: 10px;
        }
        .label{
          color: var(--color-text-main-lt);
          top: -4px;
          position: relative;
        }
        .val{
          color: var(--color-text-main);
        }
      }
    }
  }
}
@media (max-width: @tablet-max) {
  .device-temp-widget{
    .column;
    justify-content: center;
    padding: @generalPad 0;
    .body{
      //font-size: @tablet-font-grow;
      .top{
        padding-top: 0px;
        .fdp-val {
          font-size: 15vw;
          font-size: clamp(1em, 15vw, 4em);
        }
        .suff{
          font-size: 15vw;
          font-size: clamp(1em, 15vw, 4em);
        }  
      }
      .bottom {
        .from {
          .fdp:before {
            height: 4vw;
            height: clamp(0.3em, 4vw, 1em);
          }
        }
        > div {
          .label {
            padding-bottom: 4px;
            font-size: 1.3vw;
            font-size: clamp(0.7em, 1.3vw, 0.8em);
          }
          .fdp {
            .fdp-val, .suff {
              font-size: 1vw;
              font-size: clamp(1.2em, 1vw, 2.2em);
            }
          }
        }
      }
    }
  }
  .device-widget.temp.expanded{
    padding-top: 0;
    .body {
      .top{
        padding-top: 0px;
        .fdp-val {
          font-size: 5vw;
          font-size: clamp(0.5em, 5vw, 3em);
          
        }
        .suff{
          font-size: 5vw;
          font-size: clamp(0.5em, 5vw, 3em);
        }  
      }
      .bottom {
        .from {
          .fdp:before {
            height: 4vw;
            height: clamp(0.3em, 4vw, 1.3em);
          }
        }
        > div {
          .label {
            font-size: 0.8vw;
            font-size: clamp(0.7em, 0.8vw, 1em);
            padding-bottom: 4px;
          }
          .from {
            .fdp {
              font-size: 1vw;
              font-size: clamp(1em, 1vw, 2.2em);
            }
          }
        }
      }
    }
    .tablet-widget-expanded;
    .expanded-wrap{
      //overflow: hidden;
      padding: @generalPad;
      .device-high-low-graphs {
        .line-wrap {
          padding: 0px 0 11px;
          &:last-child{
            padding-bottom: 0px;
          }
        }
      }
    }
  }
}

@media (max-width: @tablet-phone-max) {
  .device-temp-widget {
    .body {
      .bottom {
        .from {
          .fdp:before {
            height: 4vw;
            height: clamp(0.3em, 4vw, 1em);
          }
        }
      }
    }
  }
  .device-widget.temp.expanded{

    .body {
      .top {
        .fdp-val {
          font-size: 15vw;
          font-size: clamp(1em, 15vw, 2.5em);
        }
        .suff{
          font-size: 15vw;
          font-size: clamp(1em, 15vw, 2.5em);
        } 
      }
      .bottom {
        .from {
          .fdp:before {
            height: 4vw;
            height: clamp(0.3em, 4vw, 1em);
          }
        }
        > div {
          line-height: 1;
          .label {
            font-size: 14px;
          }
        }
      }
    }
    .tablet-widget-expanded;
    .expanded-wrap{
      //display: none;
      .device-high-low-graphs {
        .line-wrap {
          padding: 5px 0 15px;
        }
      }
    }
  }
}


//mobile only
@media (max-width: @phone-max) {
  .device-widget.temp.expanded,
  .device-widget.temp{
    .tablet-widget-expanded;
    .device-temp-widget{
      .body{
        flex-wrap: wrap;
        //font-size: @phone-font-grow;
        padding-top: @bigPad;
        padding-bottom: 0px;
        .top,.bottom{
          width: 100%;
        }
      }
    }
  }
  .device-widget.temp.expanded{
    .device-temp-widget{
      padding: @generalPad 0;
      .body{
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: @generalPad;
        .top {
          .fdp-val {
            font-size: 20vw;
            font-size: clamp(1em, 20vw, 4em);
          }
          .suff {
            font-size: 20vw;
            font-size: clamp(1em, 20vw, 4em);
          }
        }
        .bottom {
          > div {
            .label {
              font-size: 14px;
            }
          }
        }
      }
      .expanded-wrap{
        font-size: @phone-font-grow;
        .device-high-low-graphs {
          .line-wrap {
            padding: 0px 0 25px;
          }
        }
        .line-wrap{
          padding-bottom: 40px;
          &:last-child{
            padding-bottom: 0px;
          }
        }
      }
      
    }
  }
}