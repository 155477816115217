@import '../../styles/mixins';

@keyframes slide-down{
  0% { 
    transform: translateY(0);
  }
  50% { 
    transform: translateY(100px);
  }
}
@keyframes slide-up {
  0%{
    transform: translateY(100px);
  }
  50% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
.slide-off-map-btns{
  .common-location-autocomplete.find-my-location,
  a.search-btn,
  .component-map .layer-btns{
    transform: translateX(60px);
  }
}
.mapboxgl-ctrl-bottom-right {
  z-index: 500;
}
@media (max-width: @phone-max) {
  .app.v4.home-page {
    @searchBtnBottom: @forecast-tab-height + @generalPad + 30px;
    @cordovaBottomAdd: 40px;
    overflow: hidden;
    &.sidebar {
      .mobile-post{
        transform: translateY(300px);
      }
      .user-home-page a.back-search{
        left: 0px;
      }
      .slide-off-map-btns;
    }
   .user-home-page{
      .react-swipeable-view-container{
        box-shadow: none !important;
        .ReactSwipeableBottomSheet--open,
        .ReactSwipeableBottomSheet--closed{
          background-color: transparent !important;
        }
      }
      a.back{
        position: fixed;
        left: -100px;
        top: 15px;
        top: calc(~"env(safe-area-inset-top, 0) + 15px");
        z-index: 1000;
        padding: 10px 20px 15px 40px;
        background: url(../../assets/common/arrow-left-mobile.svg) no-repeat 10px 14px;
        color: var(--color-text-main);
        font-size: 2rem;
        text-decoration: none;
        .sidebar-transition();
      }
      a.search-btn {
        .btn-map(41px);
        position: fixed;
        right: @generalPad;
        top: calc(~"env(safe-area-inset-top, 0) + 15px");
        z-index: 402;
        background-image: url(../../assets/search-black.svg);
        background-size: 55% !important;
        .sidebar-transition();
        &:hover {
          background-image: url(../../assets/search-black.svg);
          background-color: var(--color-bg-map-layer-btn-main);
        }
      }
      .common-location-autocomplete.find-my-location {
        position: fixed;
        right: 38px;
        bottom: @searchBtnBottom;
        z-index: 400;
        .sidebar-transition();
        .autocomplete-wrap{
          display: none;
        }
        .component-find-location-btn{
          top: 0px;
          left: 0px;
          .btn-map(41px);
          margin-top: 0;
          background-image: url(../../assets/map/marker-pin.svg);
          background-size: 55% !important;
          background-color: rgba(255, 255, 255, 1);
          border-color: rgba(255, 255, 255, 1);
          &:hover {
            background-color: rgba(255, 255, 255, 1);
            background-image: url(../../assets/map/marker-pin.svg);
          }
          &.btn-loading{
            &[disabled]{
              background: none;
            }
            i.glyphicon{
              background: url(../../assets/common/spinner-black.gif) no-repeat center center;
            }
          }
        }
      }
      a.search-btn.active{
        //background-image: url(../../assets/search.svg);
      }
      .search-wrap{
        // .sidebar-transition();
        transform: translateY(100px);
        position: relative;
        z-index: 400;
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        .common-location-autocomplete{
          margin-bottom: 50px;
        }
      }
  
      // bottom forecast
      .mobile-post{
        // position: fixed;
        // bottom: 0;
        width: 100%;
        display: block;
        z-index: 401;
        max-height: 100vh;
        .sidebar-transition();
        .bottom-card{
          background-color: var(--color-bg-page-block-main);
          border-top-right-radius: 15px;
          border-top-left-radius: 15px;
          padding-top: 30px;
          position: relative;
          min-height: 100vh;
          overscroll-behavior: contain;
          .sidebar-transition();
          .general-shadow;
          &:before{
            content: '';
            width: 60%;
            margin: 0px auto;
            height: 5px;
            border-radius: 5px;
            background-color: var(--color-border-main-lt);
            position: absolute;
            left: 50%;
            top: 13px;
            margin-left: -30%;
          }
          // &.has-device-detail{
          //   padding-top: 10px;
          //   &:before{
          //     display: none;
          //   }
          //   .post-wrap, .forecast-first{
          //     display: none;
          //   }
          // }
          .device-device-popup {
            max-width: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            >.top {
              padding: 0px 0px 0px 0px;
              order: 1;
              position: relative;
              .ago{
                padding-top: 3px;
              }
              .meta {
                .user-avatar {
                  width: 36px;
                  height: 36px;
                  box-shadow: 0 2px 4px 0 var(--color-shadow-main);
                }
              }
            }
            .content {
              display: none;
              padding: 10px 0 10px 0;
              order: 3;
            }
            &.windgustmph {
              .content {
                padding-top: 0;
                padding-bottom: 0;
                .wind-widget {
                  padding-bottom: 0;
                }
              }
            }
            &.hourlyrainin {
              .content {
                padding-top: 0;
                padding-bottom: 0;
                .rain-widget .outer-wrap .rain-wrap .wrap {
                  padding-bottom: 0;
                }
                .outer-wrap {
                  .rain-wrap {
                    .fdp {
                      color: var(--color-text-main);
                    }
                  }
                }
              }
            }
            >.bottom {
              order: 2;              
              background-color: transparent !important;
              padding: 3px 0 0px 0;
              justify-content: flex-start;
              padding-left: 63px;
              .btn {
                color: #C5C5C5 !important;
                background-color: transparent !important;
                padding: 0 !important;
              }
              .component-share {
                display: none;
              }
            }
            .device-temp-widget {
              .body {
                display: flex;
                justify-content: center;
                .top .tempf {
                  position: relative;
                  padding-right: 1.8em;
                  @media (max-width: @phone-max){
                    padding-right: 0.6em;
                  }
                  @media (max-width: @super-small){
                    padding-right: 0;
                  }
                }
                .top .fdp-val {
                  font-size: 3.5em !important;
                }
                .top .suff {
                  font-size: 3em !important;
                  position: absolute;
                  @media (max-width: @super-small){
                    font-size: 2.4em !important;
                  }
                }
                .bottom {
                  margin-left: 10px;
                  padding: 0;
                  
                  > div {
                    border: none;
                    text-align: left;
                    margin-top: 5px;
                    .val {
                      font-size: 1.2em;
                    }
                  }
                }
              }
              .bottom > div .label {
                color: #C5C5C5;
                font-size: 0.85em;
              }
            }
            .wind-widget {
              .compass {
                margin: 0 auto;
                .compass-create(120px);
              }

              .corner-wrap {
                .fdp {
                  color: var(--color-text-main);
                }
              }
              .val {
                top: 40px;
                font-size: 1.3em;
                > .fdp {
                  font-size: 1.2em;
                }
                .label {
                  font-size: 0.5em;
                }
                .max {
                  margin-top: 0;
                  display: none;
                  .peak {
                    font-size: 10px;
                  }
                  .maxdailygust {
                    font-size: 14px;
                  }
                }
              }
            }
          }
          .device-detail{
            position: relative;
            .close{
              position: absolute;
              right: @generalPad;
              top: 5px;
              z-index: 100;
              padding: 10px;
              background-image: var(--bg-image-close);
              background-size: 20px;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
  
        .post-wrap{ 
          align-items: center;
          display: none;
          border-top: 1px solid var(--color-border-alt-lt);
        }
        .whats-it-like{
          flex-grow: 1;
          display: flex;
          align-items: center;
          text-decoration: none;
          padding-left: 15px;
          .user-avatar{
            display: none;
            // width: 40px;
            // height: 40px;
          }
          > span {
            display: block;
            padding-left: 10px;
            flex-grow: 1;
            color: var(--color-text-main-lt);
          }
        }
        a.component-image-upload-link{
          .camera-btn;
        }
      }
      .forecast-first{
        border-top: 1px solid var(--color-border-alt-lt);
        position: relative;
        a.forecast-open{
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 150px;
          z-index: 5;
        }
        // generic gps location
        &.local{
          .forecast{
            .location{
              padding-top: 20px;
            }
            .forecast-summary{
              margin-top: 25px;
            }
          }
        }
        &.hyper-local{
          a.forecast-open{
            top: 75px;
            height: 90px;
          }
          .device-name-wrap b {
            width: 100%;
            .ellipsis;
          }
        }
      }
      .mapboxgl-ctrl-bottom-left{
        bottom: 41px;
        z-index: 0;
      }
      

      .user-mobile-home-page {
        .common-location-autocomplete{
          .sidebar-transition();
        }
        &.search{
          a.back-search{
            left: 0px;
          }
          .mobile-post{
            transform: translateY(300px);
          }
          .search-wrap-enter-active{
            animation: slide-up 0.4s ease-in-out;
          }
          .search-wrap-enter-done,
          .search-wrap-exit{
            transform: translateY(0px);
          }
          .search-wrap-exit-active{
            animation: slide-down 0.4s ease-in-out;
          }
          .slide-off-map-btns;
        }
        &.focused{
          .common-location-autocomplete{
            padding-bottom: 230px;
          }
        }
        // forecast is open
        &.forecast{
          .slide-off-map-btns;
          a.search-btn{
            transform: translateX(0px);
            margin-top: 5px;
            // right: 192px;
            box-shadow: none;
          }
          a.back-map {
            left: 0px;
          }
          .settings-nav{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            top: calc(~"env(safe-area-inset-top, 0) + 0px");
            left: 0px;
            width: 100%;
            height: 70px;
            z-index: 3;
            padding-right: 50px;  
            .settings-back{
              padding: 22px 20px 15px 40px;
              background: url(../../assets/common/arrow-left-mobile.svg) no-repeat 10px 22px;
              color: var(--color-text-main);
              font-size: 2rem;
              text-decoration: none;
              display: none;
            }
            .show-settings{
              padding: 0px 25px;
              background: url(../../assets/common/settings.svg) no-repeat center 30px;
              height: 70px;
            }
          }
          .mobile-post{
            // -webkit-overflow-scrolling: touch;
            .bottom-card{
              padding-top: 70px;
              padding-top: calc(~"env(safe-area-inset-top, 0) + 70px");
              position: relative;
              &:before{
                display: none;
              }
              .post-wrap{
                display: none;
              }
              .open.common-location-autocomplete{
                display: block;
                position: fixed;
                right: 100px;
                top: calc(~"env(safe-area-inset-top, 0) + 24px");
                padding: 0px;
                margin: 0px;
                z-index: 10;
                .autocomplete-wrap{
                  display: none;
                }
                a.component-find-location-btn{
                  position: relative;
                  top: 0px;
                  lefT: 0px;
                  display: flex;
                  flex-direction: row-reverse;
                  font-size: 11px;
                  align-items: center;
                  .text{
                    padding-left: 5px;
                    padding-right: 5px;
                    display: block;
                  }
                  .glyphicon{
                    font-size: 15px;
                  }
                }
              }
            }
          }
          .forecast {
            .device-forecast-widget {
              margin-top: 0px;
            }
          }
          &.ios{
            .component-map{
              display: none;
            }
          }
          &.settings{
            a.back-map{
              left: -100px;
            }
            a.back-search{
              left: 0px;
            }
            .settings-nav{
              justify-content: flex-start;
              background-color: var(--color-bg-page-block-main);
              .settings-back{
                display: block;
              }
              .show-settings{
                display: none;
              }
            }
          }
        }
        .component-map {
          .layer-btns{
            position: fixed;
            flex-direction: column-reverse;
            bottom: @searchBtnBottom + 25px;
            align-items: flex-end;
            width: auto;
            right: 0px;
            left: auto;
            .sidebar-transition();
          }
          .map{
            // height: ~"calc(100vh - 200px)")
          }
        }
      }
      &.social-ok{
        .mobile-post{
          .post-wrap{
            display: flex;
          }
        }
        .common-location-autocomplete.find-my-location{
          bottom: @searchBtnBottom + @forecast-tab-post-btn-height;
        }
        .user-mobile-home-page .component-map .layer-btns {
          bottom: @forecast-tab-height + @generalPad + 55px;
        }
      }
      .device-outside-bar{
        .social-comments,
        > .top{
          display: none;
        }
        .card-wrap{
          margin: 0px 10px;
          .device-device-popup{
            > .bottom{
              display: none;
            }
            &.social{
              // box-shadow: inset 0px -14px 20px -6px rgba(0,0,0,0.42);
              .content{
                overflow: hidden;
                // height: 130px;
              }
            }
            &.social.has-img,
            &.webcam{
              .top{
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                z-index: 3;
                background-color: transparent;
                text-align: left;
              }
              .text{
                display: none;
              }
              .content{
                height: auto;
              }
            }
            .user-avatar{
              width: 40px;
              height: 40px;
            }
            // &.social.has-img,
            &.social.has-img .img-cover,
            &.webcam .device-webcam-image {
              height: 200px;
            }
            .social-post{
              height: auto;
              max-height: 280px;
              justify-content: flex-start;
              .expiration{
                display: none;
              }
            }
          }
        }
        &.not-fs {
          .device-device-popup{
            &.social.has-img,
            &.webcam{
              position: relative;
              &:before{
                z-index: 3;
                content: '';
                width: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
                height: 60px;
              }
              .meta{
                color: #fff;
                font-size: 1.4rem;
                // -webkit-text-stroke: 1px @black;
                .favstar{
                  display: none;
                }
                .device-name-wrap{
                  margin-left: 7px;
                }
              }
              > .bottom{
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 0;
                z-index: 4;
                background-color: transparent;
                width: 100%;
                height: 50px;
                padding: 0px;
                .component-like,
                .component-share{
                  display: none;
                }
                .btn-primary{
                  background-color: transparent !important;
                  color: transparent !important;
                  flex-grow: 1;
                  border-radius: 0px;
                  width: 100%;
                }
              }
            }
            .top{
              .meta {
                width: 100%;
                .device-name-wrap {
                  flex-grow: 1;
                  min-width: 0;
                  span{
                    display: block;
                    .ellipsis;
                  }
                } 
              }
              .ago{
                display: none;
              }
            }
            .rain-widget{
              height: 190px;
            }
          }
        }
      }
      .bottom-sheet-wrap-exit-done {
        display: none;
      }
    }
    // cordova specific
    &.ios.cordova{
      .user-home-page{
        .common-location-autocomplete.find-my-location {
          bottom: @searchBtnBottom + @cordovaBottomAdd;
        }
        .user-mobile-home-page {
          .component-map {
            .layer-btns{
              bottom: @searchBtnBottom + 30px + @cordovaBottomAdd;
            }
          }
        }
        &.social-ok{
          .common-location-autocomplete.find-my-location {
            bottom: @searchBtnBottom + @forecast-tab-post-btn-height + @cordovaBottomAdd;
          }
          .user-mobile-home-page .component-map .layer-btns {
            bottom: @forecast-tab-height + @generalPad + 55px + @cordovaBottomAdd;
          }
        }
      }
    }
  }
}

