@import '../../styles/mixins.less';

.alert-crud {
  select.form-control{
    background-image: url(../../assets/down-carrot-gray.svg);
  }
  .create{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    div.device-chooser {
      width: 100%;
    }
    select{
      display: inline-block;
      flex: 1;
      margin-right: @generalPad;
    }
    .input-group{
      margin-right: @generalPad;
      border: 1px solid @blue-dark;
      display: flex;
      input{
        border: none;
        margin: 4px 0;
        padding-top: 0px;
        padding-bottom: 0px;
        height: 22px;
        width: 100px;
        background-color: transparent;
      }
      select{
        border: none;
        box-shadow: none;
        min-width: 75px;
        margin-right: 0px;
      }
    }
    &.notReporting{
      .condition,
      .amount{
        display: none;
      }
    }
  }
}

@media (max-width: @phone-max) {
  .alert-crud{
    .create{
      display: block;
      .form-control,
      .input-group{
        margin-bottom: 7px;
        margin-right: 0px;
      }
    }
  }
  
}
