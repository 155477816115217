@import '../../styles/mixins.less';

@leftColumnWidth: 150px;

.device-device-data-table {
    position: relative;
    input[type="checkbox"]{
        margin-right: 7px;
    }
    .glyphicon{
        display:inline-block;
        padding-right: 7px;
        padding-left: 7px;
    }
    .buttons{
        // text-align: right;
        .btn{
            margin-left: 10px;
        }
    }
    table{
        &.table-header{
            position: absolute;
            top: 0;
            left: 0;
            tbody{
                display: none;
            }
        }
        &.main{
          margin-bottom: 0px;
          font-size: 0.9em;
            tr:nth-of-type(odd){
                td.dateutc{
                }
            }
            th.dateutc,td.dateutc{
                position: absolute;
                top: auto;
                left: 0;
            }
            th{
              background-color: var(--color-bg-chart-header-main);
              border: none !important;
              &.dateutc{
                  padding-top: 44px;
                  background-color: @blue-med;
                  color: @text-dark;
                  &.checks{
                    padding-top: 20px;
                    .buttons{
                      margin-bottom: 5px;
                    }
                  }
              }
            }
        }
        th.dateutc,
        td.dateutc{
          background-color: #71cff3;
          width: @leftColumnWidth;
          color: @white;
          a{
            color: @white;
            text-decoration: none;
            &:hover{
              color: @blue;
            }
          }
        }
        td{
            text-align: center;
            border: none !important;
            padding: 13px !important;
            font-size: 1.1em;
        }
        th{
            min-width: 100px;
            text-align: center;
        }
        .fdp{
          .suff{
            font-size: 0.7em;
          }
        }
    }
    .scroller{
        margin-left: @leftColumnWidth;
        overflow-x: auto;
        overflow-y: visible;
    }
}
