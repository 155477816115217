@import '../../styles/mixins';

.common-dropdown-menu{
  position: relative;
  width: 250px;
  &.open{
    .dropdown-menu-button{
      background-image: url(../../assets/up-carrot.svg);
    }
  }
}
.dropdown-menu {
  &-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 8px 16px 8px 30px;
    background: url(../../assets/down-carrot.svg) no-repeat right 10px top 50%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 0px;
      width: 33px; // Adjust to match your SVG size
      height: 33px; // Adjust to match your SVG size
      background: inherit;
      filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(177deg) brightness(98%) contrast(98%);
    }
    border: none;
    cursor: pointer;
    font-size: 14px;
    .dropdown-menu-button-before{
      margin-right: 10px;
      color: @blue;
      font-weight: 500;
    }

    &-text {
      color: var(--color-text-main);
      text-transform: uppercase;
    }

  }

  &-content {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--color-bg-page-block-main);
    z-index: 10;
  }

  &-list {
    margin: 0;
    padding: 4px 0;
    list-style: none;

    &-item {
      padding: 8px 16px;
      cursor: pointer;
      font-size: 14px;
      color: var(--color-text-main-md);
      text-transform: uppercase;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}