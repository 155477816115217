@import '../../styles/mixins.less';

.device-my-devices {
  p.later{
    display: none;
  }
  .device-default-page{
    margin-top: 30px;
    h4{
      font-size: 1.1em;
    }
  }
}

.app.loggedout{
  .device-my-devices {
    header.main{
      display: none;
    }
    .block{
      background-color: transparent;
      box-shadow: none;
      padding: 0px;
      h3{
        display: none;
      }
    }
    .page-body{
      padding: 0;
    }
  }
}
@media(max-width: @phone-max) {
  .device-my-devices{
    &.v3{
      header.main{
        padding-left: 15px;
      }
      .page-body{
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
}