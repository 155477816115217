@import '../../styles/mixins.less';

.uv-widget {
  flex-grow: 1;
  .column;
  .top{
    flex-grow: 1;
    .column;
    justify-content: center;
    align-items: center;
    .wrap{
      padding: 10px @generalPad;
      border-top: 2px solid @gold;
      border-bottom: 2px solid @gold;
      text-align: center;
      .uv-data{
        color: var(--color-text-widget-big-number);
        font-size: 3em;
        line-height: 1;
      }
      .level{
        padding-top: 2px;
        color: @text-dark;
        font-weight: 300;
        font-size: 0.7em;
        text-transform: uppercase;
      }
    }
  }
  .bottom{
    display: flex;
    justify-content: center;
    padding: @generalPad 0 0;
    a{
      @s: 30px;
      color: transparent;
      background: url(../../assets/light-skin.svg) center center no-repeat;
      background-size: 100%;
      margin: 0 5px;
      height: @s;
      width: @s;
      display: block;
      &.med{
        background-image: url(../../assets/med-skin.svg);
      }
      &.dark{
        background-image: url(../../assets/dark-skin.svg);
      }

    } 
  }
}
.device-widget.uv{
  .column;
  .buttons-wrap{
    // display: none;
  }
}
@media (max-width: @tablet-max) {
  .uv-widget{
    font-size: @tablet-font-grow + 0.1em;
  }
}
@media (max-device-width: @phone-max) {
  .uv-widget{
    padding: @bigPad 0 @generalPad;
    font-size: @phone-font-grow + 0.5em;
    .bottom{
      a{
        @s: 40px;
        width: @s;
        height: @s;
        margin: @generalPad 10px 0 10px;
      }
    }
  }
}
