@import '../../styles/mixins';

.device-webcam-archive {
  padding: @bigPad;
  display: flex;
  flex-wrap: wrap;
  .webcam-thumb{
    @mr: 2%;
    width: 100 / 3 - (2 * @mr);
    margin-right: @mr;
    margin-bottom: @mr;
    text-decoration: none;
    background-color: var(--color-bg-page-block-main);
    &:nth-child(3n){
      margin-right: 0;
    }
    .general-shadow();
    .thumb{
      aspect-ratio: 1920 / 1160;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
    }
    header{
      color: var(--color-text-main-md);
      padding: 5px @generalPad;
      font-size: 1.1em;
      display: flex;
      justify-content: space-between;
      a.play{
        display: block;
        color: transparent;
        width: 25px;
        height: 25px;
        background: url(../../assets/map/btn-play.svg) center center no-repeat;
        background-size: 14px;
      }
    }
  }
}

@media(max-width: @tablet-phone-max){
  .device-webcam-archive {
    padding-right: 0;
  }
}

@media(max-width: @phone-max){
  .device-webcam-archive {
    .webcam-thumb {
      width: 93%;
    }
  }
}