@import '../../styles/mixins.less';
.device-battery-widget {
  overflow: auto;
  .fdp{
    color: @pink;
    &.ok{
      color: @green;
    }
  }
  &.ok{
    display: flex;
    flex-grow: 1;
    padding-top: 10px;
    .wrap{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      h5{
        text-align: center;
        color: @text-dark;
        font-weight: normal;
        margin-top: 0px;
        margin-bottom: 40px;
        .battery-full{
          display: block;
          height: 22px;
          width: 100%;
          margin-top: 15px;
          background: url(../../assets/battery-ok.svg) center center no-repeat;
          background-size: auto 100%;
        }
      }
    }
  }
  .line{
    padding-left: 10px;
    padding-right: 10px;
  }
  .battery-low{
    display: block;
    height: 20px;
    width: 40px;
    float: right;
    margin-top: 0px;
    background: url(../../assets/battery-low.svg) 0px 2px no-repeat;
    background-size: auto 61%;
  }
}
.device-widget.battery{
  .no-graph-button;
  display: flex;
  flex-direction: column;
  &.expanded {
    h5 {
      font-size: 2em;
      font-size: clamp(1em, 3vw, 1.5em);
    }

    .small-flex-lines {
      .line {

        .label  {
          font-size: 1.1em;
          font-size: clamp(0.9em, 2vw, 1.1em);
        }
      }
    }
  }
}

@media (max-width: @phone-max) {
  .device-battery-widget {
    &.ok{
      .wrap{
        h5{
          margin-top: 40px;
        }
      }
    }
  }
}