@import '../../styles/mixins';

.subscribe-process {
  .cart{
    display: flex;
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 15px;
    border-top: 2px solid @white;
    width: 100%;
    justify-content: space-between;
    font-size: 1.2em;
    .code{
      p{
        font-size: 1.2em;
        a{
          color: @gray-dark-alt;
        }
      }
    }
    form{
      padding: 0;
    }
    .input-group{
      position: relative;
      max-width: 300px;
      width: 100%;
      input{
        width: 100%;
        background-color: rgba(255, 255, 255, 0.5);
      }
      .btn{
        position: absolute;
        z-index: 1;
        right: 2px;
        top: 2px;
        padding: 7px 20px 8px;
      }
    }
    .price{
      > div{
        font-size: 1.7em;
        padding-bottom: 5px;
      }
      p{
        font-size: 0.8em;
      }
    }
    &.error{
      input{
        border-color: @red;
      }
      p.error{
        padding: 10px;
        font-size: 0.9em;
        color: @red;
      }
    }
  }
  .buyBtn{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    button{
      padding: 10px 40px;
      font-size: 1.2em;
      margin-bottom: 10px;
    }
  }
  &.plus{
    .cart{
      justify-content: center;
      .input-group{
        margin: 0 auto 0px;
        .btn{
          display: none;
        }
      }
      p{
        text-align: center;
      }
      .price{
        display: none;
      }
    }

  }
}

@media (max-width: @phone-max) {
  .subscribe-process {
    .then{
      display: block;
    }
    .cart{
      flex-direction: column;
      align-items: center;
      .code{
        text-align: center;
        .input-group{
          margin:0px auto 20px;
        }
      }
  
    }
  }
}