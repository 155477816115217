@import '../../styles/mixins';

.app.get-started{
  .mobile-header{
    .navbar-brand{
      margin: 0px auto !important;
    }
  }
  .loggedout,
  &.dashboard::before,
  .component-dashboard-nav,
  .social-notifications,
  .user-nav,
  .common-mobile-nav{
    display: none;
  }
  footer{
    display: none;
    // position: relative;
    // margin-top: 40px;
    margin-bottom: 10px;
  }
  // they are logged in
  &.dashboard{
    .user-get-started{
      .step-1{
        h4,
        input[type="email"],
        li:last-of-type{
          display: none;
        }
      }
      &.s-3{
        a.back{
          display: none;
        }
      }
    }
  }
}

.user-get-started {
  padding: 0px 35px;
  max-width: 500px;
  margin: 0 auto;
  header{
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
    .logo{
      width: 200px;
      height: 80px;
      background: url(../../assets/AWN_full_logo.svg) center center no-repeat;
      background-size: 100%;
    }
    a.back{
      position: absolute;
      left: 0px;
      top: 0px;
      color: @text-dark;
      padding: 20px 10px 0px 0px;
      font-size: 1.3em;
      text-decoration: none;
    }
  }
  &.s-1{
    .back{
      display: none;
    }
  }
  .step-4,
  .step-3,
  .step-1{

    h2,
    h1{
      color: @blue;
      text-align: center;
    }
    h1{
      margin-top: 32px;
      margin-bottom: 5px;
      font-size: 25px;
    }
    h2{
      margin-top: 0;
      font-weight: normal;
      font-size: 1.4em;
      line-height: 1.5;
      margin-bottom: 40px;
    }
    h3{
      font-size: 1.4em;
      text-align: center;
      margin-bottom: 20px;
      color: var(--color-text-main);
    }
    p{
      text-align: center;
      color: var(--color-text-main-md);
    }
    ul{
      padding: 0px 0px;
      li{
        list-style-type: none;
        margin-bottom: 20px;
        display: flex;
        align-items: flex-start;
        color: var(--color-text-main);
        .user-avatar,
        i{
          flex-shrink: 0;
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
        i.glyphicon{
          color: @blue;
          font-size: 2.3em;
          margin-left: 5px;
          margin-right: 5px;
        }
        span{
          // font-size: .95em;
        }
      }
    }
    h4{
      font-weight: normal;
      font-size: 1.2em;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 10px;
    }
  }
  input[type="password"],
  input[type="email"],
  input[type="text"]{
    width: 100%;
    margin-bottom: 20px;
  }
  .step-2{
    h3{
      margin: 30px 0;
    }
    h4{
      font-weight: normal;
      font-size: 1.1em;
      margin-bottom: 5px;
    }
    .user-signup{
      form{
        padding: 0px;
        input[type="email"],
        > h4:nth-of-type(1){
          display: none;
        }
      }
      .plus-13 label{
        justify-content: flex-start;
      }
      p.gray{
        color: #777777;
        margin-top: 40px;
        text-align: center;
      }
      .btn-wrap{
        margin-bottom: 10px;
        text-align: center;
      }
      .terms-btn{
        text-align: center;
      }
    }
  }
  .step-3{
    margin-top: 80px;
    h3{
      margin: 30px 0 5px;
    }
    .interests{
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      justify-content: center;
      .interest{
        width: 48%;
        margin-right: 4%;
        border: 1px solid var(--color-text-main-md);
        background-color: rgba(255, 255, 255, 0.78);
        margin-bottom: 4%;
        padding: 10px;
        border-radius: 16px;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // font-weight: bold;
        font-size: 1.3em;
        color: var(--color-text-main-md);
        &:last-of-type,
        &:nth-of-type(2n){
          margin-right: 0;
        }
        > div {
          display: flex;
          i{
            width: 40px;
            height: 40px;
            margin-right: 8px;
            background: url(../../assets/sun.svg) center center no-repeat;
            background-size: 80%;
            filter: invert(48%) sepia(14%) saturate(638%) hue-rotate(176deg) brightness(94%) contrast(91%);
          }
        }
        &.garden > div i{
          background-image: url(../../assets/soil.svg);
          background-size: 107%;
        }
        &.pool > div i{
          background-image: url(../../assets/widgets/kestrel/relative-humidity.svg);
          background-size: 80%;
        }
        &.indoor > div i{
          background-image: url(../../assets/house.svg);
          background-size: 70%;
        }
        &.outdoor > div i{
          background-image: url(../../assets/tree.svg);
          background-size: 55%;
        }
        &.weather > div i{
          background-image: url(../../assets/map/btn-cyclones-white.svg);
          background-size: 90%;
        }
        &.education > div i{
          background-image: url(../../assets/lightbulb.svg);
          background-size: 80%;
        }
        &.active{
          border-width: 2px;
          border-color: @blue;
          position: relative;
          &::after{
            position: absolute;
            top: -8px;
            right: -8px;
            content: '';
            width: 20px;
            height: 20px;
            background: url(../../assets/common/check-blue.svg) no-repeat;
            background-size: 100%;
          }
          > div i {
            filter: invert(48%) sepia(85%) saturate(2103%) hue-rotate(165deg) brightness(97%) contrast(98%);
          }
        }
        a.more{
          display: block;
          text-align: right;
          font-size: 0.7em;;
          color: var(--color-text-main-md);
          align-self: flex-end;
        }
      }
    }
    .btn-wrap{
      display: flex;
      flex-direction: column;
      align-items: center;
      a{
        margin-top: 10px;
        color: var(--color-text-main-md);
      }
    }
  }
  .step-4{
    display: flex;
    flex-direction: column;
    align-items: center;
    p{
      padding: 10px 0px 40px;
      border-bottom: 1px solid @gray-amb;
      margin-bottom: 40px;
    }
    a:not(.btn){
      color: var(--color-text-main-md);
    }
    i.sent{
      display: block;
      width: 100px;
      height: 100px;
      background: url(../../assets/common/mail-check.svg) center center no-repeat;
      background-size: 100%;
      margin: 50px auto 15px;
    }
    a.btn{
      width: 250px;
      margin-bottom: 15px;
      font-size: 1.2em;
      padding-top: 8px;
      padding-bottom: 8px;
      border: 1px solid @blue;
      i{
        margin-right: 5px;
        top: 4px;
        position: relative;
        font-size: 1.2em;
      }
    }
  }
}
.app.dark{
  .user-get-started{
    header{
      .logo{
        background-image: url(../../assets//AWN_full_logo_for_dkbkgd.svg);
      }
    }
    .step-3 .interests .interest{
      background-color: transparent;
      color: var(--color-text-main);
    }
  }
}
@media(min-width: @phone-max + 1) {
  .user-get-started {
    .step-1,
    .step-3,
    .step-4{
      h1{
        font-size: 35px;
      }
      h2{
        font-size: 1.6em;
      }
    }
    .step-1{
      .email-wrap{
        display: flex;
        input[type="email"]{
          margin-bottom: 0;
          flex-grow: 1;
          width: 200%;
          margin-right: 5px;
        }
      }
    }
  }
}
