@import '../../styles/mixins.less';
@import './DefaultPage.less';
@import './Signup.less';
@import './Dashboard.less';
@import './Signin.less';
@import './Nav.less';
@import './Confirm.less';
@import './Settings.less';
@import './SettingRadio.less';
@import './SettingSelect.less';
@import './PhoneProcess.less';
@import './Avatar.less';
@import './Account.less';
@import './Admin.less';
@import './UserCard.less';
@import './PasswordReset.less';
@import './OauthTile.less';
@import './AdminAlexa.less';
@import './ApiKeys.less';
@import './HomePage.less';
@import './WhereTo.less';
@import './MetricChooser.less';
@import './UsernameEdit.less';
@import './CreateUsernameModal.less';
@import './AdminSocial.less';
@import './MobileHomePage';
@import './KmSupport.less';
@import './ForecastSettings';
@import './BarometerCalibration';
@import './DeleteAccount';
@import './GetStarted';
@import './AwnPlusPage';

.user-signup, .user-signin {
    .row{
        margin-bottom: 10px;
        label{
            display: block;
        }
        input{
            box-sizing: border-box;
            width: 100%;
        }
    }
    .error{
        padding: 5px;
        color: @color-alert;
    }

}
