@import '../../styles/mixins.less';
.soil-widget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  .top{
    text-align: center;
    position: relative;
    //flex-grow: 1;
    
    .label {
      font-size: 3vw;
      font-size: clamp(0.7em, 3vw, 0.8em);
      font-weight: 300;
    }
    .wrap{
      display: flex;
      justify-content: space-between;
      padding-bottom: 0px;
      >div {
        flex-grow: 1;
        line-height: 1.25;
      }
    }
    .fdp-val, .suff {
      font-size: 3vw;
      font-size: clamp(1em, 3vw, 4em);
      color: var(--color-text-widget-big-number);
    }
  }
  .bottom{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .label{
      border-bottom: 1px solid var(--color-border-widget-header-main);
      padding-bottom: 3px;
      margin-bottom: 5px;
      font-weight: 300;
      font-size: 1.3vw;
     font-size: clamp(0.6em, 1.3vw, 0.65em);

    }
    .value{
      text-transform: uppercase;
      color: @blue;
    }
  }
  &.single{
    .top{
      padding-top: 0px;
      .fdp{
        font-size: 6rem;
        line-height: 1.1;
      }
    }
  }
}

.device-widget.soil{
  display: flex;
  flex-direction: column;
}
@media (max-width: @tablet-max) {
  .soil-widget{
    .top{
      padding-bottom: @generalPad;
      .wrap{
        flex-direction: column;
        > div {
          
          &:last-child {
            padding-bottom: 0;
          }
          .fdp {
            .fdp-val{
              font-size: 6vw;
              font-size: clamp(2em, 6vw, 5.5em);
            }
            .suff {
              //font-size: 5vw;
              //font-size: clamp(0.9em, 5vw, 1.8em);
              font-size: 6vw;
              font-size: clamp(2em, 6vw, 5.5em);
            }
          }
        }
      }
    }
    .bottom {
      padding-top: @generalPad;
      padding-bottom: @generalPad;
      .label {
        padding-bottom: 4px;
        font-size: 1.3vw;
        font-size: clamp(0.7em, 1.3vw, 0.8em);
      }
    }
    &.single {
      .top {
        .fdp {
          font-size: 3vw;
          font-size: clamp(1.5em, 3vw, 4em);
        }
      }
    }
  }
}
@media (max-width: @phone-max) {
  .soil-widget{
    padding-top: @generalPad;
    .expanded-wrap{
      padding-top: @generalPad;
    }
    .top {
      .label {
        font-size: 0.8vw;
        font-size: clamp(0.9em, 0.8vw, 1em);
      }
      .wrap {
        >div {
          .fdp {
            .fdp-val, .suff {
              font-size: 13vw;
              font-size: clamp(1em, 13vw, 4em);
            }
          }
        }
      }   
    }
  }
}