@import '../../styles/mixins.less';

.user-where-to {
  .wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btn-primary{
      margin-bottom: 20px;
    }
    a{
      text-decoration: none;
    }
  }
  img{
    border-radius: 15px;
    margin-bottom: 25px;
    max-width: 100%;
  }
  .favstar{
    display: none;
  }
}
.app.where-to{
  display: block;
  min-height: 100vh;
  .user-nav,
  &:before{
    display: none;
  }
  .component-dashboard-nav{
    display: none;
  }
  .loggedout{
    display: block;
    background-color: transparent;
  }
}

@media (max-width: @phone-max) {
  .user-where-to{
    img{
      display: block;
      margin-left: 25px;
      margin-right: 25px;
      max-width: ~"calc(100vw - 50px)";
    }
  }
}
