@import '../../styles/mixins.less';

.device-widget.webcam{
  .full-bleed-widget;
  &.minimized{
    .timestamp{
      display: none;
    }
  }
  > a {
    text-decoration: none;
  }
}

@media(max-width: @phone-max){
  .device-widget.webcam{
    padding-bottom: 0;
    .device-webcam-widget{
      height: 100vw;
    }
  }
}