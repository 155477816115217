@import '../../styles/mixins.less';

.social-comments {
  font-size: 1.2rem;
  .comments{
    padding: 10px 10px 5px;
    border-bottom: 1px solid @bg;
    &.empty{
      display: none;
    }
    .comment{
      margin-bottom: 5px;
      line-height: 1.3;
      .username{
        font-weight: bold;
        display: inline-block;
        margin-right: 5px;
      }
      .body {
        a {
          display: block;
          .ellipsis
        }
      }
      a.delete{
        padding-left: 10px;
        display: none;
      }
      &:hover{
        a.delete{
          display: inline-block;
        }
      }
    }
    a{
      text-decoration: none;
    }
  }
  .add-comment{
    position: relative;
    padding: 5px 10px;
    .component-loader{
      padding: 10px 0 20px;
    }
    .component-protected-link{
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      z-index: 2;
      height: 40px;
    }
    .username-edit{
      padding-bottom: 5px;
      .label{
        font-weight: bold;
      }
      .user-username-edit {
        .form{
          margin-top: 5px;
          flex-wrap: wrap;
        }
        .message{
          width: 100%;
          margin: 5px 0px 0px;
        }
      }
    }
  }
  form{
    padding: 0;
    display: flex;
    align-items: center;
    .username{
      font-weight: bold;
      color: @text-dark;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: 2px;
    }
    .form-control{
      flex-grow: 1;
      box-shadow: none;
      border: none;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
