@import '../styles/mixins.less';

@w: 200px;
.component-dashboard-nav {
  width: @w;
  padding-left: @w;
  background-color: var(--color-bg-dashboard-nav-main);
  min-height: 100vh;
  .wrap{
    position: fixed;
    top: 0;
    left: 0;
    width: @w;
    min-height: 100vh;
    a.account{
      display: none;
    }
    .scroll-wrap{
      height: ~"calc(100vh - 200px)";
      overflow-y: auto;
    }
  }
  a.logout{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: block;
    font-weight: 300;
    padding: 5px 0;
    font-size: 0.9em;
    background-color: transparent;
    color: var(--brandColor);
    &:hover{
      color: @white;
      background-color: var(--brandColor);
    }
  }
  a.trial{
    text-decoration: none;
    display: inline-block;
    text-align: center;
    color: @text-dark;
    width: 100%;
    padding: 4px 0px;
    background-color: @green-alt;
  }
  a.nav{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      text-decoration: none;
      color: var(--color-text-main-lt);
      padding: 25px 0 25px 30px;
      border-left: 7px solid transparent;
      &.plus{
        // background-color: var(--color-bg-btn-primary);
        // color: @white;
        background: url(../assets/awn+btn-long.png) no-repeat left top;
        background-size: auto 100%;
        height: 80px;
        border-left: none;
        color: transparent;
        cursor: pointer;
      }
      
      &.adminsocial,
      &.admindata,
      &.adminalexa{
        padding: 10px 0px 10px 70px;
      }
      i{
        width: 30px;
        height: 22px;
        background: url(../assets/dashboard.svg) center bottom no-repeat;
        background-size: 100%;
        display: block;
        margin-right: 10px;
        &.devices{
          height: 29px;
          background-image: url(../assets/radio.svg);
        }
        &.awn-plus{
          height: 29px;
          background-image: url(../assets/awn+white.svg);
          background-size: 40px;
          background-position-y: -2px;
        }
        &.wxw{
          height: 35px !important;
          background-image: url(../assets/nav/wxw-gray.svg);
          background-position: 0px 0px;
        }
        &.fa-chart-line{
          background-image: url(../assets/graph-plus.svg);
          height: 42px !important;
          background-position-y: 6px;
        }

        &.kmsupport{
          height: 26px;
          background-image: url(../assets/community.svg);
          background-size: 100%;
          background-position: 0px 3px;
        }
        &.alerts{
          height: 29px;
          background-image: url(../assets/alarm.svg);
        }
        &.settings{
          height: 26px;
          background-image: url(../assets/settings.svg);
          background-size: 87%;
        }
        &.fb{
          height: 26px;
          background-image: url(../assets/community.svg);
          background-size: 100%;
          background-position: 0px 3px;
        }
        &.admin{
          height: 26px;
          background-image: url(../assets/admin.svg);
          background-size: 100%;
          background-position: -3px 3px;
        }
        &.glyphicon{
          background-image: none;
          font-size: 25px;
          height: 25px;
        }
      }
      &:hover,
      &.active{
        border-left-color: var(--brandColor);
        background-color: var(--color-bg-active-dashboard-nav-main);
        color: var(--color-text-active-dashboard-nav-main);
        &.plus{
          background-color: var(--color-bg-hover-btn-primary);
          color: var(--color-btn-primary);
          border-color: var(--color-bg-hover-btn-primary);
          // i.awn-plus{
          //   background-image: url(../assets/awn+gray.svg);
          // }
        }
        // i{
        //   background-image: url(../assets/dashboard-on.svg);
        //   &.devices{
        //     background-image: url(../assets/radio-on.svg);
        //   }
        // }
      }
      > span{
        flex-grow: 1;
        > span{
          display: block;
        }
      }
  }
  .nav-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    a.map{
      margin-top: 25px;
      background-image: url(../assets/dashboard/icon-map.svg);
      background-position: 14px 10px;
      background-size: auto 47%;
      background-repeat: no-repeat;
      padding: 11px 20px 11px 41px;
    }
  }
}
.app.v3{
  .component-dashboard-nav{
    a.logout{
      background-color: @blue-darker;
      color: @white;
      padding: 10px 0;
      font-weight: normal;
      .btn-primary-active-hover-colors;
    }
  }
}
@media (max-width: @tablet-phone-max) {
  .app.dashboard{
    .component-dashboard-nav {
      @w: 500px;
      width: 0px;
      padding-left: 0px;
      z-index: 3 !important; 
      .wrap{
        left: -@w;
        transition: all 0.3s ease-out;
        width: @w;
        height: 100vh;
        background-color: var(--color-bg-main);
        a.account{
          background-image: none;
          background-color: var(--brandColor);
          width: 100%;
          display: flex;
          align-items: center;
          padding-left: 25px;
          font-size: 0.9em;
          .user-avatar{
            .square(60px);
            flex-grow: 0;
          }
          > div {
            h4{
              margin-bottom: 5px;
              font-size: 16px;
            }
            p{
              padding-bottom: 8px;
            }
            .btn.btn-primary{
              font-size: 13px;
            }
            flex-grow: 1;
            margin-left: 15px;
            color: @white;
          }
        }
        a.navbar-brand{
          display: none;
        }
        a.logout{
          left: auto;
          right: 0;
          bottom: auto;
          top: 0;
          width: auto;
          color: @white;
          font-size: 1.1em;
          background-color: var(--brandColor);
          padding: 30px 15px 15px 0;
        }
        .scroll-wrap{
          height: 100vh;
        }
      }
      a.nav{
        padding: 20px;
        > span{
          font-size: 1.2em;
          line-height: 1.1;
          display: block;
          padding-bottom: 7px;
          > span{
            display: inline;
          }
        }
        i{
          width: 40px;
          height: 29px;
          margin-right: 20px;
          &.settings,
          &.alerts,
          &.fa-chart-line,
          &.admin,
          &.devices{
            height: 43px;
          }
          &.fa-chart-line{
            background-size: 88%;
          }
        }
      }
      a.nav:hover,
      a.nav.active{
        border-left-color: transparent;
      }
    }
    
  }
  .app.open.dashboard{
    .component-dashboard-nav{
      .wrap {
        left: 0;
      }
    }
    
  }
}
@media (max-width: @phone-max) {
  .app.dashboard{
    .component-dashboard-nav {
      .wrap{
        a.account{
          padding-left: 0px;
        }
        a.logout{
          text-transform: lowercase;
          font-size: 0.9em;
          i{
            display: none;
          }
        }
        a.plus{
          background-position-x: -28px;
        }
      }
    }
  }
}
