@import '../styles/mixins.less';

.component-site-alerts {
  .device-alert-banner{
    margin: 0;
    a.dismiss{
      text-decoration: none;
      font-size: 1em;
      margin-left: 15px;
    }
  }
}
