@import '../../styles/mixins.less';

.black-btn{
  border-radius: 3px;
  color: @white;
  z-index: 2;
  font-size: 13px;
  display: block;
  background-color: #272727;
  padding: 1px 9px;
}
.component-sitewide-modals.create-post{
  .ReactModal__Content{
    padding: 0 !important;
    > .close{
      background-image: var(--bg-image-close);
      top: 0px;
      margin-bottom: 0;
      height: 45px;
      right: 10px;
    }
  }
}
.social-create-post {
  .success{
    font-size: 1.2em;
    background: url(../../assets/common/check-green.svg) no-repeat center 25px rgba(255, 255, 255, 0.9);
    background-size: 100px;
    padding: 150px 15px 0;
    text-align: center;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btn{
      margin: 20px;
    }
  }
  &.success{
    .success{
      display: flex;
    }
    .rect-tabs,
    .tab-content,
    .create-post{
      display: none !important;
    }
  }
  &.widget,
  &.image{
    .component-image-upload-link{
      display: none;
    }
  }
  &.alert{
    padding: 0;
    font-size: 1em;
    border: none;
    margin: 0;
    font-weight: normal;
  }
  &.widget,
  &.image,
  &.remote{
    .create-forecast-link{
      display: none;
    }
    .location{
      padding-top: @generalPad;
      display: flex;
      .component-location{
        > .top{
          margin-top: 0;
          margin-bottom: 0;
          padding-left: 10px;
          .autocomplete-wrap,
          > span{
            display: none;
          }
        }
        > p {
          display: none;
        }
        .component-find-location-btn{
          padding: 5px 10px; 
          font-size: 13px;
        }
      }
    }
    &.admin{
      .location{
        .component-location > p {
          display: block;
        }
      }
    }
  }
  &.multi-step{
    display: flex;
    flex-direction: column;
    width: 100%;
    &.alert{
      .text-wrap{
        padding-left: 35px;
        background: url(../../assets/common/alert.svg) @generalPad @generalPad no-repeat;
        background-size: 20px;
      }
    }
    &.widget{
      .is-alert{
        display: none;
      }
    }
    > .top{
      display: none;
      .btn{
        margin-right: 15px;
        padding-left: 25px;
        padding-right: 25px;
        margin-top: env(safe-area-inset-top, 0);
      }
    }
    .rect-tabs{
      a {
        text-decoration: none;
        flex: 1;
        background-color: var(--color-bg-page-container-main);
        color: var(--color-text-main-lt);
        font-weight: bold;
        &.active{
          color: var(--color-bg-btn-primary);
          background-color: var(--color-bg-widget-main);
        }
      }
    }
    textarea,
    .img-wrap,
    .img-wrap .img-contain,
    .tab-content{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    .text-wrap{
      height: 150px;
      display: flex;
      flex-direction: column;
      .chars-left{
        display: none;
      }
      &.not-empty{
        .chars-left{
          display: block;
        }
      }
    }
    .text-wrap.not-empty,
    .tab-content{
      .chars-left{
        top: auto;
        left: 15px;
        bottom: 15px;
      }
    }
    .tab-content{
      padding-bottom: @generalPad;
    }
    .is-alert{
      padding: @generalPad;
      justify-content: flex-start;
      height: 56px;
      input{
        margin-right: 10px;
      }
      label{
        text-transform: none;
        color: var(--color-text-main-lt);
        font-weight: normal;
        font-size: 1.3rem;
      }
      &.type-alert{
        label{
          color: var(--color-text-main);
        }
      }
    }
    .img-wrap,
    .video-container{
      position: relative;
      padding: 15px;
      .close{
        right: 15px;
        top: 15px;
        left: auto;
        background: url(../../assets/x-white.svg) center center #272727 no-repeat;
        border-radius: 3px;
        width: 25px;
      }
    }
    .img-wrap{
      height: 300px;
      .component-image-upload-link{
        position: absolute;
        top: 14px;
        left: 15px;
        .black-btn;
      }
    }
    .social-create-forecast{
      padding: 0 @generalPad @generalPad;
      .how-many .day-options .option{
        padding-left: 0;
        padding-right: 0;
      }
      h2,
      .text-center{
        display: none;
      }
    }
    textarea{
      border: none;
      height: auto;
      padding: 15px;
      &.filled{
        font-size: 17px;;
        font-weight: 500;
      }
    }
    .component-image-upload-link{
      text-decoration: none;
      color: var(--color-text-main);
      padding: 0px 10px;
      border-bottom: solid 1px var(--color-border-widget-header-main);
      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        text-transform: uppercase;
        font-size: 0.95em;
        .camera-btn{
          margin: 0px;
          background-color: @white;
        }
      }
    }
    .search-locations{
      display: none;
    }
    &.step-2{
      .text-wrap{
        height: auto;
        .textarea{
          position: relative;
          text-align: center;
          padding: 40px 30px 30px;
          font-size: 17px;;
          font-weight: 500;
          .edit{
            position: absolute;
            top: 14px;
            left: 10px;
            .black-btn;
          }
        }
      }
      .device-every-widget .close,
      .rect-tabs,
      .img-wrap a,
      .text-wrap .chars-left,
      .text-wrap.empty textarea,
      .is-alert{
        display: none;
      }
      .text-wrap.empty{
        height: 60px;
      }
      .img-wrap{
        height: 150px;
        flex-grow: 0;
      }
      .expires,
      .component-quick-location{
        padding: @generalPad;
        border-top: solid 1px var(--color-border-widget-header-main);
        a.selected{
          background: url(../../assets/right-arrow.svg) right 12px no-repeat;
        }
        .autocomplete-wrap input{
          width: 100%;
        }
        &.fake{
          b{
            display: block;
          }
        }
      }
      .expires{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px var(--color-border-widget-header-main);
        label{
          text-transform: none;
          flex-grow: 1;
          font-size: 14px;
        }
        select.form-control{
          width: auto;
        }
      }
    }
    .bottom-btn{
      padding: @generalPad @generalPad 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn{
        padding: 7px 70px;
        text-transform: uppercase;
        font-size: 1.2em;
      }
    }
  } // END multi-step // //
  .component-loader.image-loading{
    padding-top: 10px;
  }
  &.create-forecast{
    > .bottom,
    > .btn-wrap{
      display: none;
    }
  }
  h3{
    color: @blue;
    text-align: center;
  }
  .beta:before{
    bottom: 3px;
  }
  .top{
    // text-align: center;
    // margin: 20px 0 10px;
    // font-size: 1.1em;
    // font-weight: 300;
  }
  .text-wrap{
    position: relative;
    .component-image-upload-link{
      position: absolute;
      bottom: 0px;
      right: 3px;
      width: 30px;
      height: 30px;
      background: url(../../assets/common/camera.svg) center center no-repeat;
      filter: invert(100%) opacity(0.5);
      background-size: 60%;
    }
    .chars-left{
      position: absolute;
      left: 9px;
      top: 106px;
      font-size: 0.9em;
      color: var(--color-text-main-lt);
      background-color: transparent;
      &.error{  
        color: @color-alert;
      }
    }
    .img{
      width: 100%;
      height: 200px;
      margin-top: 10px;
    }
    .close{
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 40px;
      height: 40px;
      background: url(../../assets/x-black.svg) center center no-repeat;
      background-size: 45%;
      margin-bottom: 0;
    }
  }
  .device-every-widget {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    margin: 0px 30px 30px;
  }
  .rain-widget {
    .outer-wrap {
      .rain-wrap {
        .wrap {
          padding-bottom: 0;
          .label {
            font-weight: 300;
          }
          .fdp {
            color: @blue;
            font-size: 1.2em;
          }
        }
        .rain-tube {
          .bg {
            height: 120px;
          }
        }
      }
    }
  }
  .wind-widget {
    .compass {
      margin-top: 0;
      margin-bottom: 0;
    }
    .corner-wrap {
      font-size: 16px;
    }
    .rel {
      padding-bottom: 0;
    }
    .val {
      font-size: 20px;
      .suff {
        font-weight: 300;
      }
    }
    .expanded-wrap {
      margin-top: 0;
      .avg {
        color: @blue;
        font-size: 1.2em;
        margin-top: 0;
        margin-bottom: 0;
        .flex {
          justify-content: space-around;
        }
      }
    }
  }
  .device-temp-widget {
    padding: 0;
    .body {
      display: flex;
      justify-content: center;
      .top {
        margin: 0;
        .fdp-val, .suff {
          font-size: 8vw;
          font-size: clamp(3em, 7vw, 4em);
          font-weight: normal;
        }
      }
      .bottom {
        padding: 0;
        margin: 0;
        >div {
          border-right: 1px solid #cdd4da;
          padding: 0px 15px;
          text-align: left;
          margin-top: 0;
          &:last-of-type {
            border-right: none;
          }
          .fdp .fdp-val, .fdp .suff {
            font-size: 2rem;
          }
        }
        .label {
          font-size: initial;
          font-weight: 300;
          font-size: 1.35rem;
        }
        .val {
          color: @blue;
          font-size: 2rem;
          @media (max-width: @phone-max) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  @w: 200px;
  .device-air-2-widget.air, .device-air-2-widget.airin {
    .fill-column;
    height: 220px !important;
    .gauge-wrap{
      position: relative;
      flex: 1.5;
      overflow: hidden;
      .arrow,
      .gauge{
        position: absolute;
        z-index: 1;
        background: url(../../assets/widgets/aqi-gauge.svg) center top no-repeat;
        background-size: 80% auto;
        content: '';
        top: 20px;
        left: 15%;
        width: 72%;
        padding-top: 220px;
      }
      .gauge:before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: var(--color-bg-widget-main);
        padding-top: @w * 0.552;
        z-index: 2;
      }
      .arrow{
        z-index: 4;
        background: url(../../assets/widgets/aqi-gauge-arrow-main.svg) center top no-repeat;
        background-size: 78% auto;
        transition: transform 4000ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
        transform: rotate(0deg);
        &.alt{
          z-index: 5;
          background-image: url(../../assets/widgets/aqi-gauge-arrow-alt.svg);
        }
      }

      .values{
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 4;
        text-align: center;
        color: var(--color-text-widget-big-number);
        padding-bottom: @w * 0.05;
        .main{
          font-weight: bold;
          font-size: 3.6rem;
          line-height: 1.3;
        }
        .units{
          font-size: 1.2rem;
        }
      }
    }

    .bottom {
      flex: 1;
      display: block;
      margin-top: 0;
      text-align: center;
      .label {
        font-size: 2rem;
      }
    }
    .corners{
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 15px 15px 0 15px;
      color: var(--color-text-main);
      font-size: 1.5rem;
      font-weight: 300;
      margin-top: 15px;
      .fdp{
        color: var(--color-text-widget-main);
        font-size: 2rem;
        font-weight: normal;
        display: inline-block;
        padding-left: 5px;
      }
      .suff{
        display: none;
      }
    }
    
  }
  .device-air-2-widget.aqin {
    .fill-column;
    height: 220px !important;
    .gauge-wrap{
      position: relative;
      flex: 1.5;
      overflow: hidden;
      .arrow,
      .gauge{
        position: absolute;
        z-index: 1;
        background: url(../../assets/widgets/aqi-gauge.svg) center top no-repeat;
        background-size: 80% auto;
        content: '';
        top: 20px;
        left: 15%;
        width: 72%;
        padding-top: 220px;
      }
      .gauge:before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: var(--color-bg-widget-main);
        padding-top: @w * 0.552;
        z-index: 2;
      }
      .arrow{
        z-index: 4;
        background: url(../../assets/widgets/aqi-gauge-arrow-main.svg) center top no-repeat;
        background-size: 78% auto;
        transition: transform 4000ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
        transform: rotate(0deg);
        &.alt{
          display: none;
        }
        .units{
          font-size: 1.2rem;
        }
      }
    }

    .bottom {
      flex: 1;
      display: block;
      margin-top: 0;
      text-align: center;
      .label {
        font-size: 2rem;
      }
    }
    .corners{
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 15px 15px 0 15px;
      color: var(--color-text-main);
      font-size: 1.5rem;
      font-weight: 300;
      margin-top: 15px;
      .fdp{
        color: var(--color-text-widget-main);
        font-size: 2rem;
        font-weight: normal;
        display: inline-block;
        padding-left: 5px;
      }
      .suff{
        display: none;
      }
    }
    
  }
  .device-air-2-widget.aqin {
    .fill-column;
    height: 220px !important;
    .gauge-wrap{
      position: relative;
      flex: 1.5;
      overflow: hidden;
      .arrow,
      .gauge{
        position: absolute;
        z-index: 1;
        background: url(../../assets/widgets/aqi-gauge.svg) center top no-repeat;
        background-size: 80% auto;
        content: '';
        top: 20px;
        left: 15%;
        width: 72%;
        padding-top: 220px;
      }
      .gauge:before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: var(--color-bg-widget-main);
        padding-top: @w * 0.552;
        z-index: 2;
      }
      .arrow{
        z-index: 4;
        background: url(../../assets/widgets/aqi-gauge-arrow-main.svg) center top no-repeat;
        background-size: 78% auto;
        transition: transform 4000ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
        transform: rotate(0deg);
        &.alt{
          display: none;
        }
      }

      .values{
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 4;
        text-align: center;
        color: var(--color-text-widget-big-number);
        padding-bottom: @w * 0.18;
        .main{
          font-weight: bold;
          font-size: 3.6rem;
          line-height: 1.3;
        }
        .units{
          font-size: 1.2rem;
        }
      }
    }
    .bottom { 
      justify-content: center;
      margin-bottom: 15px;
      margin-top: 0;
      .extended {
        display: none;
      }
      .label {
        font-weight: 500;
        font-size: 2.5rem;
      }
    }
  }
  .device-co-2-widget {
    .fill-column;
    .value {
      color: var(--color-text-main);
      font-size: 1.8em;
      align-self: center;
      flex: 1;
      margin-top: 0.5em;
      .fdp-val {
        font-size: 2em;
        font-weight: bold;
        margin: 0 8px;
        color: var(--color-text-widget-big-number) !important;
      }
      .suff {
        font-weight: 300;
        color: #959595;
        font-size: 0.6em;
      }
    }
    .label{
      color: #27AE60;
      font-weight: 500;
      font-size: 1.5em;
      align-self: center;
      &.unhealthy-for-sensitive-groups,
      &.unhealthy{
        color: #F7D66B;
      }
      &.very-unhealthy,
      &.hazardous{
        color: #FD5C1A;
      }
    }
    .gauge{
      @h: 20px;
      height: @h;
      background: url(../../assets/widgets/co2-gauge.svg) center center no-repeat;
      background-size: 100% auto;
      width: 90%;
      border-radius: @h / 2;
      position: relative;
      margin: 1.2em 0;
      align-self: center;
      .dot{
        @dotH: @h * 2.2;
        height: @dotH;
        width: @dotH;
        position: absolute;
        top: -((@dotH - @h) / 2);
        left: -((@dotH - @h) / 2);
        z-index: 2;
        background: url(../../assets/widgets/co2-dot.svg) center center no-repeat;
        background-size: 100%;
      }
    }
    .bottom {
      .fill-column;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      flex: 1;
      padding: 5px 25px;
      color: var(--color-text-main);
      font-weight: 300;
      span {
        color: @blue;
        font-weight: normal;
        margin-left: 2px;
        font-size: 1.8rem;
      }

      .values{
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 4;
        text-align: center;
        color: var(--color-text-widget-big-number);
        padding-bottom: @w * 0.18;
        .main{
          font-weight: bold;
          font-size: 3.6rem;
          line-height: 1.3;
        }
        .units{
          font-size: 1.2rem;
        }
      }
    }
    .bottom { 
      justify-content: center;
      margin-bottom: 15px;
      margin-top: 0;
      .extended {
        display: none;
      }
      .label {
        font-weight: 500;
        font-size: 2.5rem;
      }
    }
  }
  .device-co-2-widget {
    .fill-column;
    .value {
      color: var(--color-text-main);
      font-size: 1.8em;
      align-self: center;
      flex: 1;
      margin-top: 0.5em;
      .fdp-val {
        font-size: 2em;
        font-weight: bold;
        margin: 0 8px;
        color: var(--color-text-widget-big-number) !important;
      }
      .suff {
        font-weight: 300;
        color: #959595;
        font-size: 0.6em;
      }
    }
    .label{
      color: #27AE60;
      font-weight: 500;
      font-size: 1.5em;
      align-self: center;
      &.unhealthy-for-sensitive-groups,
      &.unhealthy{
        color: #F7D66B;
      }
      &.very-unhealthy,
      &.hazardous{
        color: #FD5C1A;
      }
    }
    .gauge{
      @h: 20px;
      height: @h;
      background: url(../../assets/widgets/co2-gauge.svg) center center no-repeat;
      background-size: 100% auto;
      width: 90%;
      border-radius: @h / 2;
      position: relative;
      margin: 1.2em 0;
      align-self: center;
      .dot{
        @dotH: @h * 2.2;
        height: @dotH;
        width: @dotH;
        position: absolute;
        top: -((@dotH - @h) / 2);
        left: -((@dotH - @h) / 2);
        z-index: 2;
        background: url(../../assets/widgets/co2-dot.svg) center center no-repeat;
        background-size: 100%;
      }
    }
    .bottom {
      .fill-column;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      flex: 1;
      padding: 5px 25px;
      color: var(--color-text-main);
      font-weight: 300;
      span {
        color: @blue;
        font-weight: normal;
        margin-left: 2px;
        font-size: 1.8rem;
      }
    }
  }
  .device-air-widget {
    overflow: hidden;
    .wrap {
      .meter {
        flex: auto;
        height: 180px;
        background-size: 100% 200%;
        margin-top: 0;
        .fdp {
          font-size: 2.5em;
          padding-left: 2%;
        }
      }
      .bottom {
        padding-bottom: 0;
        margin-top: 0;
        .stats {
          font-size: 1.2em;
        }
      }
    }
  }
  .uv-widget {
    .top {
      .wrap {
        .uv-data {
          font-size: 4em;
          font-weight: normal;
        }
        .level {
          font-size: 1em;
        }
      }
    }
    .bottom {
      margin-top: 0;
    }
  }
  .device-lightning-widget {
    .top {
      display: flex;
      justify-content: center;
      color: @text-dark;
      margin-top: 0;
      .val{
        color: @text-dark;
        line-height: 0.9;
        padding-right: 2px;
        font-size: 5em;
        font-weight: normal;
      }
      .labels{
        .column;
        justify-content: flex-end;
        font-size: 1.2em;
        font-weight: normal;
        .icon{
          height: 22px;
          width: 19px;
          background: url(../../assets/lightning-black.svg) center center no-repeat;
          background-size: 100%;
        }
      }
    }
    .bottom {
      justify-content: center;
      >div {
        padding: 0 8px;
        text-align: center;
        .label {
          font-weight: 300;
        }
        .val{
          padding: 5px 0 0;
          .icon{
            display: inline-block;
            padding-right: 12px;
            background: url(../../assets/lightning-blue.svg) center right no-repeat;
            background-size: 10px;
          }
        }
      }
      > div:nth-of-type(2) {
        border-left: 1px solid #cdd4da;
        border-right: 1px solid #cdd4da;
      }
      .val {
        color: @blue;
        font-size: 1.3em;
      }
    }
  }
  .solar-widget {
    text-align: center;
    padding: 1rem;
    .solarmax {
      .fdp-val {
        font-size: 4.4em;
      }
      .suff {
        font-size: 2em;
      }
    }
    .from {
      padding: 0;
      .label {
        font-size: 1.2em;
        font-weight: 300;
        padding: 0.5rem;
      }
      .solarradiation {
        color: @blue;
        .fdp-val {
          font-size: 2rem;
        }
      }
    }
  }
  .indoor-widget {
    padding: 1rem 0;
    &.co2 {
      .bottom {
        padding-bottom: 1.75rem;
      }
    }
    .top {
      padding-bottom: 1rem;
      .wrap {
        flex-direction: row;
        justify-content: center;
        
        .label {
          font-size: 1.5rem;
        }
        .fdp {
          .fdp-val, .suff {
            font-weight: normal;
            @media (max-width: @tablet-max) {
              font-size: 4rem;
            }
          }
        }
        > div {
          flex-grow: unset;
          margin: 0 1.5rem;
          .fdp {
            @media (max-width: @tablet-max) {
              font-size: 4rem;
            }
          }
        }
      }
    }
    .bottom {
      justify-content: center;
      padding-top: 0;
      margin-top: 0;
      > div {
        margin-bottom: 0;
        .label {
          font-size: 1em;
          font-weight: 300;
        }
      }
      .val {
        color: @blue;
        font-size: 1.4em;
        @media (max-width: @phone-max) {
          font-size: 1.6rem;
        }
      }
    }
    .co2-progress {
      position: relative;
      bottom: 0;
      .label {
        font-size: 1em;
        font-weight: 300;
      }
    }
  }
  .soil-widget {
    .top {
      .wrap {
        .label {
          font-size: initial;
        }
      }
      .fdp {
        .fdp-val, .suff {
          font-weight: normal;
        }
      }
    }
    .bottom {
      padding-top: 0;
      margin-top: 0;
      .value {
        font-size: 1.3em;
        color: @blue;
      }
      .label {
        font-size: 0.95em;
        font-weight: 300;
      }
    }
  }
  .device-forecast-widget {
    text-align: center;
    padding-bottom: 0;
    .close {
      display: none;
    }
    .today {
      padding-top: 10px;
      .hl {
        .fdp {
          padding-right: 15px;
        }
      }
      .title {
        font-size: 1em;
      }
      .title-precip {
        font-size: 3em;
      }
    }
    .hourly-wrap {
      font-size: 1.3em;
    }
    .other-days {
      margin-top: 20px;
    }
  }
  .device-sun-moon-widget {
    .wrap{
      @wrapMargin: 10px;
      @wrapBottomMargin: 20px;
      position: relative;
      flex-grow: 1;
      margin: @wrapMargin @wrapMargin @wrapBottomMargin @wrapMargin;
      .recharts-responsive-container{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        background: url(../../assets/sun-moon/clock-bkg-small-ampm.svg) center center no-repeat;
        background-size: 100% 100%;
      }
      &.hour24{
        .recharts-responsive-container{
          background-image: url(../../assets/sun-moon/clock-bkg-small-24hr.svg);
        }
      }
      .info{
        position: absolute;
        bottom: -@wrapBottomMargin;
        left: -@wrapMargin;
        width: calc(100% ~"+" (2 * @wrapMargin));
        z-index: 9;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: @blue;
        padding: 0 10px 10px;
        .label{
          font-weight: 300;
          color: #313236;
        }
        
      }
      .moon {
        color: @blue;
        .label {
          font-weight: 300;
          color: #313236;
        }
      }
    }
  }
  a.create-forecast-link{
    display: block;
    text-align: center;
    font-weight: 300;
    text-decoration: none;
    padding: @bigPad 0;
    font-size: 1.2em;
  }
  .bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: @generalPad;
    label{
      text-transform: capitalize;
      font-weight: normal;
      flex-grow: 1;
      margin-left: auto;
      text-align: right;
      margin-right: 10px;
      margin-bottom: 0;
      font-size: 14px;
    }
    .alert-input{
      background: url(../../assets/common/alert.svg) left 5px no-repeat;
      padding-left: 20px;
      background-size: 15px auto;
      color: @red;
      input{
        margin-left: 8px;
        position: relative;
        top: 2px;
      }
    }
    .expires{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      select{
        text-transform: capitalize;
        width: auto;
        font-weight: 300;
        font-size: 0.9em;
      }
      > span{
        display: block;
        font-weight: 300;
        font-size: 0.9em;
        padding: 7px 0px;
      }
    }
  }
  .share-wrap{
    position: relative;
    margin-top: 15px;
    h4{
      padding-top: 15px;
      border-top: 1px solid var(--color-border-widget-header-main);
      text-align: center;
      font-size: 1em;
      font-weight: bold;
      margin: 0px 0 15px;
    }
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .twitter,
    .email,
    .fb{
      width: 40px;
      height: 20px;
      background: url(../../assets/common/share/fb-black.svg) center center no-repeat;
      background-size: auto 100%;
    }
    .twitter{
      background-image: url(../../assets/common/share/twitter-black.svg);
    }
    .email{
      background-image: url(../../assets/common/share/email-black.svg);
    }
    .component-share a.share{
      background: none;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 80px;
      z-index: 2;
    }
  }
  textarea{
    border-radius: 2px;
    border-color: var(--color-text-main-lt);
    width: 100%;
    height: 130px;
    background-color: var(--color-bg-create-post-textarea-main);
  }
  .btn-wrap{
    margin-top: 10px;
    text-align: center;
  }

}


@media (max-width: @phone-max) {
  .component-sitewide-modals.create-post{
    .ReactModal__Content{
      > .close{
        left: 15px;
        right: auto;
        top: 30px;
        top: calc(~"env(safe-area-inset-top, 0) + 30px");
      }
    }
  }
  .social-create-post.multi-step {
    height: 100vh;
    > .top{
      height: 70px;
      height: calc(~"env(safe-area-inset-top, 0) + 70px");
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-shrink: 0;
    }
    .img-wrap{
      height: auto;
    }
    .tab-content{
      padding-bottom: 0;
    }
    .component-image-upload-link{
      margin: auto 0 env(safe-area-inset-bottom, 0);
      padding: 5px 0;
      border: none;
      border-top: solid 1px var(--color-border-widget-header-main);
    }
    .expires select.form-control{
      border: none;
      box-shadow: none;
      background: url(../../assets/right-arrow.svg) right center no-repeat;
    }
    .bottom-btn{
      display: none;
    }
    .share-wrap{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 15px;
      a.share{
        top: auto;
        bottom: 0px;
      }
    }
  }

}
