@import '../../styles/mixins.less';

@notificationsRight: 15px;
.social-notifications {
  position: fixed;
  // padding: env(safe-area-inset-top, 0) 0 0 0;
  right: @notificationsRight;
  top: 23px;
  margin-left: 10px;
  z-index: 402;
  .sidebar-transition();
  .item-font{
    font-size: 13px;
    font-weight: bold;
    color: @text-dark;
  }
  .item{
    padding: 7px 10px;
    margin-bottom: @generalPad;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    margin-left: 3px;
    margin-right: 4px;
    display: flex;
    text-decoration: none;
  }
  .notification{
    .item;
    .item-font;
    justify-content: space-between;
    align-items: center;
  }
  .component-from-now{
    margin-left: 5px;
    min-width: 50px;
    text-align: right;
    line-height: 1;
    color: @text-dark;
    .long{
      font-size: 12px;
      font-weight: normal;
    }
  }
  a.item{
    &:hover{
      // .lift-shadow;
    }
  }
  .notifications{
    width: 30px;
    height: 30px;
    background: url(../../assets/common/bell.svg) center center no-repeat transparent;
    background-size: auto 100%;
    display: block;
    position: relative;
    background-color: transparent !important;
    border: none !important;
  }
  &.active{
    .notifications{
      &:before{
        content: '';
        background-color: @red;
        display: block;
        border-radius: 100%;
        width: 14px;
        height: 14px;
        border: @white 2px solid;
        position: absolute;
        top: 5px;
        right: 0px;
      }
    }
  }
  .notifications-wrap{
    display: none;
    .top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px @generalPad 15px;
      border-bottom: 1px solid var(--color-border-alt-lt);
      .settings{
        width: 20px;
        height: 18px;
        display: block;
        background: url(../../assets/settings.svg) center center no-repeat;
        background-size: auto 100%;
      }
      .pills{
        .btn{
          font-size: 0.75em;
          font-weight: 500;
          text-transform: uppercase;
          border: 1px solid var(--color-border-alt-lt);
          padding: 3px 12px;
          margin-right: 5px;
          &.active{
            box-shadow: none;
            background-color: @blue;
            color: @white;
          }
        }
      }
      .right{
        display: flex;
        align-items: center;
        .component-loader{
          margin-right: 10px;
        }
      }
    }
  }
  .notifications-list-wrap{
    padding: 0 @generalPad;
  }
  .notifications-list{
    max-height: 400px;
    overflow: auto;
    padding-top: @generalPad;
    &.tab-notifications{
      .alerts-tab{
        display: none;
      }
    }
    &.tab-alerts{
      .notifications-tab{
        display: none;
      }
    }
    .block{
      box-shadow: none;
      padding: 0px;
      margin: 0px;
      h3{
        display: none;
      }
      .alert-instances{
        .more,
        .time,
        .date{
          display: none;
        }
        .instance{
          padding-left: 0;
          justify-content: space-between;
          align-items: center;
          .item;
          &:after{
            display: none;
          }
        }
        .info{
          padding: 0px;
          margin: 0px;
          line-height: 1.3;
          .item-font;
          b{
            .item-font;
          }
          .device{
            display: inline-block;
            margin-right: 7px;
            padding-left: 25px;
            background: url(../../assets/common/alert.svg) center center no-repeat;
            background-size: auto 15px;
            background-position: 0px center;
            color: @text-dark;
            &:after{
              content: ':';
            }
          }
          > p{
            display: none;
          }
        }
      }
    }
  }
}
.app.notifications-open {
  .social-notifications {
    .notifications-wrap{
      display: block;
      position: absolute;
      top: 50px;
      right: 0px;
      background-color: var(--color-bg-page-block-main);
      padding: 10px 0px;
      .general-shadow;
      width: 400px;
      border-radius: 8px;
    }
  }
}
.app.home-page.sidebar{
  .social-notifications{
    right: @notificationsRight + @sidebar-width;
  }
}
.app.home-page.dashboard .social-notifications {
  top: 23px;
}
.dashboard .social-notifications{
  top: 23px;
  right: 25px;
}
.dashboard.dashboard-page:not(.scroll-top) .social-notifications{
  top: 10px;
}
.app.embed .social-notifications{
  display: none;
}

@media (max-width: @tablet-phone-max) {
  .dashboard .social-notifications{
    top: 25px;
  }
  .app.forecast-modal .social-notifications{
    display: none;
  }
}

@media (max-width: @phone-max) {
  .app {
    .social-notifications {
      .sidebar-transition();
      left: 44.5vw;
      width: 12vw;
      top: auto !important;
      right: auto !important;
      bottom: 0px;
      z-index: 502;
      position: fixed;
      margin-left: 0;
      height: @mobile-nav-height;
      height: calc(~"env(safe-area-inset-bottom, 0) + " @mobile-nav-height);
      .notifications{
        background: transparent;
        width: 100%;
        height: @mobile-nav-height;
        height: calc(~"env(safe-area-inset-bottom, 0) + " @mobile-nav-height);
        &:before{
          height: 12px;
          right: auto;
          top: 26px;
          left: 44%;
          width: 12px;
        }
      }
    } 
    &.plus{
      .social-notifications {
        left: 50vw;
        width: 16.6vw;
      }
    }
    &.notifications-open .social-notifications{
      z-index: 499;
      .notifications{
        display: none;
      }
      .notifications-wrap{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100vh;
        padding-top: 20px;
        padding-top: calc(~"env(safe-area-inset-top, 0) + 20px");
        .top{
          .pills .btn{
            font-size: 0.9em;
            padding: 4px 15px;
          }
          .settings{
            width: 23px;
            height: 21px;
          }
        }
      }
      .notifications-list{
        max-height: ~"calc(100vh - 68px)";
        .block .alert-instances .instance{
          margin-top: 0;
        }
      }
    }
  }
}
