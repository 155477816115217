@import '../../styles/mixins.less';

.user-admin {
  .banner-wrap{
    margin: @bigPad;
    margin-bottom: 0;
  }
  .top-bar{
    display: flex;
    margin-bottom: 15px;
    align-items: flex-end;
    > * {
      padding: 0 10px;
      margin-bottom: 0px;
    }
    .csv{
      margin-left: auto;
    }
  }
  header.main{
    .children{
      margin-left: auto;
      padding-right: 90px;
    }
  }
  .ifttt{
    margin-right: 5px;
  }
  .alexa{
    width: 18px;
    height: 18px;
    background-size: 90%;
    display: inline-block;
    margin-right: 5px;
    background-position-y: 3px;
  }
  .google-assistant{
    width: 20px;
    height: 20px;
    background-size: 100%;
    display: inline-block;
  }
  .whitelist{
    h3{
      width: 250px;
      a{
        float: right;
      }
    }
    textarea{
      border-radius: 5px;
      height: 200px;
      width: 250px;
    }
  }
  .alert-banner{
    input{
      display: block;
      width: 400px;
      margin-bottom: 10px;
    }
  }
}
