@import '../styles/mixins.less';

.component-location {
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    > span{
      margin: 0 20px;
    }
    .autocomplete-wrap{
      position: relative;
      flex-grow: 1;
      .x-gray{
        position: absolute;
        right: 14px;
        top: 11px;
        z-index: 3;
      }
    }
  }
  .autocomplete{
    width: 100%;
  }
  input[type="text"]{
    padding: 5px 15px 6px;
    .placeholder(@gray-amb);
  }
  .middle{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: @generalPad;
    .item{
      > span{
        font-size: 1.2em;
        display: inline-block;
        margin-right: 5px;
      }
      .units{
        display: flex;
        font-size: 1em;
        justify-content: flex-end;
        padding-top: 8px;
        > div{
          margin-left: 15px;
          .circle{
            margin-left: 6px;
          }
        }
      }
    }
  }
  p{
    padding-top: 10px;
  }
  .show-map{
    padding-top: 10px;
  }
  .map{
    font-size: 1.2em;
  }
  .bottom{
    padding-top: @generalPad;
    color: var(--color-text-main-md);
    a{
      text-decoration: none;
    }
  }
  .btns-wrap{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: @generalPad;
    a.skip{
      color: var(--color-text-main-md);
      text-decoration: none;
      display: inline-block;
      margin-right: 30px;
    }
  }
}

@media (max-width: @phone-max) {
  .component-location {
    .btn.btn-long{
      width: 100%;
    }
    .btns-wrap,
    .middle,
    .top{
      flex-direction: column;
      .autocomplete-wrap{
        width: 100%;
      }
      > span{
        margin-bottom: 10px;
      }
      > a,
      > div{
        margin-bottom: 10px;
      }
      .item{
        width: 100%;
        > span{
          margin-bottom: 5px;
        }
        input[type="text"] {
          width: 100%;
        }
      }
      a.skip{
        margin-right: 0px !important;
      }
    }

  }
  
}