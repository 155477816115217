@import '../../styles/mixins.less';

.recharts-x-axis{
  .recharts-cartesian-axis-tick{
    line{
      stroke: transparent;
    }
    text {
      fill: @text-dark;
    }
  }
}
.recharts-y-axis{
  .recharts-cartesian-axis-tick{
    line{
      stroke: transparent;
    }
    text {
      fill: @text-dark;
    }
    &:last-child,
    &:first-child{
      display: inline;
    }
  }
}
.common-graph-wrap{
  margin-top: 15px;
  .component-loader{
    margin-left: 40px;
    margin-top: @generalPad;
  }
  ::-webkit-scrollbar { 
    width: 0px;
    display: none; 
  }
  .recharts-legend-wrapper{
    top: -13px !important;
    color: var(--color-text-main);
    height: 40px;
    .recharts-default-legend{
      @media (max-width: @phone-max) {
        line-height: 1;
      }
      li {
        &:last-of-type{
          margin-right: 0 !important;
        }
      }
      .recharts-legend-item {
        @media (max-width: @phone-max) {
          margin-right: 5px !important;
        }
        .recharts-surface {
          margin-right: 1px !important;
          width: 10px;
          height: 10px;
        }
      }
    }
    .recharts-legend-item-text {
      font-size: 12px;
    }
  }
}
#temp_graph, #uv_graph {
  .recharts-legend-wrapper {
    @media (max-width: @phone-max) {
      top: -20px !important;
    }
  }
}