@import './variables.less';
@import './lesshat.less';

.sans{
  font-family: 'Ubuntu', sans-serif;
}
.monospace{
  font-family: 'Courier New', Courier, monospace;
  line-height: 0.5;
}
.ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wrap-text{
  white-space: pre-wrap;
  word-wrap: break-word;
}
.general-shadow {
  // box-shadow: 0 4px 4px rgba(0,0,0,0.25), 0 4px 7px rgba(0,0,0,0.22);
  box-shadow: 0 2px 4px 0 var(--color-shadow-main); 
  transition: box-shadow 0.2s cubic-bezier(.25,.8,.25,1);
}
.sidebar-transition(@type: all){
  transition: 0.2s @type ease-out;
}
.general-shadow-inset{
  box-shadow: inset 0px 2px 0px 0 rgba(0, 0, 0, 0.05); 
}
.lift-shadow{
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.blue{
  color: @blue;
}
.column{
  display: flex;
  flex-direction: column;
}
.items-center{
  display: flex;
  align-items: center;
}
.fill-column{
  flex-grow: 1;
  .column;
}
.light{
  //font-weight: 300;
  //do we know where this is being used :X it's what's making the font look thinner
}
.abs{
  position: absolute;
  top: 0;
  left: 0;
}
.blur-bg{
  background-color: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(6px);
  box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.35);
}
.btn.btn-circle,
.btn-circle{
    padding: 2px 12px;
    color: var(--color-text-main-lt);
    border-color: @gray-lighter;
    font-size: 1.3rem;
    &:hover{
      color: var(--brandColor);
    }
}
.btn-primary-active-hover-colors{
  &:hover{
    color: var(--color-btn-primary);
    background-color: var(--color-bg-hover-btn-primary);
    border-color: var(--color-bg-hover-btn-primary);
  }
  &:active{
    border-color: var(--color-bg-active-btn-primary);
    background-color: var(--color-bg-active-btn-primary);
  }
}
.btn.btn-primary,
.btn-primary,
.btn.btn-blue,
.btn-blue{
  background-color: var(--color-bg-btn-primary); 
  color: var(--color-btn-primary);
  border-color: var(--color-bg-btn-primary);
  border-radius: 25px;
  .btn-primary-active-hover-colors;
}
.btn-white{
  background-color:  var(--color-btn-primary);
  color: var(--color-bg-btn-primary); 
  border: 2px solid var(--color-bg-btn-primary);
  padding-top: 4px;
  padding-bottom: 4px;
  &:hover{
    background-color: var(--color-bg-btn-primary);
    color: var(--color-btn-primary);
  }
}
.app .btn.btn-bordered{
  background-color:  var(--color-btn-primary);
  color: var(--color-bg-btn-primary); 
  border: 1px solid @blue;
}
a.btn{
  text-decoration: none;
}
.app .btn.btn-long{
  padding: 8px 70px;
}
.no-graph-button{
  .buttons{
    padding-right: 3px;
    .graph{
      display: none;
    }
  }
}
i.awn-plus{
  display: inline-block;
  background: url(../assets/awn+blue.svg) center 1px no-repeat;
  height: 30px;
  width: 35px;
  margin: 0px 5px;
  background-size: auto 135%;
}
.btn-primary{
  i.awn-plus{
    background-image: url(../assets/awn+white.svg);
  }
}
.btn-map (@wh) {
  background: url(../assets/map/btn-air-quality.svg) center center no-repeat;
  width: @wh;
  height: @wh;
  display: block;
  background-size: 68%;
  .general-shadow;
  .btn-primary-active-hover-colors;
  color: transparent !important;
  border: 2px solid @white;
  border-radius: 100%;
  margin-top: 10px;
  background-color: var(--color-bg-map-layer-btn-main);
  &.active,
  &:hover{
    background-image: url(../assets/map/btn-air-quality-white.svg);
    background-color: var(--brandColor);
    border-color: @white;
  }
  &:hover{
    .lift-shadow;
  }
}
.small-flex-lines{
  padding: 25px 10px 0 10px;
  margin-bottom: 10px;
  .wrap{
    overflow: auto;
    .group{
      margin-bottom: 8px;
      > input.editable-title,
      h5{
        width: 100%;
        font-weight: 400;
        color: @text-dark;
        margin-bottom: 0px;
        margin-top: 5px;
        padding-bottom: 2px;
        font-size: 1.6rem;
        border-bottom: 1px solid var(--color-border-widget-header-main);
      }
      > input.editable-title{
        &:hover{
          border-bottom: 1px solid @text-dark;
        }
      }
      h5{
        padding-bottom: 5px;
      }
    }
  }
  .line{
    display: flex;
    justify-content: space-between;
    font-size: 0.85em;
    padding: 5px 0px 4px;
    &:nth-child(odd) {
      background-color: var(----color-text-main);
    }
    &:nth-child(even){
      background-color: var(--color-bg-table-widget-line-alt);
    }
    input.editable-title,
    .label{
      font-size: 0.85em;
      padding-left: 0px;
      flex-grow: 1;
    }
    input.editable-title{
      &:hover{
        border-bottom: 1px solid @gray-light-amb;
      }
    }
    .fdp{
      color: @text-dark;
      font-size: 0.85em;
      margin-left: 5px;
      text-align: left;
      width: auto;
      float: none;
      font-weight: 500;
    }
  }
}
&.weather-icon{
  height: 40px;
  width: 46px;
  background: url(../assets/forecast/sun.svg) center center no-repeat;
  background-size: auto 100%;
  &.cloudy{
    background-image: url(../assets/forecast/cloud.svg);
    background-size: auto 62%;
    background-position-x: 1px;
  }
  &.hail{
    background-image: url(../assets/forecast/hail.svg);
  }
  &.partly-cloudy-day{
    background-image: url(../assets/forecast/partly-cloudy.svg);
    background-position-x: 1px;
  }
  &.clear-night{
    background-image: url(../assets/forecast/moon.svg);
    background-size: auto 69%;
  }
  &.flurries {
    background-image: url(../assets/forecast/flurries.svg);
  }
  &.fog {
    background-image: url(../assets/forecast/fog.svg);
    background-size: 90% auto;
  }
  &.lightning {
    background-image: url(../assets/forecast/lightning.svg);
  }
  &.partly-cloudy-night {
    background-image: url(../assets/forecast/partly-cloudy-night.svg);
    background-size: auto 85%;
  }
  &.part-sun {
    background-image: url(../assets/forecast/partly-sunny.svg);
  }
  &.rain {
    background-image: url(../assets/forecast/rain.svg);
    background-size: auto 93%;
    background-position-y: 5px;
  }
  &.snow {
    background-image: url(../assets/forecast/snow.svg);
  }
  &.sleet {
    background-image: url(../assets/forecast/sleet.svg);
  }
  &.sprinkles {
    background-image: url(../assets/forecast/sprinkles.svg);
  }
  &.thunderstorm{
    background-image: url(../assets/forecast/tstorm.svg);
  }
  &.wind {
    background-image: url(../assets/forecast/wind.svg);
  }
}
.square(@s){
  width: @s;
  height: @s;
}
.round-edge-children(@br){
  &:first-child > a{
    border-bottom-left-radius: @br;
    border-top-left-radius: @br;
  }
  &:last-child > a{
    border-bottom-right-radius: @br;
    border-top-right-radius: @br;
  }
}
.close-x-blue{
  background: url(../assets/x-blue.svg) center center no-repeat;
  display: block;
  @w: 20px;
  width: @w;
  height: @w;
  background-size: 100%;
}
.general-modal{
  .ReactModal__Overlay{
    background-color: rgba(34, 34, 34, 0.75) !important;
  }
  .ReactModal__Content{
    font-family: 'Ubuntu', sans-serif;
    background-color: var(--color-bg-modal-main) !important;
    border: none !important;
    max-width: 100%;
    max-height: 90vh;
    color: var(--color-text-modal-main);
    .general-shadow;
    .close{
      .close-x-blue;
      position: sticky;
      z-index: 1;
      right: 0px;
      top: 0px;
    }
    h2{
      font-weight: 400;
      margin-top: 3px;
      border-bottom: 2px solid @gray-light-amb;
      padding-bottom: 10px;
      font-size: 18px;
      margin-bottom: 0px;
      text-align: center;
      em{
        font-weight: 500;
      }
    }
  }
  &.login-modal{
    .body{
      padding: 25px 0 10px;
      text-align: center;
      .btn{
        margin-bottom: 15px;
      }
    }
  }
}
.clean-device-chooser{
  max-width: 100%;
  .wrap{
    background-color: transparent;
    border: none;
  }
  .favstar,
  .dc__group-heading,
  .header-wrap,
  .dc__group:first-of-type{
    display: none !important;
  }
  &.sd{
    .open{
      > .search{
        input {
          border-color: transparent;
          .general-shadow();
        }
      }
    }
  }
  .open {
    > .search{
      background-color: transparent;
      border: none;
      padding: 0;
      ::placeholder{
        font-size: 22px;
        font-style: italic;
        color: #bbbbbb;
      }
      input{
        font-size: 24px;
        padding-top: 13px;
        padding-bottom: 11px;
        padding-left: 55px;
        border: none;
        border-radius: 35px;
        .general-shadow();
      }
      &:before{
        width: 20px;
        height: 20px;
        background-size: 18px;
        top: 21px;
        left: 18px;
      }
      .glyphicon.x-gray{
        width: 20px;
        height: 20px;
        background-size: 20px;
        top: 21px;
      }
      .component-loader{
        top: 26px;
      }
    }
    .device-select{
      .dc__menu{
        background-color: #fff;
        .general-shadow();
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
      .dc__group{
        border: none;
      }
    }
  }
  &.sd{
    .open {
      > .search{
        input{
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          border-bottom: none;
        }
      }
    }
  }

}
.minimized-forecast{
  padding: 10px;
  height: auto;
  margin-bottom: 0;
  justify-content: flex-start;
  .show-24{
    display: none;
  }
}
.default-input-range{
  .input-range__label{
    opacity: 0;
    transition: opacity 0.2 ease-out;
    font-weight: 500;
    color: @text-dark;
    font-size: 1.4rem;
  }
  &.dragging{
    .input-range__label.input-range__label--value{
      opacity: 1;
      top: 11px;
      z-index: 1;
    }
  }
  .input-range__track--active,
  .input-range__track{
    background-color: @white;
    height: 0.6rem;
  }
  .input-range__slider {
    height: 2rem;
    width: 2rem;
    margin-left: -1rem;
    margin-top: -1.25rem;
    background-color: @yellow;
    border-color: @white;
    border-width: 2px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      width: 3em;
      height: 3em;
      top: -1em;
      left: -1em;
    }
  }
}
.full-bleed-widget{
  display: flex;
  .graph,
  > header {
    display: none;
  }
  &.minimized{
    > header{
      display: block;
    }
  }
  > div:first-of-type,
  > div:first-of-type > a,
  > div:first-of-type > a > div,
  > div:first-of-type > div{
    display: flex;
    flex: 1;
  }
}
.rect-tabs{
  display: flex;
  a {
    display: block;
    padding: 20px 40px 20px;
    text-decoration: none;
    font-weight: bold;
    color: var(--color-text-main-lt);
    border-top: 5px solid transparent;
    text-align: center;
    &:hover{
      color: var(--brandColor);
    }
    &.active{
      background-color: @bg;
      color: var(--brandColor);
      border-color: var(--brandColor);
    }
  }
  &.inverse{
    a{
      background-color: @bg;
      &.active{
        background-color: @white;
      }
    }
  }
}

.header-rect-tabs{
  display: flex;
  align-items: normal;
  white-space: nowrap;
  overflow-x: auto;
  a {
    display: block;
    padding: 13px 18px 13px;
    text-decoration: none;
    font-weight: 500;
    color: var(--color-text-main-lt);
    border-top: 0;
    text-align: center;
    font-size: 16px;
    &:hover{
      color: var(--brandColor);
    }
    &.active{
      background-color: var(--color-bg-base);
      color: var(--brandColor);
      border-color: var(--brandColor);
    }
  }
  &.inverse{
    a{
      background-color: @bg;
      &.active{
        background-color: @white;
      }
    }
  }
}
.compass-create(@w){
    width: @w;
    height: @w;
    transform-origin: @w*0.50303 @w*0.521212;
}
.image-modal{
  &:before{
    content: '';
    position: absolute;
    top: 15px;
    top: calc(~"env(safe-area-inset-top, 0px) + 15px");
    right: 15px;
    background: url(../assets/x-white.svg) center center no-repeat;
    width: 20px;
    height: 20px;
    display: block;
    background-size: 100%;
    z-index: 10;
  }
  &:after{
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: url(../assets/logo.png) center center no-repeat;
    background-size: 88%;
    width: 200px;
    height: @headingHeight;
    display: block;
  }
  cursor: pointer;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  background-color: @black;
  .image,
  .device-webcam-image{
    width: 100vw;
    height: 100vh;
    background-size: contain;
    .timestamp{
      font-size: 1em;
      right: 50px;
      top: 15px;
    }
  }
}
.ellipsis-text(@lines: 2, @line-height: 1.5){
    line-height: @line-height;
    @total: @lines * @line-height;
    max-height: ~"@{total}em";
    overflow: hidden;
    position: relative;
    padding-right: 1em;
    &::before {
      position: absolute;
      content: "...";
      inset-block-end: 0; /* "bottom" */
      inset-inline-end: 0; /* "right" */
      bottom: 0;
      right: 0;
    }
    &::after {
      content: "";
      position: absolute;
      width: 1em;
      height: 1.2em;
      inset-inline-end: 0; /* "right" */
      background: white;
      right: 0;
    }
}
.camera-btn{
  padding: 5px 10px;
  margin: 5px 15px;
  text-decoration: none;
  background-color: var(--color-bg-page-container-main);
  border-radius: 5px;
  .camera{
    width: 28px;
    height: 27px;
    background: url(../assets/common/camera.svg) center center no-repeat;
    filter: invert(100%);
    background-size: auto 69%;
  }
}
@keyframes fadein{
  0% { 
    opacity:0; 
  }
  60% { opacity:0; }
  100% { 
    opacity:1; 
  }
}
@media(max-width: @phone-max){
  .general-modal{
    .ReactModal__Content{
      top: 0 !important;
      margin-left: -50% !important;
      height: 100vh !important;
      max-height: 100vh;
      .close{
        position: fixed;
        right: 20px;
        top: 20px;
      }
    }
    &.ios {
      .ReactModal__Content{
        // top: 30px !important;
        height: 100vh !important;
        padding: env(safe-area-inset-top, 0px) !important;
      }
    }
    &.login-modal{
      h2{
        margin-top: 100px;
      }
    }
  }
}
.video-container{
  .video{
    width: 100%;
    aspect-ratio: 16 / 9;
    max-height: 100vh;
    margin: 0 auto;
  }
}
.product-tiers {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: @phone-max) {
    flex-direction: column-reverse;
  }

  .tier {
    background-color: var(--color-bg-payment-card-main);
    border-radius: 10px;
    width: 32%;
    border: 2px solid var(--color-bg-widget-main);

    @media (max-width: @phone-max) {
      width: 100%;
      margin-bottom: 2em;

      &:first-of-type {
        margin-bottom: 0;
      }
    }

    &.active {
      border: 2px solid var(--color-text-card-content-main);

      .top {
        background-color: @blue-gray;
      }
    }

    .top {
      text-align: center;
      padding: @generalPad;
      background-color: @blue-dark-alt;
      color: @white;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      h2 {
        font-size: 2em;
        margin-top: 0.8em;
        margin-bottom: 0.8em;
        padding-bottom: 0;
        border-bottom: none;
        font-weight: 500;
        letter-spacing: 0.08em;

        @media (max-width: @phone-max) {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    .bottom {
      padding: @bigPad @generalPad;

      .group {
        margin-top: 1em;

        .line {
          display: flex;
          justify-content: space-between;
          font-size: 0.95em;
          margin-bottom: 0.8em;

          &:last-of-type {
            margin-bottom: 0;
          }

          .label {
            width: 65%;

          }

          .val {
            width: 35%;
            font-weight: 500;
          }
        }
      }
    }
  }
}