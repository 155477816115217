@import '../../styles/mixins.less';

.user-admin-alexa {
  .intent{
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }
  .output{
    font-size: 0.95em;
    color: @gray;
    margin: 10px;
  }
  i{
    margin-left: 5px;
  }
  .google-assistant{
    width: 20px;
    height: 20px;
    background-size: 100%;
    display: inline-block;
    
  }

}
