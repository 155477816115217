@import '../../styles/mixins';

.device-crosswind-widget {
  flex-grow: 1;
  display: flex;
  width: 100%;
  // justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  > div {
    flex-grow: 1;
    text-align: center;
  }
  .fdp{
    font-size: 4.5rem;
    color: var(--color-text-widget-big-number);
    .column;
    .suff{
      font-size: 0.3em;
    }
  }

}

.device-widget.crosswind{
  .column;
}