@import '../../styles/mixins';

.mobile-post{
  .settings-nav{
    display: none;
  }
  .forecast-first{
    position: relative;
    .open.common-location-autocomplete{
      display: none;
    }
    .blue.common-location-autocomplete{
      position: absolute;
      top: 32px;
      right: 15px;
      z-index: 3;
      width: 35px;
      height: 35px;
      padding: 0px;
      .component-find-location-btn{
        top: 0px;
        left: 0px;
        padding: 7px;
      }
      .autocomplete-wrap{
        display: none;
      }
    }
  }
}
.ios-bottom-sheet {
  .mobile-post{
    height: 100vh;
    position: fixed;
  }
  &.open {
    .mobile-post{
      top: 0 !important;
      bottom: auto !important;
      .column;
      display: flex !important;
      .bottom-card{
        .column;
        min-height: none;
        padding-bottom: @mobile-nav-height;
        .forecast-first{
          overflow: auto;
          .forecast{
            padding-bottom: 35px;
          }
        }
      }
    }
  }

}
