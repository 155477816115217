@import '../../styles/mixins';

.common-mobile-nav {
  display: none;
}
@media(max-width: @phone-max) {

  .common-mobile-nav {
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0px;
    height: @mobile-nav-height;
    height: calc(~"env(safe-area-inset-bottom, 0) + " @mobile-nav-height);
    background-color: var(--color-bg-widget-main);
    padding-bottom: env(safe-area-inset-bottom, 0px);
    padding-left: 10px;
    .general-shadow();
    .sidebar-transition();
    box-shadow: 0px -4px 10px 3px rgba(221, 221, 221, 0.35);
    display: flex;
    z-index: 500;
    > a {
      @icon-pad: 5px;
      @icon-hw: @mobile-nav-height - @icon-pad * 2;
      flex: 1;
      &:before {
        content: '';
        display: inline-block;
        border-radius: 100%;
        background-color: #eee;
        margin-left: @icon-pad;
        margin-top: @icon-pad;
        height: @icon-hw;
        width: @icon-hw;
        background: url(../../assets/nav/icon-map-gray.svg) no-repeat center center;
        background-size: 55%;
      }
      &.active{
        &:before{
          background-color: rgba(9, 168, 230, 0.2);
        }
      }
      &.map{
        &:before{
          background-size: 51%;
        }
        &.active:before{
          background-image: url(../../assets/nav/icon-map-blue.svg);
        }
      }
      &.account {
        .user-avatar{
          margin-left: @icon-pad + 1;
          margin-top: @icon-pad + 1;
          height: @icon-hw - 2;
          width: @icon-hw - 2;
        }
        &:before{
          display: none;
        }
        &.active .user-avatar{
          border-color: rgba(9, 168, 230, 0.2);
        }
      }
      &.forecast{
        &:before{
          background-image: url(../../assets/nav/icon-forecast-gray.svg);
        }
        &.active:before{
          background-image: url(../../assets/nav/icon-forecast-blue.svg);
        }
      }
      &.dashboard{
        &:before{
          background-image: url(../../assets/dashboard.svg);
        }
        &.active:before{
          background-image: url(../../assets/nav/icon-dashboard-blue.svg);
        }
      }
      &.alerts{
        &:before{
          background-image: url(../../assets/nav/icon-notifications-gray.svg);
        }
        &.active:before{
          background-image: url(../../assets/nav/icon-notifications-blue.svg);
        }
      }
      &.social{
        &:before{
          background-image: url(../../assets/nav/icon-social-gray.svg);
        }
        &.active:before{
          background-image: url(../../assets/nav/icon-social-blue.svg);
        }
      }
      &.menu{
        &:before{
          background-image: url(../../assets/nav/icon-hamburger-gray.svg);
        }
        &.active:before{
          background-image: url(../../assets/nav/icon-hamburger-blue.svg);
        }
      }
      &.awn-plus{
        &:before{
          background-image: url(../../assets/nav/get-awn-plus.svg);
          background-size: 35px;
        }
      }
    }
    .login-wrap{
      display: none;
    }
  }
  .app.loggedout .common-mobile-nav{
    a.alerts,
    a.menu,
    a.dashboard,
    a.awn-plus,
    a.account{
      display: none;
    }
    .login-wrap{
      flex: 5;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn.login{
        border-radius: 0px;
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }
  .app.plus .common-mobile-nav{
    a.awn-plus{
      display: none;
    }
  }
  .app.home-page.sidebar,
  .app.home-page.search-mode {
    .social-notifications,
   .common-mobile-nav {
      bottom: -@mobile-nav-height * 2;
    }
  }
  .app.devices-page.embed{
   .common-mobile-nav {
     display: none;
   }
  }
  .app.welcome-page,
  .app.register-page,
  .app.signin-page{
   .common-mobile-nav {
     .login-wrap{
       .btn{
         display: none;
       }
     }
   }
  }
}

