@import '../styles/mixins.less';

.component-find-location-btn {
  i.glyphicon{
    display: none;
  }
  &.btn-loading{
    &[disabled]{
      background-color: var(--color-bg-btn-primary);
    }
    i.glyphicon{
      color: transparent;
      background: url(../assets/common/spinner-white-blue.gif) no-repeat center center;
      background-size: 100%;
    }
  }
}
