@import '../../styles/mixins.less';

.device-mac-address-input {
  input{
    padding: 7px 46px;
    font-size: 1em;
    .placeholder(@gray-amb);
    color: @text-dark;
  }

}
