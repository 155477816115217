@import '../../styles/mixins.less';

.device-summaries-wrap {
  .pagination{
    > span{
      color: var(--color-text-main-lt);
      font-weight: bold;
      font-size: 14px;
    }
    select.months{
      appearance: none;
      background: url(../../assets/down-carrot.svg) right 8px center no-repeat @blue;
      border: none;
      color: @white;
      display: inline-block;
      width: auto;
      font-size: 14px;
      padding-right: 35px;
      margin-left: 5px;
      margin-right: 10px;
    }
    a{
      text-decoration: none;
      display: inline-block;
      padding-right: @generalPad;
      color: @text-dark;
      &.active{
        color: @blue;
      }
    }
  }

}
