@import '../../styles/mixins.less';

.social-create-forecast {
  margin: 0px 0 60px;
  h2 {
    margin-top: 20px !important;
    border-bottom: none !important;
    color: #BDBDBD;
    position: relative;
  }
  a.weather-icon{
    display: block;
    margin: 5px auto;
    background-color: var(--color-bg-create-forecast-icon-main);
    border-radius: 2px;
    width: 100%;
    background-position: center center;
    background-size: 35px 35px;
    height: 45px;
  }
  .how-many {
    .day-options {
      display: flex;
      justify-content: center;
      gap: 10px;
      .option {
        color: var(--color-text-create-forecast-option-main);
        border: 1px solid var(--color-border-create-forecast-option-main);
        border-radius: 3px;
        text-decoration: none;
        font-size: 20px;
        font-weight: 300;
        padding: 25px 35px;
        position: relative;
        width: 33%;
        text-align: center;
        &:after {
          content: ' ';
          width: 30px;
          height: 30px;
          background: url(../../assets/more-circle.svg) center center no-repeat;
          background-size: cover;
          position: absolute;
          left: calc(50% - 11px);
          bottom: -20px;
        }
        &:hover {
          background-color: var(--color-bg-hover-create-forecast-option-main);
        }
      }
    }
  }
  .days{
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    gap: 5px;
  }
  .icon-chooser{
    display: none;
  }
  .day-forecast{
    border: 1px solid #F2F2F2;
    border-radius: 3px;
    padding: 10px;
    &:hover {
      background-color: var(--color-bg-hover-create-forecast-option-day-main);
    }
    input {
      width: 60px;
      height: 47px;
      border: none;
      border-radius: 0;
      padding: 0 2px;
      background-color: @white;
      border: 1px solid #f2f2f2;
      text-align: center;
    }
    &.active{
      border: 1px solid @blue;
      .title {
        color: @blue;
      }
    }
    .title {
      font-size: 18px;
      font-weight: 300;
      color: #A9AEC1;
    }
    div {
      &.precip {
        background-color: #f8f8f8;
        a {
          display: block;
          height: 42px;
        }
        .dailyrainin {
          position: relative;
          padding-left: 8px;
          &:before{
            content: ' ';
            width: 6px;
            height: 9px;
            position: absolute;
            left: 0;
            top: 3px;
            background: url(../../assets/forecast/raindrop.svg) center center no-repeat;
            background-size: 100%;
          }
        }
      }
      &.hl {
        display: flex;
        flex-direction: column;
        input {
          background-color: var(--color-bg-create-forecast-icon-main);
        }
        a {
          background-color: var(--color-bg-create-forecast-icon-main);
          font-size: 16px;
          margin-bottom: 5px;
          &:first-child {
            color: var(--color-text-create-forecast-input-main);
          }
        }
        .suff{
          display: none;
        }
      }
      a {
        color: #B0B3C1;
        text-decoration: none;
        font-weight: normal;
        padding: 10px 5px;
      }
    }
  }
  .create-forecast{
    margin: 0px 0 ;
    position: relative;
    &.icons{
      .icon-chooser{
        position: absolute;
        top: 115px;
        .general-shadow;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 5px;
        justify-content: center;
        background: var(--color-bg-create-forecast-icon-chooser-main);
        a {
          color: var(--color-text-create-forecast-icon-chooser-main);
          text-decoration: none;
          font-size: 11px;
          text-align: center;
          flex: 0 0 13.5%;
          margin-right: 5px;
          margin: 10px 0;
          .label {
            margin-bottom: 3px;
          }
        }
        .weather-icon{
          background-size: 35px 35px;
          margin: 0 auto;
          background-position: center center;
        }
      }
    }
    .text-center{
      padding-top: 10px;
      .btn-primary{
        margin-left: 10px;
      }
    }
    .fdp{
      padding: 0 5px;
    }
  }

}
