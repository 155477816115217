@import '../../styles/mixins';

.device-wxw-tile-gallery {
  .tile-wrap{
    display: flex;
    flex-wrap: wrap;
  }
  h1{
    font-size: 3.5rem !important;
    margin-bottom: @generalPad !important;
  }
  h2{
    margin-top: 35px !important;
    font-size: 2.5rem !important;
    text-align: left !important;
    color: @blue;
    border: none !important;
  }
  .widget-preview{
    width: 200px;
    height: auto;
    aspect-ratio: 1.18;
    background-size: 190px;
    margin: 0px 10px 10px 0;
    max-width: 100%;
    position: relative;
    &.url::before{
      content: '';
      position: absolute;
      right: 12px;
      top: 11px;
      width: 20px;
      height: 20px;
      background: url(../../assets/star-blue.svg) center center no-repeat;;
      display: flex;
    }
    a{
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 3;
      width: 100%;
      height: 100%;
      @media (hover: hover){
        &:hover{
          background: url(../../assets/common/cart.svg) center center no-repeat rgba(0,0,0,0.1);
          background-size: 80px;
          border-radius: 15px;
          // background: rgba(0,0,0,0.2);
        }
      }
    }
    &.medium{
      width: 300px;
      background-size: 290px;
      &.url::before{
        top: 18px;
        right: 16px;
      }
    }
    &.large{
      width: 400px;
      background-size: 390px;
      aspect-ratio: 1.5;
      &.url::before{
        top: 11px;
        right: 15px;
      }
    }

  }

}
@media(max-width: @phone-max) {
  .device-wxw-tile-gallery {
    .widget-preview{
      width: 47%;
      height: auto;
      background-size: contain;
      &.large{
        background-size: contain;
        width: 100%;
      }
    }
  }
}
