@import '../../styles/mixins.less';

.waterph-widget {
    > .fdp {
      .suff{
        vertical-align: top;
      }
    }

  }
  .device-widget.waterph{
    .column;
    .buttons-wrap{
      // display: none;
    }
  }