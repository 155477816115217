@import '../../styles/mixins.less';

.social-post {
  @base-post-width: 320px;
  @alertLineHeight: 6px;
  //padding: 0 @generalPad 2 * @generalPad;
  padding: 0;
  min-height: 130px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-bg-card-content-main);
  &.type-alert{
    border-top: @alertLineHeight solid @red;
    padding: @generalPad - @alertLineHeight 45px (2 * @generalPad);
    background: url(../../assets/common/alert.svg) 10px 10px no-repeat;
    background-size: 30px auto;
  }
  .text{
    font-weight: bold;
    text-align: center;
    margin: 0;
    padding: 10px;
    color: var(--color-text-card-content-main);
    background-color: var(--color-bg-card-content-main);
    margin-bottom: 10px;
    line-height: 1.25;
    a {
      display: block;
      .ellipsis
    }
  }
  a.img{
    display: block;
    margin-bottom: 10px;
    height: @base-post-width / 1.9;
  }
  &.portrait a.img{
    height: @base-post-width / 3 * 4;
  }
  img.ratio{
    position: absolute;
    opacity: 0;
    width: 100%;
    display: block;
  }
  &.img-loaded{
    img.ratio{
      display: none;
    }
  }
  .show-more{
    display: none;
  }
  .expiration{
    display: none;
    box-sizing: border-box;
    position: absolute;
    padding: @generalPad 0;
    bottom: 0;
    right: 0;
    width: 100%;
    font-size: 1.1rem;
    color: var(--color-text-main-lt);
    text-align: center;
    font-weight: 500;
  }
  &.expired{
    padding-bottom: @generalPad;
    a.img{
      margin-bottom: 0;
    }
    .expiration{
      display: none;
    }
  }
  &.trash{
    .text{
      &:before{
        content: '**DELETED**';
        display: block;
      }
    }
  }
  &.banned{
    .text{
      &:before{
        content: '**BANNED**';
        color: @red;
        display: block;
      }
    }
  }
  .device-every-widget {
    background: var(--color-bg-social-post-widget-main);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    margin: 20px 20px 50px 20px;
    text-align: center;
  }
  .common-tags{
    display: flex;
    justify-content: center;
    width: 100%;
    .tag{
      background-color: #ecf6ef;
      color: #008315;
      font-weight: 300;
      text-transform: uppercase;
      font-size: 14px;
      padding-right: 10px;
      border-radius: 1px;
    }
  }
  &.type-widget-my-forecast {
    .text{
      background-color: transparent;
      margin-bottom: 0;
      padding-top: @generalPad;
    }
    background-color: @my-forecast-bg;
    .device-every-widget.my-forecast {
      background-color: transparent;
      box-shadow: none;
      .widget-title{
        padding: 0 0 10px 0;
      }

    }
  }
  &.type-widget-forecast {
    .device-forecast-widget {
      &.component-forecast-long-term {
        width: 100%;
        box-shadow: none;
        padding: 5px;
        .today .title-precip span {
          font-size: 1em;
        }
        .close {
          display: none;
        }
        .other-days{
          height: 180px;
        }
        .today {
          .hl .fdp {
            font-size: initial;
            padding-right: 10px;
          }
          .weather-icon {
            width: 1.3em;
            height: 1.3em;
          }
        }
        .forecast-text {
          font-size: 0.8em;
          padding: 0 0 5px;
        }
      }
      
    }
  }
  &.type-widget-solar {
    .solarmax {
      .fdp-val {
        font-size: 3em;
      }
      .suff {
        font-size: 1.3em;
      }
    }
    .from {
      padding-top: 0;
      &.zero {
        .label{
          font-weight: 300;
          font-size: initial;
          margin-bottom: 5px;
        }
        span {
          color: @blue;
          .suff {
            font-size: 0.8em;
          }
        }
      }
    }
  }
  &.type-widget-rain {
    .rain-widget{
      .outer-wrap {
        .rain-wrap {
          display: none;
          padding-bottom: 0;
          &.zero, &:nth-child(3) {
            display: flex;
          }
          .wrap {
            .label {
              font-size: 0.8em;
              font-weight: 300;
              display: block;
              &.rate {
                //display: inline;
              }
              &:before {
                display: none;
              }
            }
            .eventrainin {
              color: var(--color-text-main);
            }
          }
          .fdp {
            display: block;
            border-bottom: 3px solid #fed741;
            margin-bottom: 2px;
          }
        }
      }
      .hourlyrainin, .dailyrainin {
        color: var(--color-text-main);
      }
    }
  }
  &.type-widget-indoor {
    .co2-progress {
      position: relative;
      margin-top: 5px;
      bottom: 0;
    }
  }
  &.type-widget-temp {
    .device-temp-widget {
      padding: 0;
      .body {
        padding-bottom: 0;
      }
      .bottom {
        >div:last-of-type {
          @media (max-width: @super-small){
            display: none;
          }
        }
      }
    }
    
    .bottom {
      padding-left: 0;
      .label {
        font-size: 12px !important;
      }
      .val {
        color: @blue;
        font-size: 14px !important;
      }
    }
  }
  .device-lightning-widget {
    .column;
    flex: 1;
    justify-content: center;
    .top-wrap{
      //flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    .top{
      display: flex;
      justify-content: center;
      color: @text-dark;
      .val{
        color: @text-dark;
        font-size: 4.3em;
        line-height: 0.9;
        padding-right: 2px;
        font-size: 15vw;
        font-size: clamp(1em, 15vw, 4em);
      }
      .labels{
        .column;
        justify-content: flex-end;
        font-size: 0.9em;
        .icon{
          height: 22px;
          width: 19px;
          background: url(../../assets/lightning-black.svg) center center no-repeat;
          background-size: 100%;
        }
      }
    }
    .bottom{
      display: flex;
      padding: 10px;
      justify-content: space-between;
      > div {
        text-align: center;
        flex-grow: 1;
        line-height: 1.2;
        padding: 0 8px;
        &:nth-of-type(2){
          border-left: 1px solid @gray-light-amb;
          border-right: 1px solid @gray-light-amb;
        }
        .label {
          font-size: 1.3vw;
          font-size: clamp(0.6em, 1.3vw, 0.65em);
          font-weight: 300;
        }
        .val{
          padding: 5px 0 0;
          font-size: 0.8em;
          font-size: 1vw;
          font-size: clamp(0.9em, 1vw, 1em);
          color: @blue;
          .icon{
            display: inline-block;
            padding-right: 12px;
            background: url(../../assets/lightning-blue.svg) center right no-repeat;
            background-size: 10px;
          }
        }
      }
    }
  }
  
  &.type-widget-air {

    .device-air-2-widget {
      .fill-column;
      height: 220px !important;
      .gauge-wrap{
        position: relative;
        flex: 1.5;
        overflow: hidden;
        .arrow,
        .gauge{
          position: absolute;
          z-index: 1;
          background: url(../../assets/widgets/aqi-gauge.svg) center top no-repeat;
          background-size: 100% auto;
          content: '';
          top: 20px;
          left: 15%;
          width: 72%;
          padding-top: @w;
        }
        .gauge:before{
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: var(--color-bg-widget-main);
          padding-top: @w * 0.552;
          z-index: 2;
        }
        .arrow{
          z-index: 4;
          background: url(../../assets/widgets/aqi-gauge-arrow-main.svg) center top no-repeat;
          background-size: 100% auto;
          transition: transform 4000ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
          transform: rotate(0deg);
          &.alt{
            z-index: 5;
            background-image: url(../../assets/widgets/aqi-gauge-arrow-alt.svg);
          }
        }

        .values{
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          z-index: 4;
          text-align: center;
          color: var(--color-text-widget-big-number);
          padding-bottom: @w * 0.05;
          .main{
            font-weight: bold;
            font-size: 3.6rem;
            line-height: 1.3;
          }
          .units{
            font-size: 1.2rem;
          }
        }
      }

      .bottom {
        flex: 1;
        .label {
          font-size: 2rem;
        }
      }
      .corners{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 15px 15px 0 15px;
        color: var(--color-text-main);
        font-size: 1.5rem;
        font-weight: 300;
        margin-top: 15px;
        .fdp{
          color: var(--color-text-widget-main);
          font-size: 2rem;
          font-weight: normal;
          display: inline-block;
          padding-left: 5px;
        }
        .suff{
          display: none;
        }
      }
      
    }
  }
  &.type-widget-wind {
    .device-every-widget {
      text-align: unset;
    }
    .wind-widget {
      flex-grow: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-content: flex-start;
      //padding-bottom: 30px;
      &:hover {
        .corner-wrap .fdp{
          &.degrees {
            display: block;
          }
          &.direction {
            display: none;
          }
        }
      }
      .val{
        position: absolute;
        width: 100%;
        color: @text-dark;
        z-index: 2;
        text-align: center;
        top: 27%;
        > .fdp{
          font-size: 2.5vw;
          font-size: clamp(1.9em, 2.5vw, 2em);
          line-height: 1;
        }
        .label{
          font-size: 0.75em;
          line-height: 0.5em;
          .fdp-val{
            display: none;
          }
        }
        .max{
          font-weight: 300;
          display: block;
          margin-top: 10px;
          line-height: 1.2;
          .peak{
            display: block;
           // font-size: 1.2vw;
            //font-size: clamp(0.6em, 1.2vw, 0.65em);
            font-size: 14px;
          }
          .maxdailygust {
            color: var(--brandColor);
            font-weight: normal;
            font-size: 1em;
            line-height: 1;
            .suff {
              display: none;
            }
          }
        }
      }
      .compass{
        .compass-create(150px);
        position: relative;
        display: block;
        margin: 15px auto;
        transition: transform 4000ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
        background: url(../../assets/wind-circle.svg) center center no-repeat;
        background-size: 96%;
      }
      .corner-wrap{
        position: absolute;
        bottom: 0;
        left: @generalPad;
        right: auto;
        color: @gray-blue;
        font-size: 1.2vw;
        font-size: clamp(0.6em, 1.2vw, 0.65em);
        font-weight: 300;
        line-height: 1;
        color: @gray-dark-amb;
        top: unset;
        .fdp{
          margin-top: 4px;
          color: @blue;
          font-size: 2.5vw;
          font-size: clamp(0.7em, 2.5vw, 1.7em);
          display: block;
          font-weight: normal;
          &.degrees{
            display: none;
          }
        }

        &.right{
          text-align: right;
          left: auto;
          right: @generalPad;
          border: none;
        }
      }
      .expanded-wrap{
        display: flex;
        margin-top: 0;
        padding: 0 @generalPad/2;
        .avg{
          flex: 1;
          background-color: #f5f5f5;
          padding: 10px 15px;
          margin: 7.5px 7.5px 0 7.5px;
          h3{
            font-weight: 300;
            color: @gray-dark-amb;
            margin-bottom: 0;
            width: 100%;
            font-size: 0.85em;
          }
          .flex{
            display: flex;
            justify-content: flex-start;
            justify-content: space-between;
          }
          .fdp{
            font-size: 16px;
            line-height: 1;
            font-weight: 400;
          }
        }

      }
    }
  }
  &.type-widget-co2 {
    .device-co-2-widget {
      .fill-column;
      .value {
        color: var(--color-text-main);
        font-size: 1.3em;
        align-self: center;
        flex: 1;
        margin-top: 0.5em;
        .fdp-val {
          font-size: 2em;
          font-weight: bold;
          margin: 0 8px;
          color: var(--color-text-widget-big-number) !important;
        }
        .suff {
          font-weight: 300;
          color: #959595;
          font-size: 0.6em;
        }
      }
      .label{
        color: #27AE60;
        font-weight: 500;
        font-size: 1em;
        align-self: center;
        &.unhealthy-for-sensitive-groups,
        &.unhealthy{
          color: #F7D66B;
        }
        &.very-unhealthy,
        &.hazardous{
          color: #FD5C1A;
        }
      }
      .gauge{
        @h: 15px;
        height: @h;
        background: url(../../assets/widgets/co2-gauge.svg) center center no-repeat;
        background-size: 100% auto;
        width: 90%;
        border-radius: @h / 2;
        position: relative;
        margin: 1em 0;
        align-self: center;
        .dot{
          @dotH: @h * 2.2;
          height: @dotH;
          width: @dotH;
          position: absolute;
          top: -((@dotH - @h) / 2);
          left: -((@dotH - @h) / 2);
          z-index: 2;
          background: url(../../assets/widgets/co2-dot.svg) center center no-repeat;
          background-size: 100%;
        }
      }
      .bottom {
        .fill-column;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        flex: 1;
        padding: 5px 25px;
        color: var(--color-text-main);
        font-weight: 300;
        font-size: 14px;
        margin-top: 1.5rem;
        .pm_in_humidity_aqin, .pm_in_temp_aqin {
          color: @blue;
          font-weight: normal;
          margin-left: 2px;
          font-size: 1.8rem;
        }
      }
    }
  }
  @w: 200px;
  &.type-widget-aqin {
    .device-air-2-widget.aqin {
    .fill-column;
      height: 190px !important;
      .gauge-wrap{
        position: relative;
        flex: 1.5;
        overflow: hidden;
        .arrow,
        .gauge{
          position: absolute;
          z-index: 1;
          background: url(../../assets/widgets/aqi-gauge.svg) center top no-repeat;
          background-size: 100% auto;
          content: '';
          top: 20px;
          left: 15%;
          width: 72%;
          padding-top: 210px;
        }
        .gauge:before{
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: var(--color-bg-widget-main);
          padding-top: @w * 0.552;
          z-index: 2;
        }
        .arrow{
          z-index: 4;
          background: url(../../assets/widgets/aqi-gauge-arrow-main.svg) center top no-repeat;
          background-size: 100% auto;
          transition: transform 4000ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
          transform: rotate(0deg);
          &.alt{
            display: none;
          }
        }

        .values{
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          z-index: 4;
          text-align: center;
          color: var(--color-text-widget-big-number);
          padding-bottom: @w * 0.1;
          .main{
            font-weight: bold;
            font-size: 3.6rem;
            line-height: 1.3;
          }
          .units{
            font-size: 1.2rem;
          }
        }
      }
      .bottom { 
        justify-content: center;
        margin-bottom: 15px;
        .extended {
          display: none;
        }
        .label {
          font-weight: 500;
          font-size: 2.5rem;
        }
      }
    }
  }
  &.type-widget-airin {
    .device-air-2-widget.airin {
      .fill-column;
      height: 220px !important;
      .gauge-wrap{
        position: relative;
        flex: 1.5;
        overflow: hidden;
        .arrow,
        .gauge{
          position: absolute;
          z-index: 1;
          background: url(../../assets/widgets/aqi-gauge.svg) center top no-repeat;
          background-size: 100% auto;
          content: '';
          top: 20px;
          left: 15%;
          width: 72%;
          padding-top: @w;
        }
        .gauge:before{
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: var(--color-bg-widget-main);
          padding-top: @w * 0.552;
          z-index: 2;
        }
        .arrow{
          z-index: 4;
          background: url(../../assets/widgets/aqi-gauge-arrow-main.svg) center top no-repeat;
          background-size: 100% auto;
          transition: transform 4000ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
          transform: rotate(0deg);
          &.alt{
            z-index: 5;
            background-image: url(../../assets/widgets/aqi-gauge-arrow-alt.svg);
          }
        }

        .values{
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          z-index: 4;
          text-align: center;
          color: var(--color-text-widget-big-number);
          padding-bottom: @w * 0.05;
          .main{
            font-weight: bold;
            font-size: 3.6rem;
            line-height: 1.3;
          }
          .units{
            font-size: 1.2rem;
          }
        }
      }

      .bottom {
        flex: 1;
        .label {
          font-size: 2rem;
        }
      }
      .corners{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 15px 15px 0 15px;
        color: var(--color-text-main);
        font-size: 1.5rem;
        font-weight: 300;
        margin-top: 15px;
        .fdp{
          color: var(--color-text-widget-main);
          font-size: 2rem;
          font-weight: normal;
          display: inline-block;
          padding-left: 5px;
        }
        .suff{
          display: none;
        }
      }
      
    }
  }
  
}
