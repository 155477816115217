@import '../../styles/mixins.less';

.app.devices-public{
  .user-nav,
  .mobile-header,
  .component-dashboard-nav{
    display: none;
  }
  .social-notifications {
    display: none;
  }
  .page-container{
    // background-color: @bg;
  }
  > footer{
    .dashboard{
      display: none;
    }
    .powered{
      text-align: center;
      display: block;
    }
  }
  &.embed{
    > footer {
      .powered{
        display: none;
      }
    }
  }
  .glyphicon-pencil{
    display: none;
  }
}

.device-public {
  .buttons-wrap{
    display: none;
  }
  .sorry{
    color: @white;
    text-align: center;
    padding-top: 25px;
  }

}

@media (max-width: @phone-max) {
  .app.devices-public{
    .navbar-brand{
      span{
        display: none;
      }
    }
    .device-device-realtime-dashboard {
      padding-top: @bigPad;
    }
    
  }
}