@import '../../styles/mixins.less';
.device-wbgt-widget-expanded{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .body{
      padding: 0;
      padding-bottom: 15px;
      border-bottom: 1px solid var(--color-border-widget-header-main);
      .top{
        .fdp {
          font-size: 4.3em;
          line-height: 1;
        }
        .suff{
          font-size: 2em;
          vertical-align: top;
        }
      }
      .bottom{
        flex-grow: 1;
        align-self: flex-top;
        .from {
          .fdp:before {
            height: 2vw;
            height: clamp(0.3em, 2vw, 1em);
          }
        }

        > div {
          line-height: 1.4;
          .label {
            font-size: 1.3vw;
            font-size: clamp(0.6em, 1.3vw, 0.65em);
          }
        }
      }
    }
}
.device-wbgt-widget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  .compact-show{
    display: none;
  }
  .top{
    color: var(--color-text-widget-big-number);
    text-align: center;
    line-height: 1;
    .fdp-val {
      font-size: 4.0em;
      line-height: 1;
    }
    .suff{
      font-size: 1.75em;
      vertical-align: top;
    }
  }
  .bottom{
    display: flex;
    justify-content: center;
    align-items: flex-top;
    padding: 0 10px;
    > div {
      margin-top: 10px;
      padding: 0px 10px;
      line-height: 1.2;
      text-align: center;
      border-right: 1px solid var(--color-border-line-separator-main);

      &:last-of-type{
        border-right: none;
      }
      .label{
        font-size: 0.66em;
        line-height: 1.2;
        padding-bottom: 2px;
        font-weight: 300;
      }
    }
  }
}
.compact {
  .device-wbgt-widget{
    .compact-show{
      display: block;
    }
  }
}
.device-widget.wbgt{
  .column;
}
.tablet-widget-expanded {
  .device-wbgt-widget{
    .body{
      flex-direction: row;
      padding-bottom: @generalPad;
    }
  }
}
.device-outside-bar{
  .device-wbgt-widget {
    .body{
      display: flex;
      align-items: center;
      padding: 5px @generalPad @generalPad @generalPad;
    }
    .top {
      font-size: 0;
      padding-top: 0;
      .wbgtf{
        font-size: 50px;
      }
    }
    .bottom{
      padding-right: 0;
      flex-grow: 1;
      > div{
        border: none;
        padding-right: 0;
        &:first-of-type{
          padding-right: 10px;
        }
        .label{
          color: var(--color-text-main-lt);
          top: -4px;
          position: relative;
        }
        .val{
          color: var(--color-text-main);
        }
      }
    }
  }
}
.flag-zone {
  color: @text-dark;
  font-size: @phone-font-grow;
  margin-top: 0.5em;
}
@media (max-width: @tablet-max) {
  .device-wbgt-widget{
    .column;
    justify-content: center;
    padding: @generalPad 0;
    .body{
      .bottom {
        .from {
          .fdp:before {
            height: 4vw;
            height: clamp(0.3em, 4vw, 1em);
          }
        }
        > div {
          .label {
            padding-bottom: 4px;
            font-size: 1.3vw;
            font-size: clamp(0.7em, 1.3vw, 0.8em);
          }
          .fdp {
            .fdp-val, .suff {
              font-size: 1vw;
              font-size: clamp(1.2em, 1vw, 2.2em);
            }
          }
        }
      }
    }
  }
}

@media (max-width: @tablet-phone-max) {
  .device-wbgt-widget {
    .body {
      .bottom {
        .from {
          .fdp:before {
            height: 4vw;
            height: clamp(0.3em, 4vw, 1em);
          }
        }
      }
    }
  }
  .device-widget.wbgt.expanded{
    .body {
      .bottom {
        .from {
          .fdp:before {
            height: 4vw;
            height: clamp(0.3em, 4vw, 1em);
          }
        }
        > div {
          line-height: 1;
          .label {
            font-size: 14px;
          }
        }
      }
    }
    .tablet-widget-expanded;
    .expanded-wrap{
      .device-high-low-graphs {
        .line-wrap {
          padding: 5px 0 15px;
        }
      }
    }
  }
}


//mobile only
@media (max-width: @phone-max) {
  .device-widget.wbgt.expanded,
  .device-widget.wbgt{
    .tablet-widget-expanded;
    .device-wbgt-widget{
      .body{
        flex-wrap: wrap;
        padding-top: @bigPad;
        padding-bottom: 0px;
        .top,.bottom{
          width: 100%;
        }
      }
    }
  }
  .device-widget.wbgt.expanded{
    .device-wbgt-widget{
      padding: @generalPad 0;
      .body{
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: @generalPad;
        .top {
          .fdp-val {
            font-size: 4.3em;
            line-height: 1;
          }
          .suff {
            font-size: 2em;
            vertical-align: top;
          }
        }
        .bottom {
          > div {
            .label {
              font-size: 14px;
            }
          }
        }
      }
      .expanded-wrap{
        font-size: @phone-font-grow;
        .line-wrap{
          padding-bottom: 40px;
          &:last-child{
            padding-bottom: 0px;
          }
        }
      }

    }
  }
}