@import '../../styles/mixins.less';

.device-temp-graph {

}
@media (max-width: 360px) {
  #temp_graph:first-of-type{
    margin-top: 35px !important;
    .recharts-legend-wrapper{
      top: -33px !important;
    }
  }
}
