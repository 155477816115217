@import '../../styles/mixins.less';

.device-create-webcam {
  .error{
    color: @color-alert;
  }
  .camera{
    display: inline-block;
    padding-left: 30px;
    padding-top: 20px;
    position: relative;
    margin-top: 0;
    &:before{
      content: '';
      position: absolute;
      top: 21px;
      left: 0;
      background: url(../../assets/map/camera.png) left center no-repeat;
      background-size: 80%;
      filter: grayscale(100%) invert(1);
      width: 30px;
      height: 20px;
    }
  }
  &.ambientcam2{
    .camera{
      padding-left: 35px;
      &:before{
        background: url(../../assets/ambientcam2-front-small.png) left center no-repeat;
        height: 50px;
        background-size: 80%;
        top: 3px;
      }
    }
  }
  &.video {
    .camera {
      &:before {
        background: url(../../assets/map/video.png) left center no-repeat;
        background-size: 80%;
      }
    }
    &.tier-2,
    &.tier-1 {
      .camera{
        &:before{
          background: url(../../assets/map/video-1.png) left center no-repeat;
          filter: none;
          background-size: 80%;
        }
      }
    }
    &.tier-3,
    &.tier-4 {
      .camera{
        &:before{
          background: url(../../assets/map/video-2.png) left center no-repeat;
          filter: none;
          background-size: 80%;
        }
      }
    }
  }
  .component-question-mark{
    padding-left: 5px;
  }
  a.add{
    padding-top: 15px;
    text-decoration: none;
    display: inline-block;
  }
  h2{
    font-weight: 500;
    font-size: 20px;
  }
  .wrap{
    display: flex;
    .left {
      color: var(--color-text-main-lt);
    }
    .right{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      background-color: var(--color-bg-video-main);
      .img-wrap{
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .cam-banner {
        background-color: var(--color-bg-video-banner-main);
        color: var(--color-text-main-lt);
        padding: @generalPad;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        button {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }
  a{
    text-decoration: none;
    font-size: 0.95em;
  }
  .component-loader{
    display: block;
    margin-top: 15px;
  }
  h2 {
    margin-bottom: 0;
    a {
      font-size: 1.2rem;
      text-decoration: none;
      font-weight: normal;
      padding-left: 80px;
    }
  }
  
  &.open{
    h2{
      margin: 1em 0 0.6em;
    }
    .left{
      margin-right: 15px;
      max-width: 450px;
    }
  }
  .device-webcam-image.not-found{
    display: flex;
    justify-content: center;
    align-items: center;
    .not-found-message{
      color: @white;
      h5{
        line-height: 1.4;
        a{
          text-decoration: underline;
        }
      }
    }
  }

  .device-webcam-image,
  img{
    height: 200px;
    width: 355px;
    max-width: 100%;
  }
  p{
    padding: 10px 0 5px;
    margin-bottom: 0;
  }
  .radios{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    > div {
      margin-right: 20px;
      margin-bottom: 5px;
    }
    .component-amb-radio .circle{
      margin-left: 5px;
    }
  }
  .ftp{
    h3 {
      margin: 1.2em 0 0.6em;
      color: var(--color-text-main);
      font-weight: 500;
    }
    h4{
      margin: 1.2em 0 0.6em;
    }
    .bordered{
      border: 1px solid var(--color-border-widget-header-main);
      padding: 5px 15px 10px;
      .delete{
        margin-top: 10px;
        display: inline-block;
      }
    }
    table{
      tr{
        td{
          padding: 5px 15px;
          &:first-child{
            padding-left: 0;
          }
          &:last-child{
            .monospace,
            .small{
              line-height: 1.3;
            }
            font-weight: 500;
          }
        }
      }
    }
    > a {
      padding-top: 10px;
      font-size: 0.9em;
      text-decoration: none;
      display: inline-block;
    }
    label{
      text-transform: none;
      margin-top: 10px;
      margin-bottom: 10px;
      display: block;
      color: var(--color-text-main);
      font-weight: 400;
      font-size: 0.85em;
      input[type="checkbox"]{
        position: relative;
        top: 2px;
        margin-right: 3px;
      }
    }
  }
  .url{
    padding: 15px 0 10px;
    input{
      width: 400px;
      max-width: 100%;
    }
    p.note{
      font-size: 0.9em;
      color: @red-light;
    }
    p > a{
      font-size: 0.9em;
    }
  }
  .btns{
    padding: 20px 0;
  }

}
@media(max-width: @tablet-max){
  .device-create-webcam {
    .wrap{
      flex-wrap: wrap;
      .right{
        order: 1;
        margin-bottom: 10px;
        width: 100%;
      }
      .left{
        order: 2;
        max-width: 100%;
        margin-right: 0;
        flex-grow: 1;
      }
    }
    .cam-banner {
      flex-wrap: wrap;
      button {
        margin-top: 0.5em;
      }
    }
  }
}
@media(max-width: @phone-max) {
  .device-create-webcam {
    .ftp{
      table{
        font-size: 0.95em;;
        width: 100%;
      }
    }
    .url {
      p > a {
        display: block;
      }
    }
    .radios{
      flex-direction: column;
      .component-amb-radio{
        flex-direction: row-reverse;
        justify-content: flex-end;
        padding-bottom: 5px;
        > div {
          margin-right: 0;
          padding-left: 5px;
        }
        i.circle{
          margin-left: 0;
        }
      }
    }
  }

}
