@import '../../styles/mixins.less';


.social-default-page {
}
@media (max-width: @phone-max) {
  .app .social-default-page {
    position: relative;
    header.main{
      display: none;
    }
    .common-location-autocomplete{
      position: fixed;
      top: -100px;
      .sidebar-transition(top);
      left: 0px;
      z-index: 100;
      width: 100%;
      background-color: var(--color-bg-page-block-main);
    }
    .social-my-timeline{
      padding-top: 75px;
      padding-top: calc(~"env(safe-area-inset-top, 0) + 75px");
      .sidebar-transition(padding-top);
      .controls{
        position: fixed;
        top: 0px;
        padding-top: 15px;
        padding-top: calc(~"env(safe-area-inset-top, 0) + 15px");
        .sidebar-transition(top);
        width: 100%;
        left: 0;
        z-index: 200;
      }
    }
    .device-device-popup{
      max-width: 100%;
      margin-bottom: @bigPad;
      // width: 100%;
    }
    &.location{
      .social-my-timeline{
        padding-top: 125px;
        padding-top: calc(~"env(safe-area-inset-top, 0) + 125px");
        background-color: var(--color-bg-page-block-main);
        .controls{
          top: 65px;
          top: calc(~"env(safe-area-inset-top, 0) + 65px");
        }
      }
      .common-location-autocomplete{
        top: 0px;
        padding-top: 10px;
        padding-top: calc(~"env(safe-area-inset-top, 0) + 10px");
        .component-find-location-btn{
          top: 16px;
          top: calc(~"env(safe-area-inset-top, 0) + 16px");
        }
      }
    }
  }
}
