@import '../styles/mixins.less';

.component-cookies-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-indeX: 1000;
  background-color: @white;
  padding: @bigPad;
  font-size: 1.3em;
  display: flex;
  align-items: center;
  .general-shadow;
  span{
    flex-grow: 1;
  }
  a{
    &.clean{
      text-decoration: none;
      margin-right: 20px;
      font-size: 0.9em;
    }
    &.btn.btn-primary{
      font-size: 1em;
    }
  }
}
@media (max-width: @tablet-phone-max) {
  .component-cookies-banner {
    flex-wrap: wrap;
    justify-content: center;
    span{
      text-align: center;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}


.cordova,
.native-app,
.embed{
  .component-cookies-banner{
    display: none;
  }
}