@import '../../styles/mixins.less';

.device-widget.pressure{
  .column;
}
.pressure-widget {
  flex-grow: 1;
  .column;
  justify-content: center;
  .suff,.after{
    font-size: 0.7em;
    color: @text-dark;
    font-weight: 300;
  }
  .change{
    @s: 27px;
    text-align: center;
    box-sizing: border-box;
    padding: 10px 0 5px;
    margin-bottom: auto;
    .fdp {
      position: relative;
    }
    &.up,
    &.down{
      .fdp{
        padding-left: @s + 4;
        &:before{
          position: absolute;
          left: 0;
          top: -1px;
          content: ' ';
          background: url(../../assets/group-5.svg) center center no-repeat;
          background-size: 100%;
          width: @s;
          height: @s;
          display: block;
        }
      }
    }
    &.up .fdp:before{
      transform: rotate(180deg);
    }
  }
  .gauge{
    position: relative;
    display: block;
    margin: 0 auto;
    background: var(--bg-image-gauge) top center no-repeat;
    background-size: 100%;
    .gauge-maker(165px);
    .compass{
      position: absolute;
      bottom: 14px;
      transition: transform 4000ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
      background: url(../../assets/pressure-needle.svg) top center no-repeat;
      background-size: 50% 50%;
    }
  }
  &.unit-1 .gauge{
    background-image: var(--bg-image-gauge-1);
  }
  &.unit-2 .gauge{
    background-image: var(--bg-image-gauge-2);
  }
  .barom{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 0px 0 20px;
  }
  .spacer{
    height: 0px;
    margin-top: auto;
  }
  .top-spacer{
    height: 0px;
    margin-bottom: auto;
  }
}
.gauge-maker(@w) {
  width: @w;
  height: @w;
  .compass{
    width: @w * 0.66333;
    height: @w * 0.66333;
    left: @w * 0.243333;
    transform-origin: @w * 0.26333 @w * 0.26333;
  }
}
@media (max-width: @tablet-max) {
  .pressure-widget{
    font-size: @tablet-font-grow;
    .gauge{
      .gauge-maker(250px);
    }
    .barom{
      padding-bottom: 20px;
    }
  }
}
@media (max-device-width: @phone-max) {
  .pressure-widget{
    font-size: @phone-font-grow;
    .top-spacer{
      height: 20px;
    }
    .gauge{
      .gauge-maker(250px);
    }
    .change{
      font-size: @phone-font-grow;
      padding-top: 0;
      padding-bottom: @generalPad;
      border-bottom: 1px solid @gray-light-amb;
      margin-bottom: @bigPad;
      .fdp-val{
        font-size: 1.6em;
      }
    }
    .barom{
      font-size: 1.3em;
    }
  }
}
