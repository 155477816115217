@import '../../styles/mixins.less';

.device-sun-moon-widget {
  flex-grow: 1;
  display: flex;
  .recharts-pie-label-line{
    display: none;
  }
  .recharts-pie-sector {
    path.recharts-sector {
      //fill: #000;
    }
  }
  &.error{
    flex-grow: 1;
    font-size: 0.8em;
    color: @text-dark;
    text-align: center;
    padding: @bigPad;
  }
  .wrap{
    @wrapMargin: 10px;
    @wrapBottomMargin: 20px;
    position: relative;
    flex-grow: 1;
    margin: @wrapMargin @wrapMargin @wrapBottomMargin @wrapMargin;
    .recharts-responsive-container{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      background: url(../../assets/sun-moon/clock-bkg-small-ampm.svg) center center no-repeat;
      background-size: 100% 100%;
    }
    &.hour24{
      .recharts-responsive-container{
        background-image: url(../../assets/sun-moon/clock-bkg-small-24hr.svg);
      }
    }
    .info{
      position: absolute;
      bottom: -@wrapBottomMargin;
      left: -@wrapMargin;
      width: calc(100% ~"+" (2 * @wrapMargin));
      z-index: 9;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      line-height: 1.2;
      padding: 0 10px 10px;
      .label{
        font-size: 16px;
      }
      .set{
        .label{
          text-align: right;
        }
        .sun{
          padding-left: 0px;
          padding-right: 13px;
          background-position: right 2px center;
        }
        .moon{
          svg{
            margin-right: 0px;
            margin-left: 4px;
          }
        }
      }
      .sun{
        padding-left: 15px;
        background: url(../../assets/sun-moon/sun-circle-small.svg) 1px center no-repeat;
        background-size: 8px auto;
      }
      .moon{
        svg{
          margin-right: 4px;
        }
      }
      table{
        width: 100%;
        text-align: center;
        tr.headings{
          td{
            color: @text-dark;
            font-weight: 300;
          }
        }
        td{
          padding-bottom: 2px;
        }
        .sun{
          width: 10px;
          height: 12px;
          display: inline-block;
          background-size: 10px auto;
          position: relative;
          left: 2px;
        }
        .hyphen{
          display: inline-block;
          background: url(../../assets/sun-moon/grey-dash.svg) center center no-repeat;
          background-size: 10px auto;
          width: 10px;
          height: 10px;
        }
        span{
          color: @text-dark;
          &.arrow{
            display: inline-block;
            padding-left: 12px;
            background: url(../../assets/up-arrow-bolder.svg) left center no-repeat;
            background-size: 9px auto;
            &.neg{
              background-image: url(../../assets/down-arrow-bolder.svg);
            }
          }
        }

      }
    }
    .center{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      > div{
        font-size: 14px;
        line-height: 1.3;
        &.label{
          font-size: 14px;
          padding-bottom: 3px;
        }
      }
      &.sun{
        > div{
          font-size: 14px;
          &.label{
            font-size: 14px;
          }
        }
      }

    }
  }
  &.lg{
    .wrap{
      .recharts-responsive-container{
        background-image: url(../../assets/sun-moon/clock-bkg-big-ampm.svg);
      }
      &.hour24{
        .recharts-responsive-container{
          background-image: url(../../assets/sun-moon/clock-bkg-big-24hr.svg);
        }
      }
    }
  }
}

.device-widget.sunMoon{
  display: flex;
  flex-direction: column;
  .device-sun-moon-widget.exp {
    .wrap{
      .center{
        > div{
          font-size: 16px;
          &.label{
            padding-top: 5px;
            font-size: 15px;
            padding-bottom: 0px;
          }
        }
        .title{
          color: @text-dark;
          font-weight: 500;
        }
      }
      .info{
        bottom: -10px;
      }
    }
  }
}

@media (max-width: @phone-max) {
  .device-widget.sunMoon{
    height: ~"calc(100vw - 50px) !important";
    padding-bottom: 0px !important;
  }
  .device-sun-moon-widget {
    .wrap{
      margin-top: 0px;
    }
  }
}
