@import '../../styles/mixins.less';

.device-device-list-rows .device-line.v2 {
  margin-right: 20px;

  &.wxw{
    margin-bottom: 25px;
    transition: all 0.3s ease-out;
    .share-wrap,
    .connections.block .tabs{
      display: none;
    }
    &.tz-focused{
      padding-bottom: 30px;
    }
    header .info-wrap {
      .sub-head{
        position: relative;
        padding-right: 35px !important;
        .item{
          a {
            color: var(--color-text-main-lt);
            font-weight: bold;
            cursor: default;
          }
        }
        .trash {
          position: absolute;
          top: auto;
          bottom: 8px;
          right: 19px;
          height: 35px;
        }
      }
    } 
  }
  &.no-type{
    .item.station-type{
      a{
        color: @color-alert;
      }
    }
  }

  .block{
    padding: 0px;
    &.connections{
      padding-left: 0px;
      padding-right: 0px;
      flex-direction: column;
      .tabs{
        display: flex;
        > a{
          flex: 1;
          text-decoration: none;
          display: flex;
          justify-content: center;
          text-align: center;
          padding: 0px 0 10px;
          color: var(--color-text-main-lt);
          align-items: flex-end;
          border: none;
          background: url(../../assets/ambient-weather-logo.svg) center center no-repeat var(--color-bg-connections-main);
          background-size: 37px auto;
          transition: 0.2s all ease-out;
          color: transparent;
          font-size: 12px;
          height: 77px;
          font-weight: bold;
          //background-color: var();
          > span{
            max-width: 0;
            overflow: hidden;
            transition: 0.3s max-width ease-out;
            white-space: nowrap;
            position: relative;
            display: block;
          }
          &.active{
            @checkSize: 12px;
            > span{
              max-width: @checkSize;
              padding-left: @checkSize + 3px;
              &:before{
                left: 0px;
                position: absolute;
                top: 0px;
                content: '';
                width: @checkSize;
                height: @checkSize;
                display: block;
                background: url(../../assets/check.svg) center center no-repeat;
                background-size: 100%;
              }
            }
          }
          &.open,
          &:hover{
            color: var(--color-text-main-lt);
            background-color: @white;
            > span{
              max-width: 150px;
            }
          }
          &.active,
          &.open,
          &:hover{
            &.aw{
              background-position-y: 13px;
            }
            &.wu,
            &.aa,
            &.ga,
            &.pws{
              background-position-y: 14px;
            }
            &.ift{
              background-position-y: 22px;
            }
          }
          &.pws{
            background-size: 46px auto;
            background-image: url(../../assets/pws-weather.svg);
          }
          &.wu{
            background-size: 47px auto;
            background-image: url(../../assets/wundergroundlogo.svg);
          }
          &.aa{
            background-size: 30px auto;
            background-image: url(../../assets/alexa.svg);
          }
          &.ga{
            background-size: 40px auto;
            background-image: url(../../assets/google-home-logo.png);
          }
          &.ift{
            background-size: 54px auto;
            background-image: url(../../assets/ifttt.svg);
          }
        }
      }
      .content{
        max-height: 0;
        overflow: hidden;
        box-sizing: border-box;
        &.open{
          max-height: none;
          padding: @bigPad;
        }
        h1{
          font-weight: 300;
          border-bottom: 1px solid @gray-light-amb;
          padding-bottom: 5px;
          margin-bottom: 15px;
          margin-top: 0px;
        }
        h1{
          border: none;
          color: @blue;
          font-size: 2.7rem;
        }
        .intro{
          padding-top: 50px;
          background: url(../../assets/handdrawn-arrow.svg) top center no-repeat;
          background-size: auto 78px; 
          .btns{
            text-align: right;
            .close-intro{
              text-decoration: none;
              display: inline-block;
              padding-right: 20px;
              color: var(--color-text-main-lt);
            }
          }
        }
        p{
          font-size: 1.45rem;
          line-height: 1.6;
          margin-bottom: 20px;
        }
        .location{
          margin-top: @bigPad;
        }
        .component-location{
          .top{
            justify-content: flex-end;
            h2{
              display: none;
            }
          }
          p{
            margin-bottom: 10px;
          }
        }
        .device-device-edit-form{
          h2,
          .name{
            display: none;
          }
        }
      }
    }
  }
  &.rw header .item.station-type a,
  &.rw header .details > a,
  &.kestrel header .item.station-type a,
  &.kestrel header .details > a,
  &.wxw header .item.station-type a,
  &.wxw header .details > a{
    // cursor: default;
  }
  &.wxw header .details > a {
    background-image: url(../../assets/device-types/wxw.png);
    background-position: center center;
  }
  &.kestrel header .details > a {
    background-image: url(../../assets/device-types/kestrel.png);
    background-size: auto 95%;
    background-position: center bottom -2px;
  }
  &.rw header .details > a {
    background-image: url(../../assets/device-types/rw.png);
    background-size: auto 100%;
    background-position: center bottom -10px;
  }
  &.WS-1965 header .details > a {
    background-image: url(../../assets/device-types/WS-1965.png);
    background-size: 90% auto;
    background-position: center bottom 0px;
  }
  &.WS-1938 header .details > a {
    background-image: url(../../assets/device-types/WS-1938.png);
    background-size: 100% auto;
    background-position: center center;
  }
  &.WEATHERBRIDGE header .details > a {
    background-image: url(../../assets/device-types/WEATHERBRIDGE.png);
    background-size: 95% auto;
    background-position: center center;
  }
  &.WEATHERBRIDGEPRO2 header .details > a {
    background-image: url(../../assets/device-types/WEATHERBRIDGEPRO2.png);
    background-size: 95% auto;
    background-position: center center;
  }
  &.OBSERVERIP2 header .details > a {
    background-image: url(../../assets/device-types/OBSERVERIP2.png);
    background-size: 65% auto;
    background-position: center center;
  }
  &.WS-2000-AW header .details > a {
    background-image: url(../../assets/device-types/WS-2000-AW.png);
    background-size: 77% auto;
    background-position: center bottom 0px;
  }
  &.WS-2902-AW header .details > a {
    background-image: url(../../assets/device-types/WS-2902-AW.png);
    background-size: 77% auto;
    background-position: center bottom 0px;
  }
  &.WS-4000 header .details > a {
    background-image: url(../../assets/device-types/WS-4000.png);
    background-size: 63% auto;
    background-position: center center;
  }
  &.WS-5000-AW header .details > a {
    background-image: url(../../assets/device-types/WS-5000-AW.png);
    background-size: 85% auto;
    background-position: center center;
  }
  &.WS-1900A header .details > a,
  &.WS-2000 header .details > a,
  &.WS-2902 header .details > a {
    background-image: url(../../assets/device-types/WS-2902.png);
    background-size: auto 105%;
    background-position: center bottom 0px;
  }
  &.no-type header .details > a,
  &.legacy header .details > a {
    background-image: url(../../assets/device-types/missing.svg);
    background-position-y: 13px;
    background-position-x: center;
    background-size: 90% auto;
    position: relative;
  }
  &.no-type header .details > a {
    &::before{
      content: 'Configure Device Type';
      position: absolute;
      text-align: center;
      text-decoration: none;
      margin: 0px auto;
      left: 50%;
      padding: 2px 7px;;
      margin-left: -68px;
      font-size: 12px;
      top: 67px;
      .btn-primary;
    }
  }
  header{
    padding: 0px 0px 0px 0px;
    display: flex;
    position: relative;
    input.type-info-name{
      font-size: 2.1em;
      width: 100%;
      color: var(--color-text-page-block-main);
    }
    .sub-head{
      color: var(--color-text-main-lt);
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-bottom: 15px;
      .type-info-location,
      .tz-select__single-value,
      .css-xp4uvy{
        color: @blue !important;
        font-weight: normal;
        cursor: pointer;
        // color: var(--color-text-main-lt) !important;
      }
      .css-11unzgr{
        max-height: 100px;
      }
    }
    .details{
      display: flex;
      // .general-shadow;
      > a{
        width: 200px;
        background: url(../../assets/device-types/WS-5000.png) center bottom -5px no-repeat;
        background-size: auto 100%;
        flex-shrink: 0;
      }
      > div {
        padding: 15px 0px 0px 20px;
        flex-grow: 1;
      }
    }
    .device-create-webcam{
      padding: 0px 20px 10px;
    }
    .info-wrap{
      width: 100%;
      .item{
        a{
          text-decoration: none;
          font-weight: normal;
        }
        .cellLink > a{
          text-decoration: underline;
          color: rgb(64, 109, 181);
        }
      }
      .trash{
        // float: right;
        padding: 5px 10px 10px;
      }
    }
    .share-wrap{
      width: 0px;
      display: flex;
      align-items: flex-start;
      position: relative;
      margin-top: 45px;
      margin-left: -18px;
      &:hover{
        .btn-green:before{
          content: 'SHARE';
          position: absolute;
          top: 11px;
          left: -55px;
          color: @green-dark;
        }
      }
      .btn-green{
        border:none;
        padding: 9px 12px;
        margin-left: -5px;
        position: relative;
        .glyphicon{
          font-size: 23px;
        }
      }
    }
    .last-updated{
      position: absolute;
      right: 30px;
      top: 20px;
      color: var(--color-text-main-lt);
      font-size: 13px;
      background-color: var(--color-bg-page-block-main);
      border-radius: 2px;
      
    }
  }
  .service{
    header{
      display: none;
    }
    .bottom{
      .message{
        margin-bottom: 10px;
        color: @pink;
      }
      .form{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        > span{
          font-size: 20px;
          font-weight: 300;
          display: block;
          padding-right: 10px;
          &:after{
            content: ':'
          }
          a{
            display: none;
          }
        }
        .form-control{
          margin-right: 10px;
        }
      }
    }
    .instructions{
      display: inline-block;
      padding-left: 5px;
    }
    .desc{
      display: block;
    }
  }
  .share-tab{
    position: relative;
    .close{
      .close-x-blue;
      position: absolute;
      right: 20px;
    }
    .glyphicon{
      font-size: 23px;
      border-radius: 100%;
      padding: 10px;
    }
  }

  .device-share-device-modal{
    p{
      margin-left: 0px;
      &.first{
        display: none;
      }
    }
    label{
      padding-left: 0px;
    }
  }
}
@media (max-width: @tablet-phone-max) {
  .device-device-list-rows .device-line.v2 {
    .block{
      &.connections{
        .tabs{
          flex-wrap: wrap;
          > a{
            width: 33.3333%;
            flex: none;
          }
        }
      }
    }
  }
}

@media (max-width: @phone-max) {
  .device-device-list-rows .device-line.v2 {
    header{
      padding-top: 40px;
      .last-updated{
        top: 15px;
        right: 20px;
      }
      .share-wrap{
        &:hover:before{
          content: '';
        }
      }
      .details{
        flex-direction: column;
        .type{
          height: 120px;
          margin: 0 auto;
        }
      }
    }
    .block{
      &.connections{
        margin-right: 10px;
        margin-left: 10px;
        .tabs{
          > a{
            > span{
              max-width: 0 !important;
            }
            &.open,
            &:hover{
              &:not(.active) {
                background-position-y: center !important;
              }
            }
            &:hover:not(.open){
              background-color: #f7f7f7;
            }
          }
        }
        .content{
          .device-share-device-modal{
            margin-bottom: 15px;
          }
        }
      }
    }
    .service{
      .bottom{
        .form{
          flex-direction: column;
          > span{
            margin-bottom: 10px;
          }
        }
      }
      .register{
        > a {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          display: block;
        }
      }
      .instructions{
        padding-left: 0px;
        margin-top: 10px;
      }
    }
  }
}