@import '../../styles/mixins.less';

.device-share-device-modal {
    .component-amb-radio{
      display: inline-block;
      position: relative;
      top: 3px;
    }
    label{
      font-weight: normal;
      display: block;
      text-transform: none;
      font-size: 14px;
      padding-left: 15px;
      padding-top: 30px;
    }
    input[type="text"],
    input[type="text"][readonly],
    textarea{
      background-color: @white;
      width: 100%;
    }
    textarea{
      padding-top: 10px;
      height: 100px;
    }
    h3{
      margin: 20px 0 10px;
      font-size: 18px;
    }
    .a-little-bigger{
      font-size: 16px;
    }
    p{
      margin-left: 15px;
      margin-right: 15px;
    }
    .line{
      display: flex;
      .btn{
        margin-left: 10px;
      }
      .same{
        flex: 1;
        margin-right: 10px;
        &:last-of-type{
          margin-right: 0px;
          margin-left: 10px;
        }
        label{
          padding-top: 15px;
        }
      }
    }
    .btn{
      &.w100{
        margin-top: 20px;
        width: 100%;
        margin-bottom: 30px;
      }
    }

}
