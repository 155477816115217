@import '../styles/mixins.less';

.component-help-links {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  font-size: 1.1em;
  position: fixed;
  bottom: 100px;
  width: 100%;
  left: 0;
  a{
    margin-right: 25px;
    font-weight: bold;
    text-decoration: none;
    &:last-of-type{
      margin-right: 0;
    }
  }
}

@media(max-width: @phone-max) {
  .component-help-links{
    bottom: 55px;
  }
}