@import '../../styles/mixins';

.device-wxw-preview {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  aspect-ratio: 1280 / 800;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  pre{
    background-color: rgba(255,255,255, 0.9);
    bottom: 0;
    position: absolute;
    padding: 15px;
  }
}
